import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type FormattedNumber = {
  __typename?: 'FormattedNumber';
  value: Scalars['Float'];
  displayValue: Scalars['String'];
};

export type Label = {
  __typename?: 'Label';
  pageName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type LinkAttribute = {
  __typename?: 'LinkAttribute';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type Link = {
  __typename?: 'Link';
  text: Scalars['String'];
  attributes: Array<LinkAttribute>;
  url: Scalars['String'];
};

export type Price = {
  __typename?: 'Price';
  priceBefore: FormattedNumber;
  priceAfter: FormattedNumber;
  discountPercentage: FormattedNumber;
  discountAmount: FormattedNumber;
};

export type HtmlTagAttribute = {
  __typename?: 'HtmlTagAttribute';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type HtmlTag = {
  __typename?: 'HtmlTag';
  name: Scalars['String'];
  innerHtml: Scalars['String'];
  attributes: Array<HtmlTagAttribute>;
};

export type Sides = {
  __typename?: 'Sides';
  bottom?: Maybe<Scalars['String']>;
  left?: Maybe<Scalars['String']>;
  right?: Maybe<Scalars['String']>;
  top?: Maybe<Scalars['String']>;
};

export type ButtonPicker = {
  __typename?: 'ButtonPicker';
  icon?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Alignment = {
  __typename?: 'Alignment';
  desktop?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
};

export type Padding = {
  __typename?: 'Padding';
  desktop?: Maybe<Sides>;
  mobile?: Maybe<Sides>;
};

export type Device = {
  __typename?: 'Device';
  originalImageUrl?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
};

export type Image = {
  __typename?: 'Image';
  desktop?: Maybe<Device>;
  mobile?: Maybe<Device>;
  tablet?: Maybe<Device>;
  alt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type WidgetLink = {
  __typename?: 'WidgetLink';
  URL?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
};

export type EmbeddedUrl = {
  __typename?: 'EmbeddedUrl';
  URL?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
};

export type EmbeddedListItem = {
  __typename?: 'EmbeddedListItem';
  position?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['String']>;
};

export type EmbeddedList = {
  __typename?: 'EmbeddedList';
  listItems?: Maybe<Array<EmbeddedListItem>>;
  listType?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
};

export type TextEditor = {
  __typename?: 'TextEditor';
  embeddedLists?: Maybe<Array<EmbeddedList>>;
  alignment?: Maybe<Alignment>;
  colour?: Maybe<Alignment>;
  isBold?: Maybe<Scalars['Boolean']>;
  isItalic?: Maybe<Scalars['Boolean']>;
  isStrikethrough?: Maybe<Scalars['Boolean']>;
  isUnderline?: Maybe<Scalars['Boolean']>;
  padding?: Maybe<Padding>;
  text?: Maybe<Scalars['String']>;
  textSize?: Maybe<Scalars['String']>;
  embeddedUrls?: Maybe<Array<EmbeddedUrl>>;
};

export type HeroBanner = {
  __typename?: 'HeroBanner';
  curveOverlayColour?: Maybe<Scalars['String']>;
  blockOverlayColour?: Maybe<Scalars['String']>;
  button?: Maybe<ButtonPicker>;
  desktopContentPosition?: Maybe<Scalars['String']>;
  desktopContentStyle?: Maybe<Scalars['String']>;
  desktopContentWidth?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
  link?: Maybe<WidgetLink>;
  mobileContentStyle?: Maybe<Scalars['String']>;
  mobileOverlayColour?: Maybe<Scalars['String']>;
  slashOverlayColour?: Maybe<Scalars['String']>;
  text?: Maybe<Array<TextEditor>>;
  textAlignment?: Maybe<Alignment>;
  buttonHorizontalAlignment?: Maybe<Alignment>;
  buttonVerticalAlignment?: Maybe<Alignment>;
};

export type AllCategoriesWidgetItem = {
  __typename?: 'AllCategoriesWidgetItem';
  id: Scalars['Float'];
  url: Link;
  noOfProducts: Scalars['Float'];
  childCategories: Array<AllCategoriesWidgetItem>;
};

export type PopularCategoriesWidgetItem = {
  __typename?: 'PopularCategoriesWidgetItem';
  id: Scalars['Float'];
  url: Link;
  noOfProducts: Scalars['Float'];
  imageUrl: Scalars['String'];
};

export type ContentLayout = {
  __typename?: 'ContentLayout';
  widgets: Array<CampaignPageWidgets>;
  backgroundColour?: Maybe<Scalars['String']>;
  backgroundImageUrl?: Maybe<Scalars['String']>;
  borderSize?: Maybe<Sides>;
  desktopContentAlignment?: Maybe<Scalars['String']>;
  desktopWidth?: Maybe<Scalars['String']>;
  enableBorderRadius?: Maybe<Scalars['Boolean']>;
  enableShadowOnHover?: Maybe<Scalars['Boolean']>;
  mobileContentAlignment?: Maybe<Scalars['String']>;
  mobileWidth?: Maybe<Scalars['String']>;
  padding?: Maybe<Padding>;
  tabletContentAlignment?: Maybe<Scalars['String']>;
  tabletWidth?: Maybe<Scalars['String']>;
  borderColour?: Maybe<Scalars['String']>;
};

export type CampaignPageWidgets = HeroBannerWidget | ImageTextBoxWidget | VideoDataWidget | TextDataWidget | ButtonDataWidget | ImageDataWidget | EmailFieldWidget | PopularCategoriesWidget | AllCategoriesWidget | PreviouslyViewedProducts | InPageAnchorWidget;

export type HeroBannerWidget = {
  __typename?: 'HeroBannerWidget';
  banners: Array<HeroBanner>;
};

export type ImageTextBoxWidget = {
  __typename?: 'ImageTextBoxWidget';
  imageMobileWidth?: Maybe<Scalars['String']>;
  addPadding?: Maybe<Scalars['Boolean']>;
  backgroundColour?: Maybe<Scalars['String']>;
  button?: Maybe<ButtonPicker>;
  buttonVerticalAlignment?: Maybe<Alignment>;
  buttonHorizontalAlignment?: Maybe<Alignment>;
  image?: Maybe<Image>;
  imageDesktopPosition?: Maybe<Scalars['String']>;
  imageDesktopWidth?: Maybe<Scalars['String']>;
  imageMobilePosition?: Maybe<Scalars['String']>;
  link?: Maybe<WidgetLink>;
  opacity?: Maybe<Scalars['String']>;
  text?: Maybe<Array<TextEditor>>;
  textAlignment?: Maybe<Alignment>;
};

export type VideoDataWidget = {
  __typename?: 'VideoDataWidget';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  videoHorizontalAlignment?: Maybe<Alignment>;
  videoUrl?: Maybe<Scalars['String']>;
  videoVerticalAlignment?: Maybe<Alignment>;
  width?: Maybe<Scalars['String']>;
};

export type TextDataWidget = {
  __typename?: 'TextDataWidget';
  text?: Maybe<Array<TextEditor>>;
  textAlignment?: Maybe<Alignment>;
};

export type ButtonDataWidget = {
  __typename?: 'ButtonDataWidget';
  button?: Maybe<ButtonPicker>;
  buttonHorizontalAlignment?: Maybe<Alignment>;
  buttonVerticalAlignment?: Maybe<Alignment>;
  link?: Maybe<WidgetLink>;
};

export type ImageDataWidget = {
  __typename?: 'ImageDataWidget';
  image?: Maybe<Image>;
  imageHorizontalAlignment?: Maybe<Alignment>;
  imageVerticalAlignment?: Maybe<Alignment>;
  link?: Maybe<WidgetLink>;
};

export type EmailFieldWidget = {
  __typename?: 'EmailFieldWidget';
  isEmailEnabled?: Maybe<Scalars['Boolean']>;
  isEmailMandatory?: Maybe<Scalars['Boolean']>;
  isJobTitleEnabled?: Maybe<Scalars['Boolean']>;
  isJobTitleMandatory?: Maybe<Scalars['Boolean']>;
  isNameEnabled?: Maybe<Scalars['Boolean']>;
  isNameMandatory?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
};

export type PopularCategoriesWidget = {
  __typename?: 'PopularCategoriesWidget';
  title: TextEditor;
  categories: Array<PopularCategoriesWidgetItem>;
};

export type AllCategoriesWidget = {
  __typename?: 'AllCategoriesWidget';
  title: TextEditor;
  categories: Array<AllCategoriesWidgetItem>;
};

export type PreviouslyViewedProducts = {
  __typename?: 'PreviouslyViewedProducts';
  id: Scalars['String'];
};

export type InPageAnchorWidget = {
  __typename?: 'InPageAnchorWidget';
  anchorId: Scalars['String'];
};

export type CampaignPageTaggingGtm = {
  __typename?: 'CampaignPageTaggingGTM';
  dataLayer: HtmlTag;
};

export type CampaignPageTagging = {
  __typename?: 'CampaignPageTagging';
  gtm: CampaignPageTaggingGtm;
  tags: Array<HtmlTag>;
};

export type CampaignPageMeta = {
  __typename?: 'CampaignPageMeta';
  metaTags: Array<HtmlTag>;
  links?: Maybe<Array<HtmlTag>>;
  pageTitle: Scalars['String'];
  canonicalUrl: Scalars['String'];
};

export type CampaignPage = {
  __typename?: 'CampaignPage';
  labels: Array<Label>;
  layouts: Array<SectionContentLayoutDetails>;
  metaData: CampaignPageMeta;
  tagging: CampaignPageTagging;
  backgroundColour?: Maybe<Scalars['String']>;
};

export type SectionContentLayoutDetails = ContentLayout | SectionLayout;

export type SectionLayout = {
  __typename?: 'SectionLayout';
  backgroundColour?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['String']>;
  contentLayouts: Array<ContentLayout>;
  padding?: Maybe<Padding>;
  widthType?: Maybe<Scalars['String']>;
  margin?: Maybe<Padding>;
  name?: Maybe<Scalars['String']>;
  topBorderStyle?: Maybe<Scalars['String']>;
  bottomBorderStyle?: Maybe<Scalars['String']>;
};

export type StockAvailability = {
  __typename?: 'StockAvailability';
  quantityInStock?: Maybe<Scalars['Float']>;
  availabilityDate?: Maybe<Scalars['DateTime']>;
};


export type ProductEnergyLabel = {
  __typename?: 'ProductEnergyLabel';
  rating: Scalars['String'];
  imgUrl?: Maybe<Scalars['String']>;
  isShowingTwoImagesInOne?: Maybe<Scalars['Boolean']>;
};

export type ProductEnergy = {
  __typename?: 'ProductEnergy';
  energyLabel: ProductEnergyLabel;
};

export type CheckoutMeta = {
  __typename?: 'CheckoutMeta';
  metaTags?: Maybe<Array<HtmlTag>>;
  pageTitle: Scalars['String'];
  canonicalUrl: Scalars['String'];
  links?: Maybe<Array<HtmlTag>>;
};

export type CountryRedirectDetails = {
  __typename?: 'CountryRedirectDetails';
  title: Scalars['String'];
  message: Scalars['String'];
  url: Scalars['String'];
  cta: Scalars['String'];
  transferBasket: Scalars['Boolean'];
  isGroupCountry?: Maybe<Scalars['Boolean']>;
  isUSCountry?: Maybe<Scalars['Boolean']>;
};

export type Country = {
  __typename?: 'Country';
  code: Scalars['String'];
  name: Scalars['String'];
  redirection?: Maybe<CountryRedirectDetails>;
};

export type DeliveryCountries = {
  __typename?: 'DeliveryCountries';
  options: Array<Country>;
};

export type ProductType = {
  __typename?: 'ProductType';
  isVisible: Scalars['Boolean'];
  title?: Maybe<Scalars['String']>;
  modalCTA?: Maybe<Scalars['String']>;
  modalTitle?: Maybe<Scalars['String']>;
  modalMessage?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  secondaryMessages?: Maybe<Array<ProductType>>;
  mode?: Maybe<ProductTypeMode>;
};

export enum ProductTypeMode {
  Warning = 'WARNING',
  Sustainable = 'SUSTAINABLE'
}

export type ProductionPackaging = {
  __typename?: 'ProductionPackaging';
  isVisible: Scalars['Boolean'];
  isEnabled: Scalars['Boolean'];
  packageType?: Maybe<Scalars['String']>;
  unitOfMeasureCode?: Maybe<Scalars['String']>;
  unitOfMeasure?: Maybe<Scalars['String']>;
  standardUnitOfMeasure?: Maybe<Scalars['String']>;
  standardUnitOfMeasureCode?: Maybe<Scalars['String']>;
  standardMinimumOrderQuantity?: Maybe<Scalars['Float']>;
  standardStockCode: Scalars['String'];
  ppkStockCode: Scalars['String'];
  ppkSSM?: Maybe<Scalars['Float']>;
  minimumOrderQuantity?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type ProductStock = {
  __typename?: 'ProductStock';
  statusCode: StockStatus;
  title: Scalars['String'];
  stockMessage: Scalars['String'];
  dtpStockMessages?: Maybe<Array<Scalars['String']>>;
  infoMessage: Scalars['String'];
  isUsingDTP: Scalars['Boolean'];
  denyBackOrder?: Maybe<Scalars['Boolean']>;
  availabilityDate?: Maybe<Scalars['DateTime']>;
  stockAvailability?: Maybe<Array<StockAvailability>>;
  includesDate: Scalars['Boolean'];
  stockBadgeIcon?: Maybe<Scalars['String']>;
};

export enum StockStatus {
  Backordered = 'BACKORDERED',
  Discontinued = 'DISCONTINUED',
  Instock = 'INSTOCK',
  StatusUnavailable = 'STATUS_UNAVAILABLE',
  CurrentlyUnavailable = 'CURRENTLY_UNAVAILABLE',
  CurrentlyUnavailableBeingDiscontinued = 'CURRENTLY_UNAVAILABLE_BEING_DISCONTINUED',
  CurrentlyUnavailableBeingRemoved = 'CURRENTLY_UNAVAILABLE_BEING_REMOVED',
  CurrentlyUnavailableSupplyShortage = 'CURRENTLY_UNAVAILABLE_SUPPLY_SHORTAGE',
  Error = 'ERROR',
  InStockSupplyShortage = 'IN_STOCK_SUPPLY_SHORTAGE',
  LastStockBeingDiscontinued = 'LAST_STOCK_BEING_DISCONTINUED',
  LastStockBeingRemoved = 'LAST_STOCK_BEING_REMOVED',
  LimitedStock = 'LIMITED_STOCK',
  OutOfStock = 'OUT_OF_STOCK',
  OutOfStockSupplyShortage = 'OUT_OF_STOCK_SUPPLY_SHORTAGE',
  Removed = 'REMOVED',
  StockedByManufacturer = 'STOCKED_BY_MANUFACTURER',
  Preorder = 'PREORDER'
}

export type PriceTax = {
  __typename?: 'PriceTax';
  taxType: Scalars['String'];
  taxAmountDisplayValue: Scalars['String'];
};

export type ProductPriceArea = {
  __typename?: 'ProductPriceArea';
  minimumOrderQuantity: Scalars['Float'];
  totalPrice: Price;
  unitPrice: Price;
  container: Scalars['String'];
  taxType: Array<Scalars['String']>;
  taxes: Array<PriceTax>;
  errorMessage: Scalars['String'];
  unitOfMeasureCode: Scalars['String'];
  unitOfMeasure: Scalars['String'];
};

export type ProductTitle = {
  __typename?: 'ProductTitle';
  title: Scalars['String'];
  brand: Scalars['String'];
  formattedStockCode: Scalars['String'];
  hsnCode: Scalars['String'];
  impaCode?: Maybe<Scalars['String']>;
  mpn: Scalars['String'];
  stockCode: Scalars['String'];
  primaryImageUrl: Scalars['String'];
  pdpUrl: Scalars['String'];
  partNumber: Scalars['String'];
};

export type Product = {
  __typename?: 'Product';
  title: ProductTitle;
  priceArea: ProductPriceArea;
  stockArea: ProductStock;
  productTypeArea: ProductType;
  ppkArea: ProductionPackaging;
  energyArea?: Maybe<ProductEnergy>;
};

export type ParcelItem = {
  __typename?: 'ParcelItem';
  id: Scalars['Float'];
  product: Product;
  quantity: Scalars['Float'];
};

export type Parcel = {
  __typename?: 'Parcel';
  title: Scalars['String'];
  items: Array<ParcelItem>;
  description?: Maybe<Scalars['String']>;
};

export type Item = {
  __typename?: 'Item';
  label: Scalars['String'];
  description: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  price: FormattedNumber;
};

export type Promotion = {
  __typename?: 'Promotion';
  isValid: Scalars['Boolean'];
  description: Scalars['String'];
  code: Scalars['String'];
  errorMessage?: Maybe<Scalars['String']>;
  savings: FormattedNumber;
};

export type Summary = {
  __typename?: 'Summary';
  netTotal: Item;
  netTotalExcPromotion?: Maybe<FormattedNumber>;
  deliveryCharge: Item;
  deliveryOptionType?: Maybe<DeliveryOptionType>;
  surcharges: Array<Item>;
  grandTotal: Item;
  deliveryCountry?: Maybe<Country>;
  invoiceCountry?: Maybe<Country>;
  endDeliveryCountry?: Maybe<Country>;
  numberOfItems?: Maybe<Scalars['Float']>;
  promotion?: Maybe<Promotion>;
};

export enum DeliveryOptionType {
  Delivery = 'delivery',
  Collection = 'collection',
  Charge = 'charge',
  Quote = 'quote'
}

export type BasketService = {
  __typename?: 'BasketService';
  id: Scalars['Float'];
  name: Scalars['String'];
  description: Scalars['String'];
  imageUrl: Scalars['String'];
  isSelected: Scalars['Boolean'];
  price: FormattedNumber;
  optionType?: Maybe<BasketServiceType>;
};

export enum BasketServiceType {
  CharitySurcharge = 'charity_surcharge'
}

export type BasketServices = {
  __typename?: 'BasketServices';
  options: Array<BasketService>;
};

export type BasketPageConfig = {
  __typename?: 'BasketPageConfig';
  allowPartNumbers: Scalars['Boolean'];
  isCustomerLoggedIn: Scalars['Boolean'];
  allowPromoCode: Scalars['Boolean'];
  topicIdentifier: Scalars['String'];
  enablePrintQuote: Scalars['Boolean'];
  allowDeliveryOptionSelection: Scalars['Boolean'];
  hasNewDeliveryOptions: Scalars['Boolean'];
  hasDeliveryOption: Scalars['Boolean'];
  hasDualUseProducts: Scalars['Boolean'];
  isStockCheckUnavailable: Scalars['Boolean'];
  paymentMethods: Array<Scalars['String']>;
  showShareBasketNewBadge: Scalars['Boolean'];
  showShareBasketButton: Scalars['Boolean'];
};

export type DeliveryOption = {
  __typename?: 'DeliveryOption';
  id: Scalars['Float'];
  name: Scalars['String'];
  description: Scalars['String'];
  isSelected: Scalars['Boolean'];
  price: FormattedNumber;
  optionType?: Maybe<DeliveryOptionType>;
  properties?: Maybe<DeliveryOptionProperties>;
};

export type DeliveryOptionProperties = DeliveryOptionCollectionProperties;

export type DeliveryOptionCollectionProperties = {
  __typename?: 'DeliveryOptionCollectionProperties';
  requiresCourierAccountNumber: Scalars['Boolean'];
  courierAccountNumber?: Maybe<Scalars['String']>;
};

export type DeliveryOptions = {
  __typename?: 'DeliveryOptions';
  options: Array<DeliveryOption>;
};

export type QuoteHeader = {
  __typename?: 'QuoteHeader';
  quoteReference: Scalars['String'];
  accountNumber: Scalars['String'];
  validStartDate: Scalars['String'];
  validEndDate: Scalars['String'];
  siteName: Scalars['String'];
  siteAddress: Scalars['String'];
};

export type QuoteContactDetails = {
  __typename?: 'QuoteContactDetails';
  email: Scalars['String'];
  phone: Scalars['String'];
  fax: Scalars['String'];
};

export type QuoteDetails = {
  __typename?: 'QuoteDetails';
  header: QuoteHeader;
  contactDetails: QuoteContactDetails;
  labels: Array<Label>;
};

export type Ga4Item = {
  __typename?: 'GA4Item';
  item_id: Scalars['String'];
  item_name: Scalars['String'];
  currency: Scalars['String'];
  discount: Scalars['Float'];
  index: Scalars['Float'];
  item_brand: Scalars['String'];
  item_category: Scalars['String'];
  item_category2: Scalars['String'];
  item_category3: Scalars['String'];
  item_category4: Scalars['String'];
  price: Scalars['Float'];
  quantity: Scalars['Float'];
  product_url: Scalars['String'];
  image_url: Scalars['String'];
};

export type Ga4Event_ViewCart_Ecommerce = {
  __typename?: 'GA4Event_ViewCart_Ecommerce';
  currency: Scalars['String'];
  value: Scalars['Float'];
  amount: Scalars['Float'];
  total_prqt: Scalars['Float'];
  items: Array<Ga4Item>;
  email?: Maybe<Scalars['String']>;
};

export type BasketPageTaggingGtm = {
  __typename?: 'BasketPageTaggingGTM';
  dataLayer: HtmlTag;
};

export type BasketPageTagging = {
  __typename?: 'BasketPageTagging';
  gtm: BasketPageTaggingGtm;
  tags: Array<HtmlTag>;
  ga4: Array<G4BasketEvent>;
};

export type G4BasketEvent = Ga4Event_ViewCart;

export type Ga4Event_ViewCart = {
  __typename?: 'GA4Event_ViewCart';
  event: Scalars['String'];
  ecommerce: Ga4Event_ViewCart_Ecommerce;
};

export type BasketPage = {
  __typename?: 'BasketPage';
  numberOfItems: Scalars['Float'];
  isValidState: Scalars['Boolean'];
  labels: Array<Label>;
  totalWeight?: Maybe<Scalars['Float']>;
  metaData: CheckoutMeta;
  parcels: Array<Parcel>;
  summary: Summary;
  quoteDetails: QuoteDetails;
  deliveryOptions: DeliveryOptions;
  basketServices: BasketServices;
  deliveryCountries: DeliveryCountries;
  config: BasketPageConfig;
  tagging: BasketPageTagging;
};


export type BasketPageSummaryArgs = {
  data?: Maybe<GetSummaryRequest>;
};


export type BasketPageDeliveryOptionsArgs = {
  countryCode?: Maybe<Scalars['String']>;
};


export type BasketPageBasketServicesArgs = {
  countryCode?: Maybe<Scalars['String']>;
};

export type GetSummaryRequest = {
  to?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
};

export type AddToBasketEnsighten = {
  __typename?: 'AddToBasketEnsighten';
  orderQuantity: Scalars['Float'];
  productId: Scalars['String'];
  partNumber?: Maybe<Scalars['String']>;
};

export type AddToBasketGoogleAnalytics = {
  __typename?: 'AddToBasketGoogleAnalytics';
  id: Scalars['String'];
  name: Scalars['String'];
  category: Scalars['Float'];
  brand: Scalars['String'];
  quantity: Scalars['Float'];
  price: Scalars['Float'];
};

export type AddToBasketTealium = {
  __typename?: 'AddToBasketTealium';
  orderQuantity: Scalars['Float'];
  productId: Scalars['String'];
};

export type Ga4Event_AddToCart_Ecommerce = {
  __typename?: 'GA4Event_AddToCart_Ecommerce';
  items: Array<Ga4Item>;
};

export type BulkAddToBasket_ResultDetailsLine = {
  __typename?: 'BulkAddToBasket_ResultDetailsLine';
  stockCode: Scalars['String'];
  quantity: Scalars['Float'];
  link?: Maybe<Link>;
  message: Scalars['String'];
};

export type BulkAddToBasket_ResultDetailsSection = {
  __typename?: 'BulkAddToBasket_ResultDetailsSection';
  message: Scalars['String'];
  lineInfo: Array<BulkAddToBasket_ResultDetailsLine>;
};

export type BulkAddToBasket_ResultDetails = {
  __typename?: 'BulkAddToBasket_ResultDetails';
  isSuccess: Scalars['Boolean'];
  title: Scalars['String'];
  message: Scalars['String'];
  productsNotFoundSection?: Maybe<BulkAddToBasket_ResultDetailsSection>;
  discontinuedProductsSection?: Maybe<BulkAddToBasket_ResultDetailsSection>;
};

export type BulkAddToBasketResponse = {
  __typename?: 'BulkAddToBasketResponse';
  isSuccess: Scalars['Boolean'];
  errorMessage: Scalars['String'];
  infoMessage: Scalars['String'];
  result: BulkAddToBasket_ResultDetails;
  tags: Array<BulkAddToBasketResponseTags>;
};

export type BulkAddToBasketResponseTags = BulkAddToBasketEnsighten | BulkAddToBasketGoogleAnalytics | BulkAddToBasketTealium | Ga4Event_AddToCart;

export type BulkAddToBasketEnsighten = {
  __typename?: 'BulkAddToBasketEnsighten';
  products: Array<AddToBasketEnsighten>;
};

export type BulkAddToBasketGoogleAnalytics = {
  __typename?: 'BulkAddToBasketGoogleAnalytics';
  products: Array<AddToBasketGoogleAnalytics>;
};

export type BulkAddToBasketTealium = {
  __typename?: 'BulkAddToBasketTealium';
  products: Array<AddToBasketTealium>;
};

export type Ga4Event_AddToCart = {
  __typename?: 'GA4Event_AddToCart';
  event: Scalars['String'];
  ecommerce: Ga4Event_AddToCart_Ecommerce;
};

export type BulkAddToBasketStatusMessage = {
  __typename?: 'BulkAddToBasketStatusMessage';
  message?: Maybe<Scalars['String']>;
  isError?: Maybe<Scalars['Boolean']>;
  isProgress?: Maybe<Scalars['Boolean']>;
  requestId?: Maybe<Scalars['String']>;
  isFinal: Scalars['Boolean'];
};

export type RemoveFromBasketEnsighten = {
  __typename?: 'RemoveFromBasketEnsighten';
  productId: Scalars['String'];
  orderQuantity: Scalars['Float'];
};

export type RemoveFromBasketGoogleAnalytics = {
  __typename?: 'RemoveFromBasketGoogleAnalytics';
  id: Scalars['String'];
  name: Scalars['String'];
  category: Scalars['Float'];
  brand: Scalars['String'];
  quantity: Scalars['Float'];
};

export type RemoveFromBasketTealium = {
  __typename?: 'RemoveFromBasketTealium';
  productId: Scalars['String'];
};

export type UpdateBasketLinesEnsighten_BasketLine = {
  __typename?: 'UpdateBasketLinesEnsighten_BasketLine';
  articleId: Scalars['String'];
  oldQuantity?: Maybe<Scalars['Float']>;
  newQuantity?: Maybe<Scalars['Float']>;
  oldPrice?: Maybe<Scalars['String']>;
  newPrice?: Maybe<Scalars['String']>;
};

export type UpdateBasketLinesGoogleAnalytics_BasketLine = {
  __typename?: 'UpdateBasketLinesGoogleAnalytics_BasketLine';
  articleId: Scalars['String'];
  oldQuantity?: Maybe<Scalars['Float']>;
  newQuantity?: Maybe<Scalars['Float']>;
  oldPrice?: Maybe<Scalars['String']>;
  newPrice?: Maybe<Scalars['String']>;
};

export type UpdateBasketLinesTealium_BasketLine = {
  __typename?: 'UpdateBasketLinesTealium_BasketLine';
  articleId: Scalars['String'];
  oldQuantity?: Maybe<Scalars['Float']>;
  newQuantity?: Maybe<Scalars['Float']>;
  oldPrice?: Maybe<Scalars['String']>;
  newPrice?: Maybe<Scalars['String']>;
};

export type Ga4Event_RemoveFromCart_Ecommerce = {
  __typename?: 'GA4Event_RemoveFromCart_Ecommerce';
  currency: Scalars['String'];
  value: Scalars['Float'];
  items: Array<Ga4Item>;
};

export type Ga4Event_SelectPromotion_Ecommerce = {
  __typename?: 'GA4Event_SelectPromotion_Ecommerce';
  promotion_id: Scalars['String'];
  promotion_name: Scalars['String'];
  items: Array<Ga4Item>;
};

export type Ga4Event_UpdateBasketLines_Ecommerce = {
  __typename?: 'GA4Event_UpdateBasketLines_Ecommerce';
  currency: Scalars['String'];
  value: Scalars['Float'];
  items: Array<Ga4Item>;
  total_prqt: Scalars['Float'];
  email?: Maybe<Scalars['String']>;
};

export type SetPromoCodeResponse = {
  __typename?: 'SetPromoCodeResponse';
  isSuccess: Scalars['Boolean'];
  errorCode?: Maybe<Scalars['String']>;
  tags: Array<SetPromoCodeResponseTags>;
};

export type SetPromoCodeResponseTags = SetPromoCodeEnsighten | SetPromoCodeGoogleAnalytics | Ga4Event_SelectPromotion;

export type SetPromoCodeEnsighten = {
  __typename?: 'SetPromoCodeEnsighten';
  promoCode: Scalars['String'];
  pageType: Scalars['String'];
  pageCreationDate: Scalars['String'];
  containsPOA: Scalars['Boolean'];
};

export type SetPromoCodeGoogleAnalytics = {
  __typename?: 'SetPromoCodeGoogleAnalytics';
  promoCode: Scalars['String'];
  pageCreationDate: Scalars['String'];
  containsPOA: Scalars['Boolean'];
};

export type Ga4Event_SelectPromotion = {
  __typename?: 'GA4Event_SelectPromotion';
  event: Scalars['String'];
  ecommerce: Ga4Event_SelectPromotion_Ecommerce;
};

export type RemoveFromBasketResponse = {
  __typename?: 'RemoveFromBasketResponse';
  isSuccess: Scalars['Boolean'];
  tags: Array<RemoveFromBasketResponseTags>;
};

export type RemoveFromBasketResponseTags = BulkRemoveFromBasketEnsighten | BulkRemoveFromBasketGoogleAnalytics | BulkRemoveFromBasketTealium | Ga4Event_RemoveFromCart | Ga4Event_UpdateBasketLines;

export type BulkRemoveFromBasketEnsighten = {
  __typename?: 'BulkRemoveFromBasketEnsighten';
  products: Array<RemoveFromBasketEnsighten>;
};

export type BulkRemoveFromBasketGoogleAnalytics = {
  __typename?: 'BulkRemoveFromBasketGoogleAnalytics';
  products: Array<RemoveFromBasketGoogleAnalytics>;
};

export type BulkRemoveFromBasketTealium = {
  __typename?: 'BulkRemoveFromBasketTealium';
  products: Array<RemoveFromBasketTealium>;
};

export type Ga4Event_RemoveFromCart = {
  __typename?: 'GA4Event_RemoveFromCart';
  event: Scalars['String'];
  ecommerce: Ga4Event_RemoveFromCart_Ecommerce;
};

export type Ga4Event_UpdateBasketLines = {
  __typename?: 'GA4Event_UpdateBasketLines';
  event: Scalars['String'];
  ecommerce: Ga4Event_UpdateBasketLines_Ecommerce;
};

export type SetDeliveryMethodResponse = {
  __typename?: 'SetDeliveryMethodResponse';
  isSuccess: Scalars['Boolean'];
};

export type UpdateBasketLinesResponse = {
  __typename?: 'UpdateBasketLinesResponse';
  isSuccess: Scalars['Boolean'];
  tags: Array<UpdateBasketLinesResponseTags>;
};

export type UpdateBasketLinesResponseTags = UpdateBasketLinesEnsighten | UpdateBasketLinesGoogleAnalytics | UpdateBasketLinesTealium | Ga4Event_UpdateBasketLines;

export type UpdateBasketLinesEnsighten = {
  __typename?: 'UpdateBasketLinesEnsighten';
  lines: Array<UpdateBasketLinesEnsighten_BasketLine>;
};

export type UpdateBasketLinesGoogleAnalytics = {
  __typename?: 'UpdateBasketLinesGoogleAnalytics';
  lines: Array<UpdateBasketLinesGoogleAnalytics_BasketLine>;
};

export type UpdateBasketLinesTealium = {
  __typename?: 'UpdateBasketLinesTealium';
  lines: Array<UpdateBasketLinesTealium_BasketLine>;
};

export type AddBasketServiceResponse = {
  __typename?: 'AddBasketServiceResponse';
  isSuccess: Scalars['Boolean'];
};

export type RemoveBasketServiceResponse = {
  __typename?: 'RemoveBasketServiceResponse';
  isSuccess: Scalars['Boolean'];
};

export type AddressFields = {
  __typename?: 'AddressFields';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetComplement?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
  hasTaxCertificateNumber?: Maybe<Scalars['Boolean']>;
  taxCertificateNumber?: Maybe<Scalars['String']>;
  eoriNumber?: Maybe<Scalars['String']>;
  taxOfficeName?: Maybe<Scalars['String']>;
  paymentDepartmentNumber?: Maybe<Scalars['String']>;
  gstCustomerType?: Maybe<GstCustomerType>;
  gstRegistrationType?: Maybe<GstRegistrationType>;
  gstRegistrationNumber?: Maybe<Scalars['String']>;
  panCardNumber?: Maybe<Scalars['String']>;
};

export enum GstCustomerType {
  NoCustomerType = 'NO_CUSTOMER_TYPE',
  Unregistered = 'UNREGISTERED',
  Registered = 'REGISTERED',
  DeemedExport = 'DEEMED_EXPORT',
  Exempted = 'EXEMPTED',
  Unrecognized = 'UNRECOGNIZED'
}

export enum GstRegistrationType {
  NoRegistrationType = 'NO_REGISTRATION_TYPE',
  Gstin = 'GSTIN',
  Uid = 'UID',
  Gid = 'GID',
  Unrecognized = 'UNRECOGNIZED'
}

export type Address = {
  __typename?: 'Address';
  uniqueId: Scalars['String'];
  details?: Maybe<AddressFields>;
  formattedMultiLine: Array<Scalars['String']>;
  isValid: Scalars['Boolean'];
};

export type PurchasePageContactDetails = {
  __typename?: 'PurchasePageContactDetails';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNo: Scalars['String'];
  isValid: Scalars['Boolean'];
  accountType?: Maybe<Purchase_SaveContactDetails_AccountType>;
  taxRegistrationNumber?: Maybe<Scalars['String']>;
};

export enum Purchase_SaveContactDetails_AccountType {
  Business = 'business',
  Individual = 'individual'
}

export type PurchasePageOrderDetails = {
  __typename?: 'PurchasePageOrderDetails';
  orderReference: Scalars['String'];
  lines: Array<OrderLine>;
};

export type OrderLine = {
  __typename?: 'OrderLine';
  stockCode: Scalars['String'];
  title: Scalars['String'];
  quantity: Scalars['Float'];
};

export type PurchasePagePaymentOption_ControlledPurchasing_Purchasers = {
  __typename?: 'PurchasePagePaymentOption_ControlledPurchasing_Purchasers';
  id: Scalars['Float'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  emailAddress: Scalars['String'];
};

export type PurchasePagePaymentOption = {
  __typename?: 'PurchasePagePaymentOption';
  id: Scalars['Float'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isSelected: Scalars['Boolean'];
  optionType?: Maybe<PaymentOptionType>;
  details?: Maybe<PurchasePagePaymentOptionTypes>;
  callbackUrl: Scalars['String'];
  callbackMode: PaymentOptionCallbackMode;
};

export enum PaymentOptionType {
  Account = 'account',
  PaymentGateway = 'payment_gateway',
  BankTransfer = 'bank_transfer',
  Other = 'other',
  CashOnDelivery = 'cash_on_delivery',
  ControlledPurchasing = 'controlled_purchasing'
}

export type PurchasePagePaymentOptionTypes = PurchasePagePaymentOption_Account | PurchasePagePaymentOption_ControlledPurchasing;

export type PurchasePagePaymentOption_Account = {
  __typename?: 'PurchasePagePaymentOption_Account';
  accountNumberLabel: Scalars['String'];
  soldToNumber: Scalars['String'];
};

export type PurchasePagePaymentOption_ControlledPurchasing = {
  __typename?: 'PurchasePagePaymentOption_ControlledPurchasing';
  purchasers: Array<PurchasePagePaymentOption_ControlledPurchasing_Purchasers>;
};

export enum PaymentOptionCallbackMode {
  Redirect = 'redirect',
  Iframe = 'iframe'
}

export type PurchasePagePaymentOptions = {
  __typename?: 'PurchasePagePaymentOptions';
  options: Array<PurchasePagePaymentOption>;
};

export type PurchasePageDeliveryAddress = {
  __typename?: 'PurchasePageDeliveryAddress';
  uniqueId: Scalars['String'];
  details?: Maybe<AddressFields>;
  formattedMultiLine: Array<Scalars['String']>;
  isValid: Scalars['Boolean'];
};

export type PurchasePageDeliveryDetails = {
  __typename?: 'PurchasePageDeliveryDetails';
  country: Country;
  deliveryCountries: DeliveryCountries;
  address?: Maybe<PurchasePageDeliveryAddress>;
  endDeliveryCountries: DeliveryCountries;
};

export type PurchasePageBillingAddress = {
  __typename?: 'PurchasePageBillingAddress';
  uniqueId: Scalars['String'];
  details?: Maybe<AddressFields>;
  formattedMultiLine: Array<Scalars['String']>;
  isValid: Scalars['Boolean'];
};

export type PurchasePageBillingDetails = {
  __typename?: 'PurchasePageBillingDetails';
  countries: Array<Country>;
  selectedCountry?: Maybe<Country>;
  address?: Maybe<PurchasePageBillingAddress>;
};

export type PurchasePageConfig = {
  __typename?: 'PurchasePageConfig';
  paymentMethods: Array<Scalars['String']>;
  userMayNotHavePanCardNumber: Scalars['Boolean'];
};

export type Ga4Event_AddPaymentInfo_Ecommerce = {
  __typename?: 'GA4Event_AddPaymentInfo_Ecommerce';
  currency: Scalars['String'];
  value: Scalars['Float'];
  coupon: Scalars['String'];
  payment_type: Scalars['String'];
  items: Array<Ga4Item>;
  email?: Maybe<Scalars['String']>;
};

export type PurchasePageTaggingGtm = {
  __typename?: 'PurchasePageTaggingGTM';
  dataLayer: HtmlTag;
};

export type PurchasePageTagging = {
  __typename?: 'PurchasePageTagging';
  gtm: PurchasePageTaggingGtm;
  tags: Array<HtmlTag>;
  ga4: Array<Ga4PurchaseEvent>;
};

export type Ga4PurchaseEvent = Ga4Event_AddPaymentInfo;

export type Ga4Event_AddPaymentInfo = {
  __typename?: 'GA4Event_AddPaymentInfo';
  event: Scalars['String'];
  ecommerce: Ga4Event_AddPaymentInfo_Ecommerce;
};

export type PurchasePage = {
  __typename?: 'PurchasePage';
  isSuccess: Scalars['Boolean'];
  numberOfItems: Scalars['Float'];
  isQuote: Scalars['Boolean'];
  labels: Array<Label>;
  metaData: CheckoutMeta;
  summary: Summary;
  contactDetails: PurchasePageContactDetails;
  orderDetails: PurchasePageOrderDetails;
  deliveryDetails?: Maybe<PurchasePageDeliveryDetails>;
  billingDetails?: Maybe<PurchasePageBillingDetails>;
  paymentOptions: PurchasePagePaymentOptions;
  requiresBasketValidation: Scalars['Boolean'];
  config: PurchasePageConfig;
  tagging: PurchasePageTagging;
};

export type Purchase_ConfirmOrderResponse = {
  __typename?: 'Purchase_ConfirmOrderResponse';
  isSuccess: Scalars['Boolean'];
  errorMessage: Scalars['String'];
};

export type Purchase_ConfirmQuoteResponse = {
  __typename?: 'Purchase_ConfirmQuoteResponse';
  isSuccess: Scalars['Boolean'];
  errorMessage: Scalars['String'];
};

export type Purchase_SaveContactDetailsResponse = {
  __typename?: 'Purchase_SaveContactDetailsResponse';
  isSuccess: Scalars['Boolean'];
  errorMessage: Scalars['String'];
};

export type Purchase_SavePersonalOrderReferenceResponse = {
  __typename?: 'Purchase_SavePersonalOrderReferenceResponse';
  isSuccess: Scalars['Boolean'];
  errorMessage: Scalars['String'];
};

export type Purchase_SetBasketAddressResponse = {
  __typename?: 'Purchase_SetBasketAddressResponse';
  isSuccess: Scalars['Boolean'];
  errorMessage: Scalars['String'];
  isAddressValid: Scalars['Boolean'];
};

export type Purchase_SetBasketPaymentOptionResponse = {
  __typename?: 'Purchase_SetBasketPaymentOptionResponse';
  isSuccess: Scalars['Boolean'];
  errorMessage: Scalars['String'];
};

export type OrderConfirmationPage_BillingAddress = {
  __typename?: 'OrderConfirmationPage_BillingAddress';
  uniqueId: Scalars['String'];
  details?: Maybe<AddressFields>;
  formattedMultiLine: Array<Scalars['String']>;
  isValid: Scalars['Boolean'];
};

export type OrderConfirmationPage_DeliveryAddress = {
  __typename?: 'OrderConfirmationPage_DeliveryAddress';
  uniqueId: Scalars['String'];
  details?: Maybe<AddressFields>;
  formattedMultiLine: Array<Scalars['String']>;
  isValid: Scalars['Boolean'];
};

export type OrderPaymentOption_OrderConfirmationMessage = {
  __typename?: 'OrderPaymentOption_OrderConfirmationMessage';
  content?: Maybe<Array<TextEditor>>;
  title?: Maybe<Scalars['String']>;
};

export type OrderPaymentOption_BankDetailItem = {
  __typename?: 'OrderPaymentOption_BankDetailItem';
  detail?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type OrderPaymentOption_PaymentOptionConfiguration = {
  __typename?: 'OrderPaymentOption_PaymentOptionConfiguration';
  orderConfirmationMessage?: Maybe<OrderPaymentOption_OrderConfirmationMessage>;
};

export type OrderPaymentOption_BankDetailsConfiguration = {
  __typename?: 'OrderPaymentOption_BankDetailsConfiguration';
  accountNumber?: Maybe<OrderPaymentOption_BankDetailItem>;
  bankCode?: Maybe<OrderPaymentOption_BankDetailItem>;
  bankName?: Maybe<OrderPaymentOption_BankDetailItem>;
  branchCode?: Maybe<OrderPaymentOption_BankDetailItem>;
  branchName?: Maybe<OrderPaymentOption_BankDetailItem>;
  companyName?: Maybe<OrderPaymentOption_BankDetailItem>;
  iban?: Maybe<OrderPaymentOption_BankDetailItem>;
  showBankDetails?: Maybe<Scalars['Boolean']>;
  swiftCode?: Maybe<OrderPaymentOption_BankDetailItem>;
};

export type OrderPaymentOption_ControlledPurchasingConfiguration_Purchaser = {
  __typename?: 'OrderPaymentOption_ControlledPurchasingConfiguration_Purchaser';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  emailAddress: Scalars['String'];
};

export type OrderPaymentOption_ControlledPurchasingConfiguration = {
  __typename?: 'OrderPaymentOption_ControlledPurchasingConfiguration';
  purchaser?: Maybe<OrderPaymentOption_ControlledPurchasingConfiguration_Purchaser>;
};

export type OrderConfirmationPage_PaymentOption = {
  __typename?: 'OrderConfirmationPage_PaymentOption';
  title: Scalars['String'];
  description: Scalars['String'];
  optionType: PaymentOptionType;
  details?: Maybe<PaymentOptionDetails>;
};

export type PaymentOptionDetails = OrderConfirmationPage_BankTransferDetails | OrderConfirmationPage_ControlledPurchasingDetails;

export type OrderConfirmationPage_BankTransferDetails = {
  __typename?: 'OrderConfirmationPage_BankTransferDetails';
  bankDetails?: Maybe<OrderPaymentOption_BankDetailsConfiguration>;
  configuration?: Maybe<OrderPaymentOption_PaymentOptionConfiguration>;
};

export type OrderConfirmationPage_ControlledPurchasingDetails = {
  __typename?: 'OrderConfirmationPage_ControlledPurchasingDetails';
  controlledPurchasingDetails?: Maybe<OrderPaymentOption_ControlledPurchasingConfiguration>;
};

export type Ga4Event_Purchase_Ecommerce = {
  __typename?: 'GA4Event_Purchase_Ecommerce';
  transactionId: Scalars['String'];
  value: Scalars['Float'];
  tax: Scalars['Float'];
  shipping: Scalars['Float'];
  currency: Scalars['String'];
  coupon: Scalars['String'];
  v_landing: Scalars['String'];
  items: Array<Ga4Item>;
  total_prqt: Scalars['Float'];
  email?: Maybe<Scalars['String']>;
};

export type OrderConfirmationPageTaggingGtm = {
  __typename?: 'OrderConfirmationPageTaggingGTM';
  dataLayer: HtmlTag;
};

export type OrderConfirmationPageTagging = {
  __typename?: 'OrderConfirmationPageTagging';
  gtm: OrderConfirmationPageTaggingGtm;
  tags: Array<HtmlTag>;
  ga4: Array<Ga4OrderConfirmationEvent>;
};

export type Ga4OrderConfirmationEvent = Ga4Event_Purchase;

export type Ga4Event_Purchase = {
  __typename?: 'GA4Event_Purchase';
  event: Scalars['String'];
  ecommerce: Ga4Event_Purchase_Ecommerce;
};

export type OrderConfirmationPageConfig = {
  __typename?: 'OrderConfirmationPageConfig';
  hasDualUseProducts: Scalars['Boolean'];
  isUsingDtp: Scalars['Boolean'];
};

export type OrderConfirmationPageDetails = {
  __typename?: 'OrderConfirmationPageDetails';
  numberOfItems: Scalars['Float'];
  isQuote: Scalars['Boolean'];
  orderReference: Scalars['String'];
  orderReferencePersonal?: Maybe<Scalars['String']>;
  labels: Array<Label>;
  metaData: CheckoutMeta;
  summary: Summary;
  contactDetails: PurchasePageContactDetails;
  deliveryAddress: OrderConfirmationPage_DeliveryAddress;
  billingAddress?: Maybe<OrderConfirmationPage_BillingAddress>;
  paymentOption?: Maybe<OrderConfirmationPage_PaymentOption>;
  parcels: Array<Parcel>;
  tagging: OrderConfirmationPageTagging;
  config: OrderConfirmationPageConfig;
};

export type OrderConfirmationPage = {
  __typename?: 'OrderConfirmationPage';
  isSuccess: Scalars['Boolean'];
  details?: Maybe<OrderConfirmationPageDetails>;
};

export type CategoryListPageMeta = {
  __typename?: 'CategoryListPageMeta';
  structuredData: Scalars['String'];
  metaTags?: Maybe<Array<HtmlTag>>;
  pageTitle: Scalars['String'];
  canonicalUrl: Scalars['String'];
  links?: Maybe<Array<HtmlTag>>;
};

export type CategoryItem = {
  __typename?: 'CategoryItem';
  id: Scalars['Float'];
  url: Link;
  noOfChildren: Scalars['Float'];
  noOfProducts: Scalars['Float'];
  shopAllLink: Link;
  imageUrl: Scalars['String'];
  childCategories?: Maybe<Array<CategoryItem>>;
};

export type ClpTaggingGtm = {
  __typename?: 'CLPTaggingGTM';
  dataLayer: HtmlTag;
};

export type ClpTagging = {
  __typename?: 'CLPTagging';
  gtm: ClpTaggingGtm;
  tags: Array<HtmlTag>;
};

export type CategoryListPage = {
  __typename?: 'CategoryListPage';
  redirectUrl: Scalars['String'];
  categoryId: Scalars['Float'];
  isSuccess: Scalars['Boolean'];
  categoryName: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  labels: Array<Label>;
  leftMenu: Array<CategoryItem>;
  childCategories: Array<CategoryItem>;
  breadcrumb: Array<Link>;
  metaData: CategoryListPageMeta;
  tagging: ClpTagging;
};

export type CookiePolicy = {
  __typename?: 'CookiePolicy';
  isVisible: Scalars['Boolean'];
  labels: Array<Label>;
};

export type AccountOverviewPageTagging = {
  __typename?: 'AccountOverviewPageTagging';
  tags: Array<HtmlTag>;
};

export type AccountOverviewMeta = {
  __typename?: 'AccountOverviewMeta';
  metaTags?: Maybe<Array<HtmlTag>>;
  pageTitle: Scalars['String'];
};

export type RecentlyViewedProduct = {
  __typename?: 'RecentlyViewedProduct';
  price: Price;
  stockCode: Scalars['String'];
  title: Scalars['String'];
  imageUrl: Scalars['String'];
  pdpUrl: Scalars['String'];
  isInBasket: Scalars['Boolean'];
};

export type RecentlyViewedItem = {
  __typename?: 'RecentlyViewedItem';
  id: Scalars['String'];
  url: Link;
  imageUrl?: Maybe<Scalars['String']>;
};

export type RecentlyViewedArea = {
  __typename?: 'RecentlyViewedArea';
  products?: Maybe<Array<RecentlyViewedProduct>>;
  categories?: Maybe<Array<RecentlyViewedItem>>;
  brands?: Maybe<Array<RecentlyViewedItem>>;
};

export type AccountOverviewConfig = {
  __typename?: 'AccountOverviewConfig';
  topicIdentifier: Scalars['String'];
};

export type ControlledPurchasingArea = {
  __typename?: 'ControlledPurchasingArea';
  company?: Maybe<Scalars['String']>;
  assignedUsers?: Maybe<Array<Scalars['String']>>;
  assignedPurchasers?: Maybe<Array<Scalars['String']>>;
};

export type IndustryBanner = {
  __typename?: 'IndustryBanner';
  title: Scalars['String'];
  link: Scalars['String'];
  eventName: Scalars['String'];
};

export type DiscoverMoreArea = {
  __typename?: 'DiscoverMoreArea';
  industryBanner: IndustryBanner;
};

export type AccountOverviewProduct = {
  __typename?: 'AccountOverviewProduct';
  stockCode: Scalars['String'];
  title: Scalars['String'];
  imageUrl: Scalars['String'];
  pdpUrl: Scalars['String'];
};

export type AccountOverViewOrderLine = {
  __typename?: 'AccountOverViewOrderLine';
  product: AccountOverviewProduct;
};

export type OrdersArea = {
  __typename?: 'OrdersArea';
  orderLines?: Maybe<Array<AccountOverViewOrderLine>>;
};

export type PartsListLine = {
  __typename?: 'PartsListLine';
  product: AccountOverviewProduct;
};

export type PartsListArea = {
  __typename?: 'PartsListArea';
  partslistLines?: Maybe<Array<PartsListLine>>;
};

export type AccountOverviewPage = {
  __typename?: 'AccountOverviewPage';
  isSuccess: Scalars['Boolean'];
  tagging: AccountOverviewPageTagging;
  metaData: AccountOverviewMeta;
  config: AccountOverviewConfig;
  labels: Array<Label>;
  ordersArea?: Maybe<OrdersArea>;
  partsListArea?: Maybe<PartsListArea>;
  discoverMoreArea?: Maybe<DiscoverMoreArea>;
  controlledPurchasingArea?: Maybe<ControlledPurchasingArea>;
  recentlyViewedArea?: Maybe<RecentlyViewedArea>;
};

export type Account = {
  __typename?: 'Account';
  isSuccess: Scalars['Boolean'];
  labels: Array<Label>;
  customerName?: Maybe<Scalars['String']>;
};

export type DeliveryAddressConfiguration = {
  __typename?: 'DeliveryAddressConfiguration';
  showVatNo: Scalars['Boolean'];
  requireVatNo: Scalars['Boolean'];
};

export type AddressConfiguration = {
  __typename?: 'AddressConfiguration';
  showPostCode: Scalars['Boolean'];
  requireEORI: Scalars['Boolean'];
  showShipToNumber: Scalars['Boolean'];
  validationRegexPostCode: Scalars['String'];
  formatPostCode: Scalars['String'];
  errorMessagePostCode: Scalars['String'];
  deliveryAddress: DeliveryAddressConfiguration;
};

export type State = {
  __typename?: 'State';
  code: Scalars['String'];
  name: Scalars['String'];
  requireTaxExemptionCertificate: Scalars['Boolean'];
  tinNumber: Scalars['String'];
};

export type CountryAddressConfigurationResponse = {
  __typename?: 'CountryAddressConfigurationResponse';
  states: Array<State>;
  addressDetails: AddressConfiguration;
};

export type SaveAddressResponse = {
  __typename?: 'SaveAddressResponse';
  isSuccess: Scalars['Boolean'];
  errorMessage: Scalars['String'];
  addressUniqueId: Scalars['String'];
  fieldErrors: Array<AddressFieldErrorType>;
};

export enum AddressFieldErrorType {
  InvalidVatNumber = 'invalid_vat_number',
  InvalidGstNumber = 'invalid_gst_number'
}

export type DeleteAddressResponse = {
  __typename?: 'DeleteAddressResponse';
  isSuccess: Scalars['Boolean'];
  errorMessage: Scalars['String'];
};

export type CountryStatesResponse = {
  __typename?: 'CountryStatesResponse';
  states: Array<State>;
};

export type AddressList = {
  __typename?: 'AddressList';
  addresses: Array<Address>;
};

export type SearchAddressResponse = {
  __typename?: 'SearchAddressResponse';
  addresses: Array<AddressFields>;
};

export type ValidateVatDetailsResponse = {
  __typename?: 'ValidateVATDetailsResponse';
  isSuccess: Scalars['Boolean'];
};

export type ShareProductsTransactionResponse = {
  __typename?: 'ShareProductsTransactionResponse';
  isSuccess: Scalars['Boolean'];
};

export type EmailSubscriptionPageTagging = {
  __typename?: 'EmailSubscriptionPageTagging';
  tags: Array<HtmlTag>;
};

export type EmailSubscriptionTransactionRepsonse = {
  __typename?: 'EmailSubscriptionTransactionRepsonse';
  labels: Array<Label>;
  tagging: EmailSubscriptionPageTagging;
};

export type ResetPasswordResponse = {
  __typename?: 'ResetPasswordResponse';
  isSuccess: Scalars['Boolean'];
  username?: Maybe<Scalars['String']>;
};

export type Customer = {
  __typename?: 'Customer';
  id: Scalars['Float'];
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  industry: Scalars['Float'];
  purchasingFor?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  subscribed: Scalars['Boolean'];
};

export type SaveCustomerResponse = {
  __typename?: 'SaveCustomerResponse';
  isSuccess: Scalars['Boolean'];
  errorMessage: Scalars['String'];
  fieldErrors: Array<CustomerFieldErrorType>;
};

export enum CustomerFieldErrorType {
  ErrorEmailInUse = 'error_email_in_use',
  ErrorInvalidEmail = 'error_invalid_email',
  ErrorInvalidFirstName = 'error_invalid_first_name',
  ErrorInvalidLastName = 'error_invalid_last_name',
  ErrorInvalidPassword = 'error_invalid_password',
  ErrorInvalidMobile = 'error_invalid_mobile',
  ErrorInvalidIndustry = 'error_invalid_industry',
  ErrorInvalidSubscribed = 'error_invalid_subscribed'
}

export type ResetPasswordTransactionResponse = {
  __typename?: 'ResetPasswordTransactionResponse';
  isSuccess: Scalars['Boolean'];
  showUsername: Scalars['Boolean'];
};

export type UpdateCustomerResponse = {
  __typename?: 'UpdateCustomerResponse';
  isSuccess: Scalars['Boolean'];
};

export type ValidateEmailResponse = {
  __typename?: 'ValidateEmailResponse';
  isSuccess: Scalars['Boolean'];
};

export type DataCaptureFieldError = {
  __typename?: 'DataCaptureFieldError';
  name: Scalars['String'];
  errorMessage: Scalars['String'];
  errorCode: Scalars['String'];
};

export type DataCaptureResponse = {
  __typename?: 'DataCaptureResponse';
  isSuccess: Scalars['Boolean'];
  errorMessage: Scalars['String'];
  errorCode: Scalars['String'];
  errors: Array<DataCaptureFieldError>;
};

export type SendDocumentCommunicationResponse = {
  __typename?: 'SendDocumentCommunicationResponse';
  isSuccess: Scalars['Boolean'];
  noOfCommunications: Scalars['Float'];
};

export type ForgotPasswordPageTagging = {
  __typename?: 'ForgotPasswordPageTagging';
  tags: Array<HtmlTag>;
};

export type ForgotPasswordPage = {
  __typename?: 'ForgotPasswordPage';
  isSuccess: Scalars['Boolean'];
  labels: Array<Label>;
  tagging: ForgotPasswordPageTagging;
};

export type Ga4Event_User_Login_Ecommerce = {
  __typename?: 'GA4Event_User_Login_Ecommerce';
  email?: Maybe<Scalars['String']>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  isSuccess: Scalars['Boolean'];
  customerName?: Maybe<Scalars['String']>;
  basketChanged: Scalars['Boolean'];
  redirectUrl?: Maybe<Scalars['String']>;
  tags: Array<LoginResponseTags>;
};

export type LoginResponseTags = Ga4Event_User_Login;

export type Ga4Event_User_Login = {
  __typename?: 'GA4Event_User_Login';
  event: Scalars['String'];
  ecommerce: Ga4Event_User_Login_Ecommerce;
};

export type OktaLoginResponse = {
  __typename?: 'OktaLoginResponse';
  isSuccess: Scalars['Boolean'];
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type OktaTokenExchangeResponse = {
  __typename?: 'OktaTokenExchangeResponse';
  isSuccess: Scalars['Boolean'];
  customerName?: Maybe<Scalars['String']>;
  basketChanged: Scalars['Boolean'];
  redirectUrl?: Maybe<Scalars['String']>;
  tags: Array<LoginResponseTags>;
};

export type OktaLoginByEmailResponse = {
  __typename?: 'OktaLoginByEmailResponse';
  isSuccess: Scalars['Boolean'];
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type LogoutResponse = {
  __typename?: 'LogoutResponse';
  isSuccess: Scalars['Boolean'];
};

export type LoginModal = {
  __typename?: 'LoginModal';
  labels: Array<Label>;
};

export type LoginPageTagging = {
  __typename?: 'LoginPageTagging';
  tags: Array<HtmlTag>;
};

export type LoginPage = {
  __typename?: 'LoginPage';
  isSuccess: Scalars['Boolean'];
  labels: Array<Label>;
  isProfileEnabled: Scalars['Boolean'];
  tagging: LoginPageTagging;
};

export type OrderDetailsPage_BillingAddress = {
  __typename?: 'OrderDetailsPage_BillingAddress';
  uniqueId: Scalars['String'];
  details?: Maybe<AddressFields>;
  formattedMultiLine: Array<Scalars['String']>;
  isValid: Scalars['Boolean'];
};

export type OrderConfirmationPageContactDetails = {
  __typename?: 'OrderConfirmationPageContactDetails';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNo: Scalars['String'];
  taxRegistrationNumber?: Maybe<Scalars['String']>;
};

export type OrderDetailsPage_DeliveryAddress = {
  __typename?: 'OrderDetailsPage_DeliveryAddress';
  uniqueId: Scalars['String'];
  details?: Maybe<AddressFields>;
  formattedMultiLine: Array<Scalars['String']>;
  isValid: Scalars['Boolean'];
};

export type OrderDetailsProductPriceArea = {
  __typename?: 'OrderDetailsProductPriceArea';
  minimumOrderQuantity: Scalars['Float'];
  totalPrice: Price;
  unitPrice: Price;
  container: Scalars['String'];
  errorMessage: Scalars['String'];
  unitOfMeasure: Scalars['String'];
  taxType: Array<Scalars['String']>;
  isInBasket: Scalars['Boolean'];
};

export type OrderDetailsProductTitle = {
  __typename?: 'OrderDetailsProductTitle';
  title: Scalars['String'];
  brand: Scalars['String'];
  formattedStockCode: Scalars['String'];
  hsnCode: Scalars['String'];
  impaCode?: Maybe<Scalars['String']>;
  mpn: Scalars['String'];
  stockCode: Scalars['String'];
  primaryImageUrl: Scalars['String'];
  pdpUrl: Scalars['String'];
  partNumber: Scalars['String'];
};

export type OrderDetailsProduct = {
  __typename?: 'OrderDetailsProduct';
  title: OrderDetailsProductTitle;
  priceArea: OrderDetailsProductPriceArea;
  energyArea?: Maybe<ProductEnergy>;
};

export type OrderDetailsParcelItem = {
  __typename?: 'OrderDetailsParcelItem';
  id: Scalars['Float'];
  product: OrderDetailsProduct;
  quantity: Scalars['Float'];
};

export type OrderDetailsPage_PaymentOption_ControlledPurchasingConfiguration_Purchaser = {
  __typename?: 'OrderDetailsPage_PaymentOption_ControlledPurchasingConfiguration_Purchaser';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  emailAddress: Scalars['String'];
};

export type OrderDetailsPage_PaymentOption_ControlledPurchasingConfiguration = {
  __typename?: 'OrderDetailsPage_PaymentOption_ControlledPurchasingConfiguration';
  purchaser?: Maybe<OrderDetailsPage_PaymentOption_ControlledPurchasingConfiguration_Purchaser>;
};

export type OrderDetailsPage_PaymentOption = {
  __typename?: 'OrderDetailsPage_PaymentOption';
  title: Scalars['String'];
  description: Scalars['String'];
  optionType: PaymentOptionType;
  details?: Maybe<OrderDetailsPage_PaymentOptionDetails>;
};

export type OrderDetailsPage_PaymentOptionDetails = OrderDetailsPage__PaymentOption_ControlledPurchasingDetails;

export type OrderDetailsPage__PaymentOption_ControlledPurchasingDetails = {
  __typename?: 'OrderDetailsPage__PaymentOption_ControlledPurchasingDetails';
  controlledPurchasingDetails?: Maybe<OrderDetailsPage_PaymentOption_ControlledPurchasingConfiguration>;
};

export type OrderDetailsPageDetails = {
  __typename?: 'OrderDetailsPageDetails';
  orderReference: Scalars['String'];
  orderReferencePersonal?: Maybe<Scalars['String']>;
  dateOrdered: Scalars['DateTime'];
  contactDetails: OrderConfirmationPageContactDetails;
  deliveryAddress: OrderDetailsPage_DeliveryAddress;
  billingAddress?: Maybe<OrderDetailsPage_BillingAddress>;
  paymentOption?: Maybe<OrderDetailsPage_PaymentOption>;
  parcels: Array<OrderDetailsParcelItem>;
  summary: Summary;
  isDeliveryAddressSameAsBillingAddress: Scalars['Boolean'];
};

export type OrderDetailsHeader = {
  __typename?: 'OrderDetailsHeader';
  siteName: Scalars['String'];
  siteAddress: Scalars['String'];
};

export type OrderDetailsConfig = {
  __typename?: 'OrderDetailsConfig';
  topicIdentifier: Scalars['String'];
};

export type OrderDetailsMeta = {
  __typename?: 'OrderDetailsMeta';
  metaTags?: Maybe<Array<HtmlTag>>;
  pageTitle: Scalars['String'];
};

export type OrderDetailsPageTagging = {
  __typename?: 'OrderDetailsPageTagging';
  tags: Array<HtmlTag>;
};

export type OrderDetailsPage = {
  __typename?: 'OrderDetailsPage';
  isSuccess: Scalars['Boolean'];
  metaData: OrderDetailsMeta;
  header: OrderDetailsHeader;
  tagging: OrderDetailsPageTagging;
  labels: Array<Label>;
  details?: Maybe<OrderDetailsPageDetails>;
  config: OrderDetailsConfig;
};

export type OrderHistoryMeta = {
  __typename?: 'OrderHistoryMeta';
  metaTags?: Maybe<Array<HtmlTag>>;
  pageTitle: Scalars['String'];
};

export type OrderHistoryProduct = {
  __typename?: 'OrderHistoryProduct';
  stockCode: Scalars['String'];
  title: Scalars['String'];
  imageUrl: Scalars['String'];
  pdpUrl: Scalars['String'];
  mpn: Scalars['String'];
  customerPartNumber?: Maybe<Scalars['String']>;
};

export type OrderHistoryArea = {
  __typename?: 'OrderHistoryArea';
  noOfOrders: Scalars['Float'];
  orders: Array<OrderHistoryOrder>;
};

export type OrderHistoryOrder = {
  __typename?: 'OrderHistoryOrder';
  id: Scalars['String'];
  orderReference: Scalars['Float'];
  orderReferencePersonal?: Maybe<Scalars['String']>;
  dateOrdered: Scalars['DateTime'];
  total: FormattedNumber;
  products: Array<OrderHistoryProduct>;
  isControlledPurchasing: Scalars['Boolean'];
};

export type OrderHistoryPageTagging = {
  __typename?: 'OrderHistoryPageTagging';
  tags: Array<HtmlTag>;
};

export type OrderHistoryPage = {
  __typename?: 'OrderHistoryPage';
  isSuccess: Scalars['Boolean'];
  tagging: OrderHistoryPageTagging;
  metaData: OrderHistoryMeta;
  labels: Array<Label>;
  orderHistoryArea?: Maybe<OrderHistoryArea>;
};

export type DeletePartsListLinesResponse = {
  __typename?: 'DeletePartsListLinesResponse';
  isSuccess: Scalars['Boolean'];
  errorMessage: Scalars['String'];
};

export type DeletePartsListResponse = {
  __typename?: 'DeletePartsListResponse';
  isSuccess: Scalars['Boolean'];
  errorMessage: Scalars['String'];
};

export type PartsListProductPriceArea = {
  __typename?: 'PartsListProductPriceArea';
  minimumOrderQuantity: Scalars['Float'];
  totalPrice: Price;
  unitPrice: Price;
  container: Scalars['String'];
  errorMessage: Scalars['String'];
  unitOfMeasure: Scalars['String'];
};

export type PartsListProductTitle = {
  __typename?: 'PartsListProductTitle';
  title: Scalars['String'];
  brand: Scalars['String'];
  formattedStockCode: Scalars['String'];
  hsnCode: Scalars['String'];
  impaCode?: Maybe<Scalars['String']>;
  mpn: Scalars['String'];
  stockCode: Scalars['String'];
  primaryImageUrl: Scalars['String'];
  pdpUrl: Scalars['String'];
};

export type MyAccount_PartsListDetails_PartsListProduct = {
  __typename?: 'MyAccount_PartsListDetails_PartsListProduct';
  title: PartsListProductTitle;
  priceArea: PartsListProductPriceArea;
  energyArea?: Maybe<ProductEnergy>;
};

export type PartsListLineItem = {
  __typename?: 'PartsListLineItem';
  id: Scalars['Float'];
  product: MyAccount_PartsListDetails_PartsListProduct;
  quantity: Scalars['Float'];
  isInBasket: Scalars['Boolean'];
};

export type UpdatePartsListLineResponse = {
  __typename?: 'UpdatePartsListLineResponse';
  isSuccess: Scalars['Boolean'];
  errorMessage: Scalars['String'];
  priceArea?: Maybe<PartsListProductPriceArea>;
};

export type MyAccount_PartsListDetails_Config = {
  __typename?: 'MyAccount_PartsListDetails_Config';
  topicIdentifier: Scalars['String'];
};

export type MyAccount_PartsListDetails_PartsListsArea = {
  __typename?: 'MyAccount_PartsListDetails_PartsListsArea';
  partsLists: Array<MyAccount_PartsListDetails_PartsList>;
};

export type MyAccount_PartsListDetails_PartsList = {
  __typename?: 'MyAccount_PartsListDetails_PartsList';
  id: Scalars['Float'];
  name: Scalars['String'];
  selected: Scalars['Boolean'];
};

export type PartsListDetailsMeta = {
  __typename?: 'PartsListDetailsMeta';
  metaTags?: Maybe<Array<HtmlTag>>;
  pageTitle: Scalars['String'];
};

export type PartsListDetails = {
  __typename?: 'PartsListDetails';
  id: Scalars['Float'];
  name: Scalars['String'];
  description: Scalars['String'];
  lines: Array<PartsListLineItem>;
};

export type PartsListDetailsPageTagging = {
  __typename?: 'PartsListDetailsPageTagging';
  tags: Array<HtmlTag>;
};

export type MyAccountPartsListDetailsPage = {
  __typename?: 'MyAccountPartsListDetailsPage';
  isSuccess: Scalars['Boolean'];
  redirectUrl?: Maybe<Scalars['String']>;
  config: MyAccount_PartsListDetails_Config;
  tagging: PartsListDetailsPageTagging;
  metaData: PartsListDetailsMeta;
  labels: Array<Label>;
  partsLists: MyAccount_PartsListDetails_PartsListsArea;
  details?: Maybe<PartsListDetails>;
};

export type MyAccount_PartsLists_Config = {
  __typename?: 'MyAccount_PartsLists_Config';
  topicIdentifier: Scalars['String'];
};

export type MyAccount_PartsList_Product = {
  __typename?: 'MyAccount_PartsList_Product';
  stockCode: Scalars['String'];
  title: Scalars['String'];
  imageUrl: Scalars['String'];
  pdpUrl: Scalars['String'];
  qty: Scalars['Float'];
  isInvalid: Scalars['Boolean'];
};

export type PartsListsArea = {
  __typename?: 'PartsListsArea';
  noOfPartsLists: Scalars['Float'];
  partsLists: Array<PartsListsPartList>;
};

export type PartsListsPartList = {
  __typename?: 'PartsListsPartList';
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  lastModified: Scalars['DateTime'];
  noOfProducts: Scalars['Float'];
  totalPrice: Price;
  products: Array<MyAccount_PartsList_Product>;
};

export type PartsListsMeta = {
  __typename?: 'PartsListsMeta';
  metaTags?: Maybe<Array<HtmlTag>>;
  pageTitle: Scalars['String'];
};

export type PartsListsPageTagging = {
  __typename?: 'PartsListsPageTagging';
  tags: Array<HtmlTag>;
};

export type MyAccountPartsListsPage = {
  __typename?: 'MyAccountPartsListsPage';
  isSuccess: Scalars['Boolean'];
  tagging: PartsListsPageTagging;
  metaData: PartsListsMeta;
  labels: Array<Label>;
  partsListsArea: PartsListsArea;
  config: MyAccount_PartsLists_Config;
};

export type ProfileMeta = {
  __typename?: 'ProfileMeta';
  metaTags?: Maybe<Array<HtmlTag>>;
  pageTitle: Scalars['String'];
};

export type ProfilePageTagging = {
  __typename?: 'ProfilePageTagging';
  tags: Array<HtmlTag>;
};

export type ProfilePage = {
  __typename?: 'ProfilePage';
  isSuccess: Scalars['Boolean'];
  labels: Array<Label>;
  metaData: ProfileMeta;
  industries: Array<ProfilePageIndustry>;
  customer?: Maybe<Customer>;
  tagging: ProfilePageTagging;
};

export type ProfilePageIndustry = {
  __typename?: 'ProfilePageIndustry';
  id: Scalars['Float'];
  value: Scalars['String'];
};

export type AddRecentlyViewedResponse = {
  __typename?: 'AddRecentlyViewedResponse';
  isSuccess: Scalars['Boolean'];
};

export type RecentlyViewed = {
  __typename?: 'RecentlyViewed';
  labels: Array<Label>;
  recentlyViewedArea?: Maybe<RecentlyViewedArea>;
};

export type RegistrationPageTagging = {
  __typename?: 'RegistrationPageTagging';
  tags: Array<HtmlTag>;
};

export type RegistrationPage = {
  __typename?: 'RegistrationPage';
  isSuccess: Scalars['Boolean'];
  labels: Array<Label>;
  industries: Array<RegistrationPageIndustry>;
  tagging: RegistrationPageTagging;
};

export type RegistrationPageIndustry = {
  __typename?: 'RegistrationPageIndustry';
  id: Scalars['Float'];
  value: Scalars['String'];
};

export type RegistrationTokenExpiredPage = {
  __typename?: 'RegistrationTokenExpiredPage';
  isSuccess: Scalars['Boolean'];
  redirectUrl?: Maybe<Scalars['String']>;
  labels: Array<Label>;
};

export type ResetPasswordMeta = {
  __typename?: 'ResetPasswordMeta';
  metaTags?: Maybe<Array<HtmlTag>>;
  pageTitle: Scalars['String'];
};

export type ResetPasswordPageTagging = {
  __typename?: 'ResetPasswordPageTagging';
  tags: Array<HtmlTag>;
};

export type ResetPasswordPage = {
  __typename?: 'ResetPasswordPage';
  isSuccess: Scalars['Boolean'];
  labels: Array<Label>;
  tagging: ResetPasswordPageTagging;
  metaData: ResetPasswordMeta;
};

export type EmailCampaignPage = {
  __typename?: 'EmailCampaignPage';
  isSuccess: Scalars['Boolean'];
  html?: Maybe<Scalars['String']>;
};

export type PlpTaggingGtm = {
  __typename?: 'PLPTaggingGTM';
  dataLayer: HtmlTag;
};

export type PlpTagging = {
  __typename?: 'PLPTagging';
  gtm: PlpTaggingGtm;
  tags: Array<HtmlTag>;
};

export type PlpMeta = {
  __typename?: 'PLPMeta';
  metaTags?: Maybe<Array<HtmlTag>>;
  pageTitle: Scalars['String'];
  canonicalUrl: Scalars['String'];
  links?: Maybe<Array<HtmlTag>>;
};

export type TechnicalErrorPage = {
  __typename?: 'TechnicalErrorPage';
  labels: Array<Label>;
  tagging: PlpTagging;
  metaData: PlpMeta;
  sessionId?: Maybe<Scalars['String']>;
};

export type NotFoundErrorPage = {
  __typename?: 'NotFoundErrorPage';
  labels: Array<Label>;
  tagging: PlpTagging;
  metaData: PlpMeta;
};

export type SettingsLanguage = {
  __typename?: 'SettingsLanguage';
  name: Scalars['String'];
  baseDirectory: Scalars['String'];
  countryCodeISO2: Scalars['String'];
  isSelected: Scalars['Boolean'];
};

export type SettingsCurrency = {
  __typename?: 'SettingsCurrency';
  displayName: Scalars['String'];
  name: Scalars['String'];
  symbol: Scalars['String'];
  id: Scalars['Float'];
  shortName: Scalars['String'];
};

export type SettingsCountry = {
  __typename?: 'SettingsCountry';
  name: Scalars['String'];
  codeISO2: Scalars['String'];
  id: Scalars['String'];
  contactEmail: Scalars['String'];
  contactPhone: Scalars['String'];
  contactAddress: Scalars['String'];
  isSelected: Scalars['Boolean'];
};

export type SiteSettings = {
  __typename?: 'SiteSettings';
  isVisible: Scalars['Boolean'];
  showCurrencies: Scalars['Boolean'];
  showLanguages: Scalars['Boolean'];
  showCountries: Scalars['Boolean'];
  showVATToggle: Scalars['Boolean'];
  languages: Array<SettingsLanguage>;
  currencies: Array<SettingsCurrency>;
  countries: Array<SettingsCountry>;
};

export type FooterMeta = {
  __typename?: 'FooterMeta';
  structuredData?: Maybe<Array<HtmlTag>>;
};

export type FooterConfig = {
  __typename?: 'FooterConfig';
  phoneNumber: Scalars['String'];
  emailAddress: Scalars['String'];
  businessAddress: Scalars['String'];
  paymentMethods: Array<Scalars['String']>;
  socialPlatforms: Array<Link>;
  partnerLinks: Array<Link>;
};

export type FooterSettingsLanguage = {
  __typename?: 'FooterSettingsLanguage';
  name: Scalars['String'];
  baseDirectory: Scalars['String'];
  countryCodeISO2: Scalars['String'];
  isSelected: Scalars['Boolean'];
};

export type FooterSettingsCurrency = {
  __typename?: 'FooterSettingsCurrency';
  displayName: Scalars['String'];
  name: Scalars['String'];
  symbol: Scalars['String'];
  id: Scalars['Float'];
  shortName: Scalars['String'];
};

export type FooterSettingsCountry = {
  __typename?: 'FooterSettingsCountry';
  name: Scalars['String'];
  codeISO2: Scalars['String'];
  id: Scalars['String'];
  contactEmail: Scalars['String'];
  contactPhone: Scalars['String'];
  contactAddress: Scalars['String'];
  isSelected: Scalars['Boolean'];
};

export type FooterSiteSettings = {
  __typename?: 'FooterSiteSettings';
  isVisible: Scalars['Boolean'];
  showCurrencies: Scalars['Boolean'];
  showLanguages: Scalars['Boolean'];
  showCountries: Scalars['Boolean'];
  showVATToggle: Scalars['Boolean'];
  languages: Array<FooterSettingsLanguage>;
  currencies: Array<FooterSettingsCurrency>;
  countries: Array<FooterSettingsCountry>;
};

export type FooterLinkSection = {
  __typename?: 'FooterLinkSection';
  title: Scalars['String'];
  links: Array<Link>;
};

export type Footer = {
  __typename?: 'Footer';
  config: FooterConfig;
  labels: Array<Label>;
  columnLinks: Array<FooterLinkSection>;
  bottomLinks: FooterLinkSection;
  siteSettings: FooterSiteSettings;
  metaData: FooterMeta;
};

export type CustomerInfo = {
  __typename?: 'CustomerInfo';
  name?: Maybe<Scalars['String']>;
  isLoggedIn: Scalars['Boolean'];
  userCTA: Link;
  email?: Maybe<Scalars['String']>;
};

export type HeaderMeta = {
  __typename?: 'HeaderMeta';
  structuredData?: Maybe<Array<HtmlTag>>;
};

export type Header = {
  __typename?: 'Header';
  metaData: HeaderMeta;
  links: Array<Link>;
  labels: Array<Label>;
  basketTotal: FormattedNumber;
  noOfPartsLists: Scalars['Float'];
  noOfItemsInBasket: Scalars['Float'];
  basketCTA: Link;
  customer: CustomerInfo;
  siteSettings: SiteSettings;
};

export type RecentSearch = {
  __typename?: 'RecentSearch';
  searchQuery: Scalars['String'];
  url: Scalars['String'];
};

export type RecentSearches = {
  __typename?: 'RecentSearches';
  recentSearches: Array<RecentSearch>;
  labels: Array<Label>;
};

export type SuggestedSearchTerm = {
  __typename?: 'SuggestedSearchTerm';
  searchQuery: Scalars['String'];
  highlightedFragment: Scalars['String'];
  url: Scalars['String'];
  noOfMatches?: Maybe<Scalars['Float']>;
  parentSuggest?: Maybe<SuggestedSearchTerm>;
};

export type SuggestedSearches = {
  __typename?: 'SuggestedSearches';
  searchTerms: Array<SuggestedSearch>;
  labels: Array<Label>;
};

export type SuggestedSearch = SuggestedSearchTerm | SuggestedProduct;

export type SuggestedProduct = {
  __typename?: 'SuggestedProduct';
  brand: Scalars['String'];
  formattedStockCode: Scalars['String'];
  mpn: Scalars['String'];
  stockCode: Scalars['String'];
  title: Scalars['String'];
  primaryImageUrl: Scalars['String'];
  pdpUrl: Scalars['String'];
  highlightedFragment: Scalars['String'];
  isAlternative: Scalars['Boolean'];
  comparisonPageUrl?: Maybe<Scalars['String']>;
};

export type AddRecentSearchResponse = {
  __typename?: 'AddRecentSearchResponse';
  isSuccess: Scalars['Boolean'];
};

export type LifecyclePage = {
  __typename?: 'LifecyclePage';
  isSuccess: Scalars['Boolean'];
  html?: Maybe<Scalars['String']>;
};

export type MasterMeta = {
  __typename?: 'MasterMeta';
  htmlLang: Scalars['String'];
  metaTags?: Maybe<Array<HtmlTag>>;
  links?: Maybe<Array<HtmlTag>>;
};

export type MasterScripts = {
  __typename?: 'MasterScripts';
  head: Array<HtmlTag>;
  bodyEnd: Array<HtmlTag>;
};

export type MasterTaggingGtm = {
  __typename?: 'MasterTaggingGTM';
  gtmid: Scalars['String'];
  isEnabled: Scalars['Boolean'];
};

export type MasterTagging = {
  __typename?: 'MasterTagging';
  gtm: MasterTaggingGtm;
};

export type ContextInfo = {
  __typename?: 'ContextInfo';
  siteId: Scalars['Float'];
  langCode: Scalars['String'];
  locale: Locale;
  isBot: Scalars['Boolean'];
};

export enum Locale {
  Core = 'CORE',
  Ru = 'RU',
  Lv = 'LV',
  Lt = 'LT',
  Ly = 'LY',
  Cy = 'CY',
  Ee = 'EE',
  Fi = 'FI',
  Il = 'IL',
  Ro = 'RO',
  Mt = 'MT',
  Gr = 'GR',
  Tr = 'TR',
  In = 'IN',
  Ae = 'AE',
  Int = 'INT',
  Hr = 'HR',
  Ua = 'UA',
  Sa = 'SA',
  Az = 'AZ',
  Export = 'EXPORT',
  Cl = 'CL',
  Sk = 'SK',
  Br = 'BR',
  Id = 'ID',
  Kz = 'KZ',
  Af = 'AF',
  Eg = 'EG',
  Bh = 'BH',
  Bg = 'BG',
  Om = 'OM',
  Mo = 'MO'
}

export type MasterConfig = {
  __typename?: 'MasterConfig';
  enableSiteWideBanner: Scalars['Boolean'];
  enableMyAccount: Scalars['Boolean'];
};

export type SiteWideBanner = {
  __typename?: 'SiteWideBanner';
  type: Scalars['String'];
  headline: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
};

export type Master = {
  __typename?: 'Master';
  metaData: MasterMeta;
  scripts: MasterScripts;
  tagging: MasterTagging;
  contextInfo: ContextInfo;
  labels?: Maybe<Array<Label>>;
  config: MasterConfig;
  siteWideBanner?: Maybe<SiteWideBanner>;
};

export type MenuItem = {
  __typename?: 'MenuItem';
  id: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  link: Link;
  children?: Maybe<Array<Link>>;
};

export type FeaturedBrand = {
  __typename?: 'FeaturedBrand';
  name: Scalars['String'];
  link: Link;
  imageUrl: Scalars['String'];
};

export type FeaturedBrands = {
  __typename?: 'FeaturedBrands';
  brands: Array<FeaturedBrand>;
  viewAll: Link;
};

export type Menu = {
  __typename?: 'Menu';
  categories: Array<MenuItem>;
  otherLinks: Array<Link>;
  menuLinks: Array<Link>;
  labels: Array<Label>;
  featuredBrands: FeaturedBrands;
};


export type MenuCategoriesArgs = {
  parentId?: Maybe<Scalars['Int']>;
};

export type OnboardingSettingsLanguage = {
  __typename?: 'OnboardingSettingsLanguage';
  name: Scalars['String'];
  baseDirectory: Scalars['String'];
  countryCodeISO2: Scalars['String'];
  isSelected: Scalars['Boolean'];
};

export type OnboardingSettingsCurrency = {
  __typename?: 'OnboardingSettingsCurrency';
  displayName: Scalars['String'];
  name: Scalars['String'];
  symbol: Scalars['String'];
  id: Scalars['Float'];
  shortName: Scalars['String'];
};

export type OnboardingCountry = {
  __typename?: 'OnboardingCountry';
  siteId: Scalars['Float'];
  countryCode: Scalars['String'];
  countryDisplayName: Scalars['String'];
};

export type OnboardingSiteSettings = {
  __typename?: 'OnboardingSiteSettings';
  siteId: Scalars['Float'];
  domain: Scalars['String'];
  languages: Array<OnboardingSettingsLanguage>;
  currencies: Array<OnboardingSettingsCurrency>;
  showVATToggle: Scalars['Boolean'];
};

export type Onboarding = {
  __typename?: 'Onboarding';
  labels: Array<Label>;
  countries: Array<OnboardingCountry>;
  siteSettings: Array<OnboardingSiteSettings>;
};

export type PartsListProduct = {
  __typename?: 'PartsListProduct';
  stockCode: Scalars['String'];
  imageUrl: Scalars['String'];
  title: Scalars['String'];
  quantity: Scalars['Float'];
  container: Scalars['String'];
  price: FormattedNumber;
  energyArea?: Maybe<ProductEnergy>;
};

export type PartsList = {
  __typename?: 'PartsList';
  id: Scalars['Float'];
  title: Scalars['String'];
  noOfProducts: Scalars['Float'];
  totalPrice: Price;
  products: Array<PartsListProduct>;
  isContextProductInList: Scalars['Boolean'];
};

export type PartsLists = {
  __typename?: 'PartsLists';
  partsLists: Array<PartsList>;
};

export type AddPartsListsToBasketResponse = {
  __typename?: 'AddPartsListsToBasketResponse';
  isSuccess: Scalars['Boolean'];
};

export type AddToPartsListResponse = {
  __typename?: 'AddToPartsListResponse';
  isSuccess: Scalars['Boolean'];
  partsListId: Scalars['Float'];
  redirectUrl: Scalars['String'];
  requireLogin: Scalars['Boolean'];
};

export type EditPartsListResponse = {
  __typename?: 'EditPartsListResponse';
  isSuccess: Scalars['Boolean'];
};

export type ManualAddToPartsList_ResultDetailsLine = {
  __typename?: 'ManualAddToPartsList_ResultDetailsLine';
  stockCode: Scalars['String'];
  quantity: Scalars['Float'];
  link?: Maybe<Link>;
  message: Scalars['String'];
};

export type ManualAddToPartsList_ResultDetailsSection = {
  __typename?: 'ManualAddToPartsList_ResultDetailsSection';
  message: Scalars['String'];
  lineInfo: Array<ManualAddToPartsList_ResultDetailsLine>;
};

export type ManualAddToPartsList_ResultDetails = {
  __typename?: 'ManualAddToPartsList_ResultDetails';
  isSuccess: Scalars['Boolean'];
  title: Scalars['String'];
  message: Scalars['String'];
  productsNotFoundSection?: Maybe<ManualAddToPartsList_ResultDetailsSection>;
};

export type ManualAddToPartsListResponse = {
  __typename?: 'ManualAddToPartsListResponse';
  isSuccess: Scalars['Boolean'];
  partsListId: Scalars['Float'];
  redirectUrl: Scalars['String'];
  requireLogin: Scalars['Boolean'];
  errorMessage: Scalars['String'];
  result: ManualAddToPartsList_ResultDetails;
};

export type PartsListLabels = {
  __typename?: 'PartsListLabels';
  labels: Array<Label>;
};

export type PartsListPage = {
  __typename?: 'PartsListPage';
  labels?: Maybe<Array<Label>>;
  partsListsArea?: Maybe<PartsLists>;
  redirectUrl: Scalars['String'];
  requireLogin: Scalars['Boolean'];
};


export type PartsListPagePartsListsAreaArgs = {
  partsListId?: Maybe<Scalars['Int']>;
  contextStockCode?: Maybe<Scalars['String']>;
};

export type PdpAlternativesRsPro = {
  __typename?: 'PDPAlternativesRSPro';
  isVisible: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
  minimumPrice?: Maybe<FormattedNumber>;
};

export type PdpAttributeItem = {
  __typename?: 'PDPAttributeItem';
  name: Scalars['String'];
  value: Scalars['String'];
  nameId?: Maybe<Scalars['String']>;
  valueId?: Maybe<Scalars['String']>;
};

export type PdpAttributes = {
  __typename?: 'PDPAttributes';
  attributes: Array<PdpAttributeItem>;
};

export type PdpBreadcrumb = {
  __typename?: 'PDPBreadcrumb';
  links: Array<Link>;
  bookId: Scalars['Float'];
};

export type PdpTracePartsDataItem = {
  __typename?: 'PDPTracePartsDataItem';
  id: Scalars['Float'];
  name: Scalars['String'];
};

export type PdpTracePartsCadData = {
  __typename?: 'PDPTracePartsCADData';
  documents: Array<PdpTracePartsDataItem>;
};

export type PdpcadData = {
  __typename?: 'PDPCADData';
  tracePartsData: PdpTracePartsCadData;
};

export type PdpPriceBreakItem = {
  __typename?: 'PDPPriceBreakItem';
  displayName: Scalars['String'];
  maximum: Scalars['Float'];
  minimum: Scalars['Float'];
  packPrice: Price;
  unitPrice: Price;
};

export type PdpPricesItem = {
  __typename?: 'PDPPricesItem';
  quantity: Scalars['Float'];
  totalPrice: Price;
  unitPrice: Price;
};

export type PdpPricing = {
  __typename?: 'PDPPricing';
  priceBreaks: Array<PdpPriceBreakItem>;
  priceBreaksExVat: Array<PdpPriceBreakItem>;
  prices: Array<PdpPricesItem>;
  pricesExVat: Array<PdpPricesItem>;
  minimumOrderQuantity: Scalars['Float'];
  price: Price;
  priceDualCurrency?: Maybe<Price>;
  priceExVat: Price;
  priceExVatDualCurrency?: Maybe<Price>;
  showExVat: Scalars['Boolean'];
  container: Scalars['String'];
  packPriceHeader?: Maybe<Scalars['String']>;
  errorMessage: Scalars['String'];
  unitOfMeasureCode: Scalars['String'];
  unitOfMeasure: Scalars['String'];
  isInBasket: Scalars['Boolean'];
};

export type PdpCalibrationDetails = {
  __typename?: 'PDPCalibrationDetails';
  stockCode: Scalars['String'];
  isDefault: Scalars['Boolean'];
  title: Scalars['String'];
  description: Scalars['String'];
  pricing: PdpPricing;
};

export type PdpCalibration = {
  __typename?: 'PDPCalibration';
  hasCalibrationOptions: Scalars['Boolean'];
  details: Array<PdpCalibrationDetails>;
};

export type PdpConfig = {
  __typename?: 'PDPConfig';
  showDesignSparkArea: Scalars['Boolean'];
};

export type PdpDescriptionItem = {
  __typename?: 'PDPDescriptionItem';
  title: Scalars['String'];
  description: Scalars['String'];
};

export type PdpDescription = {
  __typename?: 'PDPDescription';
  descriptions: Array<PdpDescriptionItem>;
};

export type PdpDocumentItem = {
  __typename?: 'PDPDocumentItem';
  documentType: DocumentType;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  urlText?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  requestId: Scalars['String'];
  stockCode: Scalars['String'];
  tracePartsPartId?: Maybe<Scalars['String']>;
};

export enum DocumentType {
  Pdf = 'PDF',
  RohsCompliance = 'ROHSCompliance',
  ConformityStatement = 'ConformityStatement',
  TraceParts3DCad = 'TraceParts3DCad',
  ElrProductInformationSheet = 'ElrProductInformationSheet'
}

export type PdpDocuments = {
  __typename?: 'PDPDocuments';
  documents: Array<PdpDocumentItem>;
};

export type PcbAvailibilityCheck = {
  __typename?: 'PCBAvailibilityCheck';
  isPCBAvailable: Scalars['Boolean'];
};

export type PdpPunchoutInfo = {
  __typename?: 'PDPPunchoutInfo';
  isBarred: Scalars['Boolean'];
  isRestricted: Scalars['Boolean'];
};

export type PdpStickersItem = {
  __typename?: 'PDPStickersItem';
  position: PdpStickerPosition;
  id: Scalars['Float'];
  content: Scalars['String'];
  contentType: PdpStickerContenType;
};

export enum PdpStickerPosition {
  NotVisible = 'NotVisible',
  DetailsSectionBottom = 'DetailsSectionBottom',
  DescriptionLeft = 'DescriptionLeft',
  DetailsSection = 'DetailsSection',
  DescriptionRight = 'DescriptionRight'
}

export enum PdpStickerContenType {
  Html = 'HTML',
  ImageUrl = 'ImageUrl'
}

export type PdpStickers = {
  __typename?: 'PDPStickers';
  stickers: Array<PdpStickersItem>;
};

export type PdppcbDetails = {
  __typename?: 'PDPPCBDetails';
  brand: Scalars['String'];
  mpn: Scalars['String'];
  langCode: Scalars['String'];
};

export type PdpMediaItem = {
  __typename?: 'PDPMediaItem';
  id: Scalars['String'];
  imageType?: Maybe<ImageType>;
  mediaType: MediaType;
  name: Scalars['String'];
  url: Scalars['String'];
  thumbnailUrl: Scalars['String'];
};

export enum ImageType {
  PrimaryImage = 'PRIMARY_IMAGE',
  ColourPhotograph = 'COLOUR_PHOTOGRAPH',
  VendorLogo = 'VENDOR_LOGO',
  UkEnergyLabelImage = 'UK_ENERGY_LABEL_IMAGE',
  EuEnergyLabelImage = 'EU_ENERGY_LABEL_IMAGE'
}

export enum MediaType {
  Image = 'IMAGE',
  YoutubeVideo = 'YOUTUBE_VIDEO',
  View_3D = 'VIEW_3D'
}

export type PdpMedia = {
  __typename?: 'PDPMedia';
  media: Array<PdpMediaItem>;
  vendorLogo?: Maybe<PdpMediaItem>;
  mediaUrl: Scalars['String'];
  showPCBFootprint: Scalars['Boolean'];
  disclaimerMessage: Scalars['String'];
  pcbFootprintParameters?: Maybe<PdppcbDetails>;
  isZoomEnabled: Scalars['Boolean'];
};

export type ProductDetailPageMeta = {
  __typename?: 'ProductDetailPageMeta';
  structuredData: Scalars['String'];
  metaTags?: Maybe<Array<HtmlTag>>;
  pageTitle: Scalars['String'];
  canonicalUrl: Scalars['String'];
  links?: Maybe<Array<HtmlTag>>;
};

export type Pdpppk = {
  __typename?: 'PDPPPK';
  isVisible: Scalars['Boolean'];
  isEnabled: Scalars['Boolean'];
  packageType?: Maybe<Scalars['String']>;
  unitOfMeasure?: Maybe<Scalars['String']>;
  unitOfMeasureCode?: Maybe<Scalars['String']>;
  standardUnitOfMeasure?: Maybe<Scalars['String']>;
  standardUnitOfMeasureCode?: Maybe<Scalars['String']>;
  standardStockCode: Scalars['String'];
  standardMinimumOrderQuantity?: Maybe<Scalars['Float']>;
  ppkStockCode: Scalars['String'];
  ppkSSM?: Maybe<Scalars['Float']>;
  minimumOrderQuantity?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type PdpProductType = {
  __typename?: 'PDPProductType';
  isVisible: Scalars['Boolean'];
  title?: Maybe<Scalars['String']>;
  modalCTA?: Maybe<Scalars['String']>;
  modalTitle?: Maybe<Scalars['String']>;
  modalMessage?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  secondaryMessages?: Maybe<Array<PdpProductType>>;
  mode?: Maybe<ProductTypeMode>;
};

export type PdpStock = {
  __typename?: 'PDPStock';
  statusCode: StockStatus;
  title: Scalars['String'];
  stockMessage: Scalars['String'];
  dtpStockMessages?: Maybe<Array<Scalars['String']>>;
  infoMessage: Scalars['String'];
  isUsingDTP: Scalars['Boolean'];
  denyBackOrder?: Maybe<Scalars['Boolean']>;
  availabilityDate?: Maybe<Scalars['DateTime']>;
  stockAvailability?: Maybe<Array<StockAvailability>>;
  includesDate: Scalars['Boolean'];
  stockBadgeIcon?: Maybe<Scalars['String']>;
};

export type PdpTaggingGtm = {
  __typename?: 'PDPTaggingGTM';
  dataLayer: HtmlTag;
};

export type PdpTagging = {
  __typename?: 'PDPTagging';
  gtm: PdpTaggingGtm;
  tags: Array<HtmlTag>;
};

export type PdpTitle = {
  __typename?: 'PDPTitle';
  brand: Scalars['String'];
  formattedStockCode: Scalars['String'];
  hsnCode?: Maybe<Scalars['String']>;
  mpn: Scalars['String'];
  stockCode: Scalars['String'];
  title: Scalars['String'];
  has3DCADData: Scalars['Boolean'];
  allow3DCadDownload: Scalars['Boolean'];
  impaCode?: Maybe<Scalars['String']>;
  distrelecProductId?: Maybe<Scalars['String']>;
  familyId?: Maybe<Scalars['Float']>;
};

export type ProductDetailPage = {
  __typename?: 'ProductDetailPage';
  metaData?: Maybe<ProductDetailPageMeta>;
  tagging?: Maybe<PdpTagging>;
  labels?: Maybe<Array<Label>>;
  config: PdpConfig;
  titleArea?: Maybe<PdpTitle>;
  mediaArea?: Maybe<PdpMedia>;
  priceArea?: Maybe<PdpPricing>;
  ppkArea?: Maybe<Pdpppk>;
  stockArea?: Maybe<PdpStock>;
  documentsArea: PdpDocuments;
  isSuccess: Scalars['Boolean'];
  allowAddToPartsList: Scalars['Boolean'];
  punchoutInfoArea: PdpPunchoutInfo;
  productTypeArea: PdpProductType;
  descriptionArea: PdpDescription;
  attributesArea: PdpAttributes;
  stickersArea: PdpStickers;
  breadcrumb: PdpBreadcrumb;
  pdpUrl: Scalars['String'];
  alternativesRSPro: PdpAlternativesRsPro;
  energyArea?: Maybe<ProductEnergy>;
  calibrationArea?: Maybe<PdpCalibration>;
  alternativeProducts: Array<ProductDetailPage>;
};


export type ProductDetailPageMediaAreaArgs = {
  mediaType?: Maybe<Array<MediaType>>;
  imageType?: Maybe<Array<ImageType>>;
};


export type ProductDetailPagePriceAreaArgs = {
  quantity?: Maybe<Scalars['Int']>;
};


export type ProductDetailPageStockAreaArgs = {
  queryStock?: Maybe<Scalars['Boolean']>;
};


export type ProductDetailPageDocumentsAreaArgs = {
  requestId: Scalars['String'];
};

export type AddToBasketResponse = {
  __typename?: 'AddToBasketResponse';
  isSuccess: Scalars['Boolean'];
  tags: Array<AddToBasketResponseTags>;
};

export type AddToBasketResponseTags = AddToBasketEnsighten | AddToBasketGoogleAnalytics | AddToBasketYandex | AddToBasketTealium | Ga4Event_AddToCart;

export type AddToBasketYandex = {
  __typename?: 'AddToBasketYandex';
  script: Scalars['String'];
};

export type SlugifyStringDataItem = {
  __typename?: 'SlugifyStringDataItem';
  origString: Scalars['String'];
  slug: Scalars['String'];
};

export type SlugifyStringsResponse = {
  __typename?: 'SlugifyStringsResponse';
  data: Array<SlugifyStringDataItem>;
};

export type GetPdpUrlsDataItem = {
  __typename?: 'GetPDPUrlsDataItem';
  siteId: Scalars['Float'];
  langCode: Scalars['String'];
  stockCode: Scalars['String'];
  url: Scalars['String'];
};

export type GetPdpUrlsResponse = {
  __typename?: 'GetPDPUrlsResponse';
  data: Array<GetPdpUrlsDataItem>;
};

export type FacetValue = {
  __typename?: 'FacetValue';
  id: Scalars['String'];
  displayValue?: Maybe<Scalars['String']>;
  resultsCount: Scalars['Float'];
  disabled?: Maybe<Scalars['Boolean']>;
};

export type Facet = {
  __typename?: 'Facet';
  id: Scalars['String'];
  displayName: Scalars['String'];
  values: Array<FacetValue>;
};

export type PlpFacets = {
  __typename?: 'PLPFacets';
  facets: Array<Facet>;
  flagFacets: Array<FacetValue>;
  appliedFilters: Array<Facet>;
  appliedFlagFilters: Array<FacetValue>;
};

export type PlpPricesItem = {
  __typename?: 'PLPPricesItem';
  quantity: Scalars['Float'];
  totalPrice: Price;
  unitPrice: Price;
};

export type PlpPricing = {
  __typename?: 'PLPPricing';
  minimumOrderQuantity: Scalars['Float'];
  price: Price;
  container: Scalars['String'];
  isInBasket: Scalars['Boolean'];
};

export type PlpAttributeItem = {
  __typename?: 'PLPAttributeItem';
  name: Scalars['String'];
  value: Scalars['String'];
  attributeNameId: Scalars['String'];
  attributeValueId: Scalars['String'];
};

export type PlpCategoryAttribute = {
  __typename?: 'PLPCategoryAttribute';
  name: Scalars['String'];
  id: Scalars['String'];
};

export type PlpCategoryAttributes = {
  __typename?: 'PLPCategoryAttributes';
  attributes: Array<PlpCategoryAttribute>;
};

export type Plpppk = {
  __typename?: 'PLPPPK';
  isVisible: Scalars['Boolean'];
  pdpURL: Scalars['String'];
};

export type PlpProductType = {
  __typename?: 'PLPProductType';
  isVisible: Scalars['Boolean'];
  title?: Maybe<Scalars['String']>;
  modalCTA?: Maybe<Scalars['String']>;
  modalTitle?: Maybe<Scalars['String']>;
  modalMessage?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  secondaryMessages?: Maybe<Array<PlpProductType>>;
  mode?: Maybe<ProductTypeMode>;
};

export type PlpPunchoutInfo = {
  __typename?: 'PLPPunchoutInfo';
  isBarred: Scalars['Boolean'];
  isRestricted: Scalars['Boolean'];
};

export type PlpStock = {
  __typename?: 'PLPStock';
  statusCode: StockStatus;
  title: Scalars['String'];
  stockMessage: Scalars['String'];
  dtpStockMessages?: Maybe<Array<Scalars['String']>>;
  infoMessage: Scalars['String'];
  isUsingDTP: Scalars['Boolean'];
  denyBackOrder?: Maybe<Scalars['Boolean']>;
  availabilityDate?: Maybe<Scalars['DateTime']>;
  stockAvailability?: Maybe<Array<StockAvailability>>;
  includesDate: Scalars['Boolean'];
  stockBadgeIcon?: Maybe<Scalars['String']>;
};

export type PlpTitle = {
  __typename?: 'PLPTitle';
  brand: Scalars['String'];
  formattedStockCode: Scalars['String'];
  hsnCode: Scalars['String'];
  impaCode?: Maybe<Scalars['String']>;
  mpn: Scalars['String'];
  stockCode: Scalars['String'];
  title: Scalars['String'];
  primaryImageUrl: Scalars['String'];
  pdpUrl: Scalars['String'];
};

export type PlpProduct = {
  __typename?: 'PLPProduct';
  attributes: Array<PlpAttributeItem>;
  categoryAttributes: Array<PlpAttributeItem>;
  title: PlpTitle;
  priceArea: PlpPricing;
  stockArea: PlpStock;
  ppkArea: Plpppk;
  productTypeArea: PlpProductType;
  punchoutInfoArea: PlpPunchoutInfo;
  energyArea?: Maybe<ProductEnergy>;
};

export type PlpProductsConfig = {
  __typename?: 'PLPProductsConfig';
  showYourPrice: Scalars['Boolean'];
  isLoggedIn: Scalars['Boolean'];
  queryStock: Scalars['Boolean'];
};

export type PlpProducts = {
  __typename?: 'PLPProducts';
  products: Array<PlpProduct>;
  categoryAttributes?: Maybe<PlpCategoryAttributes>;
  config: PlpProductsConfig;
};

export type PlpSummary = {
  __typename?: 'PLPSummary';
  totalProducts: Scalars['Float'];
};

export type PlpCategoryItem = {
  __typename?: 'PLPCategoryItem';
  id: Scalars['Float'];
  name: Scalars['String'];
  noOfChildren: Scalars['Float'];
  noOfProducts: Scalars['Float'];
  childCategories?: Maybe<Array<PlpCategoryItem>>;
};

export type ProductListPage = {
  __typename?: 'ProductListPage';
  redirectUrl: Scalars['String'];
  categoryId: Scalars['Float'];
  isSuccess: Scalars['Boolean'];
  categoryName: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  labels: Array<Label>;
  productsArea?: Maybe<PlpProducts>;
  facetsArea?: Maybe<PlpFacets>;
  summary?: Maybe<PlpSummary>;
  categoriesArea: Array<PlpCategoryItem>;
  productPrices: Array<PlpPricesItem>;
  tagging: PlpTagging;
  metaData: PlpMeta;
  config: PlpProductsConfig;
  breadcrumb: Array<Link>;
};


export type ProductListPageProductsAreaArgs = {
  queryStock?: Maybe<Scalars['Boolean']>;
};


export type ProductListPageProductPricesArgs = {
  stockCode: Scalars['String'];
};

export type CompareAlternativesPage = {
  __typename?: 'CompareAlternativesPage';
  labels: Array<Label>;
  redirectUrl: Scalars['String'];
  productsArea: Array<PlpProduct>;
};

export type NoResultsPage = {
  __typename?: 'NoResultsPage';
  labels: Array<Label>;
  tagging: PlpTagging;
  metaData: PlpMeta;
};

export type AddressFieldsInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetComplement?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
  hasTaxCertificateNumber?: Maybe<Scalars['Boolean']>;
  taxCertificateNumber?: Maybe<Scalars['String']>;
  eoriNumber?: Maybe<Scalars['String']>;
  taxOfficeName?: Maybe<Scalars['String']>;
  paymentDepartmentNumber?: Maybe<Scalars['String']>;
  gstCustomerType?: Maybe<GstCustomerType>;
  gstRegistrationType?: Maybe<GstRegistrationType>;
  gstRegistrationNumber?: Maybe<Scalars['String']>;
  panCardNumber?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  header: Header;
  getRecentSearches: RecentSearches;
  getSuggestedSearches: SuggestedSearches;
  footer: Footer;
  menu: Menu;
  categoryListPage: CategoryListPage;
  productsList: Array<ProductListPage>;
  productListPage: ProductListPage;
  noResultsPage: NoResultsPage;
  compareAlternativesPage: CompareAlternativesPage;
  master: Master;
  cookiePolicy: CookiePolicy;
  campaignPage: CampaignPage;
  checkPCBAvailability: PcbAvailibilityCheck;
  productDetailPage: ProductDetailPage;
  productDetailPages: Array<ProductDetailPage>;
  dataSheets: Array<PdpDocumentItem>;
  cadDocuments: PdpcadData;
  tracePartsDocumentUrl: Scalars['String'];
  partsListPage: PartsListPage;
  getPartsListLabels: PartsListLabels;
  countryStates: CountryStatesResponse;
  validateVATDetails: ValidateVatDetailsResponse;
  searchAddress: SearchAddressResponse;
  countryAddressConfiguration: CountryAddressConfigurationResponse;
  addressList: AddressList;
  addressListV2: AddressList;
  loginModal: LoginModal;
  validateEmail: ValidateEmailResponse;
  recentlyViewed: RecentlyViewed;
  orderHistoryPage: OrderHistoryPage;
  orderDetailsPage: OrderDetailsPage;
  myAccountPartsListsPage: MyAccountPartsListsPage;
  myAccountPartsListDetailsPage: MyAccountPartsListDetailsPage;
  registrationPage: RegistrationPage;
  registrationTokenExpiredPage: RegistrationTokenExpiredPage;
  profilePage: ProfilePage;
  loginPage: LoginPage;
  forgotPasswordPage: ForgotPasswordPage;
  resetPasswordPage: ResetPasswordPage;
  account: Account;
  accountOverviewPage: AccountOverviewPage;
  slugifyStrings: SlugifyStringsResponse;
  getPDPUrls: GetPdpUrlsResponse;
  technicalErrorPage: TechnicalErrorPage;
  notFoundErrorPage: NotFoundErrorPage;
  basketPage: BasketPage;
  purchasePage: PurchasePage;
  orderConfirmationPage: OrderConfirmationPage;
  onboarding: Onboarding;
  emailCampaignPage: EmailCampaignPage;
  lifecyclePage: LifecyclePage;
};


export type QueryGetSuggestedSearchesArgs = {
  searchQuery: Scalars['String'];
};


export type QueryCategoryListPageArgs = {
  id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryProductsListArgs = {
  stockCodes: Array<Scalars['String']>;
};


export type QueryProductListPageArgs = {
  id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  searchQuery?: Maybe<Scalars['String']>;
  tagName?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<SearchSort>;
  selector?: Maybe<SearchSelector>;
};


export type QueryNoResultsPageArgs = {
  searchQuery: Scalars['String'];
  isProductNotFound?: Maybe<Scalars['Boolean']>;
};


export type QueryCompareAlternativesPageArgs = {
  stockCode: Scalars['String'];
};


export type QueryMasterArgs = {
  includeEnsighten?: Maybe<Scalars['Boolean']>;
};


export type QueryCampaignPageArgs = {
  url: Scalars['String'];
  isPreview: Scalars['Boolean'];
};


export type QueryCheckPcbAvailabilityArgs = {
  manufacturer: Scalars['String'];
  partNumber: Scalars['String'];
};


export type QueryProductDetailPageArgs = {
  stockCode: Scalars['String'];
};


export type QueryProductDetailPagesArgs = {
  stockCodes: Array<Scalars['String']>;
};


export type QueryDataSheetsArgs = {
  stockCode: Scalars['String'];
  documentsType: Scalars['String'];
};


export type QueryCadDocumentsArgs = {
  stockCode: Scalars['String'];
  tracePartsPartId?: Maybe<Scalars['String']>;
};


export type QueryTracePartsDocumentUrlArgs = {
  stockCode: Scalars['String'];
  tracePartsPartId?: Maybe<Scalars['String']>;
  documentId: Scalars['Float'];
};


export type QueryCountryStatesArgs = {
  countryCode: Scalars['String'];
};


export type QueryValidateVatDetailsArgs = {
  input: ValidateVatDetailsRequest;
};


export type QuerySearchAddressArgs = {
  input: SearchAddressRequest;
};


export type QueryCountryAddressConfigurationArgs = {
  countryCode: Scalars['String'];
};


export type QueryAddressListArgs = {
  addressIdCipher?: Maybe<Scalars['String']>;
  type: AddressType;
  countryCode?: Maybe<Scalars['String']>;
};


export type QueryAddressListV2Args = {
  input: AddressListRequest;
};


export type QueryValidateEmailArgs = {
  input: ValidateEmailRequest;
};


export type QueryOrderHistoryPageArgs = {
  searchQuery?: Maybe<Scalars['String']>;
  sort?: Maybe<OrderSort>;
  page?: Maybe<OrderPage>;
};


export type QueryOrderDetailsPageArgs = {
  orderId: Scalars['Float'];
};


export type QueryMyAccountPartsListsPageArgs = {
  searchQuery?: Maybe<Scalars['String']>;
  sort?: Maybe<PartsListsSort>;
  page?: Maybe<PartsListsPage>;
};


export type QueryMyAccountPartsListDetailsPageArgs = {
  partsListId: Scalars['Float'];
};


export type QueryRegistrationPageArgs = {
  token?: Maybe<Scalars['String']>;
};


export type QueryResetPasswordPageArgs = {
  token: Scalars['String'];
};


export type QuerySlugifyStringsArgs = {
  data: SlugifyStringsRequest;
};


export type QueryGetPdpUrlsArgs = {
  data: GetPdpUrlsRequest;
};


export type QueryBasketPageArgs = {
  asQuote?: Maybe<Scalars['Boolean']>;
};


export type QueryEmailCampaignPageArgs = {
  data: EmailCampaignPageRequest;
};


export type QueryLifecyclePageArgs = {
  data: LifecyclePageRequest;
};

export type SearchSort = {
  sortField: Scalars['String'];
  direction: Scalars['Float'];
};

export type SearchSelector = {
  facets: Array<FacetFilter>;
};

export type FacetFilter = {
  facetId: Scalars['String'];
  facetValueId: Scalars['String'];
};

export type ValidateVatDetailsRequest = {
  countryCode: Scalars['String'];
  vatNumber: Scalars['String'];
};

export type SearchAddressRequest = {
  countryCode: Scalars['String'];
  postCode: Scalars['String'];
};

export enum AddressType {
  Any = 'ANY',
  Billing = 'BILLING',
  Delivery = 'DELIVERY'
}

export type AddressListRequest = {
  addressIdCipher?: Maybe<Scalars['Float']>;
  addressType: AddressType;
  countryCode?: Maybe<Scalars['String']>;
  accountType?: Maybe<AddressList_AccountType>;
};

export enum AddressList_AccountType {
  Business = 'business',
  Individual = 'individual'
}

export type ValidateEmailRequest = {
  email: Scalars['String'];
};

export type OrderSort = {
  sortField: OrderSortFieldType;
  direction: SortDirection;
};

export enum OrderSortFieldType {
  DateCreated = 'DATE_CREATED',
  ReferenceWeb = 'REFERENCE_WEB',
  CustomerOrderReference = 'CUSTOMER_ORDER_REFERENCE',
  GrandTotal = 'GRAND_TOTAL'
}

export enum SortDirection {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export type OrderPage = {
  first?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type PartsListsSort = {
  sortField: PartsListsSortFieldType;
  direction: SortDirection;
};

export enum PartsListsSortFieldType {
  Name = 'NAME',
  DateModified = 'DATE_MODIFIED',
  NoItems = 'NO_ITEMS',
  Total = 'TOTAL'
}

export type PartsListsPage = {
  first?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

export type SlugifyStringsRequest = {
  strings?: Array<Scalars['String']>;
};

export type GetPdpUrlsRequest = {
  stockCodes?: Array<Scalars['String']>;
};

export type EmailCampaignPageRequest = {
  token: Scalars['String'];
};

export type LifecyclePageRequest = {
  token: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addRecentSearch: AddRecentSearchResponse;
  removeRecentSearch: Scalars['Boolean'];
  removeAllRecentSearches: Scalars['Boolean'];
  clearCache: Scalars['String'];
  addToBasket: Scalars['Boolean'];
  addToBasketV2: AddToBasketResponse;
  /** @deprecated Prefer using addToPartsListV2 */
  addToPartsList: Scalars['Boolean'];
  addToPartsListV2: AddToPartsListResponse;
  addToPartsListV3: AddToPartsListResponse;
  manualAddToPartsList: ManualAddToPartsListResponse;
  addPartsListsToBasket: AddPartsListsToBasketResponse;
  savePartsList: Scalars['Boolean'];
  editPartsList: EditPartsListResponse;
  storeData: DataCaptureResponse;
  sendDocumentCommunication: SendDocumentCommunicationResponse;
  saveDeliveryAddress: SaveAddressResponse;
  saveBillingAddress: SaveAddressResponse;
  deleteAddress: DeleteAddressResponse;
  login: LoginResponse;
  oktaLogin: OktaLoginResponse;
  oktaTokenExchange: OktaTokenExchangeResponse;
  oktaLoginByEmail: OktaLoginByEmailResponse;
  logout: LogoutResponse;
  saveCustomer: SaveCustomerResponse;
  resetPassword: ResetPasswordResponse;
  resetPasswordTransaction: ResetPasswordTransactionResponse;
  emailSubscriptionTransaction: EmailSubscriptionTransactionRepsonse;
  updateCustomer: UpdateCustomerResponse;
  shareProductsTransaction: ShareProductsTransactionResponse;
  addRecentlyViewed: AddRecentlyViewedResponse;
  deletePartsList: DeletePartsListResponse;
  deletePartsListLines: DeletePartsListLinesResponse;
  updatePartsListLine: UpdatePartsListLineResponse;
  removeFromBasket: RemoveFromBasketResponse;
  clearBasket: RemoveFromBasketResponse;
  setDeliveryMethod: SetDeliveryMethodResponse;
  updateLines: UpdateBasketLinesResponse;
  bulkAddToBasket: BulkAddToBasketResponse;
  setPromoCode: SetPromoCodeResponse;
  removePromoCode: SetPromoCodeResponse;
  addBasketService: AddBasketServiceResponse;
  removeBasketService: RemoveBasketServiceResponse;
  savePersonalOrderReference: Purchase_SavePersonalOrderReferenceResponse;
  saveContactDetails: Purchase_SaveContactDetailsResponse;
  setBasketDeliveryAddress: Purchase_SetBasketAddressResponse;
  setBasketBillingAddress: Purchase_SetBasketAddressResponse;
  setBasketPaymentOption: Purchase_SetBasketPaymentOptionResponse;
  confirmBasketAsOrder: Purchase_ConfirmOrderResponse;
  confirmBasketAsQuote: Purchase_ConfirmQuoteResponse;
};


export type MutationAddRecentSearchArgs = {
  searchQuery: Scalars['String'];
};


export type MutationRemoveRecentSearchArgs = {
  searchQuery: Scalars['String'];
};


export type MutationAddToBasketArgs = {
  stockCode: Scalars['String'];
  quantity: Scalars['Float'];
};


export type MutationAddToBasketV2Args = {
  stockCode: Scalars['String'];
  quantity: Scalars['Float'];
  pageType?: Maybe<PageType>;
};


export type MutationAddToPartsListArgs = {
  stockCode: Scalars['String'];
  quantity: Scalars['Float'];
  partsListId?: Maybe<Scalars['Float']>;
  partsListName?: Maybe<Scalars['String']>;
  partsListDescription?: Maybe<Scalars['String']>;
};


export type MutationAddToPartsListV2Args = {
  data: AddToPartsListRequest;
};


export type MutationAddToPartsListV3Args = {
  data: AddToPartsListRequestV3;
};


export type MutationManualAddToPartsListArgs = {
  data: ManualAddToPartsListRequest;
};


export type MutationAddPartsListsToBasketArgs = {
  data: AddPartsListsToBasketRequest;
};


export type MutationSavePartsListArgs = {
  title: Scalars['String'];
  description: Scalars['String'];
  partsListId?: Maybe<Scalars['Int']>;
};


export type MutationEditPartsListArgs = {
  data: EditPartsListRequest;
};


export type MutationStoreDataArgs = {
  data: DataCaptureRequest;
};


export type MutationSendDocumentCommunicationArgs = {
  data: SendDocumentCommmunicationRequest;
};


export type MutationSaveDeliveryAddressArgs = {
  input: SaveAddressRequest;
};


export type MutationSaveBillingAddressArgs = {
  input: SaveAddressRequest;
};


export type MutationDeleteAddressArgs = {
  input: DeleteAddressRequest;
};


export type MutationLoginArgs = {
  data: LoginRequest;
};


export type MutationOktaLoginArgs = {
  data: OktaLoginRequest;
};


export type MutationOktaTokenExchangeArgs = {
  data: OktaTokenExchangeRequest;
};


export type MutationOktaLoginByEmailArgs = {
  data: OktaLoginByEmailRequest;
};


export type MutationSaveCustomerArgs = {
  input: SaveCustomerRequest;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordRequest;
};


export type MutationResetPasswordTransactionArgs = {
  input: ResetPasswordTransactionRequest;
};


export type MutationEmailSubscriptionTransactionArgs = {
  input: EmailSubscriptionTransactionRequest;
};


export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerRequest;
};


export type MutationShareProductsTransactionArgs = {
  input: ShareProductsTransactionRequest;
};


export type MutationAddRecentlyViewedArgs = {
  input: AddRecentlyViewedRequest;
};


export type MutationDeletePartsListArgs = {
  input: DeletePartsListRequest;
};


export type MutationDeletePartsListLinesArgs = {
  input: DeletePartsListLinesRequest;
};


export type MutationUpdatePartsListLineArgs = {
  input: UpdatePartsListLineRequest;
};


export type MutationRemoveFromBasketArgs = {
  lineIds: Array<Scalars['Int']>;
};


export type MutationSetDeliveryMethodArgs = {
  data: SetDeliveryMethodRequest;
};


export type MutationUpdateLinesArgs = {
  data: UpdateBasketLinesRequest;
};


export type MutationBulkAddToBasketArgs = {
  data: BulkAddToBasketRequest;
};


export type MutationSetPromoCodeArgs = {
  data: SetPromoCodeRequest;
};


export type MutationAddBasketServiceArgs = {
  data: AddBasketServiceRequest;
};


export type MutationRemoveBasketServiceArgs = {
  data: RemoveBasketServiceRequest;
};


export type MutationSavePersonalOrderReferenceArgs = {
  input: Purchase_SavePersonalOrderReferenceRequest;
};


export type MutationSaveContactDetailsArgs = {
  data: Purchase_SaveContactDetailsRequest;
};


export type MutationSetBasketDeliveryAddressArgs = {
  input: Purchase_SetBasketAddressRequest;
};


export type MutationSetBasketBillingAddressArgs = {
  input: Purchase_SetBasketAddressRequest;
};


export type MutationSetBasketPaymentOptionArgs = {
  input: Purchase_SetBasketPaymentOptionRequest;
};


export type MutationConfirmBasketAsOrderArgs = {
  input: Purchase_ConfirmOrderRequest;
};


export type MutationConfirmBasketAsQuoteArgs = {
  input: Purchase_ConfirmQuoteRequest;
};

export enum PageType {
  Clp = 'CLP',
  Plp = 'PLP',
  Pdp = 'PDP',
  Srp = 'SRP',
  Hp = 'HP',
  Basket = 'Basket'
}

export type AddToPartsListRequest = {
  stockCode: Scalars['String'];
  quantity: Scalars['Float'];
  partsListId?: Maybe<Scalars['Float']>;
  partsListName?: Maybe<Scalars['String']>;
  partsListDescription?: Maybe<Scalars['String']>;
};

export type AddToPartsListRequestV3 = {
  products: Array<AddToPartsListProduct>;
  partsListId?: Maybe<Scalars['Float']>;
  partsListName?: Maybe<Scalars['String']>;
  partsListDescription?: Maybe<Scalars['String']>;
};

export type AddToPartsListProduct = {
  stockCode: Scalars['String'];
  quantity: Scalars['Float'];
};

export type ManualAddToPartsListRequest = {
  products: Array<ManualAddToPartsListProduct>;
  partsListId?: Maybe<Scalars['Float']>;
  partsListName?: Maybe<Scalars['String']>;
  partsListDescription?: Maybe<Scalars['String']>;
};

export type ManualAddToPartsListProduct = {
  stockCode?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type AddPartsListsToBasketRequest = {
  partsLists: Array<AddToPartsListsToBasket_PartsList>;
  appendToOrder: Scalars['Boolean'];
};

export type AddToPartsListsToBasket_PartsList = {
  partsListId: Scalars['Float'];
  quantity: Scalars['Float'];
};

export type EditPartsListRequest = {
  partsListId?: Maybe<Scalars['Float']>;
  newPartsListName: Scalars['String'];
};

export type DataCaptureRequest = {
  fields?: Array<DataCaptureField>;
};

export type DataCaptureField = {
  name: Scalars['String'];
  value: Scalars['String'];
  type: Scalars['String'];
};

export type SendDocumentCommmunicationRequest = {
  documentCode: Scalars['String'];
};

export type SaveAddressRequest = {
  address: AddressFieldsInput;
  uniqueId?: Maybe<Scalars['String']>;
};

export type DeleteAddressRequest = {
  uniqueId?: Maybe<Scalars['String']>;
};

export type LoginRequest = {
  username: Scalars['String'];
  password: Scalars['String'];
};

export type OktaLoginRequest = {
  username: Scalars['String'];
  password: Scalars['String'];
};

export type OktaTokenExchangeRequest = {
  accessToken: Scalars['String'];
  authUserId: Scalars['String'];
};

export type OktaLoginByEmailRequest = {
  username: Scalars['String'];
};

export type SaveCustomerRequest = {
  customer: CustomerFields;
};

export type CustomerFields = {
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  industry: Scalars['Float'];
  purchasingFor?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  subscribed?: Maybe<Scalars['Boolean']>;
};

export type ResetPasswordRequest = {
  resetPassword: ResetPasswordFields;
};

export type ResetPasswordFields = {
  token?: Maybe<Scalars['String']>;
  password: Scalars['String'];
};

export type ResetPasswordTransactionRequest = {
  identifier: Scalars['String'];
};

export type EmailSubscriptionTransactionRequest = {
  token: Scalars['String'];
  email: Scalars['String'];
  subscribe: Scalars['Boolean'];
};

export type UpdateCustomerRequest = {
  useOwnPartNumber?: Maybe<Scalars['Boolean']>;
};

export type ShareProductsTransactionRequest = {
  senderEmail?: Maybe<Scalars['String']>;
  recipientEmails: Array<Scalars['String']>;
  optionalMessage?: Maybe<Scalars['String']>;
  productQuantities: Array<ProductQuantity>;
  productPartNumbers: Array<ProductPartNumber>;
  shareType: ShareProductsType;
};

export type ProductQuantity = {
  stockCode: Scalars['String'];
  quantity: Scalars['Float'];
};

export type ProductPartNumber = {
  stockCode: Scalars['String'];
  partNumber: Scalars['String'];
};

export enum ShareProductsType {
  Basket = 'basket',
  PartsList = 'partsList'
}

export type AddRecentlyViewedRequest = {
  stockcode: Scalars['String'];
  familyId: Scalars['Float'];
  brand: Scalars['String'];
};

export type DeletePartsListRequest = {
  partsListId: Scalars['Float'];
};

export type DeletePartsListLinesRequest = {
  partsListId: Scalars['Float'];
  partsListLineIds: Array<Scalars['Float']>;
};

export type UpdatePartsListLineRequest = {
  partsListId: Scalars['Float'];
  partsListLineId: Scalars['Float'];
  quantity: Scalars['Float'];
};

export type SetDeliveryMethodRequest = {
  deliveryMethodId: Scalars['Float'];
  deliveryCountryCode: Scalars['String'];
  invoiceCountryCode: Scalars['String'];
  endDeliveryCountryCode: Scalars['String'];
  courierAccountNumber?: Maybe<Scalars['String']>;
};

export type UpdateBasketLinesRequest = {
  lines?: Array<BasketLine>;
};

export type BasketLine = {
  lineId: Scalars['Float'];
  stockCode?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  partNumber?: Maybe<Scalars['String']>;
};

export type BulkAddToBasketRequest = {
  lines?: Array<BulkAddToBasket_BasketLine>;
  requestId: Scalars['String'];
};

export type BulkAddToBasket_BasketLine = {
  stockCode?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type SetPromoCodeRequest = {
  promoCode: Scalars['String'];
};

export type AddBasketServiceRequest = {
  serviceId: Scalars['Float'];
  deliveryCountryCode: Scalars['String'];
  invoiceCountryCode: Scalars['String'];
  endDeliveryCountryCode: Scalars['String'];
};

export type RemoveBasketServiceRequest = {
  serviceId: Scalars['Float'];
};

export type Purchase_SavePersonalOrderReferenceRequest = {
  personalOrderReference: Scalars['String'];
};

export type Purchase_SaveContactDetailsRequest = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNo: Scalars['String'];
  accountType?: Maybe<Purchase_SaveContactDetails_AccountType>;
  taxRegistrationNumber?: Maybe<Scalars['String']>;
};

export type Purchase_SetBasketAddressRequest = {
  addressUniqueId: Scalars['String'];
};

export type Purchase_SetBasketPaymentOptionRequest = {
  paymentOptionId: Scalars['Float'];
  purchaserId?: Maybe<Scalars['Float']>;
};

export type Purchase_ConfirmOrderRequest = {
  personalOrderReference?: Maybe<Scalars['String']>;
  endDeliveryCountryDetails?: Maybe<Purchase_ConfirmOrderRequest_EndCountryDetails>;
};

export type Purchase_ConfirmOrderRequest_EndCountryDetails = {
  endCountryType?: Maybe<EndCountryType>;
  endDeliveryCountryCode?: Maybe<Scalars['String']>;
};

export enum EndCountryType {
  NoEndCountry = 'NO_END_COUNTRY',
  Gcc = 'GCC',
  Other = 'OTHER'
}

export type Purchase_ConfirmQuoteRequest = {
  address: Purchase_QuoteAddress;
  contactDetails: Purchase_QuoteContactDetails;
};

export type Purchase_QuoteAddress = {
  fields?: AddressFieldsInput;
  endDeliveryCountryCode?: Maybe<Scalars['String']>;
};

export type Purchase_QuoteContactDetails = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNo: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  technicalDocumentLoaded: PdpDocuments;
  bulkAddToBasketStatusUpdate: BulkAddToBasketStatusMessage;
};


export type SubscriptionTechnicalDocumentLoadedArgs = {
  stockCode: Scalars['String'];
  requestId: Scalars['String'];
};


export type SubscriptionBulkAddToBasketStatusUpdateArgs = {
  requestId: Scalars['String'];
};

export type AddressFieldsFragment = (
  { __typename?: 'AddressFields' }
  & Pick<AddressFields, 'firstName' | 'lastName' | 'companyName' | 'building' | 'street' | 'streetComplement' | 'city' | 'postCode' | 'state' | 'stateCode' | 'countryCode' | 'country' | 'vatNumber' | 'taxCertificateNumber' | 'hasTaxCertificateNumber' | 'eoriNumber' | 'taxOfficeName' | 'paymentDepartmentNumber' | 'gstCustomerType' | 'gstRegistrationType' | 'gstRegistrationNumber' | 'panCardNumber'>
);

export type SummaryFragment = (
  { __typename?: 'Summary' }
  & Pick<Summary, 'deliveryOptionType' | 'numberOfItems'>
  & { netTotal: (
    { __typename?: 'Item' }
    & Pick<Item, 'label' | 'description'>
    & { price: (
      { __typename?: 'FormattedNumber' }
      & FormattedNumberFragment
    ) }
  ), netTotalExcPromotion?: Maybe<(
    { __typename?: 'FormattedNumber' }
    & FormattedNumberFragment
  )>, deliveryCharge: (
    { __typename?: 'Item' }
    & Pick<Item, 'label' | 'description' | 'notes'>
    & { price: (
      { __typename?: 'FormattedNumber' }
      & FormattedNumberFragment
    ) }
  ), surcharges: Array<(
    { __typename?: 'Item' }
    & Pick<Item, 'label' | 'description'>
    & { price: (
      { __typename?: 'FormattedNumber' }
      & FormattedNumberFragment
    ) }
  )>, grandTotal: (
    { __typename?: 'Item' }
    & Pick<Item, 'label' | 'description'>
    & { price: (
      { __typename?: 'FormattedNumber' }
      & FormattedNumberFragment
    ) }
  ), deliveryCountry?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'code' | 'name'>
  )>, invoiceCountry?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'code' | 'name'>
  )>, endDeliveryCountry?: Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'code' | 'name'>
  )>, promotion?: Maybe<(
    { __typename?: 'Promotion' }
    & Pick<Promotion, 'isValid' | 'code' | 'description' | 'errorMessage'>
    & { savings: (
      { __typename?: 'FormattedNumber' }
      & FormattedNumberFragment
    ) }
  )> }
);

export type AttributeFragment = (
  { __typename?: 'LinkAttribute' }
  & Pick<LinkAttribute, 'name' | 'value'>
);

export type FormattedNumberFragment = (
  { __typename?: 'FormattedNumber' }
  & Pick<FormattedNumber, 'value' | 'displayValue'>
);

export type HtmlTagAttributeFragment = (
  { __typename?: 'HtmlTagAttribute' }
  & Pick<HtmlTagAttribute, 'name' | 'value'>
);

export type HtmlTagFragment = (
  { __typename?: 'HtmlTag' }
  & Pick<HtmlTag, 'name' | 'innerHtml'>
  & { attributes: Array<(
    { __typename?: 'HtmlTagAttribute' }
    & HtmlTagAttributeFragment
  )> }
);

export type LabelFragment = (
  { __typename?: 'Label' }
  & Pick<Label, 'pageName' | 'name' | 'text'>
);

export type LinkFragment = (
  { __typename?: 'Link' }
  & Pick<Link, 'text' | 'url'>
  & { attributes: Array<(
    { __typename?: 'LinkAttribute' }
    & AttributeFragment
  )> }
);

export type ProductEnergyLabelFragment = (
  { __typename?: 'ProductEnergyLabel' }
  & Pick<ProductEnergyLabel, 'rating' | 'imgUrl' | 'isShowingTwoImagesInOne'>
);

export type FacetFragment = (
  { __typename?: 'Facet' }
  & Pick<Facet, 'id' | 'displayName'>
  & { values: Array<(
    { __typename?: 'FacetValue' }
    & FacetValueFragment
  )> }
);

export type FacetValueFragment = (
  { __typename?: 'FacetValue' }
  & Pick<FacetValue, 'id' | 'displayValue' | 'resultsCount' | 'disabled'>
);

export type Ga4ItemFragment = (
  { __typename?: 'GA4Item' }
  & Pick<Ga4Item, 'item_id' | 'item_name' | 'currency' | 'discount' | 'index' | 'item_brand' | 'item_category' | 'item_category2' | 'item_category3' | 'item_category4' | 'price' | 'quantity' | 'product_url' | 'image_url'>
);

export type PartsListDetailsFragment = (
  { __typename?: 'PartsListDetails' }
  & Pick<PartsListDetails, 'id' | 'name' | 'description'>
  & { lines: Array<(
    { __typename?: 'PartsListLineItem' }
    & Pick<PartsListLineItem, 'id' | 'quantity' | 'isInBasket'>
    & { product: (
      { __typename?: 'MyAccount_PartsListDetails_PartsListProduct' }
      & { title: (
        { __typename?: 'PartsListProductTitle' }
        & Pick<PartsListProductTitle, 'title' | 'brand' | 'formattedStockCode' | 'hsnCode' | 'mpn' | 'stockCode' | 'primaryImageUrl' | 'pdpUrl' | 'impaCode'>
      ), priceArea: (
        { __typename?: 'PartsListProductPriceArea' }
        & Pick<PartsListProductPriceArea, 'container' | 'minimumOrderQuantity' | 'unitOfMeasure' | 'errorMessage'>
        & { unitPrice: (
          { __typename?: 'Price' }
          & { priceBefore: (
            { __typename?: 'FormattedNumber' }
            & FormattedNumberFragment
          ), priceAfter: (
            { __typename?: 'FormattedNumber' }
            & FormattedNumberFragment
          ) }
        ), totalPrice: (
          { __typename?: 'Price' }
          & { priceBefore: (
            { __typename?: 'FormattedNumber' }
            & FormattedNumberFragment
          ), priceAfter: (
            { __typename?: 'FormattedNumber' }
            & FormattedNumberFragment
          ) }
        ) }
      ), energyArea?: Maybe<(
        { __typename?: 'ProductEnergy' }
        & { energyLabel: (
          { __typename?: 'ProductEnergyLabel' }
          & ProductEnergyLabelFragment
        ) }
      )> }
    ) }
  )> }
);

export type PriceDataFragment = (
  { __typename?: 'Price' }
  & { priceBefore: (
    { __typename?: 'FormattedNumber' }
    & FormattedNumberFragment
  ), priceAfter: (
    { __typename?: 'FormattedNumber' }
    & FormattedNumberFragment
  ) }
);

export type PriceBreakItemFragment = (
  { __typename?: 'PDPPriceBreakItem' }
  & Pick<PdpPriceBreakItem, 'minimum' | 'maximum' | 'displayName'>
  & { unitPrice: (
    { __typename?: 'Price' }
    & PriceDataFragment
  ), packPrice: (
    { __typename?: 'Price' }
    & PriceDataFragment
  ) }
);

export type PriceItemFragment = (
  { __typename?: 'PDPPricesItem' }
  & Pick<PdpPricesItem, 'quantity'>
  & { unitPrice: (
    { __typename?: 'Price' }
    & PriceDataFragment
  ), totalPrice: (
    { __typename?: 'Price' }
    & PriceDataFragment
  ) }
);

export type PricingFragment = (
  { __typename?: 'PDPPricing' }
  & Pick<PdpPricing, 'container' | 'minimumOrderQuantity' | 'showExVat' | 'errorMessage' | 'packPriceHeader' | 'unitOfMeasure' | 'isInBasket'>
  & { price: (
    { __typename?: 'Price' }
    & { priceBefore: (
      { __typename?: 'FormattedNumber' }
      & FormattedNumberFragment
    ), priceAfter: (
      { __typename?: 'FormattedNumber' }
      & FormattedNumberFragment
    ) }
  ), priceDualCurrency?: Maybe<(
    { __typename?: 'Price' }
    & { priceBefore: (
      { __typename?: 'FormattedNumber' }
      & FormattedNumberFragment
    ), priceAfter: (
      { __typename?: 'FormattedNumber' }
      & FormattedNumberFragment
    ) }
  )>, priceExVat: (
    { __typename?: 'Price' }
    & { priceBefore: (
      { __typename?: 'FormattedNumber' }
      & FormattedNumberFragment
    ), priceAfter: (
      { __typename?: 'FormattedNumber' }
      & FormattedNumberFragment
    ) }
  ), priceExVatDualCurrency?: Maybe<(
    { __typename?: 'Price' }
    & { priceBefore: (
      { __typename?: 'FormattedNumber' }
      & FormattedNumberFragment
    ), priceAfter: (
      { __typename?: 'FormattedNumber' }
      & FormattedNumberFragment
    ) }
  )>, priceBreaks: Array<(
    { __typename?: 'PDPPriceBreakItem' }
    & PriceBreakItemFragment
  )>, priceBreaksExVat: Array<(
    { __typename?: 'PDPPriceBreakItem' }
    & PriceBreakItemFragment
  )>, prices: Array<(
    { __typename?: 'PDPPricesItem' }
    & PriceItemFragment
  )>, pricesExVat: Array<(
    { __typename?: 'PDPPricesItem' }
    & PriceItemFragment
  )> }
);

export type ProductTypeFragment = (
  { __typename?: 'ProductType' }
  & Pick<ProductType, 'isVisible' | 'title' | 'mode' | 'message'>
);

export type BasketPageQueryVariables = Exact<{
  asQuote?: Maybe<Scalars['Boolean']>;
  summaryData?: Maybe<GetSummaryRequest>;
}>;


export type BasketPageQuery = (
  { __typename?: 'Query' }
  & { basketPage: (
    { __typename?: 'BasketPage' }
    & Pick<BasketPage, 'totalWeight' | 'numberOfItems' | 'isValidState'>
    & { labels: Array<(
      { __typename?: 'Label' }
      & LabelFragment
    )>, metaData: (
      { __typename?: 'CheckoutMeta' }
      & Pick<CheckoutMeta, 'pageTitle' | 'canonicalUrl'>
      & { links?: Maybe<Array<(
        { __typename?: 'HtmlTag' }
        & HtmlTagFragment
      )>>, metaTags?: Maybe<Array<(
        { __typename?: 'HtmlTag' }
        & { attributes: Array<(
          { __typename?: 'HtmlTagAttribute' }
          & HtmlTagAttributeFragment
        )> }
      )>> }
    ), tagging: (
      { __typename?: 'BasketPageTagging' }
      & { gtm: (
        { __typename?: 'BasketPageTaggingGTM' }
        & { dataLayer: (
          { __typename?: 'HtmlTag' }
          & Pick<HtmlTag, 'innerHtml'>
        ) }
      ), tags: Array<(
        { __typename?: 'HtmlTag' }
        & HtmlTagFragment
      )>, ga4: Array<(
        { __typename?: 'GA4Event_ViewCart' }
        & Pick<Ga4Event_ViewCart, 'event'>
        & { ecommerce: (
          { __typename?: 'GA4Event_ViewCart_Ecommerce' }
          & Pick<Ga4Event_ViewCart_Ecommerce, 'currency' | 'value' | 'amount' | 'total_prqt' | 'email'>
          & { items: Array<(
            { __typename?: 'GA4Item' }
            & Ga4ItemFragment
          )> }
        ) }
      )> }
    ), config: (
      { __typename?: 'BasketPageConfig' }
      & Pick<BasketPageConfig, 'allowPartNumbers' | 'isCustomerLoggedIn' | 'allowPromoCode' | 'topicIdentifier' | 'enablePrintQuote' | 'hasNewDeliveryOptions' | 'hasDeliveryOption' | 'hasDualUseProducts' | 'isStockCheckUnavailable' | 'paymentMethods' | 'showShareBasketButton'>
    ), parcels: Array<(
      { __typename?: 'Parcel' }
      & Pick<Parcel, 'title'>
      & { items: Array<(
        { __typename?: 'ParcelItem' }
        & Pick<ParcelItem, 'quantity' | 'id'>
        & { product: (
          { __typename?: 'Product' }
          & { productTypeArea: (
            { __typename?: 'ProductType' }
            & { secondaryMessages?: Maybe<Array<(
              { __typename?: 'ProductType' }
              & ProductTypeFragment
            )>> }
            & ProductTypeFragment
          ), title: (
            { __typename?: 'ProductTitle' }
            & Pick<ProductTitle, 'brand' | 'formattedStockCode' | 'hsnCode' | 'mpn' | 'stockCode' | 'title' | 'primaryImageUrl' | 'pdpUrl' | 'partNumber' | 'impaCode'>
          ), stockArea: (
            { __typename?: 'ProductStock' }
            & Pick<ProductStock, 'statusCode' | 'title' | 'stockMessage' | 'dtpStockMessages' | 'infoMessage' | 'isUsingDTP' | 'denyBackOrder' | 'includesDate' | 'stockBadgeIcon'>
            & { stockAvailability?: Maybe<Array<(
              { __typename?: 'StockAvailability' }
              & Pick<StockAvailability, 'quantityInStock' | 'availabilityDate'>
            )>> }
          ), priceArea: (
            { __typename?: 'ProductPriceArea' }
            & Pick<ProductPriceArea, 'minimumOrderQuantity' | 'container' | 'taxType' | 'errorMessage' | 'unitOfMeasure'>
            & { totalPrice: (
              { __typename?: 'Price' }
              & { priceBefore: (
                { __typename?: 'FormattedNumber' }
                & Pick<FormattedNumber, 'value' | 'displayValue'>
              ), priceAfter: (
                { __typename?: 'FormattedNumber' }
                & Pick<FormattedNumber, 'value' | 'displayValue'>
              ) }
            ), unitPrice: (
              { __typename?: 'Price' }
              & { priceAfter: (
                { __typename?: 'FormattedNumber' }
                & Pick<FormattedNumber, 'value' | 'displayValue'>
              ) }
            ), taxes: Array<(
              { __typename?: 'PriceTax' }
              & Pick<PriceTax, 'taxType' | 'taxAmountDisplayValue'>
            )> }
          ), ppkArea: (
            { __typename?: 'ProductionPackaging' }
            & Pick<ProductionPackaging, 'isVisible' | 'isEnabled' | 'packageType' | 'standardUnitOfMeasure' | 'unitOfMeasure' | 'ppkStockCode' | 'standardStockCode' | 'ppkSSM' | 'standardMinimumOrderQuantity' | 'minimumOrderQuantity' | 'title' | 'message'>
          ), energyArea?: Maybe<(
            { __typename?: 'ProductEnergy' }
            & { energyLabel: (
              { __typename?: 'ProductEnergyLabel' }
              & ProductEnergyLabelFragment
            ) }
          )> }
        ) }
      )> }
    )>, deliveryCountries: (
      { __typename?: 'DeliveryCountries' }
      & { options: Array<(
        { __typename?: 'Country' }
        & Pick<Country, 'code' | 'name'>
        & { redirection?: Maybe<(
          { __typename?: 'CountryRedirectDetails' }
          & Pick<CountryRedirectDetails, 'title' | 'message' | 'url' | 'cta' | 'transferBasket' | 'isGroupCountry' | 'isUSCountry'>
        )> }
      )> }
    ), summary: (
      { __typename?: 'Summary' }
      & SummaryFragment
    ), quoteDetails: (
      { __typename?: 'QuoteDetails' }
      & { header: (
        { __typename?: 'QuoteHeader' }
        & Pick<QuoteHeader, 'quoteReference' | 'accountNumber' | 'validStartDate' | 'validEndDate' | 'siteName' | 'siteAddress'>
      ), contactDetails: (
        { __typename?: 'QuoteContactDetails' }
        & Pick<QuoteContactDetails, 'email' | 'phone' | 'fax'>
      ), labels: Array<(
        { __typename?: 'Label' }
        & LabelFragment
      )> }
    ) }
  ) }
);

export type AddToBasketMutationVariables = Exact<{
  stockCode: Scalars['String'];
  quantity: Scalars['Float'];
}>;


export type AddToBasketMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addToBasket'>
);

export type AddToBasketV2MutationVariables = Exact<{
  stockCode: Scalars['String'];
  quantity: Scalars['Float'];
}>;


export type AddToBasketV2Mutation = (
  { __typename?: 'Mutation' }
  & { addToBasketV2: (
    { __typename?: 'AddToBasketResponse' }
    & Pick<AddToBasketResponse, 'isSuccess'>
    & { tags: Array<(
      { __typename?: 'AddToBasketEnsighten' }
      & Pick<AddToBasketEnsighten, 'orderQuantity' | 'productId'>
    ) | (
      { __typename?: 'AddToBasketGoogleAnalytics' }
      & Pick<AddToBasketGoogleAnalytics, 'id' | 'name' | 'category' | 'brand' | 'quantity' | 'price'>
    ) | (
      { __typename?: 'AddToBasketYandex' }
      & Pick<AddToBasketYandex, 'script'>
    ) | (
      { __typename?: 'AddToBasketTealium' }
      & Pick<AddToBasketTealium, 'orderQuantity' | 'productId'>
    ) | (
      { __typename?: 'GA4Event_AddToCart' }
      & Pick<Ga4Event_AddToCart, 'event'>
      & { ecommerce: (
        { __typename?: 'GA4Event_AddToCart_Ecommerce' }
        & { items: Array<(
          { __typename?: 'GA4Item' }
          & Ga4ItemFragment
        )> }
      ) }
    )> }
  ) }
);

export type Ga4Event_UpdateBasketLinesFragment = (
  { __typename?: 'GA4Event_UpdateBasketLines' }
  & Pick<Ga4Event_UpdateBasketLines, 'event'>
  & { ecommerce: (
    { __typename?: 'GA4Event_UpdateBasketLines_Ecommerce' }
    & Pick<Ga4Event_UpdateBasketLines_Ecommerce, 'currency' | 'value' | 'total_prqt' | 'email'>
    & { items: Array<(
      { __typename?: 'GA4Item' }
      & Ga4ItemFragment
    )> }
  ) }
);

type RemoveFromBasketResponseTags_BulkRemoveFromBasketEnsighten_Fragment = (
  { __typename?: 'BulkRemoveFromBasketEnsighten' }
  & { products: Array<(
    { __typename?: 'RemoveFromBasketEnsighten' }
    & Pick<RemoveFromBasketEnsighten, 'productId' | 'orderQuantity'>
  )> }
);

type RemoveFromBasketResponseTags_BulkRemoveFromBasketGoogleAnalytics_Fragment = (
  { __typename?: 'BulkRemoveFromBasketGoogleAnalytics' }
  & { products: Array<(
    { __typename?: 'RemoveFromBasketGoogleAnalytics' }
    & Pick<RemoveFromBasketGoogleAnalytics, 'id' | 'name' | 'category' | 'brand' | 'quantity'>
  )> }
);

type RemoveFromBasketResponseTags_BulkRemoveFromBasketTealium_Fragment = (
  { __typename?: 'BulkRemoveFromBasketTealium' }
  & { products: Array<(
    { __typename?: 'RemoveFromBasketTealium' }
    & Pick<RemoveFromBasketTealium, 'productId'>
  )> }
);

type RemoveFromBasketResponseTags_Ga4Event_RemoveFromCart_Fragment = (
  { __typename?: 'GA4Event_RemoveFromCart' }
  & Pick<Ga4Event_RemoveFromCart, 'event'>
  & { ecommerce: (
    { __typename?: 'GA4Event_RemoveFromCart_Ecommerce' }
    & Pick<Ga4Event_RemoveFromCart_Ecommerce, 'currency' | 'value'>
    & { items: Array<(
      { __typename?: 'GA4Item' }
      & Ga4ItemFragment
    )> }
  ) }
);

type RemoveFromBasketResponseTags_Ga4Event_UpdateBasketLines_Fragment = (
  { __typename?: 'GA4Event_UpdateBasketLines' }
  & Ga4Event_UpdateBasketLinesFragment
);

export type RemoveFromBasketResponseTagsFragment = RemoveFromBasketResponseTags_BulkRemoveFromBasketEnsighten_Fragment | RemoveFromBasketResponseTags_BulkRemoveFromBasketGoogleAnalytics_Fragment | RemoveFromBasketResponseTags_BulkRemoveFromBasketTealium_Fragment | RemoveFromBasketResponseTags_Ga4Event_RemoveFromCart_Fragment | RemoveFromBasketResponseTags_Ga4Event_UpdateBasketLines_Fragment;

export type RemoveFromBasketMutationVariables = Exact<{
  lineIds: Array<Scalars['Int']>;
}>;


export type RemoveFromBasketMutation = (
  { __typename?: 'Mutation' }
  & { removeFromBasket: (
    { __typename?: 'RemoveFromBasketResponse' }
    & Pick<RemoveFromBasketResponse, 'isSuccess'>
    & { tags: Array<(
      { __typename?: 'BulkRemoveFromBasketEnsighten' }
      & RemoveFromBasketResponseTags_BulkRemoveFromBasketEnsighten_Fragment
    ) | (
      { __typename?: 'BulkRemoveFromBasketGoogleAnalytics' }
      & RemoveFromBasketResponseTags_BulkRemoveFromBasketGoogleAnalytics_Fragment
    ) | (
      { __typename?: 'BulkRemoveFromBasketTealium' }
      & RemoveFromBasketResponseTags_BulkRemoveFromBasketTealium_Fragment
    ) | (
      { __typename?: 'GA4Event_RemoveFromCart' }
      & RemoveFromBasketResponseTags_Ga4Event_RemoveFromCart_Fragment
    ) | (
      { __typename?: 'GA4Event_UpdateBasketLines' }
      & RemoveFromBasketResponseTags_Ga4Event_UpdateBasketLines_Fragment
    )> }
  ) }
);

export type UpdateLinesMutationVariables = Exact<{
  data: UpdateBasketLinesRequest;
}>;


export type UpdateLinesMutation = (
  { __typename?: 'Mutation' }
  & { updateLines: (
    { __typename?: 'UpdateBasketLinesResponse' }
    & Pick<UpdateBasketLinesResponse, 'isSuccess'>
    & { tags: Array<(
      { __typename?: 'UpdateBasketLinesEnsighten' }
      & { lines: Array<(
        { __typename?: 'UpdateBasketLinesEnsighten_BasketLine' }
        & Pick<UpdateBasketLinesEnsighten_BasketLine, 'articleId' | 'oldQuantity' | 'newQuantity' | 'oldPrice' | 'newPrice'>
      )> }
    ) | (
      { __typename?: 'UpdateBasketLinesGoogleAnalytics' }
      & { lines: Array<(
        { __typename?: 'UpdateBasketLinesGoogleAnalytics_BasketLine' }
        & Pick<UpdateBasketLinesGoogleAnalytics_BasketLine, 'articleId' | 'oldQuantity' | 'newQuantity' | 'oldPrice' | 'newPrice'>
      )> }
    ) | (
      { __typename?: 'UpdateBasketLinesTealium' }
      & { lines: Array<(
        { __typename?: 'UpdateBasketLinesTealium_BasketLine' }
        & Pick<UpdateBasketLinesTealium_BasketLine, 'articleId' | 'oldQuantity' | 'newQuantity' | 'oldPrice' | 'newPrice'>
      )> }
    ) | (
      { __typename?: 'GA4Event_UpdateBasketLines' }
      & Ga4Event_UpdateBasketLinesFragment
    )> }
  ) }
);

export type BulkAddToBasketMutationVariables = Exact<{
  data: BulkAddToBasketRequest;
}>;


export type BulkAddToBasketMutation = (
  { __typename?: 'Mutation' }
  & { bulkAddToBasket: (
    { __typename?: 'BulkAddToBasketResponse' }
    & Pick<BulkAddToBasketResponse, 'isSuccess' | 'errorMessage' | 'infoMessage'>
    & { result: (
      { __typename?: 'BulkAddToBasket_ResultDetails' }
      & Pick<BulkAddToBasket_ResultDetails, 'isSuccess' | 'title' | 'message'>
      & { productsNotFoundSection?: Maybe<(
        { __typename?: 'BulkAddToBasket_ResultDetailsSection' }
        & Pick<BulkAddToBasket_ResultDetailsSection, 'message'>
        & { lineInfo: Array<(
          { __typename?: 'BulkAddToBasket_ResultDetailsLine' }
          & Pick<BulkAddToBasket_ResultDetailsLine, 'stockCode' | 'quantity' | 'message'>
          & { link?: Maybe<(
            { __typename?: 'Link' }
            & Pick<Link, 'text' | 'url'>
          )> }
        )> }
      )>, discontinuedProductsSection?: Maybe<(
        { __typename?: 'BulkAddToBasket_ResultDetailsSection' }
        & Pick<BulkAddToBasket_ResultDetailsSection, 'message'>
        & { lineInfo: Array<(
          { __typename?: 'BulkAddToBasket_ResultDetailsLine' }
          & Pick<BulkAddToBasket_ResultDetailsLine, 'stockCode' | 'quantity' | 'message'>
          & { link?: Maybe<(
            { __typename?: 'Link' }
            & Pick<Link, 'text' | 'url'>
          )> }
        )> }
      )> }
    ), tags: Array<(
      { __typename?: 'BulkAddToBasketEnsighten' }
      & { products: Array<(
        { __typename?: 'AddToBasketEnsighten' }
        & Pick<AddToBasketEnsighten, 'orderQuantity' | 'productId' | 'partNumber'>
      )> }
    ) | (
      { __typename?: 'BulkAddToBasketGoogleAnalytics' }
      & { products: Array<(
        { __typename?: 'AddToBasketGoogleAnalytics' }
        & Pick<AddToBasketGoogleAnalytics, 'id' | 'name' | 'category' | 'brand' | 'quantity' | 'price'>
      )> }
    ) | (
      { __typename?: 'BulkAddToBasketTealium' }
      & { products: Array<(
        { __typename?: 'AddToBasketTealium' }
        & Pick<AddToBasketTealium, 'orderQuantity' | 'productId'>
      )> }
    ) | (
      { __typename?: 'GA4Event_AddToCart' }
      & Pick<Ga4Event_AddToCart, 'event'>
      & { ecommerce: (
        { __typename?: 'GA4Event_AddToCart_Ecommerce' }
        & { items: Array<(
          { __typename?: 'GA4Item' }
          & Ga4ItemFragment
        )> }
      ) }
    )> }
  ) }
);

export type BulkAddToBasketStatusUpdateSubscriptionVariables = Exact<{
  requestId: Scalars['String'];
}>;


export type BulkAddToBasketStatusUpdateSubscription = (
  { __typename?: 'Subscription' }
  & { bulkAddToBasketStatusUpdate: (
    { __typename?: 'BulkAddToBasketStatusMessage' }
    & Pick<BulkAddToBasketStatusMessage, 'message' | 'isError' | 'isProgress' | 'requestId' | 'isFinal'>
  ) }
);

export type ClearBasketMutationVariables = Exact<{ [key: string]: never; }>;


export type ClearBasketMutation = (
  { __typename?: 'Mutation' }
  & { clearBasket: (
    { __typename?: 'RemoveFromBasketResponse' }
    & Pick<RemoveFromBasketResponse, 'isSuccess'>
    & { tags: Array<(
      { __typename?: 'BulkRemoveFromBasketEnsighten' }
      & RemoveFromBasketResponseTags_BulkRemoveFromBasketEnsighten_Fragment
    ) | (
      { __typename?: 'BulkRemoveFromBasketGoogleAnalytics' }
      & RemoveFromBasketResponseTags_BulkRemoveFromBasketGoogleAnalytics_Fragment
    ) | (
      { __typename?: 'BulkRemoveFromBasketTealium' }
      & RemoveFromBasketResponseTags_BulkRemoveFromBasketTealium_Fragment
    ) | (
      { __typename?: 'GA4Event_RemoveFromCart' }
      & RemoveFromBasketResponseTags_Ga4Event_RemoveFromCart_Fragment
    ) | (
      { __typename?: 'GA4Event_UpdateBasketLines' }
      & RemoveFromBasketResponseTags_Ga4Event_UpdateBasketLines_Fragment
    )> }
  ) }
);

export type SetDeliveryMethodMutationVariables = Exact<{
  data: SetDeliveryMethodRequest;
}>;


export type SetDeliveryMethodMutation = (
  { __typename?: 'Mutation' }
  & { setDeliveryMethod: (
    { __typename?: 'SetDeliveryMethodResponse' }
    & Pick<SetDeliveryMethodResponse, 'isSuccess'>
  ) }
);

export type DeliveryOptionsQueryVariables = Exact<{
  countryCode?: Maybe<Scalars['String']>;
}>;


export type DeliveryOptionsQuery = (
  { __typename?: 'Query' }
  & { basketPage: (
    { __typename?: 'BasketPage' }
    & { basketServices: (
      { __typename?: 'BasketServices' }
      & { options: Array<(
        { __typename?: 'BasketService' }
        & Pick<BasketService, 'id' | 'name' | 'description' | 'imageUrl' | 'isSelected'>
      )> }
    ), deliveryOptions: (
      { __typename?: 'DeliveryOptions' }
      & { options: Array<(
        { __typename?: 'DeliveryOption' }
        & Pick<DeliveryOption, 'id' | 'name' | 'description' | 'isSelected' | 'optionType'>
        & { price: (
          { __typename?: 'FormattedNumber' }
          & FormattedNumberFragment
        ), properties?: Maybe<(
          { __typename?: 'DeliveryOptionCollectionProperties' }
          & Pick<DeliveryOptionCollectionProperties, 'requiresCourierAccountNumber' | 'courierAccountNumber'>
        )> }
      )> }
    ) }
  ) }
);

export type BasketPageSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type BasketPageSummaryQuery = (
  { __typename?: 'Query' }
  & { basketPage: (
    { __typename?: 'BasketPage' }
    & { summary: (
      { __typename?: 'Summary' }
      & SummaryFragment
    ) }
  ) }
);

export type SetPromoCodeMutationVariables = Exact<{
  data: SetPromoCodeRequest;
}>;


export type SetPromoCodeMutation = (
  { __typename?: 'Mutation' }
  & { setPromoCode: (
    { __typename?: 'SetPromoCodeResponse' }
    & Pick<SetPromoCodeResponse, 'isSuccess' | 'errorCode'>
    & { tags: Array<(
      { __typename?: 'SetPromoCodeEnsighten' }
      & Pick<SetPromoCodeEnsighten, 'pageType' | 'promoCode' | 'pageCreationDate' | 'containsPOA'>
    ) | (
      { __typename?: 'SetPromoCodeGoogleAnalytics' }
      & Pick<SetPromoCodeGoogleAnalytics, 'promoCode' | 'pageCreationDate' | 'containsPOA'>
    ) | (
      { __typename?: 'GA4Event_SelectPromotion' }
      & Pick<Ga4Event_SelectPromotion, 'event'>
      & { ecommerce: (
        { __typename?: 'GA4Event_SelectPromotion_Ecommerce' }
        & Pick<Ga4Event_SelectPromotion_Ecommerce, 'promotion_id' | 'promotion_name'>
        & { items: Array<(
          { __typename?: 'GA4Item' }
          & Ga4ItemFragment
        )> }
      ) }
    )> }
  ) }
);

export type RemovePromoCodeMutationVariables = Exact<{ [key: string]: never; }>;


export type RemovePromoCodeMutation = (
  { __typename?: 'Mutation' }
  & { removePromoCode: (
    { __typename?: 'SetPromoCodeResponse' }
    & Pick<SetPromoCodeResponse, 'isSuccess' | 'errorCode'>
  ) }
);

export type AddBasketServiceMutationVariables = Exact<{
  data: AddBasketServiceRequest;
}>;


export type AddBasketServiceMutation = (
  { __typename?: 'Mutation' }
  & { addBasketService: (
    { __typename?: 'AddBasketServiceResponse' }
    & Pick<AddBasketServiceResponse, 'isSuccess'>
  ) }
);

export type RemoveBasketServiceMutationVariables = Exact<{
  data: RemoveBasketServiceRequest;
}>;


export type RemoveBasketServiceMutation = (
  { __typename?: 'Mutation' }
  & { removeBasketService: (
    { __typename?: 'RemoveBasketServiceResponse' }
    & Pick<RemoveBasketServiceResponse, 'isSuccess'>
  ) }
);

export type CampaignPageQueryVariables = Exact<{
  url: Scalars['String'];
  isPreview: Scalars['Boolean'];
}>;


export type CampaignPageQuery = (
  { __typename?: 'Query' }
  & { campaignPage: (
    { __typename?: 'CampaignPage' }
    & Pick<CampaignPage, 'backgroundColour'>
    & { metaData: (
      { __typename?: 'CampaignPageMeta' }
      & Pick<CampaignPageMeta, 'canonicalUrl' | 'pageTitle'>
      & { metaTags: Array<(
        { __typename?: 'HtmlTag' }
        & Pick<HtmlTag, 'name' | 'innerHtml'>
        & { attributes: Array<(
          { __typename?: 'HtmlTagAttribute' }
          & Pick<HtmlTagAttribute, 'name' | 'value'>
        )> }
      )>, links?: Maybe<Array<(
        { __typename?: 'HtmlTag' }
        & Pick<HtmlTag, 'name' | 'innerHtml'>
        & { attributes: Array<(
          { __typename?: 'HtmlTagAttribute' }
          & Pick<HtmlTagAttribute, 'name' | 'value'>
        )> }
      )>> }
    ), tagging: (
      { __typename?: 'CampaignPageTagging' }
      & { gtm: (
        { __typename?: 'CampaignPageTaggingGTM' }
        & { dataLayer: (
          { __typename?: 'HtmlTag' }
          & Pick<HtmlTag, 'innerHtml'>
        ) }
      ), tags: Array<(
        { __typename?: 'HtmlTag' }
        & HtmlTagFragment
      )> }
    ), layouts: Array<(
      { __typename: 'ContentLayout' }
      & ContentLayoutFragment
    ) | (
      { __typename: 'SectionLayout' }
      & Pick<SectionLayout, 'backgroundImage' | 'backgroundColour' | 'topBorderStyle' | 'bottomBorderStyle' | 'widthType'>
      & { padding?: Maybe<(
        { __typename?: 'Padding' }
        & PaddingFragment
      )>, margin?: Maybe<(
        { __typename?: 'Padding' }
        & PaddingFragment
      )>, contentLayouts: Array<(
        { __typename?: 'ContentLayout' }
        & ContentLayoutFragment
      )> }
    )> }
  ) }
);

export type ContentLayoutFragment = (
  { __typename?: 'ContentLayout' }
  & Pick<ContentLayout, 'desktopContentAlignment' | 'tabletContentAlignment' | 'mobileContentAlignment' | 'enableBorderRadius' | 'enableShadowOnHover' | 'backgroundColour' | 'backgroundImageUrl' | 'borderColour' | 'desktopWidth' | 'mobileWidth' | 'tabletWidth'>
  & { padding?: Maybe<(
    { __typename?: 'Padding' }
    & PaddingFragment
  )>, borderSize?: Maybe<(
    { __typename?: 'Sides' }
    & SidesFragment
  )>, widgets: Array<(
    { __typename?: 'HeroBannerWidget' }
    & HeroBannerWidgetFragment
  ) | (
    { __typename?: 'ImageTextBoxWidget' }
    & ImageTextBoxWidgetFragment
  ) | (
    { __typename?: 'VideoDataWidget' }
    & VideoDataWidgetFragment
  ) | (
    { __typename?: 'TextDataWidget' }
    & TextDataWidgetFragment
  ) | (
    { __typename?: 'ButtonDataWidget' }
    & ButtonDataWidgetFragment
  ) | (
    { __typename?: 'ImageDataWidget' }
    & ImageDataWidgetFragment
  ) | { __typename?: 'EmailFieldWidget' } | (
    { __typename?: 'PopularCategoriesWidget' }
    & PopularCategoriesWidgetFragment
  ) | (
    { __typename?: 'AllCategoriesWidget' }
    & AllCategoriesWidgetFragment
  ) | (
    { __typename?: 'PreviouslyViewedProducts' }
    & PreviouslyViewedProductsFragment
  ) | (
    { __typename?: 'InPageAnchorWidget' }
    & InPageAnchorWidgetFragment
  )> }
);

export type SidesFragment = (
  { __typename?: 'Sides' }
  & Pick<Sides, 'top' | 'right' | 'bottom' | 'left'>
);

export type ButtonPickerFragment = (
  { __typename?: 'ButtonPicker' }
  & Pick<ButtonPicker, 'icon' | 'style' | 'text' | 'type'>
);

export type AlignmentFragment = (
  { __typename?: 'Alignment' }
  & Pick<Alignment, 'desktop' | 'mobile'>
);

export type PaddingFragment = (
  { __typename?: 'Padding' }
  & { desktop?: Maybe<(
    { __typename?: 'Sides' }
    & SidesFragment
  )>, mobile?: Maybe<(
    { __typename?: 'Sides' }
    & SidesFragment
  )> }
);

export type DeviceFragment = (
  { __typename?: 'Device' }
  & Pick<Device, 'originalImageUrl' | 'url' | 'width'>
);

export type ImageFragment = (
  { __typename?: 'Image' }
  & Pick<Image, 'alt' | 'description'>
  & { desktop?: Maybe<(
    { __typename?: 'Device' }
    & DeviceFragment
  )>, mobile?: Maybe<(
    { __typename?: 'Device' }
    & DeviceFragment
  )>, tablet?: Maybe<(
    { __typename?: 'Device' }
    & DeviceFragment
  )> }
);

export type WidgetLinkFragment = (
  { __typename?: 'WidgetLink' }
  & Pick<WidgetLink, 'URL' | 'target'>
);

export type TextEditorFragment = (
  { __typename?: 'TextEditor' }
  & Pick<TextEditor, 'isBold' | 'isItalic' | 'isStrikethrough' | 'isUnderline' | 'text' | 'textSize'>
  & { alignment?: Maybe<(
    { __typename?: 'Alignment' }
    & AlignmentFragment
  )>, colour?: Maybe<(
    { __typename?: 'Alignment' }
    & AlignmentFragment
  )>, padding?: Maybe<(
    { __typename?: 'Padding' }
    & PaddingFragment
  )>, embeddedUrls?: Maybe<Array<(
    { __typename?: 'EmbeddedUrl' }
    & Pick<EmbeddedUrl, 'URL' | 'label' | 'placeholder' | 'target'>
  )>>, embeddedLists?: Maybe<Array<(
    { __typename?: 'EmbeddedList' }
    & Pick<EmbeddedList, 'listType' | 'placeholder'>
    & { listItems?: Maybe<Array<(
      { __typename?: 'EmbeddedListItem' }
      & Pick<EmbeddedListItem, 'position' | 'value'>
    )>> }
  )>> }
);

export type EmailCampaignPageQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type EmailCampaignPageQuery = (
  { __typename?: 'Query' }
  & { emailCampaignPage: (
    { __typename?: 'EmailCampaignPage' }
    & Pick<EmailCampaignPage, 'isSuccess' | 'html'>
  ) }
);

export type AllCategoriesWidgetFragment = (
  { __typename?: 'AllCategoriesWidget' }
  & { title: (
    { __typename?: 'TextEditor' }
    & TextEditorFragment
  ), categories: Array<(
    { __typename?: 'AllCategoriesWidgetItem' }
    & Pick<AllCategoriesWidgetItem, 'id' | 'noOfProducts'>
    & { url: (
      { __typename?: 'Link' }
      & LinkFragment
    ), childCategories: Array<(
      { __typename?: 'AllCategoriesWidgetItem' }
      & Pick<AllCategoriesWidgetItem, 'id' | 'noOfProducts'>
      & { url: (
        { __typename?: 'Link' }
        & LinkFragment
      ) }
    )> }
  )> }
);

export type ButtonDataWidgetFragment = (
  { __typename?: 'ButtonDataWidget' }
  & { button?: Maybe<(
    { __typename?: 'ButtonPicker' }
    & ButtonPickerFragment
  )>, buttonVerticalAlignment?: Maybe<(
    { __typename?: 'Alignment' }
    & AlignmentFragment
  )>, buttonHorizontalAlignment?: Maybe<(
    { __typename?: 'Alignment' }
    & AlignmentFragment
  )>, link?: Maybe<(
    { __typename?: 'WidgetLink' }
    & WidgetLinkFragment
  )> }
);

export type HeroBannerWidgetFragment = (
  { __typename?: 'HeroBannerWidget' }
  & { banners: Array<(
    { __typename?: 'HeroBanner' }
    & Pick<HeroBanner, 'curveOverlayColour' | 'blockOverlayColour' | 'desktopContentPosition' | 'desktopContentStyle' | 'desktopContentWidth' | 'mobileContentStyle' | 'mobileOverlayColour' | 'slashOverlayColour'>
    & { button?: Maybe<(
      { __typename?: 'ButtonPicker' }
      & ButtonPickerFragment
    )>, buttonVerticalAlignment?: Maybe<(
      { __typename?: 'Alignment' }
      & AlignmentFragment
    )>, buttonHorizontalAlignment?: Maybe<(
      { __typename?: 'Alignment' }
      & AlignmentFragment
    )>, image?: Maybe<(
      { __typename?: 'Image' }
      & ImageFragment
    )>, link?: Maybe<(
      { __typename?: 'WidgetLink' }
      & WidgetLinkFragment
    )>, text?: Maybe<Array<(
      { __typename?: 'TextEditor' }
      & TextEditorFragment
    )>>, textAlignment?: Maybe<(
      { __typename?: 'Alignment' }
      & AlignmentFragment
    )> }
  )> }
);

export type ImageDataWidgetFragment = (
  { __typename?: 'ImageDataWidget' }
  & { image?: Maybe<(
    { __typename?: 'Image' }
    & ImageFragment
  )>, imageHorizontalAlignment?: Maybe<(
    { __typename?: 'Alignment' }
    & AlignmentFragment
  )>, imageVerticalAlignment?: Maybe<(
    { __typename?: 'Alignment' }
    & AlignmentFragment
  )>, link?: Maybe<(
    { __typename?: 'WidgetLink' }
    & WidgetLinkFragment
  )> }
);

export type ImageTextBoxWidgetFragment = (
  { __typename?: 'ImageTextBoxWidget' }
  & Pick<ImageTextBoxWidget, 'addPadding' | 'backgroundColour' | 'imageDesktopPosition' | 'imageMobilePosition' | 'opacity'>
  & { button?: Maybe<(
    { __typename?: 'ButtonPicker' }
    & ButtonPickerFragment
  )>, buttonVerticalAlignment?: Maybe<(
    { __typename?: 'Alignment' }
    & AlignmentFragment
  )>, buttonHorizontalAlignment?: Maybe<(
    { __typename?: 'Alignment' }
    & AlignmentFragment
  )>, image?: Maybe<(
    { __typename?: 'Image' }
    & ImageFragment
  )>, link?: Maybe<(
    { __typename?: 'WidgetLink' }
    & WidgetLinkFragment
  )>, text?: Maybe<Array<(
    { __typename?: 'TextEditor' }
    & TextEditorFragment
  )>>, textAlignment?: Maybe<(
    { __typename?: 'Alignment' }
    & AlignmentFragment
  )> }
);

export type InPageAnchorWidgetFragment = (
  { __typename?: 'InPageAnchorWidget' }
  & Pick<InPageAnchorWidget, 'anchorId'>
);

export type PopularCategoriesWidgetFragment = (
  { __typename?: 'PopularCategoriesWidget' }
  & { title: (
    { __typename?: 'TextEditor' }
    & TextEditorFragment
  ), categories: Array<(
    { __typename?: 'PopularCategoriesWidgetItem' }
    & Pick<PopularCategoriesWidgetItem, 'id' | 'noOfProducts' | 'imageUrl'>
    & { url: (
      { __typename?: 'Link' }
      & LinkFragment
    ) }
  )> }
);

export type PreviouslyViewedProductsFragment = (
  { __typename?: 'PreviouslyViewedProducts' }
  & Pick<PreviouslyViewedProducts, 'id'>
);

export type TextDataWidgetFragment = (
  { __typename?: 'TextDataWidget' }
  & { textData?: Maybe<Array<(
    { __typename?: 'TextEditor' }
    & TextEditorFragment
  )>>, textAlignment?: Maybe<(
    { __typename?: 'Alignment' }
    & AlignmentFragment
  )> }
);

export type VideoDataWidgetFragment = (
  { __typename?: 'VideoDataWidget' }
  & Pick<VideoDataWidget, 'description' | 'name' | 'thumbnailUrl' | 'videoUrl' | 'width'>
  & { videoHorizontalAlignment?: Maybe<(
    { __typename?: 'Alignment' }
    & AlignmentFragment
  )>, videoVerticalAlignment?: Maybe<(
    { __typename?: 'Alignment' }
    & AlignmentFragment
  )> }
);

export type CategoryPageQueryVariables = Exact<{
  slug?: Maybe<Scalars['String']>;
}>;


export type CategoryPageQuery = (
  { __typename?: 'Query' }
  & { categoryListPage: (
    { __typename?: 'CategoryListPage' }
    & Pick<CategoryListPage, 'isSuccess' | 'redirectUrl' | 'categoryId' | 'categoryName' | 'title' | 'description'>
    & { breadcrumb: Array<(
      { __typename?: 'Link' }
      & LinkFragment
    )>, labels: Array<(
      { __typename?: 'Label' }
      & LabelFragment
    )>, leftMenu: Array<(
      { __typename?: 'CategoryItem' }
      & Pick<CategoryItem, 'noOfChildren'>
      & { url: (
        { __typename?: 'Link' }
        & Pick<Link, 'text' | 'url'>
      ) }
    )>, childCategories: Array<(
      { __typename?: 'CategoryItem' }
      & Pick<CategoryItem, 'id' | 'imageUrl' | 'noOfChildren' | 'noOfProducts'>
      & { url: (
        { __typename?: 'Link' }
        & Pick<Link, 'text' | 'url'>
      ), childCategories?: Maybe<Array<(
        { __typename?: 'CategoryItem' }
        & Pick<CategoryItem, 'id' | 'imageUrl' | 'noOfChildren' | 'noOfProducts'>
        & { url: (
          { __typename?: 'Link' }
          & Pick<Link, 'text' | 'url'>
        ) }
      )>> }
    )>, metaData: (
      { __typename?: 'CategoryListPageMeta' }
      & Pick<CategoryListPageMeta, 'pageTitle' | 'structuredData' | 'canonicalUrl'>
      & { metaTags?: Maybe<Array<(
        { __typename?: 'HtmlTag' }
        & { attributes: Array<(
          { __typename?: 'HtmlTagAttribute' }
          & HtmlTagAttributeFragment
        )> }
      )>>, links?: Maybe<Array<(
        { __typename?: 'HtmlTag' }
        & { attributes: Array<(
          { __typename?: 'HtmlTagAttribute' }
          & HtmlTagAttributeFragment
        )> }
      )>> }
    ), tagging: (
      { __typename?: 'CLPTagging' }
      & { gtm: (
        { __typename?: 'CLPTaggingGTM' }
        & { dataLayer: (
          { __typename?: 'HtmlTag' }
          & Pick<HtmlTag, 'innerHtml'>
        ) }
      ), tags: Array<(
        { __typename?: 'HtmlTag' }
        & HtmlTagFragment
      )> }
    ) }
  ) }
);

export type CategoryPageTitleQueryVariables = Exact<{
  slug?: Maybe<Scalars['String']>;
}>;


export type CategoryPageTitleQuery = (
  { __typename?: 'Query' }
  & { categoryListPage: (
    { __typename?: 'CategoryListPage' }
    & Pick<CategoryListPage, 'isSuccess' | 'categoryName' | 'description'>
  ) }
);

export type CompareAlternativesQueryVariables = Exact<{
  stockCode: Scalars['String'];
}>;


export type CompareAlternativesQuery = (
  { __typename?: 'Query' }
  & { compareAlternativesPage: (
    { __typename?: 'CompareAlternativesPage' }
    & Pick<CompareAlternativesPage, 'redirectUrl'>
    & { labels: Array<(
      { __typename?: 'Label' }
      & LabelFragment
    )>, productsArea: Array<(
      { __typename?: 'PLPProduct' }
      & { attributes: Array<(
        { __typename?: 'PLPAttributeItem' }
        & Pick<PlpAttributeItem, 'name' | 'value'>
      )>, stockArea: (
        { __typename?: 'PLPStock' }
        & Pick<PlpStock, 'statusCode' | 'title' | 'stockMessage' | 'dtpStockMessages' | 'infoMessage' | 'isUsingDTP' | 'denyBackOrder' | 'includesDate'>
        & { stockAvailability?: Maybe<Array<(
          { __typename?: 'StockAvailability' }
          & Pick<StockAvailability, 'quantityInStock' | 'availabilityDate'>
        )>> }
      ) }
      & PlpProductFragment
    )> }
  ) }
);

export type GetContextInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContextInfoQuery = (
  { __typename?: 'Query' }
  & { master: (
    { __typename?: 'Master' }
    & { contextInfo: (
      { __typename?: 'ContextInfo' }
      & Pick<ContextInfo, 'locale' | 'isBot'>
    ) }
  ) }
);

export type NotFoundErrorPage404QueryVariables = Exact<{ [key: string]: never; }>;


export type NotFoundErrorPage404Query = (
  { __typename?: 'Query' }
  & { notFoundErrorPage: (
    { __typename?: 'NotFoundErrorPage' }
    & { tagging: (
      { __typename?: 'PLPTagging' }
      & { gtm: (
        { __typename?: 'PLPTaggingGTM' }
        & { dataLayer: (
          { __typename?: 'HtmlTag' }
          & Pick<HtmlTag, 'innerHtml'>
        ) }
      ), tags: Array<(
        { __typename?: 'HtmlTag' }
        & HtmlTagFragment
      )> }
    ), labels: Array<(
      { __typename?: 'Label' }
      & Pick<Label, 'name' | 'text'>
    )> }
  ) }
);

export type TechnicalErrorPageQueryVariables = Exact<{ [key: string]: never; }>;


export type TechnicalErrorPageQuery = (
  { __typename?: 'Query' }
  & { technicalErrorPage: (
    { __typename?: 'TechnicalErrorPage' }
    & Pick<TechnicalErrorPage, 'sessionId'>
    & { tagging: (
      { __typename?: 'PLPTagging' }
      & { gtm: (
        { __typename?: 'PLPTaggingGTM' }
        & { dataLayer: (
          { __typename?: 'HtmlTag' }
          & Pick<HtmlTag, 'innerHtml'>
        ) }
      ), tags: Array<(
        { __typename?: 'HtmlTag' }
        & HtmlTagFragment
      )> }
    ), labels: Array<(
      { __typename?: 'Label' }
      & LabelFragment
    )> }
  ) }
);

export type BasketTotalQueryVariables = Exact<{ [key: string]: never; }>;


export type BasketTotalQuery = (
  { __typename?: 'Query' }
  & { header: (
    { __typename?: 'Header' }
    & Pick<Header, 'noOfItemsInBasket'>
    & { basketTotal: (
      { __typename?: 'FormattedNumber' }
      & Pick<FormattedNumber, 'value' | 'displayValue'>
    ) }
  ) }
);

export type PartsListsTotalQueryVariables = Exact<{ [key: string]: never; }>;


export type PartsListsTotalQuery = (
  { __typename?: 'Query' }
  & { header: (
    { __typename?: 'Header' }
    & Pick<Header, 'noOfPartsLists'>
  ) }
);

export type HeaderTotalsQueryVariables = Exact<{ [key: string]: never; }>;


export type HeaderTotalsQuery = (
  { __typename?: 'Query' }
  & { header: (
    { __typename?: 'Header' }
    & Pick<Header, 'noOfPartsLists' | 'noOfItemsInBasket'>
    & { customer: (
      { __typename?: 'CustomerInfo' }
      & Pick<CustomerInfo, 'isLoggedIn' | 'name' | 'email'>
    ), basketTotal: (
      { __typename?: 'FormattedNumber' }
      & Pick<FormattedNumber, 'value' | 'displayValue'>
    ) }
  ) }
);

export type LifecyclePageQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type LifecyclePageQuery = (
  { __typename?: 'Query' }
  & { lifecyclePage: (
    { __typename?: 'LifecyclePage' }
    & Pick<LifecyclePage, 'isSuccess' | 'html'>
  ) }
);

export type MasterQueryVariables = Exact<{ [key: string]: never; }>;


export type MasterQuery = (
  { __typename?: 'Query' }
  & { master: (
    { __typename?: 'Master' }
    & { labels?: Maybe<Array<(
      { __typename?: 'Label' }
      & LabelFragment
    )>>, metaData: (
      { __typename?: 'MasterMeta' }
      & Pick<MasterMeta, 'htmlLang'>
      & { links?: Maybe<Array<(
        { __typename?: 'HtmlTag' }
        & HtmlTagFragment
      )>>, metaTags?: Maybe<Array<(
        { __typename?: 'HtmlTag' }
        & { attributes: Array<(
          { __typename?: 'HtmlTagAttribute' }
          & HtmlTagAttributeFragment
        )> }
      )>> }
    ), tagging: (
      { __typename?: 'MasterTagging' }
      & { gtm: (
        { __typename?: 'MasterTaggingGTM' }
        & Pick<MasterTaggingGtm, 'gtmid' | 'isEnabled'>
      ) }
    ), scripts: (
      { __typename?: 'MasterScripts' }
      & { head: Array<(
        { __typename?: 'HtmlTag' }
        & HtmlTagFragment
      )>, bodyEnd: Array<(
        { __typename?: 'HtmlTag' }
        & HtmlTagFragment
      )> }
    ), config: (
      { __typename?: 'MasterConfig' }
      & Pick<MasterConfig, 'enableSiteWideBanner'>
    ), siteWideBanner?: Maybe<(
      { __typename?: 'SiteWideBanner' }
      & Pick<SiteWideBanner, 'headline' | 'body' | 'buttonText' | 'buttonUrl' | 'type'>
    )> }
  ), header: (
    { __typename?: 'Header' }
    & { metaData: (
      { __typename?: 'HeaderMeta' }
      & { structuredData?: Maybe<Array<(
        { __typename?: 'HtmlTag' }
        & HtmlTagFragment
      )>> }
    ), links: Array<(
      { __typename?: 'Link' }
      & LinkFragment
    )>, labels: Array<(
      { __typename?: 'Label' }
      & LabelFragment
    )>, siteSettings: (
      { __typename?: 'SiteSettings' }
      & Pick<SiteSettings, 'isVisible' | 'showLanguages' | 'showCurrencies' | 'showCountries' | 'showVATToggle'>
      & { languages: Array<(
        { __typename?: 'SettingsLanguage' }
        & Pick<SettingsLanguage, 'name' | 'baseDirectory' | 'countryCodeISO2' | 'isSelected'>
      )>, currencies: Array<(
        { __typename?: 'SettingsCurrency' }
        & Pick<SettingsCurrency, 'name' | 'displayName' | 'id' | 'shortName'>
      )>, countries: Array<(
        { __typename?: 'SettingsCountry' }
        & Pick<SettingsCountry, 'name' | 'codeISO2' | 'id' | 'contactEmail' | 'contactPhone' | 'contactAddress' | 'isSelected'>
      )> }
    ) }
  ), menu: (
    { __typename?: 'Menu' }
    & { labels: Array<(
      { __typename?: 'Label' }
      & LabelFragment
    )>, categories: Array<(
      { __typename?: 'MenuItem' }
      & Pick<MenuItem, 'description'>
      & { link: (
        { __typename?: 'Link' }
        & LinkFragment
      ) }
    )>, menuLinks: Array<(
      { __typename?: 'Link' }
      & LinkFragment
    )>, otherLinks: Array<(
      { __typename?: 'Link' }
      & LinkFragment
    )> }
  ), footer: (
    { __typename?: 'Footer' }
    & { config: (
      { __typename?: 'FooterConfig' }
      & Pick<FooterConfig, 'phoneNumber' | 'emailAddress' | 'businessAddress' | 'paymentMethods'>
      & { socialPlatforms: Array<(
        { __typename?: 'Link' }
        & LinkFragment
      )>, partnerLinks: Array<(
        { __typename?: 'Link' }
        & LinkFragment
      )> }
    ), labels: Array<(
      { __typename?: 'Label' }
      & LabelFragment
    )>, columnLinks: Array<(
      { __typename?: 'FooterLinkSection' }
      & Pick<FooterLinkSection, 'title'>
      & { links: Array<(
        { __typename?: 'Link' }
        & LinkFragment
      )> }
    )>, bottomLinks: (
      { __typename?: 'FooterLinkSection' }
      & { links: Array<(
        { __typename?: 'Link' }
        & LinkFragment
      )> }
    ), siteSettings: (
      { __typename?: 'FooterSiteSettings' }
      & Pick<FooterSiteSettings, 'isVisible' | 'showLanguages' | 'showCurrencies' | 'showCountries' | 'showVATToggle'>
      & { languages: Array<(
        { __typename?: 'FooterSettingsLanguage' }
        & Pick<FooterSettingsLanguage, 'name' | 'baseDirectory' | 'countryCodeISO2' | 'isSelected'>
      )>, currencies: Array<(
        { __typename?: 'FooterSettingsCurrency' }
        & Pick<FooterSettingsCurrency, 'name' | 'displayName' | 'id' | 'shortName'>
      )>, countries: Array<(
        { __typename?: 'FooterSettingsCountry' }
        & Pick<FooterSettingsCountry, 'name' | 'codeISO2' | 'id' | 'contactEmail' | 'contactPhone' | 'contactAddress' | 'isSelected'>
      )> }
    ), metaData: (
      { __typename?: 'FooterMeta' }
      & { structuredData?: Maybe<Array<(
        { __typename?: 'HtmlTag' }
        & HtmlTagFragment
      )>> }
    ) }
  ) }
);

export type Level1QueryVariables = Exact<{ [key: string]: never; }>;


export type Level1Query = (
  { __typename?: 'Query' }
  & { menu: (
    { __typename?: 'Menu' }
    & { categories: Array<(
      { __typename?: 'MenuItem' }
      & { link: (
        { __typename?: 'Link' }
        & LinkFragment
      ) }
    )> }
  ) }
);

export type Level2QueryVariables = Exact<{
  parentId: Scalars['Int'];
}>;


export type Level2Query = (
  { __typename?: 'Query' }
  & { menu: (
    { __typename?: 'Menu' }
    & { categories: Array<(
      { __typename?: 'MenuItem' }
      & Pick<MenuItem, 'description'>
      & { link: (
        { __typename?: 'Link' }
        & LinkFragment
      ), children?: Maybe<Array<(
        { __typename?: 'Link' }
        & LinkFragment
      )>> }
    )> }
  ) }
);

export type AllLevelsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllLevelsQuery = (
  { __typename?: 'Query' }
  & { menu: (
    { __typename?: 'Menu' }
    & { categories: Array<(
      { __typename?: 'MenuItem' }
      & { link: (
        { __typename?: 'Link' }
        & LinkFragment
      ), children?: Maybe<Array<(
        { __typename?: 'Link' }
        & LinkFragment
      )>> }
    )> }
  ) }
);

export type FeaturedBrandsQueryVariables = Exact<{ [key: string]: never; }>;


export type FeaturedBrandsQuery = (
  { __typename?: 'Query' }
  & { menu: (
    { __typename?: 'Menu' }
    & { featuredBrands: (
      { __typename?: 'FeaturedBrands' }
      & { brands: Array<(
        { __typename?: 'FeaturedBrand' }
        & Pick<FeaturedBrand, 'name' | 'imageUrl'>
        & { link: (
          { __typename?: 'Link' }
          & LinkFragment
        ) }
      )>, viewAll: (
        { __typename?: 'Link' }
        & LinkFragment
      ) }
    ) }
  ) }
);

export type NoResultsPageQueryVariables = Exact<{
  searchQuery: Scalars['String'];
  isProductNotFound?: Maybe<Scalars['Boolean']>;
}>;


export type NoResultsPageQuery = (
  { __typename?: 'Query' }
  & { noResultsPage: (
    { __typename?: 'NoResultsPage' }
    & { tagging: (
      { __typename?: 'PLPTagging' }
      & { gtm: (
        { __typename?: 'PLPTaggingGTM' }
        & { dataLayer: (
          { __typename?: 'HtmlTag' }
          & Pick<HtmlTag, 'innerHtml'>
        ) }
      ), tags: Array<(
        { __typename?: 'HtmlTag' }
        & HtmlTagFragment
      )> }
    ), metaData: (
      { __typename?: 'PLPMeta' }
      & Pick<PlpMeta, 'pageTitle' | 'canonicalUrl'>
      & { links?: Maybe<Array<(
        { __typename?: 'HtmlTag' }
        & { attributes: Array<(
          { __typename?: 'HtmlTagAttribute' }
          & HtmlTagAttributeFragment
        )> }
      )>>, metaTags?: Maybe<Array<(
        { __typename?: 'HtmlTag' }
        & { attributes: Array<(
          { __typename?: 'HtmlTagAttribute' }
          & HtmlTagAttributeFragment
        )> }
      )>> }
    ), labels: Array<(
      { __typename?: 'Label' }
      & LabelFragment
    )> }
  ) }
);

export type OrderConfirmationPageQueryVariables = Exact<{ [key: string]: never; }>;


export type OrderConfirmationPageQuery = (
  { __typename?: 'Query' }
  & { orderConfirmationPage: (
    { __typename?: 'OrderConfirmationPage' }
    & Pick<OrderConfirmationPage, 'isSuccess'>
    & { details?: Maybe<(
      { __typename?: 'OrderConfirmationPageDetails' }
      & Pick<OrderConfirmationPageDetails, 'numberOfItems' | 'isQuote' | 'orderReference' | 'orderReferencePersonal'>
      & { labels: Array<(
        { __typename?: 'Label' }
        & Pick<Label, 'name' | 'text'>
      )>, summary: (
        { __typename?: 'Summary' }
        & SummaryFragment
      ), parcels: Array<(
        { __typename?: 'Parcel' }
        & Pick<Parcel, 'title' | 'description'>
        & { items: Array<(
          { __typename?: 'ParcelItem' }
          & Pick<ParcelItem, 'quantity' | 'id'>
          & { product: (
            { __typename?: 'Product' }
            & { title: (
              { __typename?: 'ProductTitle' }
              & Pick<ProductTitle, 'brand' | 'formattedStockCode' | 'hsnCode' | 'mpn' | 'stockCode' | 'title' | 'primaryImageUrl' | 'pdpUrl' | 'partNumber'>
            ), priceArea: (
              { __typename?: 'ProductPriceArea' }
              & Pick<ProductPriceArea, 'minimumOrderQuantity' | 'container' | 'taxType' | 'errorMessage' | 'unitOfMeasure'>
              & { totalPrice: (
                { __typename?: 'Price' }
                & { priceBefore: (
                  { __typename?: 'FormattedNumber' }
                  & Pick<FormattedNumber, 'value' | 'displayValue'>
                ), priceAfter: (
                  { __typename?: 'FormattedNumber' }
                  & Pick<FormattedNumber, 'value' | 'displayValue'>
                ) }
              ), unitPrice: (
                { __typename?: 'Price' }
                & { priceAfter: (
                  { __typename?: 'FormattedNumber' }
                  & Pick<FormattedNumber, 'value' | 'displayValue'>
                ) }
              ), taxes: Array<(
                { __typename?: 'PriceTax' }
                & Pick<PriceTax, 'taxType' | 'taxAmountDisplayValue'>
              )> }
            ), stockArea: (
              { __typename?: 'ProductStock' }
              & Pick<ProductStock, 'statusCode' | 'title' | 'stockMessage' | 'infoMessage' | 'isUsingDTP' | 'denyBackOrder' | 'includesDate' | 'stockBadgeIcon'>
              & { stockAvailability?: Maybe<Array<(
                { __typename?: 'StockAvailability' }
                & Pick<StockAvailability, 'quantityInStock' | 'availabilityDate'>
              )>> }
            ), energyArea?: Maybe<(
              { __typename?: 'ProductEnergy' }
              & { energyLabel: (
                { __typename?: 'ProductEnergyLabel' }
                & ProductEnergyLabelFragment
              ) }
            )> }
          ) }
        )> }
      )>, paymentOption?: Maybe<(
        { __typename?: 'OrderConfirmationPage_PaymentOption' }
        & Pick<OrderConfirmationPage_PaymentOption, 'title' | 'description' | 'optionType'>
        & { details?: Maybe<(
          { __typename?: 'OrderConfirmationPage_BankTransferDetails' }
          & { configuration?: Maybe<(
            { __typename?: 'OrderPaymentOption_PaymentOptionConfiguration' }
            & { orderConfirmationMessage?: Maybe<(
              { __typename?: 'OrderPaymentOption_OrderConfirmationMessage' }
              & Pick<OrderPaymentOption_OrderConfirmationMessage, 'title'>
              & { content?: Maybe<Array<(
                { __typename?: 'TextEditor' }
                & TextEditorFragment
              )>> }
            )> }
          )>, bankDetails?: Maybe<(
            { __typename?: 'OrderPaymentOption_BankDetailsConfiguration' }
            & Pick<OrderPaymentOption_BankDetailsConfiguration, 'showBankDetails'>
            & { accountNumber?: Maybe<(
              { __typename?: 'OrderPaymentOption_BankDetailItem' }
              & Pick<OrderPaymentOption_BankDetailItem, 'label' | 'detail'>
            )>, bankCode?: Maybe<(
              { __typename?: 'OrderPaymentOption_BankDetailItem' }
              & Pick<OrderPaymentOption_BankDetailItem, 'label' | 'detail'>
            )>, bankName?: Maybe<(
              { __typename?: 'OrderPaymentOption_BankDetailItem' }
              & Pick<OrderPaymentOption_BankDetailItem, 'label' | 'detail'>
            )>, branchCode?: Maybe<(
              { __typename?: 'OrderPaymentOption_BankDetailItem' }
              & Pick<OrderPaymentOption_BankDetailItem, 'label' | 'detail'>
            )>, branchName?: Maybe<(
              { __typename?: 'OrderPaymentOption_BankDetailItem' }
              & Pick<OrderPaymentOption_BankDetailItem, 'label' | 'detail'>
            )>, companyName?: Maybe<(
              { __typename?: 'OrderPaymentOption_BankDetailItem' }
              & Pick<OrderPaymentOption_BankDetailItem, 'label' | 'detail'>
            )>, iban?: Maybe<(
              { __typename?: 'OrderPaymentOption_BankDetailItem' }
              & Pick<OrderPaymentOption_BankDetailItem, 'label' | 'detail'>
            )>, swiftCode?: Maybe<(
              { __typename?: 'OrderPaymentOption_BankDetailItem' }
              & Pick<OrderPaymentOption_BankDetailItem, 'label' | 'detail'>
            )> }
          )> }
        ) | (
          { __typename?: 'OrderConfirmationPage_ControlledPurchasingDetails' }
          & { controlledPurchasingDetails?: Maybe<(
            { __typename?: 'OrderPaymentOption_ControlledPurchasingConfiguration' }
            & { purchaser?: Maybe<(
              { __typename?: 'OrderPaymentOption_ControlledPurchasingConfiguration_Purchaser' }
              & Pick<OrderPaymentOption_ControlledPurchasingConfiguration_Purchaser, 'firstName' | 'lastName' | 'emailAddress'>
            )> }
          )> }
        )> }
      )>, contactDetails: (
        { __typename?: 'PurchasePageContactDetails' }
        & Pick<PurchasePageContactDetails, 'firstName' | 'lastName' | 'email' | 'phoneNo' | 'accountType' | 'isValid'>
      ), deliveryAddress: (
        { __typename?: 'OrderConfirmationPage_DeliveryAddress' }
        & Pick<OrderConfirmationPage_DeliveryAddress, 'uniqueId' | 'isValid'>
        & { details?: Maybe<(
          { __typename?: 'AddressFields' }
          & AddressFieldsFragment
        )> }
      ), billingAddress?: Maybe<(
        { __typename?: 'OrderConfirmationPage_BillingAddress' }
        & Pick<OrderConfirmationPage_BillingAddress, 'uniqueId' | 'isValid'>
        & { details?: Maybe<(
          { __typename?: 'AddressFields' }
          & AddressFieldsFragment
        )> }
      )>, tagging: (
        { __typename?: 'OrderConfirmationPageTagging' }
        & { gtm: (
          { __typename?: 'OrderConfirmationPageTaggingGTM' }
          & { dataLayer: (
            { __typename?: 'HtmlTag' }
            & Pick<HtmlTag, 'innerHtml'>
          ) }
        ), tags: Array<(
          { __typename?: 'HtmlTag' }
          & HtmlTagFragment
        )>, ga4: Array<(
          { __typename?: 'GA4Event_Purchase' }
          & Pick<Ga4Event_Purchase, 'event'>
          & { ecommerce: (
            { __typename?: 'GA4Event_Purchase_Ecommerce' }
            & Pick<Ga4Event_Purchase_Ecommerce, 'transactionId' | 'value' | 'tax' | 'shipping' | 'currency' | 'coupon' | 'v_landing' | 'total_prqt' | 'email'>
            & { items: Array<(
              { __typename?: 'GA4Item' }
              & Ga4ItemFragment
            )> }
          ) }
        )> }
      ), config: (
        { __typename?: 'OrderConfirmationPageConfig' }
        & Pick<OrderConfirmationPageConfig, 'hasDualUseProducts' | 'isUsingDtp'>
      ) }
    )> }
  ) }
);

export type PartsListQueryVariables = Exact<{
  contextStockCode?: Maybe<Scalars['String']>;
}>;


export type PartsListQuery = (
  { __typename?: 'Query' }
  & { partsListPage: (
    { __typename?: 'PartsListPage' }
    & Pick<PartsListPage, 'redirectUrl' | 'requireLogin'>
    & { labels?: Maybe<Array<(
      { __typename?: 'Label' }
      & LabelFragment
    )>>, partsListsArea?: Maybe<(
      { __typename?: 'PartsLists' }
      & { partsLists: Array<(
        { __typename?: 'PartsList' }
        & Pick<PartsList, 'id' | 'title' | 'noOfProducts' | 'isContextProductInList'>
        & { totalPrice: (
          { __typename?: 'Price' }
          & { priceAfter: (
            { __typename?: 'FormattedNumber' }
            & Pick<FormattedNumber, 'value' | 'displayValue'>
          ) }
        ) }
      )> }
    )> }
  ) }
);

export type PartsListProductsQueryVariables = Exact<{
  partsListId?: Maybe<Scalars['Int']>;
}>;


export type PartsListProductsQuery = (
  { __typename?: 'Query' }
  & { partsListPage: (
    { __typename?: 'PartsListPage' }
    & { partsListsArea?: Maybe<(
      { __typename?: 'PartsLists' }
      & { partsLists: Array<(
        { __typename?: 'PartsList' }
        & Pick<PartsList, 'id' | 'title'>
        & { products: Array<(
          { __typename?: 'PartsListProduct' }
          & Pick<PartsListProduct, 'stockCode' | 'imageUrl' | 'container' | 'title' | 'quantity'>
          & { price: (
            { __typename?: 'FormattedNumber' }
            & Pick<FormattedNumber, 'value' | 'displayValue'>
          ), energyArea?: Maybe<(
            { __typename?: 'ProductEnergy' }
            & { energyLabel: (
              { __typename?: 'ProductEnergyLabel' }
              & ProductEnergyLabelFragment
            ) }
          )> }
        )> }
      )> }
    )> }
  ) }
);

export type GetPartsListLabelsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPartsListLabelsQuery = (
  { __typename?: 'Query' }
  & { getPartsListLabels: (
    { __typename?: 'PartsListLabels' }
    & { labels: Array<(
      { __typename?: 'Label' }
      & LabelFragment
    )> }
  ) }
);

export type UpdatePartsListLineMutationVariables = Exact<{
  input: UpdatePartsListLineRequest;
}>;


export type UpdatePartsListLineMutation = (
  { __typename?: 'Mutation' }
  & { updatePartsListLine: (
    { __typename?: 'UpdatePartsListLineResponse' }
    & Pick<UpdatePartsListLineResponse, 'isSuccess' | 'errorMessage'>
    & { priceArea?: Maybe<(
      { __typename?: 'PartsListProductPriceArea' }
      & Pick<PartsListProductPriceArea, 'minimumOrderQuantity'>
      & { totalPrice: (
        { __typename?: 'Price' }
        & { priceAfter: (
          { __typename?: 'FormattedNumber' }
          & Pick<FormattedNumber, 'displayValue'>
        ) }
      ), unitPrice: (
        { __typename?: 'Price' }
        & { priceAfter: (
          { __typename?: 'FormattedNumber' }
          & Pick<FormattedNumber, 'displayValue'>
        ) }
      ) }
    )> }
  ) }
);

export type AddToPartsListV3MutationVariables = Exact<{
  data: AddToPartsListRequestV3;
}>;


export type AddToPartsListV3Mutation = (
  { __typename?: 'Mutation' }
  & { addToPartsListV3: (
    { __typename?: 'AddToPartsListResponse' }
    & Pick<AddToPartsListResponse, 'isSuccess' | 'partsListId' | 'requireLogin' | 'redirectUrl'>
  ) }
);

export type AddRecentSearchMutationVariables = Exact<{
  searchQuery: Scalars['String'];
}>;


export type AddRecentSearchMutation = (
  { __typename?: 'Mutation' }
  & { addRecentSearch: (
    { __typename?: 'AddRecentSearchResponse' }
    & Pick<AddRecentSearchResponse, 'isSuccess'>
  ) }
);

export type GetRecentSearchesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRecentSearchesQuery = (
  { __typename?: 'Query' }
  & { getRecentSearches: (
    { __typename?: 'RecentSearches' }
    & { labels: Array<(
      { __typename?: 'Label' }
      & LabelFragment
    )>, recentSearches: Array<(
      { __typename?: 'RecentSearch' }
      & Pick<RecentSearch, 'searchQuery' | 'url'>
    )> }
  ) }
);

export type RemoveAllRecentSearchesMutationVariables = Exact<{ [key: string]: never; }>;


export type RemoveAllRecentSearchesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeAllRecentSearches'>
);

export type RemoveRecentSearchMutationVariables = Exact<{
  searchQuery: Scalars['String'];
}>;


export type RemoveRecentSearchMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeRecentSearch'>
);

export type GetSuggestedSearchesQueryVariables = Exact<{
  searchQuery: Scalars['String'];
}>;


export type GetSuggestedSearchesQuery = (
  { __typename?: 'Query' }
  & { getSuggestedSearches: (
    { __typename?: 'SuggestedSearches' }
    & { labels: Array<(
      { __typename?: 'Label' }
      & LabelFragment
    )>, searchTerms: Array<(
      { __typename: 'SuggestedSearchTerm' }
      & Pick<SuggestedSearchTerm, 'url' | 'highlightedFragment' | 'searchQuery' | 'noOfMatches'>
      & { parentSuggest?: Maybe<(
        { __typename?: 'SuggestedSearchTerm' }
        & Pick<SuggestedSearchTerm, 'searchQuery' | 'url'>
      )> }
    ) | (
      { __typename: 'SuggestedProduct' }
      & Pick<SuggestedProduct, 'stockCode' | 'formattedStockCode' | 'brand' | 'primaryImageUrl' | 'pdpUrl' | 'mpn' | 'title' | 'highlightedFragment' | 'isAlternative' | 'comparisonPageUrl'>
    )> }
  ) }
);

export type ProductDetailPagesQueryVariables = Exact<{
  stockCodes: Array<Scalars['String']>;
}>;


export type ProductDetailPagesQuery = (
  { __typename?: 'Query' }
  & { productDetailPages: Array<(
    { __typename?: 'ProductDetailPage' }
    & { labels?: Maybe<Array<(
      { __typename?: 'Label' }
      & LabelFragment
    )>>, titleArea?: Maybe<(
      { __typename?: 'PDPTitle' }
      & Pick<PdpTitle, 'stockCode' | 'hsnCode' | 'impaCode'>
    )>, stockArea?: Maybe<(
      { __typename?: 'PDPStock' }
      & Pick<PdpStock, 'statusCode' | 'title'>
    )>, ppkArea?: Maybe<(
      { __typename?: 'PDPPPK' }
      & Pick<Pdpppk, 'isVisible' | 'ppkStockCode'>
    )>, priceArea?: Maybe<(
      { __typename?: 'PDPPricing' }
      & Pick<PdpPricing, 'container'>
      & { price: (
        { __typename?: 'Price' }
        & { priceBefore: (
          { __typename?: 'FormattedNumber' }
          & Pick<FormattedNumber, 'value' | 'displayValue'>
        ), priceAfter: (
          { __typename?: 'FormattedNumber' }
          & Pick<FormattedNumber, 'value' | 'displayValue'>
        ) }
      ) }
    )> }
  )> }
);

export type GetPricesQueryVariables = Exact<{
  stockCode: Scalars['String'];
}>;


export type GetPricesQuery = (
  { __typename?: 'Query' }
  & { productListPage: (
    { __typename?: 'ProductListPage' }
    & { productPrices: Array<(
      { __typename?: 'PLPPricesItem' }
      & Pick<PlpPricesItem, 'quantity'>
      & { totalPrice: (
        { __typename?: 'Price' }
        & { priceAfter: (
          { __typename?: 'FormattedNumber' }
          & Pick<FormattedNumber, 'value' | 'displayValue'>
        ) }
      ) }
    )> }
  ) }
);

export type GetPricesByQtyQueryVariables = Exact<{
  stockCode: Scalars['String'];
  quantity: Scalars['Int'];
}>;


export type GetPricesByQtyQuery = (
  { __typename?: 'Query' }
  & { productDetailPage: (
    { __typename?: 'ProductDetailPage' }
    & { priceArea?: Maybe<(
      { __typename?: 'PDPPricing' }
      & Pick<PdpPricing, 'errorMessage'>
      & { prices: Array<(
        { __typename?: 'PDPPricesItem' }
        & { totalPrice: (
          { __typename?: 'Price' }
          & { priceAfter: (
            { __typename?: 'FormattedNumber' }
            & Pick<FormattedNumber, 'displayValue'>
          ) }
        ) }
      )>, pricesExVat: Array<(
        { __typename?: 'PDPPricesItem' }
        & { totalPrice: (
          { __typename?: 'Price' }
          & { priceAfter: (
            { __typename?: 'FormattedNumber' }
            & Pick<FormattedNumber, 'displayValue'>
          ) }
        ) }
      )> }
    )> }
  ) }
);

export type PdpCalibrationFragment = (
  { __typename?: 'PDPCalibration' }
  & Pick<PdpCalibration, 'hasCalibrationOptions'>
  & { details: Array<(
    { __typename?: 'PDPCalibrationDetails' }
    & Pick<PdpCalibrationDetails, 'stockCode' | 'isDefault' | 'title' | 'description'>
    & { pricing: (
      { __typename?: 'PDPPricing' }
      & PricingFragment
    ) }
  )> }
);

export type PdpStockFragment = (
  { __typename?: 'PDPStock' }
  & Pick<PdpStock, 'statusCode' | 'title' | 'stockMessage' | 'dtpStockMessages' | 'infoMessage' | 'isUsingDTP' | 'denyBackOrder' | 'availabilityDate' | 'includesDate' | 'stockBadgeIcon'>
  & { stockAvailability?: Maybe<Array<(
    { __typename?: 'StockAvailability' }
    & Pick<StockAvailability, 'quantityInStock' | 'availabilityDate'>
  )>> }
);

export type PdpProductTypeFragment = (
  { __typename?: 'PDPProductType' }
  & Pick<PdpProductType, 'isVisible' | 'title' | 'mode' | 'message'>
);

export type ProductDetailPageQueryVariables = Exact<{
  stockCode: Scalars['String'];
}>;


export type ProductDetailPageQuery = (
  { __typename?: 'Query' }
  & { productDetailPage: (
    { __typename?: 'ProductDetailPage' }
    & Pick<ProductDetailPage, 'isSuccess' | 'pdpUrl' | 'allowAddToPartsList'>
    & { metaData?: Maybe<(
      { __typename?: 'ProductDetailPageMeta' }
      & Pick<ProductDetailPageMeta, 'pageTitle' | 'structuredData' | 'canonicalUrl'>
      & { metaTags?: Maybe<Array<(
        { __typename?: 'HtmlTag' }
        & { attributes: Array<(
          { __typename?: 'HtmlTagAttribute' }
          & HtmlTagAttributeFragment
        )> }
      )>>, links?: Maybe<Array<(
        { __typename?: 'HtmlTag' }
        & { attributes: Array<(
          { __typename?: 'HtmlTagAttribute' }
          & HtmlTagAttributeFragment
        )> }
      )>> }
    )>, tagging?: Maybe<(
      { __typename?: 'PDPTagging' }
      & { gtm: (
        { __typename?: 'PDPTaggingGTM' }
        & { dataLayer: (
          { __typename?: 'HtmlTag' }
          & Pick<HtmlTag, 'innerHtml'>
        ) }
      ), tags: Array<(
        { __typename?: 'HtmlTag' }
        & HtmlTagFragment
      )> }
    )>, config: (
      { __typename?: 'PDPConfig' }
      & Pick<PdpConfig, 'showDesignSparkArea'>
    ), breadcrumb: (
      { __typename?: 'PDPBreadcrumb' }
      & Pick<PdpBreadcrumb, 'bookId'>
      & { links: Array<(
        { __typename?: 'Link' }
        & LinkFragment
      )> }
    ), labels?: Maybe<Array<(
      { __typename?: 'Label' }
      & LabelFragment
    )>>, titleArea?: Maybe<(
      { __typename?: 'PDPTitle' }
      & Pick<PdpTitle, 'stockCode' | 'formattedStockCode' | 'brand' | 'mpn' | 'hsnCode' | 'title' | 'impaCode' | 'distrelecProductId' | 'familyId'>
    )>, mediaArea?: Maybe<(
      { __typename?: 'PDPMedia' }
      & Pick<PdpMedia, 'disclaimerMessage' | 'mediaUrl' | 'showPCBFootprint' | 'isZoomEnabled'>
      & { media: Array<(
        { __typename?: 'PDPMediaItem' }
        & Pick<PdpMediaItem, 'id' | 'url' | 'mediaType' | 'imageType' | 'name' | 'thumbnailUrl'>
      )>, pcbFootprintParameters?: Maybe<(
        { __typename?: 'PDPPCBDetails' }
        & Pick<PdppcbDetails, 'brand' | 'mpn' | 'langCode'>
      )>, vendorLogo?: Maybe<(
        { __typename?: 'PDPMediaItem' }
        & Pick<PdpMediaItem, 'url'>
      )> }
    )>, ppkArea?: Maybe<(
      { __typename?: 'PDPPPK' }
      & Pick<Pdpppk, 'isEnabled' | 'isVisible' | 'ppkSSM' | 'standardMinimumOrderQuantity' | 'minimumOrderQuantity' | 'standardUnitOfMeasure' | 'unitOfMeasure' | 'standardStockCode' | 'ppkStockCode' | 'title' | 'message'>
    )>, priceArea?: Maybe<(
      { __typename?: 'PDPPricing' }
      & PricingFragment
    )>, stockArea?: Maybe<(
      { __typename?: 'PDPStock' }
      & PdpStockFragment
    )>, productTypeArea: (
      { __typename?: 'PDPProductType' }
      & { secondaryMessages?: Maybe<Array<(
        { __typename?: 'PDPProductType' }
        & PdpProductTypeFragment
      )>> }
      & PdpProductTypeFragment
    ), punchoutInfoArea: (
      { __typename?: 'PDPPunchoutInfo' }
      & Pick<PdpPunchoutInfo, 'isBarred' | 'isRestricted'>
    ), descriptionArea: (
      { __typename?: 'PDPDescription' }
      & { descriptions: Array<(
        { __typename?: 'PDPDescriptionItem' }
        & Pick<PdpDescriptionItem, 'title' | 'description'>
      )> }
    ), attributesArea: (
      { __typename?: 'PDPAttributes' }
      & { attributes: Array<(
        { __typename?: 'PDPAttributeItem' }
        & Pick<PdpAttributeItem, 'name' | 'value' | 'nameId' | 'valueId'>
      )> }
    ), stickersArea: (
      { __typename?: 'PDPStickers' }
      & { stickers: Array<(
        { __typename?: 'PDPStickersItem' }
        & Pick<PdpStickersItem, 'id' | 'content' | 'contentType' | 'position'>
      )> }
    ), documentsArea: (
      { __typename?: 'PDPDocuments' }
      & { documents: Array<(
        { __typename?: 'PDPDocumentItem' }
        & Pick<PdpDocumentItem, 'documentType' | 'title' | 'description' | 'url' | 'urlText' | 'tracePartsPartId'>
      )> }
    ), alternativesRSPro: (
      { __typename?: 'PDPAlternativesRSPro' }
      & Pick<PdpAlternativesRsPro, 'isVisible' | 'url'>
    ), energyArea?: Maybe<(
      { __typename?: 'ProductEnergy' }
      & { energyLabel: (
        { __typename?: 'ProductEnergyLabel' }
        & ProductEnergyLabelFragment
      ) }
    )>, calibrationArea?: Maybe<(
      { __typename?: 'PDPCalibration' }
      & PdpCalibrationFragment
    )>, alternativeProducts: Array<(
      { __typename?: 'ProductDetailPage' }
      & Pick<ProductDetailPage, 'pdpUrl'>
      & { titleArea?: Maybe<(
        { __typename?: 'PDPTitle' }
        & Pick<PdpTitle, 'brand' | 'stockCode' | 'title'>
      )>, priceArea?: Maybe<(
        { __typename?: 'PDPPricing' }
        & Pick<PdpPricing, 'container'>
        & { price: (
          { __typename?: 'Price' }
          & { priceAfter: (
            { __typename?: 'FormattedNumber' }
            & Pick<FormattedNumber, 'displayValue'>
          ) }
        ) }
      )>, energyArea?: Maybe<(
        { __typename?: 'ProductEnergy' }
        & { energyLabel: (
          { __typename?: 'ProductEnergyLabel' }
          & ProductEnergyLabelFragment
        ) }
      )>, mediaArea?: Maybe<(
        { __typename?: 'PDPMedia' }
        & Pick<PdpMedia, 'mediaUrl'>
        & { media: Array<(
          { __typename?: 'PDPMediaItem' }
          & Pick<PdpMediaItem, 'id' | 'url' | 'mediaType' | 'imageType' | 'name'>
        )> }
      )> }
    )> }
  ) }
);

export type GetTechnicalDocumentsQueryVariables = Exact<{
  stockCode: Scalars['String'];
  requestId: Scalars['String'];
}>;


export type GetTechnicalDocumentsQuery = (
  { __typename?: 'Query' }
  & { productDetailPage: (
    { __typename?: 'ProductDetailPage' }
    & { documentsArea: (
      { __typename?: 'PDPDocuments' }
      & { documents: Array<(
        { __typename?: 'PDPDocumentItem' }
        & Pick<PdpDocumentItem, 'documentType' | 'title' | 'url'>
      )> }
    ) }
  ) }
);

export type UpdatePpkStockCodeDataQueryVariables = Exact<{
  stockCode: Scalars['String'];
}>;


export type UpdatePpkStockCodeDataQuery = (
  { __typename?: 'Query' }
  & { productDetailPage: (
    { __typename?: 'ProductDetailPage' }
    & { titleArea?: Maybe<(
      { __typename?: 'PDPTitle' }
      & Pick<PdpTitle, 'stockCode' | 'formattedStockCode'>
    )>, priceArea?: Maybe<(
      { __typename?: 'PDPPricing' }
      & PricingFragment
    )>, stockArea?: Maybe<(
      { __typename?: 'PDPStock' }
      & PdpStockFragment
    )> }
  ) }
);

export type UpdateCalibrationStockCodeDataQueryVariables = Exact<{
  stockCode: Scalars['String'];
}>;


export type UpdateCalibrationStockCodeDataQuery = (
  { __typename?: 'Query' }
  & { productDetailPage: (
    { __typename?: 'ProductDetailPage' }
    & { titleArea?: Maybe<(
      { __typename?: 'PDPTitle' }
      & Pick<PdpTitle, 'title' | 'stockCode' | 'formattedStockCode'>
    )>, priceArea?: Maybe<(
      { __typename?: 'PDPPricing' }
      & PricingFragment
    )>, stockArea?: Maybe<(
      { __typename?: 'PDPStock' }
      & PdpStockFragment
    )> }
  ) }
);

export type GetCadDocumentsQueryVariables = Exact<{
  stockCode: Scalars['String'];
  tracePartsPartId?: Maybe<Scalars['String']>;
}>;


export type GetCadDocumentsQuery = (
  { __typename?: 'Query' }
  & { cadDocuments: (
    { __typename?: 'PDPCADData' }
    & { tracePartsData: (
      { __typename?: 'PDPTracePartsCADData' }
      & { documents: Array<(
        { __typename?: 'PDPTracePartsDataItem' }
        & Pick<PdpTracePartsDataItem, 'id' | 'name'>
      )> }
    ) }
  ) }
);

export type GetTracePartsDocumentUrlQueryVariables = Exact<{
  documentId: Scalars['Float'];
  stockCode: Scalars['String'];
  tracePartsPartId?: Maybe<Scalars['String']>;
}>;


export type GetTracePartsDocumentUrlQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'tracePartsDocumentUrl'>
);

export type DataSheetsQueryVariables = Exact<{
  stockCode: Scalars['String'];
  documentsType: Scalars['String'];
}>;


export type DataSheetsQuery = (
  { __typename?: 'Query' }
  & { dataSheets: Array<(
    { __typename?: 'PDPDocumentItem' }
    & Pick<PdpDocumentItem, 'documentType' | 'title' | 'url'>
  )> }
);

export type TechnicalDocumentsLoadedSubscriptionVariables = Exact<{
  stockCode: Scalars['String'];
  requestId: Scalars['String'];
}>;


export type TechnicalDocumentsLoadedSubscription = (
  { __typename?: 'Subscription' }
  & { technicalDocumentLoaded: (
    { __typename?: 'PDPDocuments' }
    & { documents: Array<(
      { __typename?: 'PDPDocumentItem' }
      & Pick<PdpDocumentItem, 'documentType' | 'title' | 'url'>
    )> }
  ) }
);

export type ProductDetailPageStockQueryVariables = Exact<{
  stockCode: Scalars['String'];
}>;


export type ProductDetailPageStockQuery = (
  { __typename?: 'Query' }
  & { productDetailPage: (
    { __typename?: 'ProductDetailPage' }
    & { stockArea?: Maybe<(
      { __typename?: 'PDPStock' }
      & PdpStockFragment
    )> }
  ) }
);

export type CheckPcbAvailabilityQueryVariables = Exact<{
  manufacturer: Scalars['String'];
  partNumber: Scalars['String'];
}>;


export type CheckPcbAvailabilityQuery = (
  { __typename?: 'Query' }
  & { checkPCBAvailability: (
    { __typename?: 'PCBAvailibilityCheck' }
    & Pick<PcbAvailibilityCheck, 'isPCBAvailable'>
  ) }
);

export type PlpProductTypeFragment = (
  { __typename?: 'PLPProductType' }
  & Pick<PlpProductType, 'isVisible' | 'title' | 'mode' | 'message'>
);

export type PlpProductFragment = (
  { __typename?: 'PLPProduct' }
  & { title: (
    { __typename?: 'PLPTitle' }
    & Pick<PlpTitle, 'stockCode' | 'formattedStockCode' | 'title' | 'brand' | 'hsnCode' | 'mpn' | 'primaryImageUrl' | 'pdpUrl' | 'impaCode'>
  ), categoryAttributes: Array<(
    { __typename?: 'PLPAttributeItem' }
    & Pick<PlpAttributeItem, 'attributeNameId' | 'value'>
  )>, priceArea: (
    { __typename?: 'PLPPricing' }
    & Pick<PlpPricing, 'container' | 'minimumOrderQuantity' | 'isInBasket'>
    & { price: (
      { __typename?: 'Price' }
      & PriceDataFragment
    ) }
  ), productTypeArea: (
    { __typename?: 'PLPProductType' }
    & { secondaryMessages?: Maybe<Array<(
      { __typename?: 'PLPProductType' }
      & PlpProductTypeFragment
    )>> }
    & PlpProductTypeFragment
  ), ppkArea: (
    { __typename?: 'PLPPPK' }
    & Pick<Plpppk, 'isVisible' | 'pdpURL'>
  ), punchoutInfoArea: (
    { __typename?: 'PLPPunchoutInfo' }
    & Pick<PlpPunchoutInfo, 'isBarred' | 'isRestricted'>
  ), energyArea?: Maybe<(
    { __typename?: 'ProductEnergy' }
    & { energyLabel: (
      { __typename?: 'ProductEnergyLabel' }
      & ProductEnergyLabelFragment
    ) }
  )> }
);

export type PlpProductsFragment = (
  { __typename?: 'PLPProducts' }
  & { products: Array<(
    { __typename?: 'PLPProduct' }
    & PlpProductFragment
  )>, categoryAttributes?: Maybe<(
    { __typename?: 'PLPCategoryAttributes' }
    & { attributes: Array<(
      { __typename?: 'PLPCategoryAttribute' }
      & Pick<PlpCategoryAttribute, 'name' | 'id'>
    )> }
  )> }
);

export type FacetsFragment = (
  { __typename?: 'PLPFacets' }
  & { facets: Array<(
    { __typename?: 'Facet' }
    & FacetFragment
  )>, flagFacets: Array<(
    { __typename?: 'FacetValue' }
    & FacetValueFragment
  )> }
);

export type PlpCategoryItemFragment = (
  { __typename?: 'PLPCategoryItem' }
  & Pick<PlpCategoryItem, 'id' | 'noOfProducts' | 'name'>
  & { childCategories?: Maybe<Array<(
    { __typename?: 'PLPCategoryItem' }
    & Pick<PlpCategoryItem, 'id' | 'name' | 'noOfProducts'>
  )>> }
);

export type ProductListPageQueryVariables = Exact<{
  slug?: Maybe<Scalars['String']>;
  tagName?: Maybe<Scalars['String']>;
  searchQuery?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
}>;


export type ProductListPageQuery = (
  { __typename?: 'Query' }
  & { productListPage: (
    { __typename?: 'ProductListPage' }
    & Pick<ProductListPage, 'isSuccess' | 'redirectUrl' | 'categoryId' | 'title' | 'description'>
    & { metaData: (
      { __typename?: 'PLPMeta' }
      & Pick<PlpMeta, 'pageTitle' | 'canonicalUrl'>
      & { metaTags?: Maybe<Array<(
        { __typename?: 'HtmlTag' }
        & { attributes: Array<(
          { __typename?: 'HtmlTagAttribute' }
          & HtmlTagAttributeFragment
        )> }
      )>>, links?: Maybe<Array<(
        { __typename?: 'HtmlTag' }
        & { attributes: Array<(
          { __typename?: 'HtmlTagAttribute' }
          & HtmlTagAttributeFragment
        )> }
      )>> }
    ), tagging: (
      { __typename?: 'PLPTagging' }
      & { gtm: (
        { __typename?: 'PLPTaggingGTM' }
        & { dataLayer: (
          { __typename?: 'HtmlTag' }
          & Pick<HtmlTag, 'innerHtml'>
        ) }
      ), tags: Array<(
        { __typename?: 'HtmlTag' }
        & HtmlTagFragment
      )> }
    ), labels: Array<(
      { __typename?: 'Label' }
      & LabelFragment
    )>, breadcrumb: Array<(
      { __typename?: 'Link' }
      & LinkFragment
    )>, productsArea?: Maybe<(
      { __typename?: 'PLPProducts' }
      & PlpProductsFragment
    )>, facetsArea?: Maybe<(
      { __typename?: 'PLPFacets' }
      & { appliedFilters: Array<(
        { __typename?: 'Facet' }
        & FacetFragment
      )>, appliedFlagFilters: Array<(
        { __typename?: 'FacetValue' }
        & FacetValueFragment
      )> }
      & FacetsFragment
    )>, categoriesArea: Array<(
      { __typename?: 'PLPCategoryItem' }
      & PlpCategoryItemFragment
    )>, summary?: Maybe<(
      { __typename?: 'PLPSummary' }
      & Pick<PlpSummary, 'totalProducts'>
    )>, config: (
      { __typename?: 'PLPProductsConfig' }
      & Pick<PlpProductsConfig, 'showYourPrice' | 'isLoggedIn' | 'queryStock'>
    ) }
  ) }
);

export type PreviewFiltersQueryVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
  searchQuery?: Maybe<Scalars['String']>;
  tagName?: Maybe<Scalars['String']>;
  selector?: Maybe<SearchSelector>;
}>;


export type PreviewFiltersQuery = (
  { __typename?: 'Query' }
  & { productListPage: (
    { __typename?: 'ProductListPage' }
    & { facetsArea?: Maybe<(
      { __typename?: 'PLPFacets' }
      & FacetsFragment
    )>, summary?: Maybe<(
      { __typename?: 'PLPSummary' }
      & Pick<PlpSummary, 'totalProducts'>
    )> }
  ) }
);

export type PreviewFiltersTotalProductsQueryVariables = Exact<{
  slug?: Maybe<Scalars['String']>;
  selector?: Maybe<SearchSelector>;
}>;


export type PreviewFiltersTotalProductsQuery = (
  { __typename?: 'Query' }
  & { productListPage: (
    { __typename?: 'ProductListPage' }
    & { summary?: Maybe<(
      { __typename?: 'PLPSummary' }
      & Pick<PlpSummary, 'totalProducts'>
    )> }
  ) }
);

export type FilterProductsQueryVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
  searchQuery?: Maybe<Scalars['String']>;
  tagName?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  selector?: Maybe<SearchSelector>;
  sort?: Maybe<SearchSort>;
}>;


export type FilterProductsQuery = (
  { __typename?: 'Query' }
  & { productListPage: (
    { __typename?: 'ProductListPage' }
    & { productsArea?: Maybe<(
      { __typename?: 'PLPProducts' }
      & PlpProductsFragment
    )>, facetsArea?: Maybe<(
      { __typename?: 'PLPFacets' }
      & { appliedFilters: Array<(
        { __typename?: 'Facet' }
        & FacetFragment
      )>, appliedFlagFilters: Array<(
        { __typename?: 'FacetValue' }
        & FacetValueFragment
      )> }
    )>, categoriesArea: Array<(
      { __typename?: 'PLPCategoryItem' }
      & PlpCategoryItemFragment
    )>, summary?: Maybe<(
      { __typename?: 'PLPSummary' }
      & Pick<PlpSummary, 'totalProducts'>
    )> }
  ) }
);

export type FilterProductsAndFacetsQueryVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
  searchQuery?: Maybe<Scalars['String']>;
  tagName?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  selector?: Maybe<SearchSelector>;
  sort?: Maybe<SearchSort>;
}>;


export type FilterProductsAndFacetsQuery = (
  { __typename?: 'Query' }
  & { productListPage: (
    { __typename?: 'ProductListPage' }
    & { productsArea?: Maybe<(
      { __typename?: 'PLPProducts' }
      & PlpProductsFragment
    )>, facetsArea?: Maybe<(
      { __typename?: 'PLPFacets' }
      & { appliedFilters: Array<(
        { __typename?: 'Facet' }
        & FacetFragment
      )>, appliedFlagFilters: Array<(
        { __typename?: 'FacetValue' }
        & FacetValueFragment
      )> }
      & FacetsFragment
    )>, categoriesArea: Array<(
      { __typename?: 'PLPCategoryItem' }
      & PlpCategoryItemFragment
    )>, summary?: Maybe<(
      { __typename?: 'PLPSummary' }
      & Pick<PlpSummary, 'totalProducts'>
    )> }
  ) }
);

export type GetProductsQueryVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
  searchQuery?: Maybe<Scalars['String']>;
  tagName?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  selector?: Maybe<SearchSelector>;
  sort?: Maybe<SearchSort>;
}>;


export type GetProductsQuery = (
  { __typename?: 'Query' }
  & { productListPage: (
    { __typename?: 'ProductListPage' }
    & { productsArea?: Maybe<(
      { __typename?: 'PLPProducts' }
      & PlpProductsFragment
    )> }
  ) }
);

export type GetPlpStockQueryVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
  searchQuery?: Maybe<Scalars['String']>;
  tagName?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  selector?: Maybe<SearchSelector>;
  sort?: Maybe<SearchSort>;
}>;


export type GetPlpStockQuery = (
  { __typename?: 'Query' }
  & { productListPage: (
    { __typename?: 'ProductListPage' }
    & { productsArea?: Maybe<(
      { __typename?: 'PLPProducts' }
      & { products: Array<(
        { __typename?: 'PLPProduct' }
        & { title: (
          { __typename?: 'PLPTitle' }
          & Pick<PlpTitle, 'stockCode'>
        ), stockArea: (
          { __typename?: 'PLPStock' }
          & Pick<PlpStock, 'statusCode' | 'title' | 'stockMessage' | 'dtpStockMessages' | 'infoMessage' | 'isUsingDTP' | 'denyBackOrder' | 'availabilityDate' | 'includesDate' | 'stockBadgeIcon'>
          & { stockAvailability?: Maybe<Array<(
            { __typename?: 'StockAvailability' }
            & Pick<StockAvailability, 'quantityInStock' | 'availabilityDate'>
          )>> }
        ) }
      )> }
    )> }
  ) }
);

export type PurchasePageQueryVariables = Exact<{ [key: string]: never; }>;


export type PurchasePageQuery = (
  { __typename?: 'Query' }
  & { purchasePage: (
    { __typename?: 'PurchasePage' }
    & Pick<PurchasePage, 'isSuccess' | 'isQuote'>
    & { labels: Array<(
      { __typename?: 'Label' }
      & LabelFragment
    )>, summary: (
      { __typename?: 'Summary' }
      & SummaryFragment
    ), contactDetails: (
      { __typename?: 'PurchasePageContactDetails' }
      & Pick<PurchasePageContactDetails, 'firstName' | 'lastName' | 'email' | 'phoneNo' | 'isValid' | 'accountType' | 'taxRegistrationNumber'>
    ), orderDetails: (
      { __typename?: 'PurchasePageOrderDetails' }
      & Pick<PurchasePageOrderDetails, 'orderReference'>
      & { lines: Array<(
        { __typename?: 'OrderLine' }
        & Pick<OrderLine, 'stockCode' | 'title' | 'quantity'>
      )> }
    ), deliveryDetails?: Maybe<(
      { __typename?: 'PurchasePageDeliveryDetails' }
      & { country: (
        { __typename?: 'Country' }
        & Pick<Country, 'name' | 'code'>
      ), deliveryCountries: (
        { __typename?: 'DeliveryCountries' }
        & { options: Array<(
          { __typename?: 'Country' }
          & Pick<Country, 'name' | 'code'>
        )> }
      ), address?: Maybe<(
        { __typename?: 'PurchasePageDeliveryAddress' }
        & Pick<PurchasePageDeliveryAddress, 'uniqueId' | 'isValid'>
        & { details?: Maybe<(
          { __typename?: 'AddressFields' }
          & AddressFieldsFragment
        )> }
      )>, endDeliveryCountries: (
        { __typename?: 'DeliveryCountries' }
        & { options: Array<(
          { __typename?: 'Country' }
          & Pick<Country, 'name' | 'code'>
        )> }
      ) }
    )>, billingDetails?: Maybe<(
      { __typename?: 'PurchasePageBillingDetails' }
      & { countries: Array<(
        { __typename?: 'Country' }
        & Pick<Country, 'name' | 'code'>
      )>, selectedCountry?: Maybe<(
        { __typename?: 'Country' }
        & Pick<Country, 'name' | 'code'>
      )>, address?: Maybe<(
        { __typename?: 'PurchasePageBillingAddress' }
        & Pick<PurchasePageBillingAddress, 'uniqueId' | 'isValid'>
        & { details?: Maybe<(
          { __typename?: 'AddressFields' }
          & AddressFieldsFragment
        )> }
      )> }
    )>, paymentOptions: (
      { __typename?: 'PurchasePagePaymentOptions' }
      & { options: Array<(
        { __typename?: 'PurchasePagePaymentOption' }
        & Pick<PurchasePagePaymentOption, 'id' | 'name' | 'description' | 'isSelected' | 'optionType' | 'callbackUrl' | 'callbackMode'>
        & { details?: Maybe<(
          { __typename?: 'PurchasePagePaymentOption_Account' }
          & Pick<PurchasePagePaymentOption_Account, 'accountNumberLabel' | 'soldToNumber'>
        ) | (
          { __typename?: 'PurchasePagePaymentOption_ControlledPurchasing' }
          & { purchasers: Array<(
            { __typename?: 'PurchasePagePaymentOption_ControlledPurchasing_Purchasers' }
            & Pick<PurchasePagePaymentOption_ControlledPurchasing_Purchasers, 'id' | 'firstName' | 'lastName' | 'emailAddress'>
          )> }
        )> }
      )> }
    ), config: (
      { __typename?: 'PurchasePageConfig' }
      & Pick<PurchasePageConfig, 'paymentMethods' | 'userMayNotHavePanCardNumber'>
    ), tagging: (
      { __typename?: 'PurchasePageTagging' }
      & { gtm: (
        { __typename?: 'PurchasePageTaggingGTM' }
        & { dataLayer: (
          { __typename?: 'HtmlTag' }
          & Pick<HtmlTag, 'innerHtml'>
        ) }
      ), tags: Array<(
        { __typename?: 'HtmlTag' }
        & HtmlTagFragment
      )>, ga4: Array<(
        { __typename?: 'GA4Event_AddPaymentInfo' }
        & Pick<Ga4Event_AddPaymentInfo, 'event'>
        & { ecommerce: (
          { __typename?: 'GA4Event_AddPaymentInfo_Ecommerce' }
          & Pick<Ga4Event_AddPaymentInfo_Ecommerce, 'currency' | 'value' | 'coupon' | 'payment_type' | 'email'>
          & { items: Array<(
            { __typename?: 'GA4Item' }
            & Ga4ItemFragment
          )> }
        ) }
      )> }
    ) }
  ) }
);

export type SaveContactDetailsMutationVariables = Exact<{
  data: Purchase_SaveContactDetailsRequest;
}>;


export type SaveContactDetailsMutation = (
  { __typename?: 'Mutation' }
  & { saveContactDetails: (
    { __typename?: 'Purchase_SaveContactDetailsResponse' }
    & Pick<Purchase_SaveContactDetailsResponse, 'isSuccess' | 'errorMessage'>
  ) }
);

export type ContactDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type ContactDetailsQuery = (
  { __typename?: 'Query' }
  & { purchasePage: (
    { __typename?: 'PurchasePage' }
    & { contactDetails: (
      { __typename?: 'PurchasePageContactDetails' }
      & Pick<PurchasePageContactDetails, 'firstName' | 'lastName' | 'email' | 'phoneNo' | 'isValid' | 'accountType' | 'taxRegistrationNumber'>
    ) }
  ) }
);

export type CountryAddressConfigurationQueryVariables = Exact<{
  countryCode: Scalars['String'];
}>;


export type CountryAddressConfigurationQuery = (
  { __typename?: 'Query' }
  & { countryAddressConfiguration: (
    { __typename?: 'CountryAddressConfigurationResponse' }
    & { states: Array<(
      { __typename?: 'State' }
      & Pick<State, 'code' | 'name' | 'requireTaxExemptionCertificate' | 'tinNumber'>
    )>, addressDetails: (
      { __typename?: 'AddressConfiguration' }
      & Pick<AddressConfiguration, 'showPostCode' | 'requireEORI' | 'validationRegexPostCode' | 'formatPostCode' | 'errorMessagePostCode'>
      & { deliveryAddress: (
        { __typename?: 'DeliveryAddressConfiguration' }
        & Pick<DeliveryAddressConfiguration, 'showVatNo' | 'requireVatNo'>
      ) }
    ) }
  ) }
);

export type AddressListV2QueryVariables = Exact<{
  input: AddressListRequest;
}>;


export type AddressListV2Query = (
  { __typename?: 'Query' }
  & { addressListV2: (
    { __typename?: 'AddressList' }
    & { addresses: Array<(
      { __typename?: 'Address' }
      & Pick<Address, 'uniqueId' | 'isValid'>
      & { details?: Maybe<(
        { __typename?: 'AddressFields' }
        & AddressFieldsFragment
      )> }
    )> }
  ) }
);

export type SetBasketDeliveryAddressMutationVariables = Exact<{
  input: Purchase_SetBasketAddressRequest;
}>;


export type SetBasketDeliveryAddressMutation = (
  { __typename?: 'Mutation' }
  & { setBasketDeliveryAddress: (
    { __typename?: 'Purchase_SetBasketAddressResponse' }
    & Pick<Purchase_SetBasketAddressResponse, 'isSuccess' | 'errorMessage' | 'isAddressValid'>
  ) }
);

export type SetBasketBillingAddressMutationVariables = Exact<{
  input: Purchase_SetBasketAddressRequest;
}>;


export type SetBasketBillingAddressMutation = (
  { __typename?: 'Mutation' }
  & { setBasketBillingAddress: (
    { __typename?: 'Purchase_SetBasketAddressResponse' }
    & Pick<Purchase_SetBasketAddressResponse, 'isSuccess' | 'errorMessage' | 'isAddressValid'>
  ) }
);

export type SaveDeliveryAddressMutationVariables = Exact<{
  input: SaveAddressRequest;
}>;


export type SaveDeliveryAddressMutation = (
  { __typename?: 'Mutation' }
  & { saveDeliveryAddress: (
    { __typename?: 'SaveAddressResponse' }
    & Pick<SaveAddressResponse, 'isSuccess' | 'errorMessage' | 'addressUniqueId' | 'fieldErrors'>
  ) }
);

export type SaveBillingAddressMutationVariables = Exact<{
  input: SaveAddressRequest;
}>;


export type SaveBillingAddressMutation = (
  { __typename?: 'Mutation' }
  & { saveBillingAddress: (
    { __typename?: 'SaveAddressResponse' }
    & Pick<SaveAddressResponse, 'isSuccess' | 'errorMessage' | 'addressUniqueId' | 'fieldErrors'>
  ) }
);

export type SetBasketPaymentOptionMutationVariables = Exact<{
  input: Purchase_SetBasketPaymentOptionRequest;
}>;


export type SetBasketPaymentOptionMutation = (
  { __typename?: 'Mutation' }
  & { setBasketPaymentOption: (
    { __typename?: 'Purchase_SetBasketPaymentOptionResponse' }
    & Pick<Purchase_SetBasketPaymentOptionResponse, 'isSuccess' | 'errorMessage'>
  ) }
);

export type ConfirmBasketAsOrderMutationVariables = Exact<{
  input: Purchase_ConfirmOrderRequest;
}>;


export type ConfirmBasketAsOrderMutation = (
  { __typename?: 'Mutation' }
  & { confirmBasketAsOrder: (
    { __typename?: 'Purchase_ConfirmOrderResponse' }
    & Pick<Purchase_ConfirmOrderResponse, 'isSuccess'>
  ) }
);

export type ConfirmBasketAsQuoteMutationVariables = Exact<{
  input: Purchase_ConfirmQuoteRequest;
}>;


export type ConfirmBasketAsQuoteMutation = (
  { __typename?: 'Mutation' }
  & { confirmBasketAsQuote: (
    { __typename?: 'Purchase_ConfirmQuoteResponse' }
    & Pick<Purchase_ConfirmQuoteResponse, 'isSuccess' | 'errorMessage'>
  ) }
);

export type SavePersonalOrderReferenceMutationVariables = Exact<{
  input: Purchase_SavePersonalOrderReferenceRequest;
}>;


export type SavePersonalOrderReferenceMutation = (
  { __typename?: 'Mutation' }
  & { savePersonalOrderReference: (
    { __typename?: 'Purchase_SavePersonalOrderReferenceResponse' }
    & Pick<Purchase_SavePersonalOrderReferenceResponse, 'isSuccess' | 'errorMessage'>
  ) }
);

export type DeleteAddressMutationVariables = Exact<{
  input: DeleteAddressRequest;
}>;


export type DeleteAddressMutation = (
  { __typename?: 'Mutation' }
  & { deleteAddress: (
    { __typename?: 'DeleteAddressResponse' }
    & Pick<DeleteAddressResponse, 'isSuccess' | 'errorMessage'>
  ) }
);

export type GetPaymentOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPaymentOptionsQuery = (
  { __typename?: 'Query' }
  & { purchasePage: (
    { __typename?: 'PurchasePage' }
    & { paymentOptions: (
      { __typename?: 'PurchasePagePaymentOptions' }
      & { options: Array<(
        { __typename?: 'PurchasePagePaymentOption' }
        & Pick<PurchasePagePaymentOption, 'id' | 'name' | 'description' | 'isSelected' | 'optionType' | 'callbackUrl' | 'callbackMode'>
        & { details?: Maybe<(
          { __typename?: 'PurchasePagePaymentOption_Account' }
          & Pick<PurchasePagePaymentOption_Account, 'accountNumberLabel' | 'soldToNumber'>
        ) | (
          { __typename?: 'PurchasePagePaymentOption_ControlledPurchasing' }
          & { purchasers: Array<(
            { __typename?: 'PurchasePagePaymentOption_ControlledPurchasing_Purchasers' }
            & Pick<PurchasePagePaymentOption_ControlledPurchasing_Purchasers, 'id' | 'firstName' | 'lastName' | 'emailAddress'>
          )> }
        )> }
      )> }
    ) }
  ) }
);

export type ValidateVatDetailsQueryVariables = Exact<{
  input: ValidateVatDetailsRequest;
}>;


export type ValidateVatDetailsQuery = (
  { __typename?: 'Query' }
  & { validateVATDetails: (
    { __typename?: 'ValidateVATDetailsResponse' }
    & Pick<ValidateVatDetailsResponse, 'isSuccess'>
  ) }
);

export type SearchAddressQueryVariables = Exact<{
  input: SearchAddressRequest;
}>;


export type SearchAddressQuery = (
  { __typename?: 'Query' }
  & { searchAddress: (
    { __typename?: 'SearchAddressResponse' }
    & { addresses: Array<(
      { __typename?: 'AddressFields' }
      & Pick<AddressFields, 'city' | 'postCode' | 'state' | 'stateCode' | 'gstRegistrationNumber'>
    )> }
  ) }
);

export type PurchasePageSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type PurchasePageSummaryQuery = (
  { __typename?: 'Query' }
  & { purchasePage: (
    { __typename?: 'PurchasePage' }
    & { summary: (
      { __typename?: 'Summary' }
      & SummaryFragment
    ) }
  ) }
);

export type ShareProductsTransactionMutationVariables = Exact<{
  input: ShareProductsTransactionRequest;
}>;


export type ShareProductsTransactionMutation = (
  { __typename?: 'Mutation' }
  & { shareProductsTransaction: (
    { __typename?: 'ShareProductsTransactionResponse' }
    & Pick<ShareProductsTransactionResponse, 'isSuccess'>
  ) }
);

export type ValidateEmailQueryVariables = Exact<{
  input: ValidateEmailRequest;
}>;


export type ValidateEmailQuery = (
  { __typename?: 'Query' }
  & { validateEmail: (
    { __typename?: 'ValidateEmailResponse' }
    & Pick<ValidateEmailResponse, 'isSuccess'>
  ) }
);

export type SaveCustomerMutationVariables = Exact<{
  input: SaveCustomerRequest;
}>;


export type SaveCustomerMutation = (
  { __typename?: 'Mutation' }
  & { saveCustomer: (
    { __typename?: 'SaveCustomerResponse' }
    & Pick<SaveCustomerResponse, 'isSuccess' | 'fieldErrors'>
  ) }
);

export type ResetPasswordTransactionMutationVariables = Exact<{
  input: ResetPasswordTransactionRequest;
}>;


export type ResetPasswordTransactionMutation = (
  { __typename?: 'Mutation' }
  & { resetPasswordTransaction: (
    { __typename?: 'ResetPasswordTransactionResponse' }
    & Pick<ResetPasswordTransactionResponse, 'showUsername'>
  ) }
);

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordRequest;
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword: (
    { __typename?: 'ResetPasswordResponse' }
    & Pick<ResetPasswordResponse, 'isSuccess'>
  ) }
);

export type UpdateCustomerMutationVariables = Exact<{
  input: UpdateCustomerRequest;
}>;


export type UpdateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomer: (
    { __typename?: 'UpdateCustomerResponse' }
    & Pick<UpdateCustomerResponse, 'isSuccess'>
  ) }
);

export type EmailSubscriptionTransactionMutationVariables = Exact<{
  input: EmailSubscriptionTransactionRequest;
}>;


export type EmailSubscriptionTransactionMutation = (
  { __typename?: 'Mutation' }
  & { emailSubscriptionTransaction: (
    { __typename?: 'EmailSubscriptionTransactionRepsonse' }
    & { tagging: (
      { __typename?: 'EmailSubscriptionPageTagging' }
      & { tags: Array<(
        { __typename?: 'HtmlTag' }
        & HtmlTagFragment
      )> }
    ), labels: Array<(
      { __typename?: 'Label' }
      & LabelFragment
    )> }
  ) }
);

export type ForgotPasswordPageQueryVariables = Exact<{ [key: string]: never; }>;


export type ForgotPasswordPageQuery = (
  { __typename?: 'Query' }
  & { forgotPasswordPage: (
    { __typename?: 'ForgotPasswordPage' }
    & Pick<ForgotPasswordPage, 'isSuccess'>
    & { tagging: (
      { __typename?: 'ForgotPasswordPageTagging' }
      & { tags: Array<(
        { __typename?: 'HtmlTag' }
        & HtmlTagFragment
      )> }
    ), labels: Array<(
      { __typename?: 'Label' }
      & LabelFragment
    )> }
  ) }
);

export type LoginPageQueryVariables = Exact<{ [key: string]: never; }>;


export type LoginPageQuery = (
  { __typename?: 'Query' }
  & { loginPage: (
    { __typename?: 'LoginPage' }
    & Pick<LoginPage, 'isSuccess' | 'isProfileEnabled'>
    & { tagging: (
      { __typename?: 'LoginPageTagging' }
      & { tags: Array<(
        { __typename?: 'HtmlTag' }
        & HtmlTagFragment
      )> }
    ), labels: Array<(
      { __typename?: 'Label' }
      & LabelFragment
    )> }
  ) }
);

export type LoginMutationVariables = Exact<{
  data: LoginRequest;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'isSuccess' | 'customerName' | 'basketChanged' | 'redirectUrl'>
    & { tags: Array<(
      { __typename?: 'GA4Event_User_Login' }
      & Pick<Ga4Event_User_Login, 'event'>
      & { ecommerce: (
        { __typename?: 'GA4Event_User_Login_Ecommerce' }
        & Pick<Ga4Event_User_Login_Ecommerce, 'email'>
      ) }
    )> }
  ) }
);

export type OktaTokenExchangeMutationVariables = Exact<{
  data: OktaTokenExchangeRequest;
}>;


export type OktaTokenExchangeMutation = (
  { __typename?: 'Mutation' }
  & { oktaTokenExchange: (
    { __typename?: 'OktaTokenExchangeResponse' }
    & Pick<OktaTokenExchangeResponse, 'isSuccess' | 'customerName' | 'basketChanged'>
  ) }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & { logout: (
    { __typename?: 'LogoutResponse' }
    & Pick<LogoutResponse, 'isSuccess'>
  ) }
);

export type OrderDetailsPageQueryVariables = Exact<{
  orderId: Scalars['Float'];
}>;


export type OrderDetailsPageQuery = (
  { __typename?: 'Query' }
  & { orderDetailsPage: (
    { __typename?: 'OrderDetailsPage' }
    & Pick<OrderDetailsPage, 'isSuccess'>
    & { labels: Array<(
      { __typename?: 'Label' }
      & Pick<Label, 'name' | 'text'>
    )>, tagging: (
      { __typename?: 'OrderDetailsPageTagging' }
      & { tags: Array<(
        { __typename?: 'HtmlTag' }
        & HtmlTagFragment
      )> }
    ), metaData: (
      { __typename?: 'OrderDetailsMeta' }
      & Pick<OrderDetailsMeta, 'pageTitle'>
      & { metaTags?: Maybe<Array<(
        { __typename?: 'HtmlTag' }
        & { attributes: Array<(
          { __typename?: 'HtmlTagAttribute' }
          & HtmlTagAttributeFragment
        )> }
      )>> }
    ), header: (
      { __typename?: 'OrderDetailsHeader' }
      & Pick<OrderDetailsHeader, 'siteName' | 'siteAddress'>
    ), details?: Maybe<(
      { __typename?: 'OrderDetailsPageDetails' }
      & Pick<OrderDetailsPageDetails, 'orderReference' | 'orderReferencePersonal' | 'dateOrdered' | 'isDeliveryAddressSameAsBillingAddress'>
      & { contactDetails: (
        { __typename?: 'OrderConfirmationPageContactDetails' }
        & Pick<OrderConfirmationPageContactDetails, 'firstName' | 'lastName' | 'email' | 'phoneNo' | 'taxRegistrationNumber'>
      ), deliveryAddress: (
        { __typename?: 'OrderDetailsPage_DeliveryAddress' }
        & Pick<OrderDetailsPage_DeliveryAddress, 'formattedMultiLine'>
      ), billingAddress?: Maybe<(
        { __typename?: 'OrderDetailsPage_BillingAddress' }
        & Pick<OrderDetailsPage_BillingAddress, 'formattedMultiLine'>
        & { details?: Maybe<(
          { __typename?: 'AddressFields' }
          & Pick<AddressFields, 'gstCustomerType' | 'gstRegistrationType' | 'gstRegistrationNumber' | 'panCardNumber'>
        )> }
      )>, paymentOption?: Maybe<(
        { __typename?: 'OrderDetailsPage_PaymentOption' }
        & Pick<OrderDetailsPage_PaymentOption, 'title' | 'optionType'>
        & { details?: Maybe<(
          { __typename?: 'OrderDetailsPage__PaymentOption_ControlledPurchasingDetails' }
          & { controlledPurchasingDetails?: Maybe<(
            { __typename?: 'OrderDetailsPage_PaymentOption_ControlledPurchasingConfiguration' }
            & { purchaser?: Maybe<(
              { __typename?: 'OrderDetailsPage_PaymentOption_ControlledPurchasingConfiguration_Purchaser' }
              & Pick<OrderDetailsPage_PaymentOption_ControlledPurchasingConfiguration_Purchaser, 'emailAddress'>
            )> }
          )> }
        )> }
      )>, parcels: Array<(
        { __typename?: 'OrderDetailsParcelItem' }
        & Pick<OrderDetailsParcelItem, 'id' | 'quantity'>
        & { product: (
          { __typename?: 'OrderDetailsProduct' }
          & { title: (
            { __typename?: 'OrderDetailsProductTitle' }
            & Pick<OrderDetailsProductTitle, 'brand' | 'formattedStockCode' | 'hsnCode' | 'mpn' | 'stockCode' | 'title' | 'primaryImageUrl' | 'pdpUrl' | 'partNumber' | 'impaCode'>
          ), priceArea: (
            { __typename?: 'OrderDetailsProductPriceArea' }
            & Pick<OrderDetailsProductPriceArea, 'isInBasket' | 'container' | 'minimumOrderQuantity' | 'unitOfMeasure' | 'taxType'>
            & { unitPrice: (
              { __typename?: 'Price' }
              & { priceBefore: (
                { __typename?: 'FormattedNumber' }
                & FormattedNumberFragment
              ), priceAfter: (
                { __typename?: 'FormattedNumber' }
                & FormattedNumberFragment
              ) }
            ), totalPrice: (
              { __typename?: 'Price' }
              & { priceBefore: (
                { __typename?: 'FormattedNumber' }
                & FormattedNumberFragment
              ), priceAfter: (
                { __typename?: 'FormattedNumber' }
                & FormattedNumberFragment
              ) }
            ) }
          ), energyArea?: Maybe<(
            { __typename?: 'ProductEnergy' }
            & { energyLabel: (
              { __typename?: 'ProductEnergyLabel' }
              & ProductEnergyLabelFragment
            ) }
          )> }
        ) }
      )>, summary: (
        { __typename?: 'Summary' }
        & SummaryFragment
      ) }
    )>, config: (
      { __typename?: 'OrderDetailsConfig' }
      & Pick<OrderDetailsConfig, 'topicIdentifier'>
    ) }
  ) }
);

export type OrderHistoryPageQueryVariables = Exact<{
  searchQuery?: Maybe<Scalars['String']>;
  sort?: Maybe<OrderSort>;
  page?: Maybe<OrderPage>;
}>;


export type OrderHistoryPageQuery = (
  { __typename?: 'Query' }
  & { orderHistoryPage: (
    { __typename?: 'OrderHistoryPage' }
    & Pick<OrderHistoryPage, 'isSuccess'>
    & { labels: Array<(
      { __typename?: 'Label' }
      & Pick<Label, 'name' | 'text'>
    )>, tagging: (
      { __typename?: 'OrderHistoryPageTagging' }
      & { tags: Array<(
        { __typename?: 'HtmlTag' }
        & HtmlTagFragment
      )> }
    ), metaData: (
      { __typename?: 'OrderHistoryMeta' }
      & Pick<OrderHistoryMeta, 'pageTitle'>
      & { metaTags?: Maybe<Array<(
        { __typename?: 'HtmlTag' }
        & { attributes: Array<(
          { __typename?: 'HtmlTagAttribute' }
          & HtmlTagAttributeFragment
        )> }
      )>> }
    ), orderHistoryArea?: Maybe<(
      { __typename?: 'OrderHistoryArea' }
      & Pick<OrderHistoryArea, 'noOfOrders'>
      & { orders: Array<(
        { __typename?: 'OrderHistoryOrder' }
        & Pick<OrderHistoryOrder, 'id' | 'orderReference' | 'orderReferencePersonal' | 'dateOrdered' | 'isControlledPurchasing'>
        & { total: (
          { __typename?: 'FormattedNumber' }
          & Pick<FormattedNumber, 'displayValue'>
        ), products: Array<(
          { __typename?: 'OrderHistoryProduct' }
          & Pick<OrderHistoryProduct, 'stockCode' | 'title' | 'imageUrl' | 'pdpUrl' | 'mpn' | 'customerPartNumber'>
        )> }
      )> }
    )> }
  ) }
);

export type AccountOverviewProductFragment = (
  { __typename?: 'AccountOverviewProduct' }
  & Pick<AccountOverviewProduct, 'stockCode' | 'imageUrl' | 'pdpUrl' | 'title'>
);

export type AddRecentlyViewedMutationVariables = Exact<{
  stockCode: Scalars['String'];
  brand: Scalars['String'];
  familyId: Scalars['Float'];
}>;


export type AddRecentlyViewedMutation = (
  { __typename?: 'Mutation' }
  & { addRecentlyViewed: (
    { __typename?: 'AddRecentlyViewedResponse' }
    & Pick<AddRecentlyViewedResponse, 'isSuccess'>
  ) }
);

export type AccountOverviewPageQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountOverviewPageQuery = (
  { __typename?: 'Query' }
  & { accountOverviewPage: (
    { __typename?: 'AccountOverviewPage' }
    & Pick<AccountOverviewPage, 'isSuccess'>
    & { tagging: (
      { __typename?: 'AccountOverviewPageTagging' }
      & { tags: Array<(
        { __typename?: 'HtmlTag' }
        & HtmlTagFragment
      )> }
    ), metaData: (
      { __typename?: 'AccountOverviewMeta' }
      & Pick<AccountOverviewMeta, 'pageTitle'>
      & { metaTags?: Maybe<Array<(
        { __typename?: 'HtmlTag' }
        & { attributes: Array<(
          { __typename?: 'HtmlTagAttribute' }
          & HtmlTagAttributeFragment
        )> }
      )>> }
    ), labels: Array<(
      { __typename?: 'Label' }
      & Pick<Label, 'name' | 'text'>
    )>, ordersArea?: Maybe<(
      { __typename?: 'OrdersArea' }
      & { orderLines?: Maybe<Array<(
        { __typename?: 'AccountOverViewOrderLine' }
        & { product: (
          { __typename?: 'AccountOverviewProduct' }
          & AccountOverviewProductFragment
        ) }
      )>> }
    )>, partsListArea?: Maybe<(
      { __typename?: 'PartsListArea' }
      & { partslistLines?: Maybe<Array<(
        { __typename?: 'PartsListLine' }
        & { product: (
          { __typename?: 'AccountOverviewProduct' }
          & AccountOverviewProductFragment
        ) }
      )>> }
    )>, discoverMoreArea?: Maybe<(
      { __typename?: 'DiscoverMoreArea' }
      & { industryBanner: (
        { __typename?: 'IndustryBanner' }
        & Pick<IndustryBanner, 'link' | 'title' | 'eventName'>
      ) }
    )>, controlledPurchasingArea?: Maybe<(
      { __typename?: 'ControlledPurchasingArea' }
      & Pick<ControlledPurchasingArea, 'company' | 'assignedUsers' | 'assignedPurchasers'>
    )>, config: (
      { __typename?: 'AccountOverviewConfig' }
      & Pick<AccountOverviewConfig, 'topicIdentifier'>
    ) }
  ) }
);

export type AccountPageQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountPageQuery = (
  { __typename?: 'Query' }
  & { account: (
    { __typename?: 'Account' }
    & Pick<Account, 'isSuccess' | 'customerName'>
    & { labels: Array<(
      { __typename?: 'Label' }
      & LabelFragment
    )> }
  ) }
);

export type MyAccountPartsListDetailsPageQueryVariables = Exact<{
  partsListId: Scalars['Float'];
}>;


export type MyAccountPartsListDetailsPageQuery = (
  { __typename?: 'Query' }
  & { myAccountPartsListDetailsPage: (
    { __typename?: 'MyAccountPartsListDetailsPage' }
    & Pick<MyAccountPartsListDetailsPage, 'isSuccess' | 'redirectUrl'>
    & { labels: Array<(
      { __typename?: 'Label' }
      & Pick<Label, 'name' | 'text'>
    )>, tagging: (
      { __typename?: 'PartsListDetailsPageTagging' }
      & { tags: Array<(
        { __typename?: 'HtmlTag' }
        & HtmlTagFragment
      )> }
    ), metaData: (
      { __typename?: 'PartsListDetailsMeta' }
      & Pick<PartsListDetailsMeta, 'pageTitle'>
      & { metaTags?: Maybe<Array<(
        { __typename?: 'HtmlTag' }
        & { attributes: Array<(
          { __typename?: 'HtmlTagAttribute' }
          & HtmlTagAttributeFragment
        )> }
      )>> }
    ), config: (
      { __typename?: 'MyAccount_PartsListDetails_Config' }
      & Pick<MyAccount_PartsListDetails_Config, 'topicIdentifier'>
    ), partsLists: (
      { __typename?: 'MyAccount_PartsListDetails_PartsListsArea' }
      & { partsLists: Array<(
        { __typename?: 'MyAccount_PartsListDetails_PartsList' }
        & Pick<MyAccount_PartsListDetails_PartsList, 'id' | 'name' | 'selected'>
      )> }
    ), details?: Maybe<(
      { __typename?: 'PartsListDetails' }
      & PartsListDetailsFragment
    )> }
  ) }
);

export type MyAccountPartsListDetailsProductsQueryVariables = Exact<{
  partsListId: Scalars['Float'];
}>;


export type MyAccountPartsListDetailsProductsQuery = (
  { __typename?: 'Query' }
  & { myAccountPartsListDetailsPage: (
    { __typename?: 'MyAccountPartsListDetailsPage' }
    & Pick<MyAccountPartsListDetailsPage, 'isSuccess'>
    & { details?: Maybe<(
      { __typename?: 'PartsListDetails' }
      & PartsListDetailsFragment
    )> }
  ) }
);

export type ManualAddToPartsListMutationVariables = Exact<{
  data: ManualAddToPartsListRequest;
}>;


export type ManualAddToPartsListMutation = (
  { __typename?: 'Mutation' }
  & { manualAddToPartsList: (
    { __typename?: 'ManualAddToPartsListResponse' }
    & Pick<ManualAddToPartsListResponse, 'isSuccess' | 'partsListId' | 'requireLogin' | 'redirectUrl' | 'errorMessage'>
    & { result: (
      { __typename?: 'ManualAddToPartsList_ResultDetails' }
      & Pick<ManualAddToPartsList_ResultDetails, 'isSuccess' | 'title' | 'message'>
      & { productsNotFoundSection?: Maybe<(
        { __typename?: 'ManualAddToPartsList_ResultDetailsSection' }
        & Pick<ManualAddToPartsList_ResultDetailsSection, 'message'>
        & { lineInfo: Array<(
          { __typename?: 'ManualAddToPartsList_ResultDetailsLine' }
          & Pick<ManualAddToPartsList_ResultDetailsLine, 'stockCode' | 'quantity' | 'message'>
          & { link?: Maybe<(
            { __typename?: 'Link' }
            & LinkFragment
          )> }
        )> }
      )> }
    ) }
  ) }
);

export type DeletePartsListLinesMutationVariables = Exact<{
  input: DeletePartsListLinesRequest;
}>;


export type DeletePartsListLinesMutation = (
  { __typename?: 'Mutation' }
  & { deletePartsListLines: (
    { __typename?: 'DeletePartsListLinesResponse' }
    & Pick<DeletePartsListLinesResponse, 'isSuccess' | 'errorMessage'>
  ) }
);

export type MyAccountPartsListsPageQueryVariables = Exact<{
  searchQuery?: Maybe<Scalars['String']>;
  sort?: Maybe<PartsListsSort>;
  page?: Maybe<PartsListsPage>;
}>;


export type MyAccountPartsListsPageQuery = (
  { __typename?: 'Query' }
  & { myAccountPartsListsPage: (
    { __typename?: 'MyAccountPartsListsPage' }
    & Pick<MyAccountPartsListsPage, 'isSuccess'>
    & { labels: Array<(
      { __typename?: 'Label' }
      & Pick<Label, 'name' | 'text'>
    )>, tagging: (
      { __typename?: 'PartsListsPageTagging' }
      & { tags: Array<(
        { __typename?: 'HtmlTag' }
        & HtmlTagFragment
      )> }
    ), metaData: (
      { __typename?: 'PartsListsMeta' }
      & Pick<PartsListsMeta, 'pageTitle'>
      & { metaTags?: Maybe<Array<(
        { __typename?: 'HtmlTag' }
        & { attributes: Array<(
          { __typename?: 'HtmlTagAttribute' }
          & HtmlTagAttributeFragment
        )> }
      )>> }
    ), config: (
      { __typename?: 'MyAccount_PartsLists_Config' }
      & Pick<MyAccount_PartsLists_Config, 'topicIdentifier'>
    ), partsListsArea: (
      { __typename?: 'PartsListsArea' }
      & Pick<PartsListsArea, 'noOfPartsLists'>
      & { partsLists: Array<(
        { __typename?: 'PartsListsPartList' }
        & Pick<PartsListsPartList, 'id' | 'name' | 'noOfProducts' | 'description' | 'lastModified'>
        & { totalPrice: (
          { __typename?: 'Price' }
          & { priceAfter: (
            { __typename?: 'FormattedNumber' }
            & Pick<FormattedNumber, 'displayValue'>
          ) }
        ), products: Array<(
          { __typename?: 'MyAccount_PartsList_Product' }
          & Pick<MyAccount_PartsList_Product, 'stockCode' | 'title' | 'pdpUrl' | 'imageUrl' | 'qty' | 'isInvalid'>
        )> }
      )> }
    ) }
  ) }
);

export type DeletePartsListMutationVariables = Exact<{
  input: DeletePartsListRequest;
}>;


export type DeletePartsListMutation = (
  { __typename?: 'Mutation' }
  & { deletePartsList: (
    { __typename?: 'DeletePartsListResponse' }
    & Pick<DeletePartsListResponse, 'isSuccess' | 'errorMessage'>
  ) }
);

export type EditPartsListMutationVariables = Exact<{
  data: EditPartsListRequest;
}>;


export type EditPartsListMutation = (
  { __typename?: 'Mutation' }
  & { editPartsList: (
    { __typename?: 'EditPartsListResponse' }
    & Pick<EditPartsListResponse, 'isSuccess'>
  ) }
);

export type ProfilePageQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfilePageQuery = (
  { __typename?: 'Query' }
  & { profilePage: (
    { __typename?: 'ProfilePage' }
    & Pick<ProfilePage, 'isSuccess'>
    & { tagging: (
      { __typename?: 'ProfilePageTagging' }
      & { tags: Array<(
        { __typename?: 'HtmlTag' }
        & HtmlTagFragment
      )> }
    ), metaData: (
      { __typename?: 'ProfileMeta' }
      & Pick<ProfileMeta, 'pageTitle'>
      & { metaTags?: Maybe<Array<(
        { __typename?: 'HtmlTag' }
        & { attributes: Array<(
          { __typename?: 'HtmlTagAttribute' }
          & HtmlTagAttributeFragment
        )> }
      )>> }
    ), labels: Array<(
      { __typename?: 'Label' }
      & LabelFragment
    )>, industries: Array<(
      { __typename?: 'ProfilePageIndustry' }
      & Pick<ProfilePageIndustry, 'id' | 'value'>
    )>, customer?: Maybe<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'name' | 'surname' | 'email' | 'industry' | 'purchasingFor' | 'mobile' | 'subscribed'>
    )> }
  ) }
);

export type RecentlyViewedItemFragment = (
  { __typename?: 'RecentlyViewedItem' }
  & Pick<RecentlyViewedItem, 'id' | 'imageUrl'>
  & { url: (
    { __typename?: 'Link' }
    & Pick<Link, 'text' | 'url'>
  ) }
);

export type RecentlyViewedQueryVariables = Exact<{ [key: string]: never; }>;


export type RecentlyViewedQuery = (
  { __typename?: 'Query' }
  & { recentlyViewed: (
    { __typename?: 'RecentlyViewed' }
    & { labels: Array<(
      { __typename?: 'Label' }
      & Pick<Label, 'name' | 'text'>
    )>, recentlyViewedArea?: Maybe<(
      { __typename?: 'RecentlyViewedArea' }
      & { products?: Maybe<Array<(
        { __typename?: 'RecentlyViewedProduct' }
        & Pick<RecentlyViewedProduct, 'stockCode' | 'imageUrl' | 'pdpUrl' | 'title' | 'isInBasket'>
        & { price: (
          { __typename?: 'Price' }
          & { priceAfter: (
            { __typename?: 'FormattedNumber' }
            & Pick<FormattedNumber, 'displayValue'>
          ) }
        ) }
      )>>, categories?: Maybe<Array<(
        { __typename?: 'RecentlyViewedItem' }
        & RecentlyViewedItemFragment
      )>>, brands?: Maybe<Array<(
        { __typename?: 'RecentlyViewedItem' }
        & RecentlyViewedItemFragment
      )>> }
    )> }
  ) }
);

export type RegistrationPageQueryVariables = Exact<{
  token?: Maybe<Scalars['String']>;
}>;


export type RegistrationPageQuery = (
  { __typename?: 'Query' }
  & { registrationPage: (
    { __typename?: 'RegistrationPage' }
    & Pick<RegistrationPage, 'isSuccess'>
    & { tagging: (
      { __typename?: 'RegistrationPageTagging' }
      & { tags: Array<(
        { __typename?: 'HtmlTag' }
        & HtmlTagFragment
      )> }
    ), labels: Array<(
      { __typename?: 'Label' }
      & LabelFragment
    )>, industries: Array<(
      { __typename?: 'RegistrationPageIndustry' }
      & Pick<RegistrationPageIndustry, 'id' | 'value'>
    )> }
  ) }
);

export type RegistrationTokenExpiredPageQueryVariables = Exact<{ [key: string]: never; }>;


export type RegistrationTokenExpiredPageQuery = (
  { __typename?: 'Query' }
  & { registrationTokenExpiredPage: (
    { __typename?: 'RegistrationTokenExpiredPage' }
    & Pick<RegistrationTokenExpiredPage, 'isSuccess' | 'redirectUrl'>
    & { labels: Array<(
      { __typename?: 'Label' }
      & LabelFragment
    )> }
  ) }
);

export type ResetPasswordPageQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type ResetPasswordPageQuery = (
  { __typename?: 'Query' }
  & { resetPasswordPage: (
    { __typename?: 'ResetPasswordPage' }
    & Pick<ResetPasswordPage, 'isSuccess'>
    & { tagging: (
      { __typename?: 'ResetPasswordPageTagging' }
      & { tags: Array<(
        { __typename?: 'HtmlTag' }
        & HtmlTagFragment
      )> }
    ), metaData: (
      { __typename?: 'ResetPasswordMeta' }
      & Pick<ResetPasswordMeta, 'pageTitle'>
      & { metaTags?: Maybe<Array<(
        { __typename?: 'HtmlTag' }
        & { attributes: Array<(
          { __typename?: 'HtmlTagAttribute' }
          & HtmlTagAttributeFragment
        )> }
      )>> }
    ), labels: Array<(
      { __typename?: 'Label' }
      & LabelFragment
    )> }
  ) }
);

export const AddressFieldsFragmentDoc = gql`
    fragment AddressFields on AddressFields {
  firstName
  lastName
  companyName
  building
  street
  streetComplement
  city
  postCode
  state
  stateCode
  countryCode
  country
  vatNumber
  taxCertificateNumber
  hasTaxCertificateNumber
  eoriNumber
  taxOfficeName
  paymentDepartmentNumber
  gstCustomerType
  gstRegistrationType
  gstRegistrationNumber
  panCardNumber
}
    `;
export const FormattedNumberFragmentDoc = gql`
    fragment FormattedNumber on FormattedNumber {
  value
  displayValue
}
    `;
export const SummaryFragmentDoc = gql`
    fragment Summary on Summary {
  netTotal {
    label
    description
    price {
      ...FormattedNumber
    }
  }
  netTotalExcPromotion {
    ...FormattedNumber
  }
  deliveryCharge {
    label
    description
    notes
    price {
      ...FormattedNumber
    }
  }
  deliveryOptionType
  surcharges {
    label
    description
    price {
      ...FormattedNumber
    }
  }
  grandTotal {
    label
    description
    price {
      ...FormattedNumber
    }
  }
  deliveryCountry {
    code
    name
  }
  invoiceCountry {
    code
    name
  }
  endDeliveryCountry {
    code
    name
  }
  numberOfItems
  promotion {
    isValid
    code
    description
    errorMessage
    savings {
      ...FormattedNumber
    }
  }
}
    ${FormattedNumberFragmentDoc}`;
export const HtmlTagAttributeFragmentDoc = gql`
    fragment HtmlTagAttribute on HtmlTagAttribute {
  name
  value
}
    `;
export const HtmlTagFragmentDoc = gql`
    fragment HtmlTag on HtmlTag {
  name
  innerHtml
  attributes {
    ...HtmlTagAttribute
  }
}
    ${HtmlTagAttributeFragmentDoc}`;
export const LabelFragmentDoc = gql`
    fragment Label on Label {
  pageName
  name
  text
}
    `;
export const ProductEnergyLabelFragmentDoc = gql`
    fragment ProductEnergyLabel on ProductEnergyLabel {
  rating
  imgUrl
  isShowingTwoImagesInOne
}
    `;
export const PartsListDetailsFragmentDoc = gql`
    fragment PartsListDetails on PartsListDetails {
  id
  name
  description
  lines {
    id
    quantity
    isInBasket
    product {
      title {
        title
        brand
        formattedStockCode
        hsnCode
        mpn
        stockCode
        primaryImageUrl
        pdpUrl
        impaCode
      }
      priceArea {
        container
        minimumOrderQuantity
        unitPrice {
          priceBefore {
            ...FormattedNumber
          }
          priceAfter {
            ...FormattedNumber
          }
        }
        totalPrice {
          priceBefore {
            ...FormattedNumber
          }
          priceAfter {
            ...FormattedNumber
          }
        }
        unitOfMeasure
        errorMessage
      }
      energyArea {
        energyLabel {
          ...ProductEnergyLabel
        }
      }
    }
  }
}
    ${FormattedNumberFragmentDoc}
${ProductEnergyLabelFragmentDoc}`;
export const ProductTypeFragmentDoc = gql`
    fragment ProductType on ProductType {
  isVisible
  title
  mode
  message
}
    `;
export const Ga4ItemFragmentDoc = gql`
    fragment GA4Item on GA4Item {
  item_id
  item_name
  currency
  discount
  index
  item_brand
  item_category
  item_category2
  item_category3
  item_category4
  price
  quantity
  product_url
  image_url
}
    `;
export const Ga4Event_UpdateBasketLinesFragmentDoc = gql`
    fragment GA4Event_UpdateBasketLines on GA4Event_UpdateBasketLines {
  event
  ecommerce {
    currency
    value
    items {
      ...GA4Item
    }
    total_prqt
    email
  }
}
    ${Ga4ItemFragmentDoc}`;
export const RemoveFromBasketResponseTagsFragmentDoc = gql`
    fragment RemoveFromBasketResponseTags on RemoveFromBasketResponseTags {
  ... on BulkRemoveFromBasketEnsighten {
    products {
      productId
      orderQuantity
    }
  }
  ... on BulkRemoveFromBasketGoogleAnalytics {
    products {
      id
      name
      category
      brand
      quantity
    }
  }
  ... on BulkRemoveFromBasketTealium {
    products {
      productId
    }
  }
  ... on GA4Event_RemoveFromCart {
    event
    ecommerce {
      currency
      value
      items {
        ...GA4Item
      }
    }
  }
  ... on GA4Event_UpdateBasketLines {
    ...GA4Event_UpdateBasketLines
  }
}
    ${Ga4ItemFragmentDoc}
${Ga4Event_UpdateBasketLinesFragmentDoc}`;
export const SidesFragmentDoc = gql`
    fragment Sides on Sides {
  top
  right
  bottom
  left
}
    `;
export const PaddingFragmentDoc = gql`
    fragment Padding on Padding {
  desktop {
    ...Sides
  }
  mobile {
    ...Sides
  }
}
    ${SidesFragmentDoc}`;
export const ButtonPickerFragmentDoc = gql`
    fragment ButtonPicker on ButtonPicker {
  icon
  style
  text
  type
}
    `;
export const AlignmentFragmentDoc = gql`
    fragment Alignment on Alignment {
  desktop
  mobile
}
    `;
export const DeviceFragmentDoc = gql`
    fragment Device on Device {
  originalImageUrl
  url
  width
}
    `;
export const ImageFragmentDoc = gql`
    fragment Image on Image {
  desktop {
    ...Device
  }
  mobile {
    ...Device
  }
  tablet {
    ...Device
  }
  alt
  description
}
    ${DeviceFragmentDoc}`;
export const WidgetLinkFragmentDoc = gql`
    fragment WidgetLink on WidgetLink {
  URL
  target
}
    `;
export const TextEditorFragmentDoc = gql`
    fragment TextEditor on TextEditor {
  alignment {
    ...Alignment
  }
  colour {
    ...Alignment
  }
  isBold
  isItalic
  isStrikethrough
  isUnderline
  padding {
    ...Padding
  }
  text
  textSize
  embeddedUrls {
    URL
    label
    placeholder
    target
  }
  embeddedLists {
    listItems {
      position
      value
    }
    listType
    placeholder
  }
}
    ${AlignmentFragmentDoc}
${PaddingFragmentDoc}`;
export const ImageTextBoxWidgetFragmentDoc = gql`
    fragment ImageTextBoxWidget on ImageTextBoxWidget {
  addPadding
  backgroundColour
  button {
    ...ButtonPicker
  }
  buttonVerticalAlignment {
    ...Alignment
  }
  buttonHorizontalAlignment {
    ...Alignment
  }
  image {
    ...Image
  }
  imageDesktopPosition
  imageMobilePosition
  link {
    ...WidgetLink
  }
  opacity
  text {
    ...TextEditor
  }
  textAlignment {
    ...Alignment
  }
}
    ${ButtonPickerFragmentDoc}
${AlignmentFragmentDoc}
${ImageFragmentDoc}
${WidgetLinkFragmentDoc}
${TextEditorFragmentDoc}`;
export const HeroBannerWidgetFragmentDoc = gql`
    fragment HeroBannerWidget on HeroBannerWidget {
  banners {
    curveOverlayColour
    blockOverlayColour
    button {
      ...ButtonPicker
    }
    buttonVerticalAlignment {
      ...Alignment
    }
    buttonHorizontalAlignment {
      ...Alignment
    }
    desktopContentPosition
    desktopContentStyle
    desktopContentWidth
    image {
      ...Image
    }
    link {
      ...WidgetLink
    }
    mobileContentStyle
    mobileOverlayColour
    slashOverlayColour
    text {
      ...TextEditor
    }
    textAlignment {
      ...Alignment
    }
  }
}
    ${ButtonPickerFragmentDoc}
${AlignmentFragmentDoc}
${ImageFragmentDoc}
${WidgetLinkFragmentDoc}
${TextEditorFragmentDoc}`;
export const VideoDataWidgetFragmentDoc = gql`
    fragment VideoDataWidget on VideoDataWidget {
  description
  name
  thumbnailUrl
  videoHorizontalAlignment {
    ...Alignment
  }
  videoUrl
  videoVerticalAlignment {
    ...Alignment
  }
  width
}
    ${AlignmentFragmentDoc}`;
export const TextDataWidgetFragmentDoc = gql`
    fragment TextDataWidget on TextDataWidget {
  textData: text {
    ...TextEditor
  }
  textAlignment {
    ...Alignment
  }
}
    ${TextEditorFragmentDoc}
${AlignmentFragmentDoc}`;
export const ButtonDataWidgetFragmentDoc = gql`
    fragment ButtonDataWidget on ButtonDataWidget {
  button {
    ...ButtonPicker
  }
  buttonVerticalAlignment {
    ...Alignment
  }
  buttonHorizontalAlignment {
    ...Alignment
  }
  link {
    ...WidgetLink
  }
}
    ${ButtonPickerFragmentDoc}
${AlignmentFragmentDoc}
${WidgetLinkFragmentDoc}`;
export const ImageDataWidgetFragmentDoc = gql`
    fragment ImageDataWidget on ImageDataWidget {
  image {
    ...Image
  }
  imageHorizontalAlignment {
    ...Alignment
  }
  imageVerticalAlignment {
    ...Alignment
  }
  link {
    ...WidgetLink
  }
}
    ${ImageFragmentDoc}
${AlignmentFragmentDoc}
${WidgetLinkFragmentDoc}`;
export const AttributeFragmentDoc = gql`
    fragment Attribute on LinkAttribute {
  name
  value
}
    `;
export const LinkFragmentDoc = gql`
    fragment Link on Link {
  text
  url
  attributes {
    ...Attribute
  }
}
    ${AttributeFragmentDoc}`;
export const PopularCategoriesWidgetFragmentDoc = gql`
    fragment PopularCategoriesWidget on PopularCategoriesWidget {
  title {
    ...TextEditor
  }
  categories {
    id
    url {
      ...Link
    }
    noOfProducts
    imageUrl
  }
}
    ${TextEditorFragmentDoc}
${LinkFragmentDoc}`;
export const AllCategoriesWidgetFragmentDoc = gql`
    fragment AllCategoriesWidget on AllCategoriesWidget {
  title {
    ...TextEditor
  }
  categories {
    id
    url {
      ...Link
    }
    noOfProducts
    childCategories {
      id
      url {
        ...Link
      }
      noOfProducts
    }
  }
}
    ${TextEditorFragmentDoc}
${LinkFragmentDoc}`;
export const PreviouslyViewedProductsFragmentDoc = gql`
    fragment PreviouslyViewedProducts on PreviouslyViewedProducts {
  id
}
    `;
export const InPageAnchorWidgetFragmentDoc = gql`
    fragment InPageAnchorWidget on InPageAnchorWidget {
  anchorId
}
    `;
export const ContentLayoutFragmentDoc = gql`
    fragment ContentLayout on ContentLayout {
  desktopContentAlignment
  tabletContentAlignment
  mobileContentAlignment
  padding {
    ...Padding
  }
  enableBorderRadius
  enableShadowOnHover
  backgroundColour
  backgroundImageUrl
  borderColour
  borderSize {
    ...Sides
  }
  desktopWidth
  mobileWidth
  tabletWidth
  widgets {
    ... on ImageTextBoxWidget {
      ...ImageTextBoxWidget
    }
    ... on HeroBannerWidget {
      ...HeroBannerWidget
    }
    ... on VideoDataWidget {
      ...VideoDataWidget
    }
    ... on TextDataWidget {
      ...TextDataWidget
    }
    ... on ButtonDataWidget {
      ...ButtonDataWidget
    }
    ... on ImageDataWidget {
      ...ImageDataWidget
    }
    ... on PopularCategoriesWidget {
      ...PopularCategoriesWidget
    }
    ... on AllCategoriesWidget {
      ...AllCategoriesWidget
    }
    ... on PreviouslyViewedProducts {
      ...PreviouslyViewedProducts
    }
    ... on InPageAnchorWidget {
      ...InPageAnchorWidget
    }
  }
}
    ${PaddingFragmentDoc}
${SidesFragmentDoc}
${ImageTextBoxWidgetFragmentDoc}
${HeroBannerWidgetFragmentDoc}
${VideoDataWidgetFragmentDoc}
${TextDataWidgetFragmentDoc}
${ButtonDataWidgetFragmentDoc}
${ImageDataWidgetFragmentDoc}
${PopularCategoriesWidgetFragmentDoc}
${AllCategoriesWidgetFragmentDoc}
${PreviouslyViewedProductsFragmentDoc}
${InPageAnchorWidgetFragmentDoc}`;
export const PriceDataFragmentDoc = gql`
    fragment PriceData on Price {
  priceBefore {
    ...FormattedNumber
  }
  priceAfter {
    ...FormattedNumber
  }
}
    ${FormattedNumberFragmentDoc}`;
export const PriceBreakItemFragmentDoc = gql`
    fragment PriceBreakItem on PDPPriceBreakItem {
  minimum
  maximum
  displayName
  unitPrice {
    ...PriceData
  }
  packPrice {
    ...PriceData
  }
}
    ${PriceDataFragmentDoc}`;
export const PriceItemFragmentDoc = gql`
    fragment PriceItem on PDPPricesItem {
  quantity
  unitPrice {
    ...PriceData
  }
  totalPrice {
    ...PriceData
  }
}
    ${PriceDataFragmentDoc}`;
export const PricingFragmentDoc = gql`
    fragment Pricing on PDPPricing {
  container
  minimumOrderQuantity
  showExVat
  price {
    priceBefore {
      ...FormattedNumber
    }
    priceAfter {
      ...FormattedNumber
    }
  }
  priceDualCurrency {
    priceBefore {
      ...FormattedNumber
    }
    priceAfter {
      ...FormattedNumber
    }
  }
  priceExVat {
    priceBefore {
      ...FormattedNumber
    }
    priceAfter {
      ...FormattedNumber
    }
  }
  priceExVatDualCurrency {
    priceBefore {
      ...FormattedNumber
    }
    priceAfter {
      ...FormattedNumber
    }
  }
  priceBreaks {
    ...PriceBreakItem
  }
  priceBreaksExVat {
    ...PriceBreakItem
  }
  prices {
    ...PriceItem
  }
  pricesExVat {
    ...PriceItem
  }
  errorMessage
  packPriceHeader
  unitOfMeasure
  isInBasket
}
    ${FormattedNumberFragmentDoc}
${PriceBreakItemFragmentDoc}
${PriceItemFragmentDoc}`;
export const PdpCalibrationFragmentDoc = gql`
    fragment PDPCalibration on PDPCalibration {
  hasCalibrationOptions
  details {
    stockCode
    isDefault
    title
    description
    pricing {
      ...Pricing
    }
  }
}
    ${PricingFragmentDoc}`;
export const PdpStockFragmentDoc = gql`
    fragment PDPStock on PDPStock {
  statusCode
  title
  stockMessage
  dtpStockMessages
  infoMessage
  isUsingDTP
  denyBackOrder
  availabilityDate
  stockAvailability {
    quantityInStock
    availabilityDate
  }
  includesDate
  stockBadgeIcon
}
    `;
export const PdpProductTypeFragmentDoc = gql`
    fragment PDPProductType on PDPProductType {
  isVisible
  title
  mode
  message
}
    `;
export const PlpProductTypeFragmentDoc = gql`
    fragment PLPProductType on PLPProductType {
  isVisible
  title
  mode
  message
}
    `;
export const PlpProductFragmentDoc = gql`
    fragment PLPProduct on PLPProduct {
  title {
    stockCode
    formattedStockCode
    title
    brand
    hsnCode
    mpn
    primaryImageUrl
    pdpUrl
    impaCode
  }
  categoryAttributes {
    attributeNameId
    value
  }
  priceArea {
    price {
      ...PriceData
    }
    container
    minimumOrderQuantity
    isInBasket
  }
  productTypeArea {
    ...PLPProductType
    secondaryMessages {
      ...PLPProductType
    }
  }
  ppkArea {
    isVisible
    pdpURL
  }
  punchoutInfoArea {
    isBarred
    isRestricted
  }
  energyArea {
    energyLabel {
      ...ProductEnergyLabel
    }
  }
}
    ${PriceDataFragmentDoc}
${PlpProductTypeFragmentDoc}
${ProductEnergyLabelFragmentDoc}`;
export const PlpProductsFragmentDoc = gql`
    fragment PLPProducts on PLPProducts {
  products {
    ...PLPProduct
  }
  categoryAttributes {
    attributes {
      name
      id
    }
  }
}
    ${PlpProductFragmentDoc}`;
export const FacetValueFragmentDoc = gql`
    fragment FacetValue on FacetValue {
  id
  displayValue
  resultsCount
  disabled
}
    `;
export const FacetFragmentDoc = gql`
    fragment Facet on Facet {
  id
  displayName
  values {
    ...FacetValue
  }
}
    ${FacetValueFragmentDoc}`;
export const FacetsFragmentDoc = gql`
    fragment Facets on PLPFacets {
  facets {
    ...Facet
  }
  flagFacets {
    ...FacetValue
  }
}
    ${FacetFragmentDoc}
${FacetValueFragmentDoc}`;
export const PlpCategoryItemFragmentDoc = gql`
    fragment PLPCategoryItem on PLPCategoryItem {
  id
  noOfProducts
  name
  childCategories {
    id
    name
    noOfProducts
  }
}
    `;
export const AccountOverviewProductFragmentDoc = gql`
    fragment accountOverviewProduct on AccountOverviewProduct {
  stockCode
  imageUrl
  pdpUrl
  title
}
    `;
export const RecentlyViewedItemFragmentDoc = gql`
    fragment recentlyViewedItem on RecentlyViewedItem {
  id
  url {
    text
    url
  }
  imageUrl
}
    `;
export const BasketPageDocument = gql`
    query basketPage($asQuote: Boolean, $summaryData: GetSummaryRequest) {
  basketPage(asQuote: $asQuote) {
    labels {
      ...Label
    }
    metaData {
      links {
        ...HtmlTag
      }
      pageTitle
      canonicalUrl
      metaTags {
        attributes {
          ...HtmlTagAttribute
        }
      }
    }
    tagging {
      gtm {
        dataLayer {
          innerHtml
        }
      }
      tags {
        ...HtmlTag
      }
      ga4 {
        ... on GA4Event_ViewCart {
          event
          ecommerce {
            currency
            value
            amount
            total_prqt
            items {
              ...GA4Item
            }
            email
          }
        }
      }
    }
    config {
      allowPartNumbers
      isCustomerLoggedIn
      allowPromoCode
      topicIdentifier
      enablePrintQuote
      hasNewDeliveryOptions
      hasDeliveryOption
      hasDualUseProducts
      isStockCheckUnavailable
      paymentMethods
      showShareBasketButton
    }
    totalWeight
    numberOfItems
    isValidState
    parcels {
      title
      items {
        quantity
        id
        product {
          productTypeArea {
            ...ProductType
            secondaryMessages {
              ...ProductType
            }
          }
          title {
            brand
            formattedStockCode
            hsnCode
            mpn
            stockCode
            title
            primaryImageUrl
            pdpUrl
            partNumber
            impaCode
          }
          stockArea {
            statusCode
            title
            stockMessage
            dtpStockMessages
            infoMessage
            isUsingDTP
            denyBackOrder
            stockAvailability {
              quantityInStock
              availabilityDate
            }
            includesDate
            stockBadgeIcon
          }
          priceArea {
            minimumOrderQuantity
            totalPrice {
              priceBefore {
                value
                displayValue
              }
              priceAfter {
                value
                displayValue
              }
            }
            unitPrice {
              priceAfter {
                value
                displayValue
              }
            }
            container
            taxType
            taxes {
              taxType
              taxAmountDisplayValue
            }
            errorMessage
            unitOfMeasure
          }
          ppkArea {
            isVisible
            isEnabled
            packageType
            standardUnitOfMeasure
            unitOfMeasure
            ppkStockCode
            standardStockCode
            ppkSSM
            standardMinimumOrderQuantity
            minimumOrderQuantity
            title
            message
          }
          energyArea {
            energyLabel {
              ...ProductEnergyLabel
            }
          }
        }
      }
    }
    deliveryCountries {
      options {
        code
        name
        redirection {
          title
          message
          url
          cta
          transferBasket
          isGroupCountry
          isUSCountry
        }
      }
    }
    summary(data: $summaryData) {
      ...Summary
    }
    quoteDetails {
      header {
        quoteReference
        accountNumber
        validStartDate
        validEndDate
        siteName
        siteAddress
      }
      contactDetails {
        email
        phone
        fax
      }
      labels {
        ...Label
      }
    }
  }
}
    ${LabelFragmentDoc}
${HtmlTagFragmentDoc}
${HtmlTagAttributeFragmentDoc}
${Ga4ItemFragmentDoc}
${ProductTypeFragmentDoc}
${ProductEnergyLabelFragmentDoc}
${SummaryFragmentDoc}`;

/**
 * __useBasketPageQuery__
 *
 * To run a query within a React component, call `useBasketPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useBasketPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBasketPageQuery({
 *   variables: {
 *      asQuote: // value for 'asQuote'
 *      summaryData: // value for 'summaryData'
 *   },
 * });
 */
export function useBasketPageQuery(baseOptions?: Apollo.QueryHookOptions<BasketPageQuery, BasketPageQueryVariables>) {
        return Apollo.useQuery<BasketPageQuery, BasketPageQueryVariables>(BasketPageDocument, baseOptions);
      }
export function useBasketPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BasketPageQuery, BasketPageQueryVariables>) {
          return Apollo.useLazyQuery<BasketPageQuery, BasketPageQueryVariables>(BasketPageDocument, baseOptions);
        }
export type BasketPageQueryHookResult = ReturnType<typeof useBasketPageQuery>;
export type BasketPageLazyQueryHookResult = ReturnType<typeof useBasketPageLazyQuery>;
export type BasketPageQueryResult = Apollo.QueryResult<BasketPageQuery, BasketPageQueryVariables>;
export const AddToBasketDocument = gql`
    mutation addToBasket($stockCode: String!, $quantity: Float!) {
  addToBasket(stockCode: $stockCode, quantity: $quantity)
}
    `;
export type AddToBasketMutationFn = Apollo.MutationFunction<AddToBasketMutation, AddToBasketMutationVariables>;

/**
 * __useAddToBasketMutation__
 *
 * To run a mutation, you first call `useAddToBasketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToBasketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToBasketMutation, { data, loading, error }] = useAddToBasketMutation({
 *   variables: {
 *      stockCode: // value for 'stockCode'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useAddToBasketMutation(baseOptions?: Apollo.MutationHookOptions<AddToBasketMutation, AddToBasketMutationVariables>) {
        return Apollo.useMutation<AddToBasketMutation, AddToBasketMutationVariables>(AddToBasketDocument, baseOptions);
      }
export type AddToBasketMutationHookResult = ReturnType<typeof useAddToBasketMutation>;
export type AddToBasketMutationResult = Apollo.MutationResult<AddToBasketMutation>;
export type AddToBasketMutationOptions = Apollo.BaseMutationOptions<AddToBasketMutation, AddToBasketMutationVariables>;
export const AddToBasketV2Document = gql`
    mutation addToBasketV2($stockCode: String!, $quantity: Float!) {
  addToBasketV2(stockCode: $stockCode, quantity: $quantity) {
    isSuccess
    tags {
      ... on AddToBasketGoogleAnalytics {
        id
        name
        category
        brand
        quantity
        price
      }
      ... on AddToBasketEnsighten {
        orderQuantity
        productId
      }
      ... on AddToBasketYandex {
        script
      }
      ... on AddToBasketTealium {
        orderQuantity
        productId
      }
      ... on GA4Event_AddToCart {
        event
        ecommerce {
          items {
            ...GA4Item
          }
        }
      }
    }
  }
}
    ${Ga4ItemFragmentDoc}`;
export type AddToBasketV2MutationFn = Apollo.MutationFunction<AddToBasketV2Mutation, AddToBasketV2MutationVariables>;

/**
 * __useAddToBasketV2Mutation__
 *
 * To run a mutation, you first call `useAddToBasketV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToBasketV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToBasketV2Mutation, { data, loading, error }] = useAddToBasketV2Mutation({
 *   variables: {
 *      stockCode: // value for 'stockCode'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useAddToBasketV2Mutation(baseOptions?: Apollo.MutationHookOptions<AddToBasketV2Mutation, AddToBasketV2MutationVariables>) {
        return Apollo.useMutation<AddToBasketV2Mutation, AddToBasketV2MutationVariables>(AddToBasketV2Document, baseOptions);
      }
export type AddToBasketV2MutationHookResult = ReturnType<typeof useAddToBasketV2Mutation>;
export type AddToBasketV2MutationResult = Apollo.MutationResult<AddToBasketV2Mutation>;
export type AddToBasketV2MutationOptions = Apollo.BaseMutationOptions<AddToBasketV2Mutation, AddToBasketV2MutationVariables>;
export const RemoveFromBasketDocument = gql`
    mutation removeFromBasket($lineIds: [Int!]!) {
  removeFromBasket(lineIds: $lineIds) {
    isSuccess
    tags {
      ...RemoveFromBasketResponseTags
    }
  }
}
    ${RemoveFromBasketResponseTagsFragmentDoc}`;
export type RemoveFromBasketMutationFn = Apollo.MutationFunction<RemoveFromBasketMutation, RemoveFromBasketMutationVariables>;

/**
 * __useRemoveFromBasketMutation__
 *
 * To run a mutation, you first call `useRemoveFromBasketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromBasketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromBasketMutation, { data, loading, error }] = useRemoveFromBasketMutation({
 *   variables: {
 *      lineIds: // value for 'lineIds'
 *   },
 * });
 */
export function useRemoveFromBasketMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFromBasketMutation, RemoveFromBasketMutationVariables>) {
        return Apollo.useMutation<RemoveFromBasketMutation, RemoveFromBasketMutationVariables>(RemoveFromBasketDocument, baseOptions);
      }
export type RemoveFromBasketMutationHookResult = ReturnType<typeof useRemoveFromBasketMutation>;
export type RemoveFromBasketMutationResult = Apollo.MutationResult<RemoveFromBasketMutation>;
export type RemoveFromBasketMutationOptions = Apollo.BaseMutationOptions<RemoveFromBasketMutation, RemoveFromBasketMutationVariables>;
export const UpdateLinesDocument = gql`
    mutation updateLines($data: UpdateBasketLinesRequest!) {
  updateLines(data: $data) {
    isSuccess
    tags {
      ... on UpdateBasketLinesEnsighten {
        lines {
          articleId
          oldQuantity
          newQuantity
          oldPrice
          newPrice
        }
      }
      ... on UpdateBasketLinesGoogleAnalytics {
        lines {
          articleId
          oldQuantity
          newQuantity
          oldPrice
          newPrice
        }
      }
      ... on UpdateBasketLinesTealium {
        lines {
          articleId
          oldQuantity
          newQuantity
          oldPrice
          newPrice
        }
      }
      ... on GA4Event_UpdateBasketLines {
        ...GA4Event_UpdateBasketLines
      }
    }
  }
}
    ${Ga4Event_UpdateBasketLinesFragmentDoc}`;
export type UpdateLinesMutationFn = Apollo.MutationFunction<UpdateLinesMutation, UpdateLinesMutationVariables>;

/**
 * __useUpdateLinesMutation__
 *
 * To run a mutation, you first call `useUpdateLinesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLinesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLinesMutation, { data, loading, error }] = useUpdateLinesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateLinesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLinesMutation, UpdateLinesMutationVariables>) {
        return Apollo.useMutation<UpdateLinesMutation, UpdateLinesMutationVariables>(UpdateLinesDocument, baseOptions);
      }
export type UpdateLinesMutationHookResult = ReturnType<typeof useUpdateLinesMutation>;
export type UpdateLinesMutationResult = Apollo.MutationResult<UpdateLinesMutation>;
export type UpdateLinesMutationOptions = Apollo.BaseMutationOptions<UpdateLinesMutation, UpdateLinesMutationVariables>;
export const BulkAddToBasketDocument = gql`
    mutation bulkAddToBasket($data: BulkAddToBasketRequest!) {
  bulkAddToBasket(data: $data) {
    isSuccess
    errorMessage
    infoMessage
    result {
      isSuccess
      title
      message
      productsNotFoundSection {
        message
        lineInfo {
          stockCode
          quantity
          link {
            text
            url
          }
          message
        }
      }
      discontinuedProductsSection {
        message
        lineInfo {
          stockCode
          quantity
          link {
            text
            url
          }
          message
        }
      }
    }
    tags {
      ... on BulkAddToBasketGoogleAnalytics {
        products {
          id
          name
          category
          brand
          quantity
          price
        }
      }
      ... on BulkAddToBasketEnsighten {
        products {
          orderQuantity
          productId
          partNumber
        }
      }
      ... on BulkAddToBasketTealium {
        products {
          orderQuantity
          productId
        }
      }
      ... on GA4Event_AddToCart {
        event
        ecommerce {
          items {
            ...GA4Item
          }
        }
      }
    }
  }
}
    ${Ga4ItemFragmentDoc}`;
export type BulkAddToBasketMutationFn = Apollo.MutationFunction<BulkAddToBasketMutation, BulkAddToBasketMutationVariables>;

/**
 * __useBulkAddToBasketMutation__
 *
 * To run a mutation, you first call `useBulkAddToBasketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkAddToBasketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkAddToBasketMutation, { data, loading, error }] = useBulkAddToBasketMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useBulkAddToBasketMutation(baseOptions?: Apollo.MutationHookOptions<BulkAddToBasketMutation, BulkAddToBasketMutationVariables>) {
        return Apollo.useMutation<BulkAddToBasketMutation, BulkAddToBasketMutationVariables>(BulkAddToBasketDocument, baseOptions);
      }
export type BulkAddToBasketMutationHookResult = ReturnType<typeof useBulkAddToBasketMutation>;
export type BulkAddToBasketMutationResult = Apollo.MutationResult<BulkAddToBasketMutation>;
export type BulkAddToBasketMutationOptions = Apollo.BaseMutationOptions<BulkAddToBasketMutation, BulkAddToBasketMutationVariables>;
export const BulkAddToBasketStatusUpdateDocument = gql`
    subscription bulkAddToBasketStatusUpdate($requestId: String!) {
  bulkAddToBasketStatusUpdate(requestId: $requestId) {
    message
    isError
    isProgress
    requestId
    isFinal
  }
}
    `;

/**
 * __useBulkAddToBasketStatusUpdateSubscription__
 *
 * To run a query within a React component, call `useBulkAddToBasketStatusUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBulkAddToBasketStatusUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulkAddToBasketStatusUpdateSubscription({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useBulkAddToBasketStatusUpdateSubscription(baseOptions: Apollo.SubscriptionHookOptions<BulkAddToBasketStatusUpdateSubscription, BulkAddToBasketStatusUpdateSubscriptionVariables>) {
        return Apollo.useSubscription<BulkAddToBasketStatusUpdateSubscription, BulkAddToBasketStatusUpdateSubscriptionVariables>(BulkAddToBasketStatusUpdateDocument, baseOptions);
      }
export type BulkAddToBasketStatusUpdateSubscriptionHookResult = ReturnType<typeof useBulkAddToBasketStatusUpdateSubscription>;
export type BulkAddToBasketStatusUpdateSubscriptionResult = Apollo.SubscriptionResult<BulkAddToBasketStatusUpdateSubscription>;
export const ClearBasketDocument = gql`
    mutation clearBasket {
  clearBasket {
    isSuccess
    tags {
      ...RemoveFromBasketResponseTags
    }
  }
}
    ${RemoveFromBasketResponseTagsFragmentDoc}`;
export type ClearBasketMutationFn = Apollo.MutationFunction<ClearBasketMutation, ClearBasketMutationVariables>;

/**
 * __useClearBasketMutation__
 *
 * To run a mutation, you first call `useClearBasketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearBasketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearBasketMutation, { data, loading, error }] = useClearBasketMutation({
 *   variables: {
 *   },
 * });
 */
export function useClearBasketMutation(baseOptions?: Apollo.MutationHookOptions<ClearBasketMutation, ClearBasketMutationVariables>) {
        return Apollo.useMutation<ClearBasketMutation, ClearBasketMutationVariables>(ClearBasketDocument, baseOptions);
      }
export type ClearBasketMutationHookResult = ReturnType<typeof useClearBasketMutation>;
export type ClearBasketMutationResult = Apollo.MutationResult<ClearBasketMutation>;
export type ClearBasketMutationOptions = Apollo.BaseMutationOptions<ClearBasketMutation, ClearBasketMutationVariables>;
export const SetDeliveryMethodDocument = gql`
    mutation setDeliveryMethod($data: SetDeliveryMethodRequest!) {
  setDeliveryMethod(data: $data) {
    isSuccess
  }
}
    `;
export type SetDeliveryMethodMutationFn = Apollo.MutationFunction<SetDeliveryMethodMutation, SetDeliveryMethodMutationVariables>;

/**
 * __useSetDeliveryMethodMutation__
 *
 * To run a mutation, you first call `useSetDeliveryMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDeliveryMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDeliveryMethodMutation, { data, loading, error }] = useSetDeliveryMethodMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSetDeliveryMethodMutation(baseOptions?: Apollo.MutationHookOptions<SetDeliveryMethodMutation, SetDeliveryMethodMutationVariables>) {
        return Apollo.useMutation<SetDeliveryMethodMutation, SetDeliveryMethodMutationVariables>(SetDeliveryMethodDocument, baseOptions);
      }
export type SetDeliveryMethodMutationHookResult = ReturnType<typeof useSetDeliveryMethodMutation>;
export type SetDeliveryMethodMutationResult = Apollo.MutationResult<SetDeliveryMethodMutation>;
export type SetDeliveryMethodMutationOptions = Apollo.BaseMutationOptions<SetDeliveryMethodMutation, SetDeliveryMethodMutationVariables>;
export const DeliveryOptionsDocument = gql`
    query deliveryOptions($countryCode: String) {
  basketPage {
    basketServices(countryCode: $countryCode) {
      options {
        id
        name
        description
        imageUrl
        isSelected
      }
    }
    deliveryOptions(countryCode: $countryCode) {
      options {
        id
        name
        description
        isSelected
        price {
          ...FormattedNumber
        }
        optionType
        properties {
          ... on DeliveryOptionCollectionProperties {
            requiresCourierAccountNumber
            courierAccountNumber
          }
        }
      }
    }
  }
}
    ${FormattedNumberFragmentDoc}`;

/**
 * __useDeliveryOptionsQuery__
 *
 * To run a query within a React component, call `useDeliveryOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryOptionsQuery({
 *   variables: {
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useDeliveryOptionsQuery(baseOptions?: Apollo.QueryHookOptions<DeliveryOptionsQuery, DeliveryOptionsQueryVariables>) {
        return Apollo.useQuery<DeliveryOptionsQuery, DeliveryOptionsQueryVariables>(DeliveryOptionsDocument, baseOptions);
      }
export function useDeliveryOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeliveryOptionsQuery, DeliveryOptionsQueryVariables>) {
          return Apollo.useLazyQuery<DeliveryOptionsQuery, DeliveryOptionsQueryVariables>(DeliveryOptionsDocument, baseOptions);
        }
export type DeliveryOptionsQueryHookResult = ReturnType<typeof useDeliveryOptionsQuery>;
export type DeliveryOptionsLazyQueryHookResult = ReturnType<typeof useDeliveryOptionsLazyQuery>;
export type DeliveryOptionsQueryResult = Apollo.QueryResult<DeliveryOptionsQuery, DeliveryOptionsQueryVariables>;
export const BasketPageSummaryDocument = gql`
    query basketPageSummary {
  basketPage {
    summary {
      ...Summary
    }
  }
}
    ${SummaryFragmentDoc}`;

/**
 * __useBasketPageSummaryQuery__
 *
 * To run a query within a React component, call `useBasketPageSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBasketPageSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBasketPageSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useBasketPageSummaryQuery(baseOptions?: Apollo.QueryHookOptions<BasketPageSummaryQuery, BasketPageSummaryQueryVariables>) {
        return Apollo.useQuery<BasketPageSummaryQuery, BasketPageSummaryQueryVariables>(BasketPageSummaryDocument, baseOptions);
      }
export function useBasketPageSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BasketPageSummaryQuery, BasketPageSummaryQueryVariables>) {
          return Apollo.useLazyQuery<BasketPageSummaryQuery, BasketPageSummaryQueryVariables>(BasketPageSummaryDocument, baseOptions);
        }
export type BasketPageSummaryQueryHookResult = ReturnType<typeof useBasketPageSummaryQuery>;
export type BasketPageSummaryLazyQueryHookResult = ReturnType<typeof useBasketPageSummaryLazyQuery>;
export type BasketPageSummaryQueryResult = Apollo.QueryResult<BasketPageSummaryQuery, BasketPageSummaryQueryVariables>;
export const SetPromoCodeDocument = gql`
    mutation setPromoCode($data: SetPromoCodeRequest!) {
  setPromoCode(data: $data) {
    isSuccess
    errorCode
    tags {
      ... on SetPromoCodeEnsighten {
        pageType
        promoCode
        pageCreationDate
        containsPOA
      }
      ... on SetPromoCodeGoogleAnalytics {
        promoCode
        pageCreationDate
        containsPOA
      }
      ... on GA4Event_SelectPromotion {
        event
        ecommerce {
          promotion_id
          promotion_name
          items {
            ...GA4Item
          }
        }
      }
    }
  }
}
    ${Ga4ItemFragmentDoc}`;
export type SetPromoCodeMutationFn = Apollo.MutationFunction<SetPromoCodeMutation, SetPromoCodeMutationVariables>;

/**
 * __useSetPromoCodeMutation__
 *
 * To run a mutation, you first call `useSetPromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPromoCodeMutation, { data, loading, error }] = useSetPromoCodeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSetPromoCodeMutation(baseOptions?: Apollo.MutationHookOptions<SetPromoCodeMutation, SetPromoCodeMutationVariables>) {
        return Apollo.useMutation<SetPromoCodeMutation, SetPromoCodeMutationVariables>(SetPromoCodeDocument, baseOptions);
      }
export type SetPromoCodeMutationHookResult = ReturnType<typeof useSetPromoCodeMutation>;
export type SetPromoCodeMutationResult = Apollo.MutationResult<SetPromoCodeMutation>;
export type SetPromoCodeMutationOptions = Apollo.BaseMutationOptions<SetPromoCodeMutation, SetPromoCodeMutationVariables>;
export const RemovePromoCodeDocument = gql`
    mutation removePromoCode {
  removePromoCode {
    isSuccess
    errorCode
  }
}
    `;
export type RemovePromoCodeMutationFn = Apollo.MutationFunction<RemovePromoCodeMutation, RemovePromoCodeMutationVariables>;

/**
 * __useRemovePromoCodeMutation__
 *
 * To run a mutation, you first call `useRemovePromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePromoCodeMutation, { data, loading, error }] = useRemovePromoCodeMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemovePromoCodeMutation(baseOptions?: Apollo.MutationHookOptions<RemovePromoCodeMutation, RemovePromoCodeMutationVariables>) {
        return Apollo.useMutation<RemovePromoCodeMutation, RemovePromoCodeMutationVariables>(RemovePromoCodeDocument, baseOptions);
      }
export type RemovePromoCodeMutationHookResult = ReturnType<typeof useRemovePromoCodeMutation>;
export type RemovePromoCodeMutationResult = Apollo.MutationResult<RemovePromoCodeMutation>;
export type RemovePromoCodeMutationOptions = Apollo.BaseMutationOptions<RemovePromoCodeMutation, RemovePromoCodeMutationVariables>;
export const AddBasketServiceDocument = gql`
    mutation addBasketService($data: AddBasketServiceRequest!) {
  addBasketService(data: $data) {
    isSuccess
  }
}
    `;
export type AddBasketServiceMutationFn = Apollo.MutationFunction<AddBasketServiceMutation, AddBasketServiceMutationVariables>;

/**
 * __useAddBasketServiceMutation__
 *
 * To run a mutation, you first call `useAddBasketServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBasketServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBasketServiceMutation, { data, loading, error }] = useAddBasketServiceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddBasketServiceMutation(baseOptions?: Apollo.MutationHookOptions<AddBasketServiceMutation, AddBasketServiceMutationVariables>) {
        return Apollo.useMutation<AddBasketServiceMutation, AddBasketServiceMutationVariables>(AddBasketServiceDocument, baseOptions);
      }
export type AddBasketServiceMutationHookResult = ReturnType<typeof useAddBasketServiceMutation>;
export type AddBasketServiceMutationResult = Apollo.MutationResult<AddBasketServiceMutation>;
export type AddBasketServiceMutationOptions = Apollo.BaseMutationOptions<AddBasketServiceMutation, AddBasketServiceMutationVariables>;
export const RemoveBasketServiceDocument = gql`
    mutation removeBasketService($data: RemoveBasketServiceRequest!) {
  removeBasketService(data: $data) {
    isSuccess
  }
}
    `;
export type RemoveBasketServiceMutationFn = Apollo.MutationFunction<RemoveBasketServiceMutation, RemoveBasketServiceMutationVariables>;

/**
 * __useRemoveBasketServiceMutation__
 *
 * To run a mutation, you first call `useRemoveBasketServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBasketServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBasketServiceMutation, { data, loading, error }] = useRemoveBasketServiceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveBasketServiceMutation(baseOptions?: Apollo.MutationHookOptions<RemoveBasketServiceMutation, RemoveBasketServiceMutationVariables>) {
        return Apollo.useMutation<RemoveBasketServiceMutation, RemoveBasketServiceMutationVariables>(RemoveBasketServiceDocument, baseOptions);
      }
export type RemoveBasketServiceMutationHookResult = ReturnType<typeof useRemoveBasketServiceMutation>;
export type RemoveBasketServiceMutationResult = Apollo.MutationResult<RemoveBasketServiceMutation>;
export type RemoveBasketServiceMutationOptions = Apollo.BaseMutationOptions<RemoveBasketServiceMutation, RemoveBasketServiceMutationVariables>;
export const CampaignPageDocument = gql`
    query campaignPage($url: String!, $isPreview: Boolean!) {
  campaignPage(url: $url, isPreview: $isPreview) {
    backgroundColour
    metaData {
      canonicalUrl
      pageTitle
      metaTags {
        name
        attributes {
          name
          value
        }
        innerHtml
      }
      links {
        name
        innerHtml
        attributes {
          name
          value
        }
      }
    }
    tagging {
      gtm {
        dataLayer {
          innerHtml
        }
      }
      tags {
        ...HtmlTag
      }
    }
    layouts {
      ... on SectionLayout {
        __typename
        backgroundImage
        backgroundColour
        topBorderStyle
        bottomBorderStyle
        padding {
          ...Padding
        }
        margin {
          ...Padding
        }
        widthType
        contentLayouts {
          ...ContentLayout
        }
      }
      ... on ContentLayout {
        __typename
        ...ContentLayout
      }
    }
  }
}
    ${HtmlTagFragmentDoc}
${PaddingFragmentDoc}
${ContentLayoutFragmentDoc}`;

/**
 * __useCampaignPageQuery__
 *
 * To run a query within a React component, call `useCampaignPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignPageQuery({
 *   variables: {
 *      url: // value for 'url'
 *      isPreview: // value for 'isPreview'
 *   },
 * });
 */
export function useCampaignPageQuery(baseOptions: Apollo.QueryHookOptions<CampaignPageQuery, CampaignPageQueryVariables>) {
        return Apollo.useQuery<CampaignPageQuery, CampaignPageQueryVariables>(CampaignPageDocument, baseOptions);
      }
export function useCampaignPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignPageQuery, CampaignPageQueryVariables>) {
          return Apollo.useLazyQuery<CampaignPageQuery, CampaignPageQueryVariables>(CampaignPageDocument, baseOptions);
        }
export type CampaignPageQueryHookResult = ReturnType<typeof useCampaignPageQuery>;
export type CampaignPageLazyQueryHookResult = ReturnType<typeof useCampaignPageLazyQuery>;
export type CampaignPageQueryResult = Apollo.QueryResult<CampaignPageQuery, CampaignPageQueryVariables>;
export const EmailCampaignPageDocument = gql`
    query emailCampaignPage($token: String!) {
  emailCampaignPage(data: {token: $token}) {
    isSuccess
    html
  }
}
    `;

/**
 * __useEmailCampaignPageQuery__
 *
 * To run a query within a React component, call `useEmailCampaignPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailCampaignPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailCampaignPageQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useEmailCampaignPageQuery(baseOptions: Apollo.QueryHookOptions<EmailCampaignPageQuery, EmailCampaignPageQueryVariables>) {
        return Apollo.useQuery<EmailCampaignPageQuery, EmailCampaignPageQueryVariables>(EmailCampaignPageDocument, baseOptions);
      }
export function useEmailCampaignPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailCampaignPageQuery, EmailCampaignPageQueryVariables>) {
          return Apollo.useLazyQuery<EmailCampaignPageQuery, EmailCampaignPageQueryVariables>(EmailCampaignPageDocument, baseOptions);
        }
export type EmailCampaignPageQueryHookResult = ReturnType<typeof useEmailCampaignPageQuery>;
export type EmailCampaignPageLazyQueryHookResult = ReturnType<typeof useEmailCampaignPageLazyQuery>;
export type EmailCampaignPageQueryResult = Apollo.QueryResult<EmailCampaignPageQuery, EmailCampaignPageQueryVariables>;
export const CategoryPageDocument = gql`
    query categoryPage($slug: String) {
  categoryListPage(slug: $slug) {
    isSuccess
    redirectUrl
    categoryId
    categoryName
    title
    description
    breadcrumb {
      ...Link
    }
    labels {
      ...Label
    }
    leftMenu {
      noOfChildren
      url {
        text
        url
      }
    }
    childCategories {
      id
      imageUrl
      noOfChildren
      noOfProducts
      url {
        text
        url
      }
      childCategories {
        id
        imageUrl
        noOfChildren
        noOfProducts
        url {
          text
          url
        }
      }
    }
    metaData {
      pageTitle
      structuredData
      canonicalUrl
      metaTags {
        attributes {
          ...HtmlTagAttribute
        }
      }
      links {
        attributes {
          ...HtmlTagAttribute
        }
      }
    }
    tagging {
      gtm {
        dataLayer {
          innerHtml
        }
      }
      tags {
        ...HtmlTag
      }
    }
  }
}
    ${LinkFragmentDoc}
${LabelFragmentDoc}
${HtmlTagAttributeFragmentDoc}
${HtmlTagFragmentDoc}`;

/**
 * __useCategoryPageQuery__
 *
 * To run a query within a React component, call `useCategoryPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryPageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCategoryPageQuery(baseOptions?: Apollo.QueryHookOptions<CategoryPageQuery, CategoryPageQueryVariables>) {
        return Apollo.useQuery<CategoryPageQuery, CategoryPageQueryVariables>(CategoryPageDocument, baseOptions);
      }
export function useCategoryPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryPageQuery, CategoryPageQueryVariables>) {
          return Apollo.useLazyQuery<CategoryPageQuery, CategoryPageQueryVariables>(CategoryPageDocument, baseOptions);
        }
export type CategoryPageQueryHookResult = ReturnType<typeof useCategoryPageQuery>;
export type CategoryPageLazyQueryHookResult = ReturnType<typeof useCategoryPageLazyQuery>;
export type CategoryPageQueryResult = Apollo.QueryResult<CategoryPageQuery, CategoryPageQueryVariables>;
export const CategoryPageTitleDocument = gql`
    query categoryPageTitle($slug: String) {
  categoryListPage(slug: $slug) {
    isSuccess
    categoryName
    description
  }
}
    `;

/**
 * __useCategoryPageTitleQuery__
 *
 * To run a query within a React component, call `useCategoryPageTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryPageTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryPageTitleQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCategoryPageTitleQuery(baseOptions?: Apollo.QueryHookOptions<CategoryPageTitleQuery, CategoryPageTitleQueryVariables>) {
        return Apollo.useQuery<CategoryPageTitleQuery, CategoryPageTitleQueryVariables>(CategoryPageTitleDocument, baseOptions);
      }
export function useCategoryPageTitleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryPageTitleQuery, CategoryPageTitleQueryVariables>) {
          return Apollo.useLazyQuery<CategoryPageTitleQuery, CategoryPageTitleQueryVariables>(CategoryPageTitleDocument, baseOptions);
        }
export type CategoryPageTitleQueryHookResult = ReturnType<typeof useCategoryPageTitleQuery>;
export type CategoryPageTitleLazyQueryHookResult = ReturnType<typeof useCategoryPageTitleLazyQuery>;
export type CategoryPageTitleQueryResult = Apollo.QueryResult<CategoryPageTitleQuery, CategoryPageTitleQueryVariables>;
export const CompareAlternativesDocument = gql`
    query compareAlternatives($stockCode: String!) {
  compareAlternativesPage(stockCode: $stockCode) {
    redirectUrl
    labels {
      ...Label
    }
    productsArea {
      ...PLPProduct
      attributes {
        name
        value
      }
      stockArea {
        statusCode
        title
        stockMessage
        dtpStockMessages
        infoMessage
        isUsingDTP
        denyBackOrder
        stockAvailability {
          quantityInStock
          availabilityDate
        }
        includesDate
      }
    }
  }
}
    ${LabelFragmentDoc}
${PlpProductFragmentDoc}`;

/**
 * __useCompareAlternativesQuery__
 *
 * To run a query within a React component, call `useCompareAlternativesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompareAlternativesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompareAlternativesQuery({
 *   variables: {
 *      stockCode: // value for 'stockCode'
 *   },
 * });
 */
export function useCompareAlternativesQuery(baseOptions: Apollo.QueryHookOptions<CompareAlternativesQuery, CompareAlternativesQueryVariables>) {
        return Apollo.useQuery<CompareAlternativesQuery, CompareAlternativesQueryVariables>(CompareAlternativesDocument, baseOptions);
      }
export function useCompareAlternativesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompareAlternativesQuery, CompareAlternativesQueryVariables>) {
          return Apollo.useLazyQuery<CompareAlternativesQuery, CompareAlternativesQueryVariables>(CompareAlternativesDocument, baseOptions);
        }
export type CompareAlternativesQueryHookResult = ReturnType<typeof useCompareAlternativesQuery>;
export type CompareAlternativesLazyQueryHookResult = ReturnType<typeof useCompareAlternativesLazyQuery>;
export type CompareAlternativesQueryResult = Apollo.QueryResult<CompareAlternativesQuery, CompareAlternativesQueryVariables>;
export const GetContextInfoDocument = gql`
    query GetContextInfo {
  master {
    contextInfo {
      locale
      isBot
    }
  }
}
    `;

/**
 * __useGetContextInfoQuery__
 *
 * To run a query within a React component, call `useGetContextInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContextInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContextInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContextInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetContextInfoQuery, GetContextInfoQueryVariables>) {
        return Apollo.useQuery<GetContextInfoQuery, GetContextInfoQueryVariables>(GetContextInfoDocument, baseOptions);
      }
export function useGetContextInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContextInfoQuery, GetContextInfoQueryVariables>) {
          return Apollo.useLazyQuery<GetContextInfoQuery, GetContextInfoQueryVariables>(GetContextInfoDocument, baseOptions);
        }
export type GetContextInfoQueryHookResult = ReturnType<typeof useGetContextInfoQuery>;
export type GetContextInfoLazyQueryHookResult = ReturnType<typeof useGetContextInfoLazyQuery>;
export type GetContextInfoQueryResult = Apollo.QueryResult<GetContextInfoQuery, GetContextInfoQueryVariables>;
export const NotFoundErrorPage404Document = gql`
    query notFoundErrorPage404 {
  notFoundErrorPage {
    tagging {
      gtm {
        dataLayer {
          innerHtml
        }
      }
      tags {
        ...HtmlTag
      }
    }
    labels {
      name
      text
    }
  }
}
    ${HtmlTagFragmentDoc}`;

/**
 * __useNotFoundErrorPage404Query__
 *
 * To run a query within a React component, call `useNotFoundErrorPage404Query` and pass it any options that fit your needs.
 * When your component renders, `useNotFoundErrorPage404Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotFoundErrorPage404Query({
 *   variables: {
 *   },
 * });
 */
export function useNotFoundErrorPage404Query(baseOptions?: Apollo.QueryHookOptions<NotFoundErrorPage404Query, NotFoundErrorPage404QueryVariables>) {
        return Apollo.useQuery<NotFoundErrorPage404Query, NotFoundErrorPage404QueryVariables>(NotFoundErrorPage404Document, baseOptions);
      }
export function useNotFoundErrorPage404LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotFoundErrorPage404Query, NotFoundErrorPage404QueryVariables>) {
          return Apollo.useLazyQuery<NotFoundErrorPage404Query, NotFoundErrorPage404QueryVariables>(NotFoundErrorPage404Document, baseOptions);
        }
export type NotFoundErrorPage404QueryHookResult = ReturnType<typeof useNotFoundErrorPage404Query>;
export type NotFoundErrorPage404LazyQueryHookResult = ReturnType<typeof useNotFoundErrorPage404LazyQuery>;
export type NotFoundErrorPage404QueryResult = Apollo.QueryResult<NotFoundErrorPage404Query, NotFoundErrorPage404QueryVariables>;
export const TechnicalErrorPageDocument = gql`
    query technicalErrorPage {
  technicalErrorPage {
    tagging {
      gtm {
        dataLayer {
          innerHtml
        }
      }
      tags {
        ...HtmlTag
      }
    }
    labels {
      ...Label
    }
    sessionId
  }
}
    ${HtmlTagFragmentDoc}
${LabelFragmentDoc}`;

/**
 * __useTechnicalErrorPageQuery__
 *
 * To run a query within a React component, call `useTechnicalErrorPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTechnicalErrorPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTechnicalErrorPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useTechnicalErrorPageQuery(baseOptions?: Apollo.QueryHookOptions<TechnicalErrorPageQuery, TechnicalErrorPageQueryVariables>) {
        return Apollo.useQuery<TechnicalErrorPageQuery, TechnicalErrorPageQueryVariables>(TechnicalErrorPageDocument, baseOptions);
      }
export function useTechnicalErrorPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TechnicalErrorPageQuery, TechnicalErrorPageQueryVariables>) {
          return Apollo.useLazyQuery<TechnicalErrorPageQuery, TechnicalErrorPageQueryVariables>(TechnicalErrorPageDocument, baseOptions);
        }
export type TechnicalErrorPageQueryHookResult = ReturnType<typeof useTechnicalErrorPageQuery>;
export type TechnicalErrorPageLazyQueryHookResult = ReturnType<typeof useTechnicalErrorPageLazyQuery>;
export type TechnicalErrorPageQueryResult = Apollo.QueryResult<TechnicalErrorPageQuery, TechnicalErrorPageQueryVariables>;
export const BasketTotalDocument = gql`
    query basketTotal {
  header {
    noOfItemsInBasket
    basketTotal {
      value
      displayValue
    }
  }
}
    `;

/**
 * __useBasketTotalQuery__
 *
 * To run a query within a React component, call `useBasketTotalQuery` and pass it any options that fit your needs.
 * When your component renders, `useBasketTotalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBasketTotalQuery({
 *   variables: {
 *   },
 * });
 */
export function useBasketTotalQuery(baseOptions?: Apollo.QueryHookOptions<BasketTotalQuery, BasketTotalQueryVariables>) {
        return Apollo.useQuery<BasketTotalQuery, BasketTotalQueryVariables>(BasketTotalDocument, baseOptions);
      }
export function useBasketTotalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BasketTotalQuery, BasketTotalQueryVariables>) {
          return Apollo.useLazyQuery<BasketTotalQuery, BasketTotalQueryVariables>(BasketTotalDocument, baseOptions);
        }
export type BasketTotalQueryHookResult = ReturnType<typeof useBasketTotalQuery>;
export type BasketTotalLazyQueryHookResult = ReturnType<typeof useBasketTotalLazyQuery>;
export type BasketTotalQueryResult = Apollo.QueryResult<BasketTotalQuery, BasketTotalQueryVariables>;
export const PartsListsTotalDocument = gql`
    query partsListsTotal {
  header {
    noOfPartsLists
  }
}
    `;

/**
 * __usePartsListsTotalQuery__
 *
 * To run a query within a React component, call `usePartsListsTotalQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartsListsTotalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartsListsTotalQuery({
 *   variables: {
 *   },
 * });
 */
export function usePartsListsTotalQuery(baseOptions?: Apollo.QueryHookOptions<PartsListsTotalQuery, PartsListsTotalQueryVariables>) {
        return Apollo.useQuery<PartsListsTotalQuery, PartsListsTotalQueryVariables>(PartsListsTotalDocument, baseOptions);
      }
export function usePartsListsTotalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartsListsTotalQuery, PartsListsTotalQueryVariables>) {
          return Apollo.useLazyQuery<PartsListsTotalQuery, PartsListsTotalQueryVariables>(PartsListsTotalDocument, baseOptions);
        }
export type PartsListsTotalQueryHookResult = ReturnType<typeof usePartsListsTotalQuery>;
export type PartsListsTotalLazyQueryHookResult = ReturnType<typeof usePartsListsTotalLazyQuery>;
export type PartsListsTotalQueryResult = Apollo.QueryResult<PartsListsTotalQuery, PartsListsTotalQueryVariables>;
export const HeaderTotalsDocument = gql`
    query headerTotals {
  header {
    customer {
      isLoggedIn
      name
      email
    }
    noOfPartsLists
    noOfItemsInBasket
    basketTotal {
      value
      displayValue
    }
  }
}
    `;

/**
 * __useHeaderTotalsQuery__
 *
 * To run a query within a React component, call `useHeaderTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHeaderTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeaderTotalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHeaderTotalsQuery(baseOptions?: Apollo.QueryHookOptions<HeaderTotalsQuery, HeaderTotalsQueryVariables>) {
        return Apollo.useQuery<HeaderTotalsQuery, HeaderTotalsQueryVariables>(HeaderTotalsDocument, baseOptions);
      }
export function useHeaderTotalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HeaderTotalsQuery, HeaderTotalsQueryVariables>) {
          return Apollo.useLazyQuery<HeaderTotalsQuery, HeaderTotalsQueryVariables>(HeaderTotalsDocument, baseOptions);
        }
export type HeaderTotalsQueryHookResult = ReturnType<typeof useHeaderTotalsQuery>;
export type HeaderTotalsLazyQueryHookResult = ReturnType<typeof useHeaderTotalsLazyQuery>;
export type HeaderTotalsQueryResult = Apollo.QueryResult<HeaderTotalsQuery, HeaderTotalsQueryVariables>;
export const LifecyclePageDocument = gql`
    query lifecyclePage($token: String!) {
  lifecyclePage(data: {token: $token}) {
    isSuccess
    html
  }
}
    `;

/**
 * __useLifecyclePageQuery__
 *
 * To run a query within a React component, call `useLifecyclePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLifecyclePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLifecyclePageQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useLifecyclePageQuery(baseOptions: Apollo.QueryHookOptions<LifecyclePageQuery, LifecyclePageQueryVariables>) {
        return Apollo.useQuery<LifecyclePageQuery, LifecyclePageQueryVariables>(LifecyclePageDocument, baseOptions);
      }
export function useLifecyclePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LifecyclePageQuery, LifecyclePageQueryVariables>) {
          return Apollo.useLazyQuery<LifecyclePageQuery, LifecyclePageQueryVariables>(LifecyclePageDocument, baseOptions);
        }
export type LifecyclePageQueryHookResult = ReturnType<typeof useLifecyclePageQuery>;
export type LifecyclePageLazyQueryHookResult = ReturnType<typeof useLifecyclePageLazyQuery>;
export type LifecyclePageQueryResult = Apollo.QueryResult<LifecyclePageQuery, LifecyclePageQueryVariables>;
export const MasterDocument = gql`
    query Master {
  master {
    labels {
      ...Label
    }
    metaData {
      links {
        ...HtmlTag
      }
      htmlLang
      metaTags {
        attributes {
          ...HtmlTagAttribute
        }
      }
    }
    tagging {
      gtm {
        gtmid
        isEnabled
      }
    }
    scripts {
      head {
        ...HtmlTag
      }
      bodyEnd {
        ...HtmlTag
      }
    }
    config {
      enableSiteWideBanner
    }
    siteWideBanner {
      headline
      body
      buttonText
      buttonUrl
      type
    }
  }
  header {
    metaData {
      structuredData {
        ...HtmlTag
      }
    }
    links {
      ...Link
    }
    labels {
      ...Label
    }
    siteSettings {
      isVisible
      showLanguages
      showCurrencies
      showCountries
      showVATToggle
      languages {
        name
        baseDirectory
        countryCodeISO2
        isSelected
      }
      currencies {
        name
        displayName
        id
        shortName
      }
      countries {
        name
        codeISO2
        id
        contactEmail
        contactPhone
        contactAddress
        isSelected
      }
    }
  }
  menu {
    labels {
      ...Label
    }
    categories {
      description
      link {
        ...Link
      }
    }
    menuLinks {
      ...Link
    }
    otherLinks {
      ...Link
    }
  }
  footer {
    config {
      phoneNumber
      emailAddress
      businessAddress
      paymentMethods
      socialPlatforms {
        ...Link
      }
      partnerLinks {
        ...Link
      }
    }
    labels {
      ...Label
    }
    columnLinks {
      title
      links {
        ...Link
      }
    }
    bottomLinks {
      links {
        ...Link
      }
    }
    siteSettings {
      isVisible
      showLanguages
      showCurrencies
      showCountries
      showVATToggle
      languages {
        name
        baseDirectory
        countryCodeISO2
        isSelected
      }
      currencies {
        name
        displayName
        id
        shortName
      }
      countries {
        name
        codeISO2
        id
        contactEmail
        contactPhone
        contactAddress
        isSelected
      }
    }
    metaData {
      structuredData {
        ...HtmlTag
      }
    }
  }
}
    ${LabelFragmentDoc}
${HtmlTagFragmentDoc}
${HtmlTagAttributeFragmentDoc}
${LinkFragmentDoc}`;

/**
 * __useMasterQuery__
 *
 * To run a query within a React component, call `useMasterQuery` and pass it any options that fit your needs.
 * When your component renders, `useMasterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMasterQuery({
 *   variables: {
 *   },
 * });
 */
export function useMasterQuery(baseOptions?: Apollo.QueryHookOptions<MasterQuery, MasterQueryVariables>) {
        return Apollo.useQuery<MasterQuery, MasterQueryVariables>(MasterDocument, baseOptions);
      }
export function useMasterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MasterQuery, MasterQueryVariables>) {
          return Apollo.useLazyQuery<MasterQuery, MasterQueryVariables>(MasterDocument, baseOptions);
        }
export type MasterQueryHookResult = ReturnType<typeof useMasterQuery>;
export type MasterLazyQueryHookResult = ReturnType<typeof useMasterLazyQuery>;
export type MasterQueryResult = Apollo.QueryResult<MasterQuery, MasterQueryVariables>;
export const Level1Document = gql`
    query Level1 {
  menu {
    categories {
      link {
        ...Link
      }
    }
  }
}
    ${LinkFragmentDoc}`;

/**
 * __useLevel1Query__
 *
 * To run a query within a React component, call `useLevel1Query` and pass it any options that fit your needs.
 * When your component renders, `useLevel1Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLevel1Query({
 *   variables: {
 *   },
 * });
 */
export function useLevel1Query(baseOptions?: Apollo.QueryHookOptions<Level1Query, Level1QueryVariables>) {
        return Apollo.useQuery<Level1Query, Level1QueryVariables>(Level1Document, baseOptions);
      }
export function useLevel1LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Level1Query, Level1QueryVariables>) {
          return Apollo.useLazyQuery<Level1Query, Level1QueryVariables>(Level1Document, baseOptions);
        }
export type Level1QueryHookResult = ReturnType<typeof useLevel1Query>;
export type Level1LazyQueryHookResult = ReturnType<typeof useLevel1LazyQuery>;
export type Level1QueryResult = Apollo.QueryResult<Level1Query, Level1QueryVariables>;
export const Level2Document = gql`
    query Level2($parentId: Int!) {
  menu {
    categories(parentId: $parentId) {
      description
      link {
        ...Link
      }
      children {
        ...Link
      }
    }
  }
}
    ${LinkFragmentDoc}`;

/**
 * __useLevel2Query__
 *
 * To run a query within a React component, call `useLevel2Query` and pass it any options that fit your needs.
 * When your component renders, `useLevel2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLevel2Query({
 *   variables: {
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useLevel2Query(baseOptions: Apollo.QueryHookOptions<Level2Query, Level2QueryVariables>) {
        return Apollo.useQuery<Level2Query, Level2QueryVariables>(Level2Document, baseOptions);
      }
export function useLevel2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Level2Query, Level2QueryVariables>) {
          return Apollo.useLazyQuery<Level2Query, Level2QueryVariables>(Level2Document, baseOptions);
        }
export type Level2QueryHookResult = ReturnType<typeof useLevel2Query>;
export type Level2LazyQueryHookResult = ReturnType<typeof useLevel2LazyQuery>;
export type Level2QueryResult = Apollo.QueryResult<Level2Query, Level2QueryVariables>;
export const AllLevelsDocument = gql`
    query AllLevels {
  menu {
    categories {
      link {
        ...Link
      }
      children {
        ...Link
      }
    }
  }
}
    ${LinkFragmentDoc}`;

/**
 * __useAllLevelsQuery__
 *
 * To run a query within a React component, call `useAllLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllLevelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllLevelsQuery(baseOptions?: Apollo.QueryHookOptions<AllLevelsQuery, AllLevelsQueryVariables>) {
        return Apollo.useQuery<AllLevelsQuery, AllLevelsQueryVariables>(AllLevelsDocument, baseOptions);
      }
export function useAllLevelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllLevelsQuery, AllLevelsQueryVariables>) {
          return Apollo.useLazyQuery<AllLevelsQuery, AllLevelsQueryVariables>(AllLevelsDocument, baseOptions);
        }
export type AllLevelsQueryHookResult = ReturnType<typeof useAllLevelsQuery>;
export type AllLevelsLazyQueryHookResult = ReturnType<typeof useAllLevelsLazyQuery>;
export type AllLevelsQueryResult = Apollo.QueryResult<AllLevelsQuery, AllLevelsQueryVariables>;
export const FeaturedBrandsDocument = gql`
    query FeaturedBrands {
  menu {
    featuredBrands {
      brands {
        name
        link {
          ...Link
        }
        imageUrl
      }
      viewAll {
        ...Link
      }
    }
  }
}
    ${LinkFragmentDoc}`;

/**
 * __useFeaturedBrandsQuery__
 *
 * To run a query within a React component, call `useFeaturedBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturedBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeaturedBrandsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeaturedBrandsQuery(baseOptions?: Apollo.QueryHookOptions<FeaturedBrandsQuery, FeaturedBrandsQueryVariables>) {
        return Apollo.useQuery<FeaturedBrandsQuery, FeaturedBrandsQueryVariables>(FeaturedBrandsDocument, baseOptions);
      }
export function useFeaturedBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeaturedBrandsQuery, FeaturedBrandsQueryVariables>) {
          return Apollo.useLazyQuery<FeaturedBrandsQuery, FeaturedBrandsQueryVariables>(FeaturedBrandsDocument, baseOptions);
        }
export type FeaturedBrandsQueryHookResult = ReturnType<typeof useFeaturedBrandsQuery>;
export type FeaturedBrandsLazyQueryHookResult = ReturnType<typeof useFeaturedBrandsLazyQuery>;
export type FeaturedBrandsQueryResult = Apollo.QueryResult<FeaturedBrandsQuery, FeaturedBrandsQueryVariables>;
export const NoResultsPageDocument = gql`
    query noResultsPage($searchQuery: String!, $isProductNotFound: Boolean) {
  noResultsPage(searchQuery: $searchQuery, isProductNotFound: $isProductNotFound) {
    tagging {
      gtm {
        dataLayer {
          innerHtml
        }
      }
      tags {
        ...HtmlTag
      }
    }
    metaData {
      pageTitle
      canonicalUrl
      links {
        attributes {
          ...HtmlTagAttribute
        }
      }
      metaTags {
        attributes {
          ...HtmlTagAttribute
        }
      }
    }
    labels {
      ...Label
    }
  }
}
    ${HtmlTagFragmentDoc}
${HtmlTagAttributeFragmentDoc}
${LabelFragmentDoc}`;

/**
 * __useNoResultsPageQuery__
 *
 * To run a query within a React component, call `useNoResultsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoResultsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoResultsPageQuery({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *      isProductNotFound: // value for 'isProductNotFound'
 *   },
 * });
 */
export function useNoResultsPageQuery(baseOptions: Apollo.QueryHookOptions<NoResultsPageQuery, NoResultsPageQueryVariables>) {
        return Apollo.useQuery<NoResultsPageQuery, NoResultsPageQueryVariables>(NoResultsPageDocument, baseOptions);
      }
export function useNoResultsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NoResultsPageQuery, NoResultsPageQueryVariables>) {
          return Apollo.useLazyQuery<NoResultsPageQuery, NoResultsPageQueryVariables>(NoResultsPageDocument, baseOptions);
        }
export type NoResultsPageQueryHookResult = ReturnType<typeof useNoResultsPageQuery>;
export type NoResultsPageLazyQueryHookResult = ReturnType<typeof useNoResultsPageLazyQuery>;
export type NoResultsPageQueryResult = Apollo.QueryResult<NoResultsPageQuery, NoResultsPageQueryVariables>;
export const OrderConfirmationPageDocument = gql`
    query orderConfirmationPage {
  orderConfirmationPage {
    isSuccess
    details {
      labels {
        name
        text
      }
      numberOfItems
      isQuote
      orderReference
      orderReferencePersonal
      summary {
        ...Summary
      }
      parcels {
        title
        description
        items {
          quantity
          id
          product {
            title {
              brand
              formattedStockCode
              hsnCode
              mpn
              stockCode
              title
              primaryImageUrl
              pdpUrl
              partNumber
            }
            priceArea {
              minimumOrderQuantity
              totalPrice {
                priceBefore {
                  value
                  displayValue
                }
                priceAfter {
                  value
                  displayValue
                }
              }
              unitPrice {
                priceAfter {
                  value
                  displayValue
                }
              }
              container
              taxType
              taxes {
                taxType
                taxAmountDisplayValue
              }
              errorMessage
              unitOfMeasure
            }
            stockArea {
              statusCode
              title
              stockMessage
              infoMessage
              isUsingDTP
              denyBackOrder
              stockAvailability {
                quantityInStock
                availabilityDate
              }
              includesDate
              stockBadgeIcon
            }
            energyArea {
              energyLabel {
                ...ProductEnergyLabel
              }
            }
          }
        }
      }
      paymentOption {
        title
        description
        optionType
        details {
          ... on OrderConfirmationPage_BankTransferDetails {
            configuration {
              orderConfirmationMessage {
                content {
                  ...TextEditor
                }
                title
              }
            }
            bankDetails {
              showBankDetails
              accountNumber {
                label
                detail
              }
              bankCode {
                label
                detail
              }
              bankName {
                label
                detail
              }
              branchCode {
                label
                detail
              }
              branchName {
                label
                detail
              }
              companyName {
                label
                detail
              }
              iban {
                label
                detail
              }
              swiftCode {
                label
                detail
              }
            }
          }
          ... on OrderConfirmationPage_ControlledPurchasingDetails {
            controlledPurchasingDetails {
              purchaser {
                firstName
                lastName
                emailAddress
              }
            }
          }
        }
      }
      contactDetails {
        firstName
        lastName
        email
        phoneNo
        accountType
        isValid
      }
      deliveryAddress {
        uniqueId
        isValid
        details {
          ...AddressFields
        }
      }
      billingAddress {
        uniqueId
        isValid
        details {
          ...AddressFields
        }
      }
      tagging {
        gtm {
          dataLayer {
            innerHtml
          }
        }
        tags {
          ...HtmlTag
        }
        ga4 {
          ... on GA4Event_Purchase {
            event
            ecommerce {
              transactionId
              value
              tax
              shipping
              currency
              coupon
              v_landing
              total_prqt
              items {
                ...GA4Item
              }
              email
            }
          }
        }
      }
      config {
        hasDualUseProducts
        isUsingDtp
      }
    }
  }
}
    ${SummaryFragmentDoc}
${ProductEnergyLabelFragmentDoc}
${TextEditorFragmentDoc}
${AddressFieldsFragmentDoc}
${HtmlTagFragmentDoc}
${Ga4ItemFragmentDoc}`;

/**
 * __useOrderConfirmationPageQuery__
 *
 * To run a query within a React component, call `useOrderConfirmationPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderConfirmationPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderConfirmationPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrderConfirmationPageQuery(baseOptions?: Apollo.QueryHookOptions<OrderConfirmationPageQuery, OrderConfirmationPageQueryVariables>) {
        return Apollo.useQuery<OrderConfirmationPageQuery, OrderConfirmationPageQueryVariables>(OrderConfirmationPageDocument, baseOptions);
      }
export function useOrderConfirmationPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderConfirmationPageQuery, OrderConfirmationPageQueryVariables>) {
          return Apollo.useLazyQuery<OrderConfirmationPageQuery, OrderConfirmationPageQueryVariables>(OrderConfirmationPageDocument, baseOptions);
        }
export type OrderConfirmationPageQueryHookResult = ReturnType<typeof useOrderConfirmationPageQuery>;
export type OrderConfirmationPageLazyQueryHookResult = ReturnType<typeof useOrderConfirmationPageLazyQuery>;
export type OrderConfirmationPageQueryResult = Apollo.QueryResult<OrderConfirmationPageQuery, OrderConfirmationPageQueryVariables>;
export const PartsListDocument = gql`
    query partsList($contextStockCode: String) {
  partsListPage {
    labels {
      ...Label
    }
    partsListsArea(contextStockCode: $contextStockCode) {
      partsLists {
        id
        title
        noOfProducts
        isContextProductInList
        totalPrice {
          priceAfter {
            value
            displayValue
          }
        }
      }
    }
    redirectUrl
    requireLogin
  }
}
    ${LabelFragmentDoc}`;

/**
 * __usePartsListQuery__
 *
 * To run a query within a React component, call `usePartsListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartsListQuery({
 *   variables: {
 *      contextStockCode: // value for 'contextStockCode'
 *   },
 * });
 */
export function usePartsListQuery(baseOptions?: Apollo.QueryHookOptions<PartsListQuery, PartsListQueryVariables>) {
        return Apollo.useQuery<PartsListQuery, PartsListQueryVariables>(PartsListDocument, baseOptions);
      }
export function usePartsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartsListQuery, PartsListQueryVariables>) {
          return Apollo.useLazyQuery<PartsListQuery, PartsListQueryVariables>(PartsListDocument, baseOptions);
        }
export type PartsListQueryHookResult = ReturnType<typeof usePartsListQuery>;
export type PartsListLazyQueryHookResult = ReturnType<typeof usePartsListLazyQuery>;
export type PartsListQueryResult = Apollo.QueryResult<PartsListQuery, PartsListQueryVariables>;
export const PartsListProductsDocument = gql`
    query partsListProducts($partsListId: Int) {
  partsListPage {
    partsListsArea(partsListId: $partsListId) {
      partsLists {
        id
        title
        products {
          stockCode
          imageUrl
          container
          title
          quantity
          price {
            value
            displayValue
          }
          energyArea {
            energyLabel {
              ...ProductEnergyLabel
            }
          }
        }
      }
    }
  }
}
    ${ProductEnergyLabelFragmentDoc}`;

/**
 * __usePartsListProductsQuery__
 *
 * To run a query within a React component, call `usePartsListProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartsListProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartsListProductsQuery({
 *   variables: {
 *      partsListId: // value for 'partsListId'
 *   },
 * });
 */
export function usePartsListProductsQuery(baseOptions?: Apollo.QueryHookOptions<PartsListProductsQuery, PartsListProductsQueryVariables>) {
        return Apollo.useQuery<PartsListProductsQuery, PartsListProductsQueryVariables>(PartsListProductsDocument, baseOptions);
      }
export function usePartsListProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartsListProductsQuery, PartsListProductsQueryVariables>) {
          return Apollo.useLazyQuery<PartsListProductsQuery, PartsListProductsQueryVariables>(PartsListProductsDocument, baseOptions);
        }
export type PartsListProductsQueryHookResult = ReturnType<typeof usePartsListProductsQuery>;
export type PartsListProductsLazyQueryHookResult = ReturnType<typeof usePartsListProductsLazyQuery>;
export type PartsListProductsQueryResult = Apollo.QueryResult<PartsListProductsQuery, PartsListProductsQueryVariables>;
export const GetPartsListLabelsDocument = gql`
    query getPartsListLabels {
  getPartsListLabels {
    labels {
      ...Label
    }
  }
}
    ${LabelFragmentDoc}`;

/**
 * __useGetPartsListLabelsQuery__
 *
 * To run a query within a React component, call `useGetPartsListLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartsListLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartsListLabelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPartsListLabelsQuery(baseOptions?: Apollo.QueryHookOptions<GetPartsListLabelsQuery, GetPartsListLabelsQueryVariables>) {
        return Apollo.useQuery<GetPartsListLabelsQuery, GetPartsListLabelsQueryVariables>(GetPartsListLabelsDocument, baseOptions);
      }
export function useGetPartsListLabelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartsListLabelsQuery, GetPartsListLabelsQueryVariables>) {
          return Apollo.useLazyQuery<GetPartsListLabelsQuery, GetPartsListLabelsQueryVariables>(GetPartsListLabelsDocument, baseOptions);
        }
export type GetPartsListLabelsQueryHookResult = ReturnType<typeof useGetPartsListLabelsQuery>;
export type GetPartsListLabelsLazyQueryHookResult = ReturnType<typeof useGetPartsListLabelsLazyQuery>;
export type GetPartsListLabelsQueryResult = Apollo.QueryResult<GetPartsListLabelsQuery, GetPartsListLabelsQueryVariables>;
export const UpdatePartsListLineDocument = gql`
    mutation updatePartsListLine($input: UpdatePartsListLineRequest!) {
  updatePartsListLine(input: $input) {
    isSuccess
    errorMessage
    priceArea {
      minimumOrderQuantity
      totalPrice {
        priceAfter {
          displayValue
        }
      }
      unitPrice {
        priceAfter {
          displayValue
        }
      }
    }
  }
}
    `;
export type UpdatePartsListLineMutationFn = Apollo.MutationFunction<UpdatePartsListLineMutation, UpdatePartsListLineMutationVariables>;

/**
 * __useUpdatePartsListLineMutation__
 *
 * To run a mutation, you first call `useUpdatePartsListLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartsListLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartsListLineMutation, { data, loading, error }] = useUpdatePartsListLineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePartsListLineMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePartsListLineMutation, UpdatePartsListLineMutationVariables>) {
        return Apollo.useMutation<UpdatePartsListLineMutation, UpdatePartsListLineMutationVariables>(UpdatePartsListLineDocument, baseOptions);
      }
export type UpdatePartsListLineMutationHookResult = ReturnType<typeof useUpdatePartsListLineMutation>;
export type UpdatePartsListLineMutationResult = Apollo.MutationResult<UpdatePartsListLineMutation>;
export type UpdatePartsListLineMutationOptions = Apollo.BaseMutationOptions<UpdatePartsListLineMutation, UpdatePartsListLineMutationVariables>;
export const AddToPartsListV3Document = gql`
    mutation addToPartsListV3($data: AddToPartsListRequestV3!) {
  addToPartsListV3(data: $data) {
    isSuccess
    partsListId
    requireLogin
    redirectUrl
  }
}
    `;
export type AddToPartsListV3MutationFn = Apollo.MutationFunction<AddToPartsListV3Mutation, AddToPartsListV3MutationVariables>;

/**
 * __useAddToPartsListV3Mutation__
 *
 * To run a mutation, you first call `useAddToPartsListV3Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToPartsListV3Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToPartsListV3Mutation, { data, loading, error }] = useAddToPartsListV3Mutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddToPartsListV3Mutation(baseOptions?: Apollo.MutationHookOptions<AddToPartsListV3Mutation, AddToPartsListV3MutationVariables>) {
        return Apollo.useMutation<AddToPartsListV3Mutation, AddToPartsListV3MutationVariables>(AddToPartsListV3Document, baseOptions);
      }
export type AddToPartsListV3MutationHookResult = ReturnType<typeof useAddToPartsListV3Mutation>;
export type AddToPartsListV3MutationResult = Apollo.MutationResult<AddToPartsListV3Mutation>;
export type AddToPartsListV3MutationOptions = Apollo.BaseMutationOptions<AddToPartsListV3Mutation, AddToPartsListV3MutationVariables>;
export const AddRecentSearchDocument = gql`
    mutation addRecentSearch($searchQuery: String!) {
  addRecentSearch(searchQuery: $searchQuery) {
    isSuccess
  }
}
    `;
export type AddRecentSearchMutationFn = Apollo.MutationFunction<AddRecentSearchMutation, AddRecentSearchMutationVariables>;

/**
 * __useAddRecentSearchMutation__
 *
 * To run a mutation, you first call `useAddRecentSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRecentSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRecentSearchMutation, { data, loading, error }] = useAddRecentSearchMutation({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useAddRecentSearchMutation(baseOptions?: Apollo.MutationHookOptions<AddRecentSearchMutation, AddRecentSearchMutationVariables>) {
        return Apollo.useMutation<AddRecentSearchMutation, AddRecentSearchMutationVariables>(AddRecentSearchDocument, baseOptions);
      }
export type AddRecentSearchMutationHookResult = ReturnType<typeof useAddRecentSearchMutation>;
export type AddRecentSearchMutationResult = Apollo.MutationResult<AddRecentSearchMutation>;
export type AddRecentSearchMutationOptions = Apollo.BaseMutationOptions<AddRecentSearchMutation, AddRecentSearchMutationVariables>;
export const GetRecentSearchesDocument = gql`
    query getRecentSearches {
  getRecentSearches {
    labels {
      ...Label
    }
    recentSearches {
      searchQuery
      url
    }
  }
}
    ${LabelFragmentDoc}`;

/**
 * __useGetRecentSearchesQuery__
 *
 * To run a query within a React component, call `useGetRecentSearchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentSearchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentSearchesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecentSearchesQuery(baseOptions?: Apollo.QueryHookOptions<GetRecentSearchesQuery, GetRecentSearchesQueryVariables>) {
        return Apollo.useQuery<GetRecentSearchesQuery, GetRecentSearchesQueryVariables>(GetRecentSearchesDocument, baseOptions);
      }
export function useGetRecentSearchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecentSearchesQuery, GetRecentSearchesQueryVariables>) {
          return Apollo.useLazyQuery<GetRecentSearchesQuery, GetRecentSearchesQueryVariables>(GetRecentSearchesDocument, baseOptions);
        }
export type GetRecentSearchesQueryHookResult = ReturnType<typeof useGetRecentSearchesQuery>;
export type GetRecentSearchesLazyQueryHookResult = ReturnType<typeof useGetRecentSearchesLazyQuery>;
export type GetRecentSearchesQueryResult = Apollo.QueryResult<GetRecentSearchesQuery, GetRecentSearchesQueryVariables>;
export const RemoveAllRecentSearchesDocument = gql`
    mutation removeAllRecentSearches {
  removeAllRecentSearches
}
    `;
export type RemoveAllRecentSearchesMutationFn = Apollo.MutationFunction<RemoveAllRecentSearchesMutation, RemoveAllRecentSearchesMutationVariables>;

/**
 * __useRemoveAllRecentSearchesMutation__
 *
 * To run a mutation, you first call `useRemoveAllRecentSearchesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAllRecentSearchesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAllRecentSearchesMutation, { data, loading, error }] = useRemoveAllRecentSearchesMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveAllRecentSearchesMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAllRecentSearchesMutation, RemoveAllRecentSearchesMutationVariables>) {
        return Apollo.useMutation<RemoveAllRecentSearchesMutation, RemoveAllRecentSearchesMutationVariables>(RemoveAllRecentSearchesDocument, baseOptions);
      }
export type RemoveAllRecentSearchesMutationHookResult = ReturnType<typeof useRemoveAllRecentSearchesMutation>;
export type RemoveAllRecentSearchesMutationResult = Apollo.MutationResult<RemoveAllRecentSearchesMutation>;
export type RemoveAllRecentSearchesMutationOptions = Apollo.BaseMutationOptions<RemoveAllRecentSearchesMutation, RemoveAllRecentSearchesMutationVariables>;
export const RemoveRecentSearchDocument = gql`
    mutation removeRecentSearch($searchQuery: String!) {
  removeRecentSearch(searchQuery: $searchQuery)
}
    `;
export type RemoveRecentSearchMutationFn = Apollo.MutationFunction<RemoveRecentSearchMutation, RemoveRecentSearchMutationVariables>;

/**
 * __useRemoveRecentSearchMutation__
 *
 * To run a mutation, you first call `useRemoveRecentSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRecentSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRecentSearchMutation, { data, loading, error }] = useRemoveRecentSearchMutation({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useRemoveRecentSearchMutation(baseOptions?: Apollo.MutationHookOptions<RemoveRecentSearchMutation, RemoveRecentSearchMutationVariables>) {
        return Apollo.useMutation<RemoveRecentSearchMutation, RemoveRecentSearchMutationVariables>(RemoveRecentSearchDocument, baseOptions);
      }
export type RemoveRecentSearchMutationHookResult = ReturnType<typeof useRemoveRecentSearchMutation>;
export type RemoveRecentSearchMutationResult = Apollo.MutationResult<RemoveRecentSearchMutation>;
export type RemoveRecentSearchMutationOptions = Apollo.BaseMutationOptions<RemoveRecentSearchMutation, RemoveRecentSearchMutationVariables>;
export const GetSuggestedSearchesDocument = gql`
    query getSuggestedSearches($searchQuery: String!) {
  getSuggestedSearches(searchQuery: $searchQuery) {
    labels {
      ...Label
    }
    searchTerms {
      __typename
      ... on SuggestedSearchTerm {
        url
        highlightedFragment
        searchQuery
        noOfMatches
        parentSuggest {
          searchQuery
          url
        }
      }
      ... on SuggestedProduct {
        stockCode
        formattedStockCode
        brand
        primaryImageUrl
        pdpUrl
        mpn
        title
        highlightedFragment
        isAlternative
        comparisonPageUrl
      }
    }
  }
}
    ${LabelFragmentDoc}`;

/**
 * __useGetSuggestedSearchesQuery__
 *
 * To run a query within a React component, call `useGetSuggestedSearchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSuggestedSearchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuggestedSearchesQuery({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useGetSuggestedSearchesQuery(baseOptions: Apollo.QueryHookOptions<GetSuggestedSearchesQuery, GetSuggestedSearchesQueryVariables>) {
        return Apollo.useQuery<GetSuggestedSearchesQuery, GetSuggestedSearchesQueryVariables>(GetSuggestedSearchesDocument, baseOptions);
      }
export function useGetSuggestedSearchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSuggestedSearchesQuery, GetSuggestedSearchesQueryVariables>) {
          return Apollo.useLazyQuery<GetSuggestedSearchesQuery, GetSuggestedSearchesQueryVariables>(GetSuggestedSearchesDocument, baseOptions);
        }
export type GetSuggestedSearchesQueryHookResult = ReturnType<typeof useGetSuggestedSearchesQuery>;
export type GetSuggestedSearchesLazyQueryHookResult = ReturnType<typeof useGetSuggestedSearchesLazyQuery>;
export type GetSuggestedSearchesQueryResult = Apollo.QueryResult<GetSuggestedSearchesQuery, GetSuggestedSearchesQueryVariables>;
export const ProductDetailPagesDocument = gql`
    query productDetailPages($stockCodes: [String!]!) {
  productDetailPages(stockCodes: $stockCodes) {
    labels {
      ...Label
    }
    titleArea {
      stockCode
      hsnCode
      impaCode
    }
    stockArea {
      statusCode
      title
    }
    ppkArea {
      isVisible
      ppkStockCode
    }
    priceArea {
      container
      price {
        priceBefore {
          value
          displayValue
        }
        priceAfter {
          value
          displayValue
        }
      }
    }
  }
}
    ${LabelFragmentDoc}`;

/**
 * __useProductDetailPagesQuery__
 *
 * To run a query within a React component, call `useProductDetailPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductDetailPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductDetailPagesQuery({
 *   variables: {
 *      stockCodes: // value for 'stockCodes'
 *   },
 * });
 */
export function useProductDetailPagesQuery(baseOptions: Apollo.QueryHookOptions<ProductDetailPagesQuery, ProductDetailPagesQueryVariables>) {
        return Apollo.useQuery<ProductDetailPagesQuery, ProductDetailPagesQueryVariables>(ProductDetailPagesDocument, baseOptions);
      }
export function useProductDetailPagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductDetailPagesQuery, ProductDetailPagesQueryVariables>) {
          return Apollo.useLazyQuery<ProductDetailPagesQuery, ProductDetailPagesQueryVariables>(ProductDetailPagesDocument, baseOptions);
        }
export type ProductDetailPagesQueryHookResult = ReturnType<typeof useProductDetailPagesQuery>;
export type ProductDetailPagesLazyQueryHookResult = ReturnType<typeof useProductDetailPagesLazyQuery>;
export type ProductDetailPagesQueryResult = Apollo.QueryResult<ProductDetailPagesQuery, ProductDetailPagesQueryVariables>;
export const GetPricesDocument = gql`
    query getPrices($stockCode: String!) {
  productListPage {
    productPrices(stockCode: $stockCode) {
      quantity
      totalPrice {
        priceAfter {
          value
          displayValue
        }
      }
    }
  }
}
    `;

/**
 * __useGetPricesQuery__
 *
 * To run a query within a React component, call `useGetPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPricesQuery({
 *   variables: {
 *      stockCode: // value for 'stockCode'
 *   },
 * });
 */
export function useGetPricesQuery(baseOptions: Apollo.QueryHookOptions<GetPricesQuery, GetPricesQueryVariables>) {
        return Apollo.useQuery<GetPricesQuery, GetPricesQueryVariables>(GetPricesDocument, baseOptions);
      }
export function useGetPricesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPricesQuery, GetPricesQueryVariables>) {
          return Apollo.useLazyQuery<GetPricesQuery, GetPricesQueryVariables>(GetPricesDocument, baseOptions);
        }
export type GetPricesQueryHookResult = ReturnType<typeof useGetPricesQuery>;
export type GetPricesLazyQueryHookResult = ReturnType<typeof useGetPricesLazyQuery>;
export type GetPricesQueryResult = Apollo.QueryResult<GetPricesQuery, GetPricesQueryVariables>;
export const GetPricesByQtyDocument = gql`
    query getPricesByQty($stockCode: String!, $quantity: Int!) {
  productDetailPage(stockCode: $stockCode) {
    priceArea(quantity: $quantity) {
      errorMessage
      prices {
        totalPrice {
          priceAfter {
            displayValue
          }
        }
      }
      pricesExVat {
        totalPrice {
          priceAfter {
            displayValue
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetPricesByQtyQuery__
 *
 * To run a query within a React component, call `useGetPricesByQtyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPricesByQtyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPricesByQtyQuery({
 *   variables: {
 *      stockCode: // value for 'stockCode'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useGetPricesByQtyQuery(baseOptions: Apollo.QueryHookOptions<GetPricesByQtyQuery, GetPricesByQtyQueryVariables>) {
        return Apollo.useQuery<GetPricesByQtyQuery, GetPricesByQtyQueryVariables>(GetPricesByQtyDocument, baseOptions);
      }
export function useGetPricesByQtyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPricesByQtyQuery, GetPricesByQtyQueryVariables>) {
          return Apollo.useLazyQuery<GetPricesByQtyQuery, GetPricesByQtyQueryVariables>(GetPricesByQtyDocument, baseOptions);
        }
export type GetPricesByQtyQueryHookResult = ReturnType<typeof useGetPricesByQtyQuery>;
export type GetPricesByQtyLazyQueryHookResult = ReturnType<typeof useGetPricesByQtyLazyQuery>;
export type GetPricesByQtyQueryResult = Apollo.QueryResult<GetPricesByQtyQuery, GetPricesByQtyQueryVariables>;
export const ProductDetailPageDocument = gql`
    query productDetailPage($stockCode: String!) {
  productDetailPage(stockCode: $stockCode) {
    isSuccess
    pdpUrl
    metaData {
      pageTitle
      structuredData
      canonicalUrl
      metaTags {
        attributes {
          ...HtmlTagAttribute
        }
      }
      links {
        attributes {
          ...HtmlTagAttribute
        }
      }
    }
    tagging {
      gtm {
        dataLayer {
          innerHtml
        }
      }
      tags {
        ...HtmlTag
      }
    }
    config {
      showDesignSparkArea
    }
    breadcrumb {
      links {
        ...Link
      }
      bookId
    }
    labels {
      ...Label
    }
    allowAddToPartsList
    titleArea {
      stockCode
      formattedStockCode
      brand
      mpn
      hsnCode
      title
      impaCode
      distrelecProductId
      familyId
    }
    mediaArea {
      disclaimerMessage
      mediaUrl
      media {
        id
        url
        mediaType
        imageType
        name
        thumbnailUrl
      }
      showPCBFootprint
      pcbFootprintParameters {
        brand
        mpn
        langCode
      }
      vendorLogo {
        url
      }
      isZoomEnabled
    }
    ppkArea {
      isEnabled
      isVisible
      ppkSSM
      standardMinimumOrderQuantity
      minimumOrderQuantity
      standardUnitOfMeasure
      unitOfMeasure
      standardStockCode
      ppkStockCode
      title
      message
    }
    priceArea {
      ...Pricing
    }
    stockArea {
      ...PDPStock
    }
    productTypeArea {
      ...PDPProductType
      secondaryMessages {
        ...PDPProductType
      }
    }
    punchoutInfoArea {
      isBarred
      isRestricted
    }
    descriptionArea {
      descriptions {
        title
        description
      }
    }
    attributesArea {
      attributes {
        name
        value
        nameId
        valueId
      }
    }
    stickersArea {
      stickers {
        id
        content
        contentType
        position
      }
    }
    documentsArea(requestId: "") {
      documents {
        documentType
        title
        description
        url
        urlText
        tracePartsPartId
      }
    }
    alternativesRSPro {
      isVisible
      url
    }
    energyArea {
      energyLabel {
        ...ProductEnergyLabel
      }
    }
    calibrationArea {
      ...PDPCalibration
    }
    alternativeProducts {
      pdpUrl
      titleArea {
        brand
        stockCode
        title
      }
      priceArea {
        container
        price {
          priceAfter {
            displayValue
          }
        }
      }
      energyArea {
        energyLabel {
          ...ProductEnergyLabel
        }
      }
      mediaArea {
        mediaUrl
        media {
          id
          url
          mediaType
          imageType
          name
        }
      }
    }
  }
}
    ${HtmlTagAttributeFragmentDoc}
${HtmlTagFragmentDoc}
${LinkFragmentDoc}
${LabelFragmentDoc}
${PricingFragmentDoc}
${PdpStockFragmentDoc}
${PdpProductTypeFragmentDoc}
${ProductEnergyLabelFragmentDoc}
${PdpCalibrationFragmentDoc}`;

/**
 * __useProductDetailPageQuery__
 *
 * To run a query within a React component, call `useProductDetailPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductDetailPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductDetailPageQuery({
 *   variables: {
 *      stockCode: // value for 'stockCode'
 *   },
 * });
 */
export function useProductDetailPageQuery(baseOptions: Apollo.QueryHookOptions<ProductDetailPageQuery, ProductDetailPageQueryVariables>) {
        return Apollo.useQuery<ProductDetailPageQuery, ProductDetailPageQueryVariables>(ProductDetailPageDocument, baseOptions);
      }
export function useProductDetailPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductDetailPageQuery, ProductDetailPageQueryVariables>) {
          return Apollo.useLazyQuery<ProductDetailPageQuery, ProductDetailPageQueryVariables>(ProductDetailPageDocument, baseOptions);
        }
export type ProductDetailPageQueryHookResult = ReturnType<typeof useProductDetailPageQuery>;
export type ProductDetailPageLazyQueryHookResult = ReturnType<typeof useProductDetailPageLazyQuery>;
export type ProductDetailPageQueryResult = Apollo.QueryResult<ProductDetailPageQuery, ProductDetailPageQueryVariables>;
export const GetTechnicalDocumentsDocument = gql`
    query getTechnicalDocuments($stockCode: String!, $requestId: String!) {
  productDetailPage(stockCode: $stockCode) {
    documentsArea(requestId: $requestId) {
      documents {
        documentType
        title
        url
      }
    }
  }
}
    `;

/**
 * __useGetTechnicalDocumentsQuery__
 *
 * To run a query within a React component, call `useGetTechnicalDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTechnicalDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTechnicalDocumentsQuery({
 *   variables: {
 *      stockCode: // value for 'stockCode'
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useGetTechnicalDocumentsQuery(baseOptions: Apollo.QueryHookOptions<GetTechnicalDocumentsQuery, GetTechnicalDocumentsQueryVariables>) {
        return Apollo.useQuery<GetTechnicalDocumentsQuery, GetTechnicalDocumentsQueryVariables>(GetTechnicalDocumentsDocument, baseOptions);
      }
export function useGetTechnicalDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTechnicalDocumentsQuery, GetTechnicalDocumentsQueryVariables>) {
          return Apollo.useLazyQuery<GetTechnicalDocumentsQuery, GetTechnicalDocumentsQueryVariables>(GetTechnicalDocumentsDocument, baseOptions);
        }
export type GetTechnicalDocumentsQueryHookResult = ReturnType<typeof useGetTechnicalDocumentsQuery>;
export type GetTechnicalDocumentsLazyQueryHookResult = ReturnType<typeof useGetTechnicalDocumentsLazyQuery>;
export type GetTechnicalDocumentsQueryResult = Apollo.QueryResult<GetTechnicalDocumentsQuery, GetTechnicalDocumentsQueryVariables>;
export const UpdatePpkStockCodeDataDocument = gql`
    query updatePPKStockCodeData($stockCode: String!) {
  productDetailPage(stockCode: $stockCode) {
    titleArea {
      stockCode
      formattedStockCode
    }
    priceArea {
      ...Pricing
    }
    stockArea(queryStock: true) {
      ...PDPStock
    }
  }
}
    ${PricingFragmentDoc}
${PdpStockFragmentDoc}`;

/**
 * __useUpdatePpkStockCodeDataQuery__
 *
 * To run a query within a React component, call `useUpdatePpkStockCodeDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdatePpkStockCodeDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatePpkStockCodeDataQuery({
 *   variables: {
 *      stockCode: // value for 'stockCode'
 *   },
 * });
 */
export function useUpdatePpkStockCodeDataQuery(baseOptions: Apollo.QueryHookOptions<UpdatePpkStockCodeDataQuery, UpdatePpkStockCodeDataQueryVariables>) {
        return Apollo.useQuery<UpdatePpkStockCodeDataQuery, UpdatePpkStockCodeDataQueryVariables>(UpdatePpkStockCodeDataDocument, baseOptions);
      }
export function useUpdatePpkStockCodeDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdatePpkStockCodeDataQuery, UpdatePpkStockCodeDataQueryVariables>) {
          return Apollo.useLazyQuery<UpdatePpkStockCodeDataQuery, UpdatePpkStockCodeDataQueryVariables>(UpdatePpkStockCodeDataDocument, baseOptions);
        }
export type UpdatePpkStockCodeDataQueryHookResult = ReturnType<typeof useUpdatePpkStockCodeDataQuery>;
export type UpdatePpkStockCodeDataLazyQueryHookResult = ReturnType<typeof useUpdatePpkStockCodeDataLazyQuery>;
export type UpdatePpkStockCodeDataQueryResult = Apollo.QueryResult<UpdatePpkStockCodeDataQuery, UpdatePpkStockCodeDataQueryVariables>;
export const UpdateCalibrationStockCodeDataDocument = gql`
    query updateCalibrationStockCodeData($stockCode: String!) {
  productDetailPage(stockCode: $stockCode) {
    titleArea {
      title
      stockCode
      formattedStockCode
    }
    priceArea {
      ...Pricing
    }
    stockArea(queryStock: true) {
      ...PDPStock
    }
  }
}
    ${PricingFragmentDoc}
${PdpStockFragmentDoc}`;

/**
 * __useUpdateCalibrationStockCodeDataQuery__
 *
 * To run a query within a React component, call `useUpdateCalibrationStockCodeDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateCalibrationStockCodeDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateCalibrationStockCodeDataQuery({
 *   variables: {
 *      stockCode: // value for 'stockCode'
 *   },
 * });
 */
export function useUpdateCalibrationStockCodeDataQuery(baseOptions: Apollo.QueryHookOptions<UpdateCalibrationStockCodeDataQuery, UpdateCalibrationStockCodeDataQueryVariables>) {
        return Apollo.useQuery<UpdateCalibrationStockCodeDataQuery, UpdateCalibrationStockCodeDataQueryVariables>(UpdateCalibrationStockCodeDataDocument, baseOptions);
      }
export function useUpdateCalibrationStockCodeDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateCalibrationStockCodeDataQuery, UpdateCalibrationStockCodeDataQueryVariables>) {
          return Apollo.useLazyQuery<UpdateCalibrationStockCodeDataQuery, UpdateCalibrationStockCodeDataQueryVariables>(UpdateCalibrationStockCodeDataDocument, baseOptions);
        }
export type UpdateCalibrationStockCodeDataQueryHookResult = ReturnType<typeof useUpdateCalibrationStockCodeDataQuery>;
export type UpdateCalibrationStockCodeDataLazyQueryHookResult = ReturnType<typeof useUpdateCalibrationStockCodeDataLazyQuery>;
export type UpdateCalibrationStockCodeDataQueryResult = Apollo.QueryResult<UpdateCalibrationStockCodeDataQuery, UpdateCalibrationStockCodeDataQueryVariables>;
export const GetCadDocumentsDocument = gql`
    query getCadDocuments($stockCode: String!, $tracePartsPartId: String) {
  cadDocuments(stockCode: $stockCode, tracePartsPartId: $tracePartsPartId) {
    tracePartsData {
      documents {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetCadDocumentsQuery__
 *
 * To run a query within a React component, call `useGetCadDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCadDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCadDocumentsQuery({
 *   variables: {
 *      stockCode: // value for 'stockCode'
 *      tracePartsPartId: // value for 'tracePartsPartId'
 *   },
 * });
 */
export function useGetCadDocumentsQuery(baseOptions: Apollo.QueryHookOptions<GetCadDocumentsQuery, GetCadDocumentsQueryVariables>) {
        return Apollo.useQuery<GetCadDocumentsQuery, GetCadDocumentsQueryVariables>(GetCadDocumentsDocument, baseOptions);
      }
export function useGetCadDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCadDocumentsQuery, GetCadDocumentsQueryVariables>) {
          return Apollo.useLazyQuery<GetCadDocumentsQuery, GetCadDocumentsQueryVariables>(GetCadDocumentsDocument, baseOptions);
        }
export type GetCadDocumentsQueryHookResult = ReturnType<typeof useGetCadDocumentsQuery>;
export type GetCadDocumentsLazyQueryHookResult = ReturnType<typeof useGetCadDocumentsLazyQuery>;
export type GetCadDocumentsQueryResult = Apollo.QueryResult<GetCadDocumentsQuery, GetCadDocumentsQueryVariables>;
export const GetTracePartsDocumentUrlDocument = gql`
    query getTracePartsDocumentUrl($documentId: Float!, $stockCode: String!, $tracePartsPartId: String) {
  tracePartsDocumentUrl(
    documentId: $documentId
    stockCode: $stockCode
    tracePartsPartId: $tracePartsPartId
  )
}
    `;

/**
 * __useGetTracePartsDocumentUrlQuery__
 *
 * To run a query within a React component, call `useGetTracePartsDocumentUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTracePartsDocumentUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTracePartsDocumentUrlQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      stockCode: // value for 'stockCode'
 *      tracePartsPartId: // value for 'tracePartsPartId'
 *   },
 * });
 */
export function useGetTracePartsDocumentUrlQuery(baseOptions: Apollo.QueryHookOptions<GetTracePartsDocumentUrlQuery, GetTracePartsDocumentUrlQueryVariables>) {
        return Apollo.useQuery<GetTracePartsDocumentUrlQuery, GetTracePartsDocumentUrlQueryVariables>(GetTracePartsDocumentUrlDocument, baseOptions);
      }
export function useGetTracePartsDocumentUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTracePartsDocumentUrlQuery, GetTracePartsDocumentUrlQueryVariables>) {
          return Apollo.useLazyQuery<GetTracePartsDocumentUrlQuery, GetTracePartsDocumentUrlQueryVariables>(GetTracePartsDocumentUrlDocument, baseOptions);
        }
export type GetTracePartsDocumentUrlQueryHookResult = ReturnType<typeof useGetTracePartsDocumentUrlQuery>;
export type GetTracePartsDocumentUrlLazyQueryHookResult = ReturnType<typeof useGetTracePartsDocumentUrlLazyQuery>;
export type GetTracePartsDocumentUrlQueryResult = Apollo.QueryResult<GetTracePartsDocumentUrlQuery, GetTracePartsDocumentUrlQueryVariables>;
export const DataSheetsDocument = gql`
    query dataSheets($stockCode: String!, $documentsType: String!) {
  dataSheets(stockCode: $stockCode, documentsType: $documentsType) {
    documentType
    title
    url
  }
}
    `;

/**
 * __useDataSheetsQuery__
 *
 * To run a query within a React component, call `useDataSheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataSheetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataSheetsQuery({
 *   variables: {
 *      stockCode: // value for 'stockCode'
 *      documentsType: // value for 'documentsType'
 *   },
 * });
 */
export function useDataSheetsQuery(baseOptions: Apollo.QueryHookOptions<DataSheetsQuery, DataSheetsQueryVariables>) {
        return Apollo.useQuery<DataSheetsQuery, DataSheetsQueryVariables>(DataSheetsDocument, baseOptions);
      }
export function useDataSheetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataSheetsQuery, DataSheetsQueryVariables>) {
          return Apollo.useLazyQuery<DataSheetsQuery, DataSheetsQueryVariables>(DataSheetsDocument, baseOptions);
        }
export type DataSheetsQueryHookResult = ReturnType<typeof useDataSheetsQuery>;
export type DataSheetsLazyQueryHookResult = ReturnType<typeof useDataSheetsLazyQuery>;
export type DataSheetsQueryResult = Apollo.QueryResult<DataSheetsQuery, DataSheetsQueryVariables>;
export const TechnicalDocumentsLoadedDocument = gql`
    subscription technicalDocumentsLoaded($stockCode: String!, $requestId: String!) {
  technicalDocumentLoaded(stockCode: $stockCode, requestId: $requestId) {
    documents {
      documentType
      title
      url
    }
  }
}
    `;

/**
 * __useTechnicalDocumentsLoadedSubscription__
 *
 * To run a query within a React component, call `useTechnicalDocumentsLoadedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTechnicalDocumentsLoadedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTechnicalDocumentsLoadedSubscription({
 *   variables: {
 *      stockCode: // value for 'stockCode'
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useTechnicalDocumentsLoadedSubscription(baseOptions: Apollo.SubscriptionHookOptions<TechnicalDocumentsLoadedSubscription, TechnicalDocumentsLoadedSubscriptionVariables>) {
        return Apollo.useSubscription<TechnicalDocumentsLoadedSubscription, TechnicalDocumentsLoadedSubscriptionVariables>(TechnicalDocumentsLoadedDocument, baseOptions);
      }
export type TechnicalDocumentsLoadedSubscriptionHookResult = ReturnType<typeof useTechnicalDocumentsLoadedSubscription>;
export type TechnicalDocumentsLoadedSubscriptionResult = Apollo.SubscriptionResult<TechnicalDocumentsLoadedSubscription>;
export const ProductDetailPageStockDocument = gql`
    query productDetailPageStock($stockCode: String!) {
  productDetailPage(stockCode: $stockCode) {
    stockArea(queryStock: true) {
      ...PDPStock
    }
  }
}
    ${PdpStockFragmentDoc}`;

/**
 * __useProductDetailPageStockQuery__
 *
 * To run a query within a React component, call `useProductDetailPageStockQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductDetailPageStockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductDetailPageStockQuery({
 *   variables: {
 *      stockCode: // value for 'stockCode'
 *   },
 * });
 */
export function useProductDetailPageStockQuery(baseOptions: Apollo.QueryHookOptions<ProductDetailPageStockQuery, ProductDetailPageStockQueryVariables>) {
        return Apollo.useQuery<ProductDetailPageStockQuery, ProductDetailPageStockQueryVariables>(ProductDetailPageStockDocument, baseOptions);
      }
export function useProductDetailPageStockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductDetailPageStockQuery, ProductDetailPageStockQueryVariables>) {
          return Apollo.useLazyQuery<ProductDetailPageStockQuery, ProductDetailPageStockQueryVariables>(ProductDetailPageStockDocument, baseOptions);
        }
export type ProductDetailPageStockQueryHookResult = ReturnType<typeof useProductDetailPageStockQuery>;
export type ProductDetailPageStockLazyQueryHookResult = ReturnType<typeof useProductDetailPageStockLazyQuery>;
export type ProductDetailPageStockQueryResult = Apollo.QueryResult<ProductDetailPageStockQuery, ProductDetailPageStockQueryVariables>;
export const CheckPcbAvailabilityDocument = gql`
    query checkPCBAvailability($manufacturer: String!, $partNumber: String!) {
  checkPCBAvailability(manufacturer: $manufacturer, partNumber: $partNumber) {
    isPCBAvailable
  }
}
    `;

/**
 * __useCheckPcbAvailabilityQuery__
 *
 * To run a query within a React component, call `useCheckPcbAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckPcbAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckPcbAvailabilityQuery({
 *   variables: {
 *      manufacturer: // value for 'manufacturer'
 *      partNumber: // value for 'partNumber'
 *   },
 * });
 */
export function useCheckPcbAvailabilityQuery(baseOptions: Apollo.QueryHookOptions<CheckPcbAvailabilityQuery, CheckPcbAvailabilityQueryVariables>) {
        return Apollo.useQuery<CheckPcbAvailabilityQuery, CheckPcbAvailabilityQueryVariables>(CheckPcbAvailabilityDocument, baseOptions);
      }
export function useCheckPcbAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckPcbAvailabilityQuery, CheckPcbAvailabilityQueryVariables>) {
          return Apollo.useLazyQuery<CheckPcbAvailabilityQuery, CheckPcbAvailabilityQueryVariables>(CheckPcbAvailabilityDocument, baseOptions);
        }
export type CheckPcbAvailabilityQueryHookResult = ReturnType<typeof useCheckPcbAvailabilityQuery>;
export type CheckPcbAvailabilityLazyQueryHookResult = ReturnType<typeof useCheckPcbAvailabilityLazyQuery>;
export type CheckPcbAvailabilityQueryResult = Apollo.QueryResult<CheckPcbAvailabilityQuery, CheckPcbAvailabilityQueryVariables>;
export const ProductListPageDocument = gql`
    query productListPage($slug: String, $tagName: String, $searchQuery: String, $id: Int) {
  productListPage(
    slug: $slug
    searchQuery: $searchQuery
    tagName: $tagName
    id: $id
  ) {
    isSuccess
    redirectUrl
    categoryId
    title
    description
    metaData {
      pageTitle
      canonicalUrl
      metaTags {
        attributes {
          ...HtmlTagAttribute
        }
      }
      links {
        attributes {
          ...HtmlTagAttribute
        }
      }
    }
    tagging {
      gtm {
        dataLayer {
          innerHtml
        }
      }
      tags {
        ...HtmlTag
      }
    }
    labels {
      ...Label
    }
    breadcrumb {
      ...Link
    }
    productsArea {
      ...PLPProducts
    }
    facetsArea {
      ...Facets
      appliedFilters {
        ...Facet
      }
      appliedFlagFilters {
        ...FacetValue
      }
    }
    categoriesArea {
      ...PLPCategoryItem
    }
    summary {
      totalProducts
    }
    config {
      showYourPrice
      isLoggedIn
      queryStock
    }
  }
}
    ${HtmlTagAttributeFragmentDoc}
${HtmlTagFragmentDoc}
${LabelFragmentDoc}
${LinkFragmentDoc}
${PlpProductsFragmentDoc}
${FacetsFragmentDoc}
${FacetFragmentDoc}
${FacetValueFragmentDoc}
${PlpCategoryItemFragmentDoc}`;

/**
 * __useProductListPageQuery__
 *
 * To run a query within a React component, call `useProductListPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductListPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductListPageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      tagName: // value for 'tagName'
 *      searchQuery: // value for 'searchQuery'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductListPageQuery(baseOptions?: Apollo.QueryHookOptions<ProductListPageQuery, ProductListPageQueryVariables>) {
        return Apollo.useQuery<ProductListPageQuery, ProductListPageQueryVariables>(ProductListPageDocument, baseOptions);
      }
export function useProductListPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductListPageQuery, ProductListPageQueryVariables>) {
          return Apollo.useLazyQuery<ProductListPageQuery, ProductListPageQueryVariables>(ProductListPageDocument, baseOptions);
        }
export type ProductListPageQueryHookResult = ReturnType<typeof useProductListPageQuery>;
export type ProductListPageLazyQueryHookResult = ReturnType<typeof useProductListPageLazyQuery>;
export type ProductListPageQueryResult = Apollo.QueryResult<ProductListPageQuery, ProductListPageQueryVariables>;
export const PreviewFiltersDocument = gql`
    query previewFilters($id: Int, $searchQuery: String, $tagName: String, $selector: SearchSelector) {
  productListPage(
    id: $id
    searchQuery: $searchQuery
    tagName: $tagName
    selector: $selector
  ) {
    facetsArea {
      ...Facets
    }
    summary {
      totalProducts
    }
  }
}
    ${FacetsFragmentDoc}`;

/**
 * __usePreviewFiltersQuery__
 *
 * To run a query within a React component, call `usePreviewFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewFiltersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      searchQuery: // value for 'searchQuery'
 *      tagName: // value for 'tagName'
 *      selector: // value for 'selector'
 *   },
 * });
 */
export function usePreviewFiltersQuery(baseOptions?: Apollo.QueryHookOptions<PreviewFiltersQuery, PreviewFiltersQueryVariables>) {
        return Apollo.useQuery<PreviewFiltersQuery, PreviewFiltersQueryVariables>(PreviewFiltersDocument, baseOptions);
      }
export function usePreviewFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviewFiltersQuery, PreviewFiltersQueryVariables>) {
          return Apollo.useLazyQuery<PreviewFiltersQuery, PreviewFiltersQueryVariables>(PreviewFiltersDocument, baseOptions);
        }
export type PreviewFiltersQueryHookResult = ReturnType<typeof usePreviewFiltersQuery>;
export type PreviewFiltersLazyQueryHookResult = ReturnType<typeof usePreviewFiltersLazyQuery>;
export type PreviewFiltersQueryResult = Apollo.QueryResult<PreviewFiltersQuery, PreviewFiltersQueryVariables>;
export const PreviewFiltersTotalProductsDocument = gql`
    query previewFiltersTotalProducts($slug: String, $selector: SearchSelector) {
  productListPage(slug: $slug, selector: $selector) {
    summary {
      totalProducts
    }
  }
}
    `;

/**
 * __usePreviewFiltersTotalProductsQuery__
 *
 * To run a query within a React component, call `usePreviewFiltersTotalProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewFiltersTotalProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewFiltersTotalProductsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      selector: // value for 'selector'
 *   },
 * });
 */
export function usePreviewFiltersTotalProductsQuery(baseOptions?: Apollo.QueryHookOptions<PreviewFiltersTotalProductsQuery, PreviewFiltersTotalProductsQueryVariables>) {
        return Apollo.useQuery<PreviewFiltersTotalProductsQuery, PreviewFiltersTotalProductsQueryVariables>(PreviewFiltersTotalProductsDocument, baseOptions);
      }
export function usePreviewFiltersTotalProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviewFiltersTotalProductsQuery, PreviewFiltersTotalProductsQueryVariables>) {
          return Apollo.useLazyQuery<PreviewFiltersTotalProductsQuery, PreviewFiltersTotalProductsQueryVariables>(PreviewFiltersTotalProductsDocument, baseOptions);
        }
export type PreviewFiltersTotalProductsQueryHookResult = ReturnType<typeof usePreviewFiltersTotalProductsQuery>;
export type PreviewFiltersTotalProductsLazyQueryHookResult = ReturnType<typeof usePreviewFiltersTotalProductsLazyQuery>;
export type PreviewFiltersTotalProductsQueryResult = Apollo.QueryResult<PreviewFiltersTotalProductsQuery, PreviewFiltersTotalProductsQueryVariables>;
export const FilterProductsDocument = gql`
    query filterProducts($id: Int, $searchQuery: String, $tagName: String, $first: Int, $selector: SearchSelector, $sort: SearchSort) {
  productListPage(
    id: $id
    searchQuery: $searchQuery
    tagName: $tagName
    first: $first
    selector: $selector
    sort: $sort
  ) {
    productsArea {
      ...PLPProducts
    }
    facetsArea {
      appliedFilters {
        ...Facet
      }
      appliedFlagFilters {
        ...FacetValue
      }
    }
    categoriesArea {
      ...PLPCategoryItem
    }
    summary {
      totalProducts
    }
  }
}
    ${PlpProductsFragmentDoc}
${FacetFragmentDoc}
${FacetValueFragmentDoc}
${PlpCategoryItemFragmentDoc}`;

/**
 * __useFilterProductsQuery__
 *
 * To run a query within a React component, call `useFilterProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterProductsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      searchQuery: // value for 'searchQuery'
 *      tagName: // value for 'tagName'
 *      first: // value for 'first'
 *      selector: // value for 'selector'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useFilterProductsQuery(baseOptions?: Apollo.QueryHookOptions<FilterProductsQuery, FilterProductsQueryVariables>) {
        return Apollo.useQuery<FilterProductsQuery, FilterProductsQueryVariables>(FilterProductsDocument, baseOptions);
      }
export function useFilterProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilterProductsQuery, FilterProductsQueryVariables>) {
          return Apollo.useLazyQuery<FilterProductsQuery, FilterProductsQueryVariables>(FilterProductsDocument, baseOptions);
        }
export type FilterProductsQueryHookResult = ReturnType<typeof useFilterProductsQuery>;
export type FilterProductsLazyQueryHookResult = ReturnType<typeof useFilterProductsLazyQuery>;
export type FilterProductsQueryResult = Apollo.QueryResult<FilterProductsQuery, FilterProductsQueryVariables>;
export const FilterProductsAndFacetsDocument = gql`
    query filterProductsAndFacets($id: Int, $searchQuery: String, $tagName: String, $first: Int, $offset: Int, $selector: SearchSelector, $sort: SearchSort) {
  productListPage(
    id: $id
    searchQuery: $searchQuery
    tagName: $tagName
    first: $first
    offset: $offset
    selector: $selector
    sort: $sort
  ) {
    productsArea {
      ...PLPProducts
    }
    facetsArea {
      ...Facets
      appliedFilters {
        ...Facet
      }
      appliedFlagFilters {
        ...FacetValue
      }
    }
    categoriesArea {
      ...PLPCategoryItem
    }
    summary {
      totalProducts
    }
  }
}
    ${PlpProductsFragmentDoc}
${FacetsFragmentDoc}
${FacetFragmentDoc}
${FacetValueFragmentDoc}
${PlpCategoryItemFragmentDoc}`;

/**
 * __useFilterProductsAndFacetsQuery__
 *
 * To run a query within a React component, call `useFilterProductsAndFacetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterProductsAndFacetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterProductsAndFacetsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      searchQuery: // value for 'searchQuery'
 *      tagName: // value for 'tagName'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      selector: // value for 'selector'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useFilterProductsAndFacetsQuery(baseOptions?: Apollo.QueryHookOptions<FilterProductsAndFacetsQuery, FilterProductsAndFacetsQueryVariables>) {
        return Apollo.useQuery<FilterProductsAndFacetsQuery, FilterProductsAndFacetsQueryVariables>(FilterProductsAndFacetsDocument, baseOptions);
      }
export function useFilterProductsAndFacetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilterProductsAndFacetsQuery, FilterProductsAndFacetsQueryVariables>) {
          return Apollo.useLazyQuery<FilterProductsAndFacetsQuery, FilterProductsAndFacetsQueryVariables>(FilterProductsAndFacetsDocument, baseOptions);
        }
export type FilterProductsAndFacetsQueryHookResult = ReturnType<typeof useFilterProductsAndFacetsQuery>;
export type FilterProductsAndFacetsLazyQueryHookResult = ReturnType<typeof useFilterProductsAndFacetsLazyQuery>;
export type FilterProductsAndFacetsQueryResult = Apollo.QueryResult<FilterProductsAndFacetsQuery, FilterProductsAndFacetsQueryVariables>;
export const GetProductsDocument = gql`
    query getProducts($id: Int, $searchQuery: String, $tagName: String, $first: Int, $offset: Int, $selector: SearchSelector, $sort: SearchSort) {
  productListPage(
    id: $id
    searchQuery: $searchQuery
    tagName: $tagName
    first: $first
    offset: $offset
    selector: $selector
    sort: $sort
  ) {
    productsArea {
      ...PLPProducts
    }
  }
}
    ${PlpProductsFragmentDoc}`;

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      searchQuery: // value for 'searchQuery'
 *      tagName: // value for 'tagName'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      selector: // value for 'selector'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetProductsQuery(baseOptions?: Apollo.QueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
        return Apollo.useQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, baseOptions);
      }
export function useGetProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
          return Apollo.useLazyQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, baseOptions);
        }
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>;
export type GetProductsLazyQueryHookResult = ReturnType<typeof useGetProductsLazyQuery>;
export type GetProductsQueryResult = Apollo.QueryResult<GetProductsQuery, GetProductsQueryVariables>;
export const GetPlpStockDocument = gql`
    query getPLPStock($id: Int, $searchQuery: String, $tagName: String, $first: Int, $offset: Int, $selector: SearchSelector, $sort: SearchSort) {
  productListPage(
    id: $id
    searchQuery: $searchQuery
    tagName: $tagName
    first: $first
    offset: $offset
    selector: $selector
    sort: $sort
  ) {
    productsArea(queryStock: true) {
      products {
        title {
          stockCode
        }
        stockArea {
          statusCode
          title
          stockMessage
          dtpStockMessages
          infoMessage
          isUsingDTP
          denyBackOrder
          availabilityDate
          stockAvailability {
            quantityInStock
            availabilityDate
          }
          includesDate
          stockBadgeIcon
        }
      }
    }
  }
}
    `;

/**
 * __useGetPlpStockQuery__
 *
 * To run a query within a React component, call `useGetPlpStockQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlpStockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlpStockQuery({
 *   variables: {
 *      id: // value for 'id'
 *      searchQuery: // value for 'searchQuery'
 *      tagName: // value for 'tagName'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      selector: // value for 'selector'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetPlpStockQuery(baseOptions?: Apollo.QueryHookOptions<GetPlpStockQuery, GetPlpStockQueryVariables>) {
        return Apollo.useQuery<GetPlpStockQuery, GetPlpStockQueryVariables>(GetPlpStockDocument, baseOptions);
      }
export function useGetPlpStockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlpStockQuery, GetPlpStockQueryVariables>) {
          return Apollo.useLazyQuery<GetPlpStockQuery, GetPlpStockQueryVariables>(GetPlpStockDocument, baseOptions);
        }
export type GetPlpStockQueryHookResult = ReturnType<typeof useGetPlpStockQuery>;
export type GetPlpStockLazyQueryHookResult = ReturnType<typeof useGetPlpStockLazyQuery>;
export type GetPlpStockQueryResult = Apollo.QueryResult<GetPlpStockQuery, GetPlpStockQueryVariables>;
export const PurchasePageDocument = gql`
    query purchasePage {
  purchasePage {
    isSuccess
    isQuote
    labels {
      ...Label
    }
    summary {
      ...Summary
    }
    contactDetails {
      firstName
      lastName
      email
      phoneNo
      isValid
      accountType
      taxRegistrationNumber
    }
    orderDetails {
      orderReference
      lines {
        stockCode
        title
        quantity
      }
    }
    deliveryDetails {
      country {
        name
        code
      }
      deliveryCountries {
        options {
          name
          code
        }
      }
      address {
        uniqueId
        details {
          ...AddressFields
        }
        isValid
      }
      endDeliveryCountries {
        options {
          name
          code
        }
      }
    }
    billingDetails {
      countries {
        name
        code
      }
      selectedCountry {
        name
        code
      }
      address {
        uniqueId
        details {
          ...AddressFields
        }
        isValid
      }
    }
    paymentOptions {
      options {
        id
        name
        description
        isSelected
        optionType
        details {
          ... on PurchasePagePaymentOption_Account {
            accountNumberLabel
            soldToNumber
          }
          ... on PurchasePagePaymentOption_ControlledPurchasing {
            purchasers {
              id
              firstName
              lastName
              emailAddress
            }
          }
        }
        callbackUrl
        callbackMode
      }
    }
    config {
      paymentMethods
      userMayNotHavePanCardNumber
    }
    tagging {
      gtm {
        dataLayer {
          innerHtml
        }
      }
      tags {
        ...HtmlTag
      }
      ga4 {
        ... on GA4Event_AddPaymentInfo {
          event
          ecommerce {
            currency
            value
            coupon
            payment_type
            items {
              ...GA4Item
            }
            email
          }
        }
      }
    }
  }
}
    ${LabelFragmentDoc}
${SummaryFragmentDoc}
${AddressFieldsFragmentDoc}
${HtmlTagFragmentDoc}
${Ga4ItemFragmentDoc}`;

/**
 * __usePurchasePageQuery__
 *
 * To run a query within a React component, call `usePurchasePageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchasePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchasePageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePurchasePageQuery(baseOptions?: Apollo.QueryHookOptions<PurchasePageQuery, PurchasePageQueryVariables>) {
        return Apollo.useQuery<PurchasePageQuery, PurchasePageQueryVariables>(PurchasePageDocument, baseOptions);
      }
export function usePurchasePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PurchasePageQuery, PurchasePageQueryVariables>) {
          return Apollo.useLazyQuery<PurchasePageQuery, PurchasePageQueryVariables>(PurchasePageDocument, baseOptions);
        }
export type PurchasePageQueryHookResult = ReturnType<typeof usePurchasePageQuery>;
export type PurchasePageLazyQueryHookResult = ReturnType<typeof usePurchasePageLazyQuery>;
export type PurchasePageQueryResult = Apollo.QueryResult<PurchasePageQuery, PurchasePageQueryVariables>;
export const SaveContactDetailsDocument = gql`
    mutation saveContactDetails($data: Purchase_SaveContactDetailsRequest!) {
  saveContactDetails(data: $data) {
    isSuccess
    errorMessage
  }
}
    `;
export type SaveContactDetailsMutationFn = Apollo.MutationFunction<SaveContactDetailsMutation, SaveContactDetailsMutationVariables>;

/**
 * __useSaveContactDetailsMutation__
 *
 * To run a mutation, you first call `useSaveContactDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveContactDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveContactDetailsMutation, { data, loading, error }] = useSaveContactDetailsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSaveContactDetailsMutation(baseOptions?: Apollo.MutationHookOptions<SaveContactDetailsMutation, SaveContactDetailsMutationVariables>) {
        return Apollo.useMutation<SaveContactDetailsMutation, SaveContactDetailsMutationVariables>(SaveContactDetailsDocument, baseOptions);
      }
export type SaveContactDetailsMutationHookResult = ReturnType<typeof useSaveContactDetailsMutation>;
export type SaveContactDetailsMutationResult = Apollo.MutationResult<SaveContactDetailsMutation>;
export type SaveContactDetailsMutationOptions = Apollo.BaseMutationOptions<SaveContactDetailsMutation, SaveContactDetailsMutationVariables>;
export const ContactDetailsDocument = gql`
    query contactDetails {
  purchasePage {
    contactDetails {
      firstName
      lastName
      email
      phoneNo
      isValid
      accountType
      taxRegistrationNumber
    }
  }
}
    `;

/**
 * __useContactDetailsQuery__
 *
 * To run a query within a React component, call `useContactDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useContactDetailsQuery(baseOptions?: Apollo.QueryHookOptions<ContactDetailsQuery, ContactDetailsQueryVariables>) {
        return Apollo.useQuery<ContactDetailsQuery, ContactDetailsQueryVariables>(ContactDetailsDocument, baseOptions);
      }
export function useContactDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactDetailsQuery, ContactDetailsQueryVariables>) {
          return Apollo.useLazyQuery<ContactDetailsQuery, ContactDetailsQueryVariables>(ContactDetailsDocument, baseOptions);
        }
export type ContactDetailsQueryHookResult = ReturnType<typeof useContactDetailsQuery>;
export type ContactDetailsLazyQueryHookResult = ReturnType<typeof useContactDetailsLazyQuery>;
export type ContactDetailsQueryResult = Apollo.QueryResult<ContactDetailsQuery, ContactDetailsQueryVariables>;
export const CountryAddressConfigurationDocument = gql`
    query countryAddressConfiguration($countryCode: String!) {
  countryAddressConfiguration(countryCode: $countryCode) {
    states {
      code
      name
      requireTaxExemptionCertificate
      tinNumber
    }
    addressDetails {
      showPostCode
      requireEORI
      validationRegexPostCode
      formatPostCode
      errorMessagePostCode
      deliveryAddress {
        showVatNo
        requireVatNo
      }
    }
  }
}
    `;

/**
 * __useCountryAddressConfigurationQuery__
 *
 * To run a query within a React component, call `useCountryAddressConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountryAddressConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountryAddressConfigurationQuery({
 *   variables: {
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useCountryAddressConfigurationQuery(baseOptions: Apollo.QueryHookOptions<CountryAddressConfigurationQuery, CountryAddressConfigurationQueryVariables>) {
        return Apollo.useQuery<CountryAddressConfigurationQuery, CountryAddressConfigurationQueryVariables>(CountryAddressConfigurationDocument, baseOptions);
      }
export function useCountryAddressConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountryAddressConfigurationQuery, CountryAddressConfigurationQueryVariables>) {
          return Apollo.useLazyQuery<CountryAddressConfigurationQuery, CountryAddressConfigurationQueryVariables>(CountryAddressConfigurationDocument, baseOptions);
        }
export type CountryAddressConfigurationQueryHookResult = ReturnType<typeof useCountryAddressConfigurationQuery>;
export type CountryAddressConfigurationLazyQueryHookResult = ReturnType<typeof useCountryAddressConfigurationLazyQuery>;
export type CountryAddressConfigurationQueryResult = Apollo.QueryResult<CountryAddressConfigurationQuery, CountryAddressConfigurationQueryVariables>;
export const AddressListV2Document = gql`
    query addressListV2($input: AddressListRequest!) {
  addressListV2(input: $input) {
    addresses {
      uniqueId
      details {
        ...AddressFields
      }
      isValid
    }
  }
}
    ${AddressFieldsFragmentDoc}`;

/**
 * __useAddressListV2Query__
 *
 * To run a query within a React component, call `useAddressListV2Query` and pass it any options that fit your needs.
 * When your component renders, `useAddressListV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressListV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddressListV2Query(baseOptions: Apollo.QueryHookOptions<AddressListV2Query, AddressListV2QueryVariables>) {
        return Apollo.useQuery<AddressListV2Query, AddressListV2QueryVariables>(AddressListV2Document, baseOptions);
      }
export function useAddressListV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddressListV2Query, AddressListV2QueryVariables>) {
          return Apollo.useLazyQuery<AddressListV2Query, AddressListV2QueryVariables>(AddressListV2Document, baseOptions);
        }
export type AddressListV2QueryHookResult = ReturnType<typeof useAddressListV2Query>;
export type AddressListV2LazyQueryHookResult = ReturnType<typeof useAddressListV2LazyQuery>;
export type AddressListV2QueryResult = Apollo.QueryResult<AddressListV2Query, AddressListV2QueryVariables>;
export const SetBasketDeliveryAddressDocument = gql`
    mutation setBasketDeliveryAddress($input: Purchase_SetBasketAddressRequest!) {
  setBasketDeliveryAddress(input: $input) {
    isSuccess
    errorMessage
    isAddressValid
  }
}
    `;
export type SetBasketDeliveryAddressMutationFn = Apollo.MutationFunction<SetBasketDeliveryAddressMutation, SetBasketDeliveryAddressMutationVariables>;

/**
 * __useSetBasketDeliveryAddressMutation__
 *
 * To run a mutation, you first call `useSetBasketDeliveryAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBasketDeliveryAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBasketDeliveryAddressMutation, { data, loading, error }] = useSetBasketDeliveryAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetBasketDeliveryAddressMutation(baseOptions?: Apollo.MutationHookOptions<SetBasketDeliveryAddressMutation, SetBasketDeliveryAddressMutationVariables>) {
        return Apollo.useMutation<SetBasketDeliveryAddressMutation, SetBasketDeliveryAddressMutationVariables>(SetBasketDeliveryAddressDocument, baseOptions);
      }
export type SetBasketDeliveryAddressMutationHookResult = ReturnType<typeof useSetBasketDeliveryAddressMutation>;
export type SetBasketDeliveryAddressMutationResult = Apollo.MutationResult<SetBasketDeliveryAddressMutation>;
export type SetBasketDeliveryAddressMutationOptions = Apollo.BaseMutationOptions<SetBasketDeliveryAddressMutation, SetBasketDeliveryAddressMutationVariables>;
export const SetBasketBillingAddressDocument = gql`
    mutation setBasketBillingAddress($input: Purchase_SetBasketAddressRequest!) {
  setBasketBillingAddress(input: $input) {
    isSuccess
    errorMessage
    isAddressValid
  }
}
    `;
export type SetBasketBillingAddressMutationFn = Apollo.MutationFunction<SetBasketBillingAddressMutation, SetBasketBillingAddressMutationVariables>;

/**
 * __useSetBasketBillingAddressMutation__
 *
 * To run a mutation, you first call `useSetBasketBillingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBasketBillingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBasketBillingAddressMutation, { data, loading, error }] = useSetBasketBillingAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetBasketBillingAddressMutation(baseOptions?: Apollo.MutationHookOptions<SetBasketBillingAddressMutation, SetBasketBillingAddressMutationVariables>) {
        return Apollo.useMutation<SetBasketBillingAddressMutation, SetBasketBillingAddressMutationVariables>(SetBasketBillingAddressDocument, baseOptions);
      }
export type SetBasketBillingAddressMutationHookResult = ReturnType<typeof useSetBasketBillingAddressMutation>;
export type SetBasketBillingAddressMutationResult = Apollo.MutationResult<SetBasketBillingAddressMutation>;
export type SetBasketBillingAddressMutationOptions = Apollo.BaseMutationOptions<SetBasketBillingAddressMutation, SetBasketBillingAddressMutationVariables>;
export const SaveDeliveryAddressDocument = gql`
    mutation saveDeliveryAddress($input: SaveAddressRequest!) {
  saveDeliveryAddress(input: $input) {
    isSuccess
    errorMessage
    addressUniqueId
    fieldErrors
  }
}
    `;
export type SaveDeliveryAddressMutationFn = Apollo.MutationFunction<SaveDeliveryAddressMutation, SaveDeliveryAddressMutationVariables>;

/**
 * __useSaveDeliveryAddressMutation__
 *
 * To run a mutation, you first call `useSaveDeliveryAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDeliveryAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDeliveryAddressMutation, { data, loading, error }] = useSaveDeliveryAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveDeliveryAddressMutation(baseOptions?: Apollo.MutationHookOptions<SaveDeliveryAddressMutation, SaveDeliveryAddressMutationVariables>) {
        return Apollo.useMutation<SaveDeliveryAddressMutation, SaveDeliveryAddressMutationVariables>(SaveDeliveryAddressDocument, baseOptions);
      }
export type SaveDeliveryAddressMutationHookResult = ReturnType<typeof useSaveDeliveryAddressMutation>;
export type SaveDeliveryAddressMutationResult = Apollo.MutationResult<SaveDeliveryAddressMutation>;
export type SaveDeliveryAddressMutationOptions = Apollo.BaseMutationOptions<SaveDeliveryAddressMutation, SaveDeliveryAddressMutationVariables>;
export const SaveBillingAddressDocument = gql`
    mutation saveBillingAddress($input: SaveAddressRequest!) {
  saveBillingAddress(input: $input) {
    isSuccess
    errorMessage
    addressUniqueId
    fieldErrors
  }
}
    `;
export type SaveBillingAddressMutationFn = Apollo.MutationFunction<SaveBillingAddressMutation, SaveBillingAddressMutationVariables>;

/**
 * __useSaveBillingAddressMutation__
 *
 * To run a mutation, you first call `useSaveBillingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveBillingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveBillingAddressMutation, { data, loading, error }] = useSaveBillingAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveBillingAddressMutation(baseOptions?: Apollo.MutationHookOptions<SaveBillingAddressMutation, SaveBillingAddressMutationVariables>) {
        return Apollo.useMutation<SaveBillingAddressMutation, SaveBillingAddressMutationVariables>(SaveBillingAddressDocument, baseOptions);
      }
export type SaveBillingAddressMutationHookResult = ReturnType<typeof useSaveBillingAddressMutation>;
export type SaveBillingAddressMutationResult = Apollo.MutationResult<SaveBillingAddressMutation>;
export type SaveBillingAddressMutationOptions = Apollo.BaseMutationOptions<SaveBillingAddressMutation, SaveBillingAddressMutationVariables>;
export const SetBasketPaymentOptionDocument = gql`
    mutation setBasketPaymentOption($input: Purchase_SetBasketPaymentOptionRequest!) {
  setBasketPaymentOption(input: $input) {
    isSuccess
    errorMessage
  }
}
    `;
export type SetBasketPaymentOptionMutationFn = Apollo.MutationFunction<SetBasketPaymentOptionMutation, SetBasketPaymentOptionMutationVariables>;

/**
 * __useSetBasketPaymentOptionMutation__
 *
 * To run a mutation, you first call `useSetBasketPaymentOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBasketPaymentOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBasketPaymentOptionMutation, { data, loading, error }] = useSetBasketPaymentOptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetBasketPaymentOptionMutation(baseOptions?: Apollo.MutationHookOptions<SetBasketPaymentOptionMutation, SetBasketPaymentOptionMutationVariables>) {
        return Apollo.useMutation<SetBasketPaymentOptionMutation, SetBasketPaymentOptionMutationVariables>(SetBasketPaymentOptionDocument, baseOptions);
      }
export type SetBasketPaymentOptionMutationHookResult = ReturnType<typeof useSetBasketPaymentOptionMutation>;
export type SetBasketPaymentOptionMutationResult = Apollo.MutationResult<SetBasketPaymentOptionMutation>;
export type SetBasketPaymentOptionMutationOptions = Apollo.BaseMutationOptions<SetBasketPaymentOptionMutation, SetBasketPaymentOptionMutationVariables>;
export const ConfirmBasketAsOrderDocument = gql`
    mutation confirmBasketAsOrder($input: Purchase_ConfirmOrderRequest!) {
  confirmBasketAsOrder(input: $input) {
    isSuccess
  }
}
    `;
export type ConfirmBasketAsOrderMutationFn = Apollo.MutationFunction<ConfirmBasketAsOrderMutation, ConfirmBasketAsOrderMutationVariables>;

/**
 * __useConfirmBasketAsOrderMutation__
 *
 * To run a mutation, you first call `useConfirmBasketAsOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmBasketAsOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmBasketAsOrderMutation, { data, loading, error }] = useConfirmBasketAsOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmBasketAsOrderMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmBasketAsOrderMutation, ConfirmBasketAsOrderMutationVariables>) {
        return Apollo.useMutation<ConfirmBasketAsOrderMutation, ConfirmBasketAsOrderMutationVariables>(ConfirmBasketAsOrderDocument, baseOptions);
      }
export type ConfirmBasketAsOrderMutationHookResult = ReturnType<typeof useConfirmBasketAsOrderMutation>;
export type ConfirmBasketAsOrderMutationResult = Apollo.MutationResult<ConfirmBasketAsOrderMutation>;
export type ConfirmBasketAsOrderMutationOptions = Apollo.BaseMutationOptions<ConfirmBasketAsOrderMutation, ConfirmBasketAsOrderMutationVariables>;
export const ConfirmBasketAsQuoteDocument = gql`
    mutation confirmBasketAsQuote($input: Purchase_ConfirmQuoteRequest!) {
  confirmBasketAsQuote(input: $input) {
    isSuccess
    errorMessage
  }
}
    `;
export type ConfirmBasketAsQuoteMutationFn = Apollo.MutationFunction<ConfirmBasketAsQuoteMutation, ConfirmBasketAsQuoteMutationVariables>;

/**
 * __useConfirmBasketAsQuoteMutation__
 *
 * To run a mutation, you first call `useConfirmBasketAsQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmBasketAsQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmBasketAsQuoteMutation, { data, loading, error }] = useConfirmBasketAsQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmBasketAsQuoteMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmBasketAsQuoteMutation, ConfirmBasketAsQuoteMutationVariables>) {
        return Apollo.useMutation<ConfirmBasketAsQuoteMutation, ConfirmBasketAsQuoteMutationVariables>(ConfirmBasketAsQuoteDocument, baseOptions);
      }
export type ConfirmBasketAsQuoteMutationHookResult = ReturnType<typeof useConfirmBasketAsQuoteMutation>;
export type ConfirmBasketAsQuoteMutationResult = Apollo.MutationResult<ConfirmBasketAsQuoteMutation>;
export type ConfirmBasketAsQuoteMutationOptions = Apollo.BaseMutationOptions<ConfirmBasketAsQuoteMutation, ConfirmBasketAsQuoteMutationVariables>;
export const SavePersonalOrderReferenceDocument = gql`
    mutation savePersonalOrderReference($input: Purchase_SavePersonalOrderReferenceRequest!) {
  savePersonalOrderReference(input: $input) {
    isSuccess
    errorMessage
  }
}
    `;
export type SavePersonalOrderReferenceMutationFn = Apollo.MutationFunction<SavePersonalOrderReferenceMutation, SavePersonalOrderReferenceMutationVariables>;

/**
 * __useSavePersonalOrderReferenceMutation__
 *
 * To run a mutation, you first call `useSavePersonalOrderReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePersonalOrderReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePersonalOrderReferenceMutation, { data, loading, error }] = useSavePersonalOrderReferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSavePersonalOrderReferenceMutation(baseOptions?: Apollo.MutationHookOptions<SavePersonalOrderReferenceMutation, SavePersonalOrderReferenceMutationVariables>) {
        return Apollo.useMutation<SavePersonalOrderReferenceMutation, SavePersonalOrderReferenceMutationVariables>(SavePersonalOrderReferenceDocument, baseOptions);
      }
export type SavePersonalOrderReferenceMutationHookResult = ReturnType<typeof useSavePersonalOrderReferenceMutation>;
export type SavePersonalOrderReferenceMutationResult = Apollo.MutationResult<SavePersonalOrderReferenceMutation>;
export type SavePersonalOrderReferenceMutationOptions = Apollo.BaseMutationOptions<SavePersonalOrderReferenceMutation, SavePersonalOrderReferenceMutationVariables>;
export const DeleteAddressDocument = gql`
    mutation deleteAddress($input: DeleteAddressRequest!) {
  deleteAddress(input: $input) {
    isSuccess
    errorMessage
  }
}
    `;
export type DeleteAddressMutationFn = Apollo.MutationFunction<DeleteAddressMutation, DeleteAddressMutationVariables>;

/**
 * __useDeleteAddressMutation__
 *
 * To run a mutation, you first call `useDeleteAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAddressMutation, { data, loading, error }] = useDeleteAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAddressMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAddressMutation, DeleteAddressMutationVariables>) {
        return Apollo.useMutation<DeleteAddressMutation, DeleteAddressMutationVariables>(DeleteAddressDocument, baseOptions);
      }
export type DeleteAddressMutationHookResult = ReturnType<typeof useDeleteAddressMutation>;
export type DeleteAddressMutationResult = Apollo.MutationResult<DeleteAddressMutation>;
export type DeleteAddressMutationOptions = Apollo.BaseMutationOptions<DeleteAddressMutation, DeleteAddressMutationVariables>;
export const GetPaymentOptionsDocument = gql`
    query getPaymentOptions {
  purchasePage {
    paymentOptions {
      options {
        id
        name
        description
        isSelected
        optionType
        details {
          ... on PurchasePagePaymentOption_Account {
            accountNumberLabel
            soldToNumber
          }
          ... on PurchasePagePaymentOption_ControlledPurchasing {
            purchasers {
              id
              firstName
              lastName
              emailAddress
            }
          }
        }
        callbackUrl
        callbackMode
      }
    }
  }
}
    `;

/**
 * __useGetPaymentOptionsQuery__
 *
 * To run a query within a React component, call `useGetPaymentOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPaymentOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetPaymentOptionsQuery, GetPaymentOptionsQueryVariables>) {
        return Apollo.useQuery<GetPaymentOptionsQuery, GetPaymentOptionsQueryVariables>(GetPaymentOptionsDocument, baseOptions);
      }
export function useGetPaymentOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentOptionsQuery, GetPaymentOptionsQueryVariables>) {
          return Apollo.useLazyQuery<GetPaymentOptionsQuery, GetPaymentOptionsQueryVariables>(GetPaymentOptionsDocument, baseOptions);
        }
export type GetPaymentOptionsQueryHookResult = ReturnType<typeof useGetPaymentOptionsQuery>;
export type GetPaymentOptionsLazyQueryHookResult = ReturnType<typeof useGetPaymentOptionsLazyQuery>;
export type GetPaymentOptionsQueryResult = Apollo.QueryResult<GetPaymentOptionsQuery, GetPaymentOptionsQueryVariables>;
export const ValidateVatDetailsDocument = gql`
    query validateVATDetails($input: ValidateVATDetailsRequest!) {
  validateVATDetails(input: $input) {
    isSuccess
  }
}
    `;

/**
 * __useValidateVatDetailsQuery__
 *
 * To run a query within a React component, call `useValidateVatDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateVatDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateVatDetailsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateVatDetailsQuery(baseOptions: Apollo.QueryHookOptions<ValidateVatDetailsQuery, ValidateVatDetailsQueryVariables>) {
        return Apollo.useQuery<ValidateVatDetailsQuery, ValidateVatDetailsQueryVariables>(ValidateVatDetailsDocument, baseOptions);
      }
export function useValidateVatDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateVatDetailsQuery, ValidateVatDetailsQueryVariables>) {
          return Apollo.useLazyQuery<ValidateVatDetailsQuery, ValidateVatDetailsQueryVariables>(ValidateVatDetailsDocument, baseOptions);
        }
export type ValidateVatDetailsQueryHookResult = ReturnType<typeof useValidateVatDetailsQuery>;
export type ValidateVatDetailsLazyQueryHookResult = ReturnType<typeof useValidateVatDetailsLazyQuery>;
export type ValidateVatDetailsQueryResult = Apollo.QueryResult<ValidateVatDetailsQuery, ValidateVatDetailsQueryVariables>;
export const SearchAddressDocument = gql`
    query searchAddress($input: SearchAddressRequest!) {
  searchAddress(input: $input) {
    addresses {
      city
      postCode
      state
      stateCode
      gstRegistrationNumber
    }
  }
}
    `;

/**
 * __useSearchAddressQuery__
 *
 * To run a query within a React component, call `useSearchAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAddressQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchAddressQuery(baseOptions: Apollo.QueryHookOptions<SearchAddressQuery, SearchAddressQueryVariables>) {
        return Apollo.useQuery<SearchAddressQuery, SearchAddressQueryVariables>(SearchAddressDocument, baseOptions);
      }
export function useSearchAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchAddressQuery, SearchAddressQueryVariables>) {
          return Apollo.useLazyQuery<SearchAddressQuery, SearchAddressQueryVariables>(SearchAddressDocument, baseOptions);
        }
export type SearchAddressQueryHookResult = ReturnType<typeof useSearchAddressQuery>;
export type SearchAddressLazyQueryHookResult = ReturnType<typeof useSearchAddressLazyQuery>;
export type SearchAddressQueryResult = Apollo.QueryResult<SearchAddressQuery, SearchAddressQueryVariables>;
export const PurchasePageSummaryDocument = gql`
    query purchasePageSummary {
  purchasePage {
    summary {
      ...Summary
    }
  }
}
    ${SummaryFragmentDoc}`;

/**
 * __usePurchasePageSummaryQuery__
 *
 * To run a query within a React component, call `usePurchasePageSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchasePageSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchasePageSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function usePurchasePageSummaryQuery(baseOptions?: Apollo.QueryHookOptions<PurchasePageSummaryQuery, PurchasePageSummaryQueryVariables>) {
        return Apollo.useQuery<PurchasePageSummaryQuery, PurchasePageSummaryQueryVariables>(PurchasePageSummaryDocument, baseOptions);
      }
export function usePurchasePageSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PurchasePageSummaryQuery, PurchasePageSummaryQueryVariables>) {
          return Apollo.useLazyQuery<PurchasePageSummaryQuery, PurchasePageSummaryQueryVariables>(PurchasePageSummaryDocument, baseOptions);
        }
export type PurchasePageSummaryQueryHookResult = ReturnType<typeof usePurchasePageSummaryQuery>;
export type PurchasePageSummaryLazyQueryHookResult = ReturnType<typeof usePurchasePageSummaryLazyQuery>;
export type PurchasePageSummaryQueryResult = Apollo.QueryResult<PurchasePageSummaryQuery, PurchasePageSummaryQueryVariables>;
export const ShareProductsTransactionDocument = gql`
    mutation shareProductsTransaction($input: ShareProductsTransactionRequest!) {
  shareProductsTransaction(input: $input) {
    isSuccess
  }
}
    `;
export type ShareProductsTransactionMutationFn = Apollo.MutationFunction<ShareProductsTransactionMutation, ShareProductsTransactionMutationVariables>;

/**
 * __useShareProductsTransactionMutation__
 *
 * To run a mutation, you first call `useShareProductsTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareProductsTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareProductsTransactionMutation, { data, loading, error }] = useShareProductsTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShareProductsTransactionMutation(baseOptions?: Apollo.MutationHookOptions<ShareProductsTransactionMutation, ShareProductsTransactionMutationVariables>) {
        return Apollo.useMutation<ShareProductsTransactionMutation, ShareProductsTransactionMutationVariables>(ShareProductsTransactionDocument, baseOptions);
      }
export type ShareProductsTransactionMutationHookResult = ReturnType<typeof useShareProductsTransactionMutation>;
export type ShareProductsTransactionMutationResult = Apollo.MutationResult<ShareProductsTransactionMutation>;
export type ShareProductsTransactionMutationOptions = Apollo.BaseMutationOptions<ShareProductsTransactionMutation, ShareProductsTransactionMutationVariables>;
export const ValidateEmailDocument = gql`
    query validateEmail($input: ValidateEmailRequest!) {
  validateEmail(input: $input) {
    isSuccess
  }
}
    `;

/**
 * __useValidateEmailQuery__
 *
 * To run a query within a React component, call `useValidateEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateEmailQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateEmailQuery(baseOptions: Apollo.QueryHookOptions<ValidateEmailQuery, ValidateEmailQueryVariables>) {
        return Apollo.useQuery<ValidateEmailQuery, ValidateEmailQueryVariables>(ValidateEmailDocument, baseOptions);
      }
export function useValidateEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateEmailQuery, ValidateEmailQueryVariables>) {
          return Apollo.useLazyQuery<ValidateEmailQuery, ValidateEmailQueryVariables>(ValidateEmailDocument, baseOptions);
        }
export type ValidateEmailQueryHookResult = ReturnType<typeof useValidateEmailQuery>;
export type ValidateEmailLazyQueryHookResult = ReturnType<typeof useValidateEmailLazyQuery>;
export type ValidateEmailQueryResult = Apollo.QueryResult<ValidateEmailQuery, ValidateEmailQueryVariables>;
export const SaveCustomerDocument = gql`
    mutation saveCustomer($input: SaveCustomerRequest!) {
  saveCustomer(input: $input) {
    isSuccess
    fieldErrors
  }
}
    `;
export type SaveCustomerMutationFn = Apollo.MutationFunction<SaveCustomerMutation, SaveCustomerMutationVariables>;

/**
 * __useSaveCustomerMutation__
 *
 * To run a mutation, you first call `useSaveCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCustomerMutation, { data, loading, error }] = useSaveCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveCustomerMutation(baseOptions?: Apollo.MutationHookOptions<SaveCustomerMutation, SaveCustomerMutationVariables>) {
        return Apollo.useMutation<SaveCustomerMutation, SaveCustomerMutationVariables>(SaveCustomerDocument, baseOptions);
      }
export type SaveCustomerMutationHookResult = ReturnType<typeof useSaveCustomerMutation>;
export type SaveCustomerMutationResult = Apollo.MutationResult<SaveCustomerMutation>;
export type SaveCustomerMutationOptions = Apollo.BaseMutationOptions<SaveCustomerMutation, SaveCustomerMutationVariables>;
export const ResetPasswordTransactionDocument = gql`
    mutation resetPasswordTransaction($input: ResetPasswordTransactionRequest!) {
  resetPasswordTransaction(input: $input) {
    showUsername
  }
}
    `;
export type ResetPasswordTransactionMutationFn = Apollo.MutationFunction<ResetPasswordTransactionMutation, ResetPasswordTransactionMutationVariables>;

/**
 * __useResetPasswordTransactionMutation__
 *
 * To run a mutation, you first call `useResetPasswordTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordTransactionMutation, { data, loading, error }] = useResetPasswordTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordTransactionMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordTransactionMutation, ResetPasswordTransactionMutationVariables>) {
        return Apollo.useMutation<ResetPasswordTransactionMutation, ResetPasswordTransactionMutationVariables>(ResetPasswordTransactionDocument, baseOptions);
      }
export type ResetPasswordTransactionMutationHookResult = ReturnType<typeof useResetPasswordTransactionMutation>;
export type ResetPasswordTransactionMutationResult = Apollo.MutationResult<ResetPasswordTransactionMutation>;
export type ResetPasswordTransactionMutationOptions = Apollo.BaseMutationOptions<ResetPasswordTransactionMutation, ResetPasswordTransactionMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($input: ResetPasswordRequest!) {
  resetPassword(input: $input) {
    isSuccess
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const UpdateCustomerDocument = gql`
    mutation updateCustomer($input: UpdateCustomerRequest!) {
  updateCustomer(input: $input) {
    isSuccess
  }
}
    `;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, baseOptions);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const EmailSubscriptionTransactionDocument = gql`
    mutation emailSubscriptionTransaction($input: EmailSubscriptionTransactionRequest!) {
  emailSubscriptionTransaction(input: $input) {
    tagging {
      tags {
        ...HtmlTag
      }
    }
    labels {
      ...Label
    }
  }
}
    ${HtmlTagFragmentDoc}
${LabelFragmentDoc}`;
export type EmailSubscriptionTransactionMutationFn = Apollo.MutationFunction<EmailSubscriptionTransactionMutation, EmailSubscriptionTransactionMutationVariables>;

/**
 * __useEmailSubscriptionTransactionMutation__
 *
 * To run a mutation, you first call `useEmailSubscriptionTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailSubscriptionTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailSubscriptionTransactionMutation, { data, loading, error }] = useEmailSubscriptionTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmailSubscriptionTransactionMutation(baseOptions?: Apollo.MutationHookOptions<EmailSubscriptionTransactionMutation, EmailSubscriptionTransactionMutationVariables>) {
        return Apollo.useMutation<EmailSubscriptionTransactionMutation, EmailSubscriptionTransactionMutationVariables>(EmailSubscriptionTransactionDocument, baseOptions);
      }
export type EmailSubscriptionTransactionMutationHookResult = ReturnType<typeof useEmailSubscriptionTransactionMutation>;
export type EmailSubscriptionTransactionMutationResult = Apollo.MutationResult<EmailSubscriptionTransactionMutation>;
export type EmailSubscriptionTransactionMutationOptions = Apollo.BaseMutationOptions<EmailSubscriptionTransactionMutation, EmailSubscriptionTransactionMutationVariables>;
export const ForgotPasswordPageDocument = gql`
    query forgotPasswordPage {
  forgotPasswordPage {
    isSuccess
    tagging {
      tags {
        ...HtmlTag
      }
    }
    labels {
      ...Label
    }
  }
}
    ${HtmlTagFragmentDoc}
${LabelFragmentDoc}`;

/**
 * __useForgotPasswordPageQuery__
 *
 * To run a query within a React component, call `useForgotPasswordPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useForgotPasswordPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useForgotPasswordPageQuery(baseOptions?: Apollo.QueryHookOptions<ForgotPasswordPageQuery, ForgotPasswordPageQueryVariables>) {
        return Apollo.useQuery<ForgotPasswordPageQuery, ForgotPasswordPageQueryVariables>(ForgotPasswordPageDocument, baseOptions);
      }
export function useForgotPasswordPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ForgotPasswordPageQuery, ForgotPasswordPageQueryVariables>) {
          return Apollo.useLazyQuery<ForgotPasswordPageQuery, ForgotPasswordPageQueryVariables>(ForgotPasswordPageDocument, baseOptions);
        }
export type ForgotPasswordPageQueryHookResult = ReturnType<typeof useForgotPasswordPageQuery>;
export type ForgotPasswordPageLazyQueryHookResult = ReturnType<typeof useForgotPasswordPageLazyQuery>;
export type ForgotPasswordPageQueryResult = Apollo.QueryResult<ForgotPasswordPageQuery, ForgotPasswordPageQueryVariables>;
export const LoginPageDocument = gql`
    query loginPage {
  loginPage {
    isSuccess
    tagging {
      tags {
        ...HtmlTag
      }
    }
    labels {
      ...Label
    }
    isProfileEnabled
  }
}
    ${HtmlTagFragmentDoc}
${LabelFragmentDoc}`;

/**
 * __useLoginPageQuery__
 *
 * To run a query within a React component, call `useLoginPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoginPageQuery(baseOptions?: Apollo.QueryHookOptions<LoginPageQuery, LoginPageQueryVariables>) {
        return Apollo.useQuery<LoginPageQuery, LoginPageQueryVariables>(LoginPageDocument, baseOptions);
      }
export function useLoginPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoginPageQuery, LoginPageQueryVariables>) {
          return Apollo.useLazyQuery<LoginPageQuery, LoginPageQueryVariables>(LoginPageDocument, baseOptions);
        }
export type LoginPageQueryHookResult = ReturnType<typeof useLoginPageQuery>;
export type LoginPageLazyQueryHookResult = ReturnType<typeof useLoginPageLazyQuery>;
export type LoginPageQueryResult = Apollo.QueryResult<LoginPageQuery, LoginPageQueryVariables>;
export const LoginDocument = gql`
    mutation login($data: LoginRequest!) {
  login(data: $data) {
    isSuccess
    customerName
    basketChanged
    redirectUrl
    tags {
      ... on GA4Event_User_Login {
        event
        ecommerce {
          email
        }
      }
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const OktaTokenExchangeDocument = gql`
    mutation oktaTokenExchange($data: OktaTokenExchangeRequest!) {
  oktaTokenExchange(data: $data) {
    isSuccess
    customerName
    basketChanged
  }
}
    `;
export type OktaTokenExchangeMutationFn = Apollo.MutationFunction<OktaTokenExchangeMutation, OktaTokenExchangeMutationVariables>;

/**
 * __useOktaTokenExchangeMutation__
 *
 * To run a mutation, you first call `useOktaTokenExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOktaTokenExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [oktaTokenExchangeMutation, { data, loading, error }] = useOktaTokenExchangeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useOktaTokenExchangeMutation(baseOptions?: Apollo.MutationHookOptions<OktaTokenExchangeMutation, OktaTokenExchangeMutationVariables>) {
        return Apollo.useMutation<OktaTokenExchangeMutation, OktaTokenExchangeMutationVariables>(OktaTokenExchangeDocument, baseOptions);
      }
export type OktaTokenExchangeMutationHookResult = ReturnType<typeof useOktaTokenExchangeMutation>;
export type OktaTokenExchangeMutationResult = Apollo.MutationResult<OktaTokenExchangeMutation>;
export type OktaTokenExchangeMutationOptions = Apollo.BaseMutationOptions<OktaTokenExchangeMutation, OktaTokenExchangeMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout {
    isSuccess
  }
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const OrderDetailsPageDocument = gql`
    query orderDetailsPage($orderId: Float!) {
  orderDetailsPage(orderId: $orderId) {
    isSuccess
    labels {
      name
      text
    }
    tagging {
      tags {
        ...HtmlTag
      }
    }
    metaData {
      pageTitle
      metaTags {
        attributes {
          ...HtmlTagAttribute
        }
      }
    }
    header {
      siteName
      siteAddress
    }
    details {
      orderReference
      orderReferencePersonal
      dateOrdered
      contactDetails {
        firstName
        lastName
        email
        phoneNo
        taxRegistrationNumber
      }
      deliveryAddress {
        formattedMultiLine
      }
      billingAddress {
        formattedMultiLine
        details {
          gstCustomerType
          gstRegistrationType
          gstRegistrationNumber
          panCardNumber
        }
      }
      isDeliveryAddressSameAsBillingAddress
      paymentOption {
        title
        optionType
        details {
          ... on OrderDetailsPage__PaymentOption_ControlledPurchasingDetails {
            controlledPurchasingDetails {
              purchaser {
                emailAddress
              }
            }
          }
        }
      }
      parcels {
        id
        product {
          title {
            brand
            formattedStockCode
            hsnCode
            mpn
            stockCode
            title
            primaryImageUrl
            pdpUrl
            partNumber
            impaCode
          }
          priceArea {
            isInBasket
            container
            minimumOrderQuantity
            unitPrice {
              priceBefore {
                ...FormattedNumber
              }
              priceAfter {
                ...FormattedNumber
              }
            }
            totalPrice {
              priceBefore {
                ...FormattedNumber
              }
              priceAfter {
                ...FormattedNumber
              }
            }
            unitOfMeasure
            taxType
          }
          energyArea {
            energyLabel {
              ...ProductEnergyLabel
            }
          }
        }
        quantity
      }
      summary {
        ...Summary
      }
    }
    config {
      topicIdentifier
    }
  }
}
    ${HtmlTagFragmentDoc}
${HtmlTagAttributeFragmentDoc}
${FormattedNumberFragmentDoc}
${ProductEnergyLabelFragmentDoc}
${SummaryFragmentDoc}`;

/**
 * __useOrderDetailsPageQuery__
 *
 * To run a query within a React component, call `useOrderDetailsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderDetailsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderDetailsPageQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderDetailsPageQuery(baseOptions: Apollo.QueryHookOptions<OrderDetailsPageQuery, OrderDetailsPageQueryVariables>) {
        return Apollo.useQuery<OrderDetailsPageQuery, OrderDetailsPageQueryVariables>(OrderDetailsPageDocument, baseOptions);
      }
export function useOrderDetailsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderDetailsPageQuery, OrderDetailsPageQueryVariables>) {
          return Apollo.useLazyQuery<OrderDetailsPageQuery, OrderDetailsPageQueryVariables>(OrderDetailsPageDocument, baseOptions);
        }
export type OrderDetailsPageQueryHookResult = ReturnType<typeof useOrderDetailsPageQuery>;
export type OrderDetailsPageLazyQueryHookResult = ReturnType<typeof useOrderDetailsPageLazyQuery>;
export type OrderDetailsPageQueryResult = Apollo.QueryResult<OrderDetailsPageQuery, OrderDetailsPageQueryVariables>;
export const OrderHistoryPageDocument = gql`
    query orderHistoryPage($searchQuery: String, $sort: OrderSort, $page: OrderPage) {
  orderHistoryPage(searchQuery: $searchQuery, sort: $sort, page: $page) {
    isSuccess
    labels {
      name
      text
    }
    tagging {
      tags {
        ...HtmlTag
      }
    }
    metaData {
      pageTitle
      metaTags {
        attributes {
          ...HtmlTagAttribute
        }
      }
    }
    orderHistoryArea {
      noOfOrders
      orders {
        id
        orderReference
        orderReferencePersonal
        dateOrdered
        total {
          displayValue
        }
        products {
          stockCode
          title
          imageUrl
          pdpUrl
          mpn
          customerPartNumber
        }
        isControlledPurchasing
      }
    }
  }
}
    ${HtmlTagFragmentDoc}
${HtmlTagAttributeFragmentDoc}`;

/**
 * __useOrderHistoryPageQuery__
 *
 * To run a query within a React component, call `useOrderHistoryPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderHistoryPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderHistoryPageQuery({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *      sort: // value for 'sort'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useOrderHistoryPageQuery(baseOptions?: Apollo.QueryHookOptions<OrderHistoryPageQuery, OrderHistoryPageQueryVariables>) {
        return Apollo.useQuery<OrderHistoryPageQuery, OrderHistoryPageQueryVariables>(OrderHistoryPageDocument, baseOptions);
      }
export function useOrderHistoryPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderHistoryPageQuery, OrderHistoryPageQueryVariables>) {
          return Apollo.useLazyQuery<OrderHistoryPageQuery, OrderHistoryPageQueryVariables>(OrderHistoryPageDocument, baseOptions);
        }
export type OrderHistoryPageQueryHookResult = ReturnType<typeof useOrderHistoryPageQuery>;
export type OrderHistoryPageLazyQueryHookResult = ReturnType<typeof useOrderHistoryPageLazyQuery>;
export type OrderHistoryPageQueryResult = Apollo.QueryResult<OrderHistoryPageQuery, OrderHistoryPageQueryVariables>;
export const AddRecentlyViewedDocument = gql`
    mutation addRecentlyViewed($stockCode: String!, $brand: String!, $familyId: Float!) {
  addRecentlyViewed(
    input: {stockcode: $stockCode, brand: $brand, familyId: $familyId}
  ) {
    isSuccess
  }
}
    `;
export type AddRecentlyViewedMutationFn = Apollo.MutationFunction<AddRecentlyViewedMutation, AddRecentlyViewedMutationVariables>;

/**
 * __useAddRecentlyViewedMutation__
 *
 * To run a mutation, you first call `useAddRecentlyViewedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRecentlyViewedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRecentlyViewedMutation, { data, loading, error }] = useAddRecentlyViewedMutation({
 *   variables: {
 *      stockCode: // value for 'stockCode'
 *      brand: // value for 'brand'
 *      familyId: // value for 'familyId'
 *   },
 * });
 */
export function useAddRecentlyViewedMutation(baseOptions?: Apollo.MutationHookOptions<AddRecentlyViewedMutation, AddRecentlyViewedMutationVariables>) {
        return Apollo.useMutation<AddRecentlyViewedMutation, AddRecentlyViewedMutationVariables>(AddRecentlyViewedDocument, baseOptions);
      }
export type AddRecentlyViewedMutationHookResult = ReturnType<typeof useAddRecentlyViewedMutation>;
export type AddRecentlyViewedMutationResult = Apollo.MutationResult<AddRecentlyViewedMutation>;
export type AddRecentlyViewedMutationOptions = Apollo.BaseMutationOptions<AddRecentlyViewedMutation, AddRecentlyViewedMutationVariables>;
export const AccountOverviewPageDocument = gql`
    query accountOverviewPage {
  accountOverviewPage {
    isSuccess
    tagging {
      tags {
        ...HtmlTag
      }
    }
    metaData {
      pageTitle
      metaTags {
        attributes {
          ...HtmlTagAttribute
        }
      }
    }
    labels {
      name
      text
    }
    ordersArea {
      orderLines {
        product {
          ...accountOverviewProduct
        }
      }
    }
    partsListArea {
      partslistLines {
        product {
          ...accountOverviewProduct
        }
      }
    }
    discoverMoreArea {
      industryBanner {
        link
        title
        eventName
      }
    }
    controlledPurchasingArea {
      company
      assignedUsers
      assignedPurchasers
    }
    config {
      topicIdentifier
    }
  }
}
    ${HtmlTagFragmentDoc}
${HtmlTagAttributeFragmentDoc}
${AccountOverviewProductFragmentDoc}`;

/**
 * __useAccountOverviewPageQuery__
 *
 * To run a query within a React component, call `useAccountOverviewPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountOverviewPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountOverviewPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountOverviewPageQuery(baseOptions?: Apollo.QueryHookOptions<AccountOverviewPageQuery, AccountOverviewPageQueryVariables>) {
        return Apollo.useQuery<AccountOverviewPageQuery, AccountOverviewPageQueryVariables>(AccountOverviewPageDocument, baseOptions);
      }
export function useAccountOverviewPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountOverviewPageQuery, AccountOverviewPageQueryVariables>) {
          return Apollo.useLazyQuery<AccountOverviewPageQuery, AccountOverviewPageQueryVariables>(AccountOverviewPageDocument, baseOptions);
        }
export type AccountOverviewPageQueryHookResult = ReturnType<typeof useAccountOverviewPageQuery>;
export type AccountOverviewPageLazyQueryHookResult = ReturnType<typeof useAccountOverviewPageLazyQuery>;
export type AccountOverviewPageQueryResult = Apollo.QueryResult<AccountOverviewPageQuery, AccountOverviewPageQueryVariables>;
export const AccountPageDocument = gql`
    query accountPage {
  account {
    isSuccess
    labels {
      ...Label
    }
    customerName
  }
}
    ${LabelFragmentDoc}`;

/**
 * __useAccountPageQuery__
 *
 * To run a query within a React component, call `useAccountPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountPageQuery(baseOptions?: Apollo.QueryHookOptions<AccountPageQuery, AccountPageQueryVariables>) {
        return Apollo.useQuery<AccountPageQuery, AccountPageQueryVariables>(AccountPageDocument, baseOptions);
      }
export function useAccountPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountPageQuery, AccountPageQueryVariables>) {
          return Apollo.useLazyQuery<AccountPageQuery, AccountPageQueryVariables>(AccountPageDocument, baseOptions);
        }
export type AccountPageQueryHookResult = ReturnType<typeof useAccountPageQuery>;
export type AccountPageLazyQueryHookResult = ReturnType<typeof useAccountPageLazyQuery>;
export type AccountPageQueryResult = Apollo.QueryResult<AccountPageQuery, AccountPageQueryVariables>;
export const MyAccountPartsListDetailsPageDocument = gql`
    query myAccountPartsListDetailsPage($partsListId: Float!) {
  myAccountPartsListDetailsPage(partsListId: $partsListId) {
    isSuccess
    redirectUrl
    labels {
      name
      text
    }
    tagging {
      tags {
        ...HtmlTag
      }
    }
    metaData {
      pageTitle
      metaTags {
        attributes {
          ...HtmlTagAttribute
        }
      }
    }
    config {
      topicIdentifier
    }
    partsLists {
      partsLists {
        id
        name
        selected
      }
    }
    details {
      ...PartsListDetails
    }
  }
}
    ${HtmlTagFragmentDoc}
${HtmlTagAttributeFragmentDoc}
${PartsListDetailsFragmentDoc}`;

/**
 * __useMyAccountPartsListDetailsPageQuery__
 *
 * To run a query within a React component, call `useMyAccountPartsListDetailsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAccountPartsListDetailsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAccountPartsListDetailsPageQuery({
 *   variables: {
 *      partsListId: // value for 'partsListId'
 *   },
 * });
 */
export function useMyAccountPartsListDetailsPageQuery(baseOptions: Apollo.QueryHookOptions<MyAccountPartsListDetailsPageQuery, MyAccountPartsListDetailsPageQueryVariables>) {
        return Apollo.useQuery<MyAccountPartsListDetailsPageQuery, MyAccountPartsListDetailsPageQueryVariables>(MyAccountPartsListDetailsPageDocument, baseOptions);
      }
export function useMyAccountPartsListDetailsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyAccountPartsListDetailsPageQuery, MyAccountPartsListDetailsPageQueryVariables>) {
          return Apollo.useLazyQuery<MyAccountPartsListDetailsPageQuery, MyAccountPartsListDetailsPageQueryVariables>(MyAccountPartsListDetailsPageDocument, baseOptions);
        }
export type MyAccountPartsListDetailsPageQueryHookResult = ReturnType<typeof useMyAccountPartsListDetailsPageQuery>;
export type MyAccountPartsListDetailsPageLazyQueryHookResult = ReturnType<typeof useMyAccountPartsListDetailsPageLazyQuery>;
export type MyAccountPartsListDetailsPageQueryResult = Apollo.QueryResult<MyAccountPartsListDetailsPageQuery, MyAccountPartsListDetailsPageQueryVariables>;
export const MyAccountPartsListDetailsProductsDocument = gql`
    query myAccountPartsListDetailsProducts($partsListId: Float!) {
  myAccountPartsListDetailsPage(partsListId: $partsListId) {
    isSuccess
    details {
      ...PartsListDetails
    }
  }
}
    ${PartsListDetailsFragmentDoc}`;

/**
 * __useMyAccountPartsListDetailsProductsQuery__
 *
 * To run a query within a React component, call `useMyAccountPartsListDetailsProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAccountPartsListDetailsProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAccountPartsListDetailsProductsQuery({
 *   variables: {
 *      partsListId: // value for 'partsListId'
 *   },
 * });
 */
export function useMyAccountPartsListDetailsProductsQuery(baseOptions: Apollo.QueryHookOptions<MyAccountPartsListDetailsProductsQuery, MyAccountPartsListDetailsProductsQueryVariables>) {
        return Apollo.useQuery<MyAccountPartsListDetailsProductsQuery, MyAccountPartsListDetailsProductsQueryVariables>(MyAccountPartsListDetailsProductsDocument, baseOptions);
      }
export function useMyAccountPartsListDetailsProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyAccountPartsListDetailsProductsQuery, MyAccountPartsListDetailsProductsQueryVariables>) {
          return Apollo.useLazyQuery<MyAccountPartsListDetailsProductsQuery, MyAccountPartsListDetailsProductsQueryVariables>(MyAccountPartsListDetailsProductsDocument, baseOptions);
        }
export type MyAccountPartsListDetailsProductsQueryHookResult = ReturnType<typeof useMyAccountPartsListDetailsProductsQuery>;
export type MyAccountPartsListDetailsProductsLazyQueryHookResult = ReturnType<typeof useMyAccountPartsListDetailsProductsLazyQuery>;
export type MyAccountPartsListDetailsProductsQueryResult = Apollo.QueryResult<MyAccountPartsListDetailsProductsQuery, MyAccountPartsListDetailsProductsQueryVariables>;
export const ManualAddToPartsListDocument = gql`
    mutation manualAddToPartsList($data: ManualAddToPartsListRequest!) {
  manualAddToPartsList(data: $data) {
    isSuccess
    partsListId
    requireLogin
    redirectUrl
    errorMessage
    result {
      isSuccess
      title
      message
      productsNotFoundSection {
        message
        lineInfo {
          stockCode
          quantity
          link {
            ...Link
          }
          message
        }
      }
    }
  }
}
    ${LinkFragmentDoc}`;
export type ManualAddToPartsListMutationFn = Apollo.MutationFunction<ManualAddToPartsListMutation, ManualAddToPartsListMutationVariables>;

/**
 * __useManualAddToPartsListMutation__
 *
 * To run a mutation, you first call `useManualAddToPartsListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManualAddToPartsListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manualAddToPartsListMutation, { data, loading, error }] = useManualAddToPartsListMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useManualAddToPartsListMutation(baseOptions?: Apollo.MutationHookOptions<ManualAddToPartsListMutation, ManualAddToPartsListMutationVariables>) {
        return Apollo.useMutation<ManualAddToPartsListMutation, ManualAddToPartsListMutationVariables>(ManualAddToPartsListDocument, baseOptions);
      }
export type ManualAddToPartsListMutationHookResult = ReturnType<typeof useManualAddToPartsListMutation>;
export type ManualAddToPartsListMutationResult = Apollo.MutationResult<ManualAddToPartsListMutation>;
export type ManualAddToPartsListMutationOptions = Apollo.BaseMutationOptions<ManualAddToPartsListMutation, ManualAddToPartsListMutationVariables>;
export const DeletePartsListLinesDocument = gql`
    mutation deletePartsListLines($input: DeletePartsListLinesRequest!) {
  deletePartsListLines(input: $input) {
    isSuccess
    errorMessage
  }
}
    `;
export type DeletePartsListLinesMutationFn = Apollo.MutationFunction<DeletePartsListLinesMutation, DeletePartsListLinesMutationVariables>;

/**
 * __useDeletePartsListLinesMutation__
 *
 * To run a mutation, you first call `useDeletePartsListLinesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePartsListLinesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePartsListLinesMutation, { data, loading, error }] = useDeletePartsListLinesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePartsListLinesMutation(baseOptions?: Apollo.MutationHookOptions<DeletePartsListLinesMutation, DeletePartsListLinesMutationVariables>) {
        return Apollo.useMutation<DeletePartsListLinesMutation, DeletePartsListLinesMutationVariables>(DeletePartsListLinesDocument, baseOptions);
      }
export type DeletePartsListLinesMutationHookResult = ReturnType<typeof useDeletePartsListLinesMutation>;
export type DeletePartsListLinesMutationResult = Apollo.MutationResult<DeletePartsListLinesMutation>;
export type DeletePartsListLinesMutationOptions = Apollo.BaseMutationOptions<DeletePartsListLinesMutation, DeletePartsListLinesMutationVariables>;
export const MyAccountPartsListsPageDocument = gql`
    query myAccountPartsListsPage($searchQuery: String, $sort: PartsListsSort, $page: PartsListsPage) {
  myAccountPartsListsPage(searchQuery: $searchQuery, sort: $sort, page: $page) {
    isSuccess
    labels {
      name
      text
    }
    tagging {
      tags {
        ...HtmlTag
      }
    }
    metaData {
      pageTitle
      metaTags {
        attributes {
          ...HtmlTagAttribute
        }
      }
    }
    config {
      topicIdentifier
    }
    partsListsArea {
      noOfPartsLists
      partsLists {
        id
        name
        noOfProducts
        description
        lastModified
        totalPrice {
          priceAfter {
            displayValue
          }
        }
        products {
          stockCode
          title
          pdpUrl
          imageUrl
          qty
          isInvalid
        }
      }
    }
  }
}
    ${HtmlTagFragmentDoc}
${HtmlTagAttributeFragmentDoc}`;

/**
 * __useMyAccountPartsListsPageQuery__
 *
 * To run a query within a React component, call `useMyAccountPartsListsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAccountPartsListsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAccountPartsListsPageQuery({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *      sort: // value for 'sort'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useMyAccountPartsListsPageQuery(baseOptions?: Apollo.QueryHookOptions<MyAccountPartsListsPageQuery, MyAccountPartsListsPageQueryVariables>) {
        return Apollo.useQuery<MyAccountPartsListsPageQuery, MyAccountPartsListsPageQueryVariables>(MyAccountPartsListsPageDocument, baseOptions);
      }
export function useMyAccountPartsListsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyAccountPartsListsPageQuery, MyAccountPartsListsPageQueryVariables>) {
          return Apollo.useLazyQuery<MyAccountPartsListsPageQuery, MyAccountPartsListsPageQueryVariables>(MyAccountPartsListsPageDocument, baseOptions);
        }
export type MyAccountPartsListsPageQueryHookResult = ReturnType<typeof useMyAccountPartsListsPageQuery>;
export type MyAccountPartsListsPageLazyQueryHookResult = ReturnType<typeof useMyAccountPartsListsPageLazyQuery>;
export type MyAccountPartsListsPageQueryResult = Apollo.QueryResult<MyAccountPartsListsPageQuery, MyAccountPartsListsPageQueryVariables>;
export const DeletePartsListDocument = gql`
    mutation deletePartsList($input: DeletePartsListRequest!) {
  deletePartsList(input: $input) {
    isSuccess
    errorMessage
  }
}
    `;
export type DeletePartsListMutationFn = Apollo.MutationFunction<DeletePartsListMutation, DeletePartsListMutationVariables>;

/**
 * __useDeletePartsListMutation__
 *
 * To run a mutation, you first call `useDeletePartsListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePartsListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePartsListMutation, { data, loading, error }] = useDeletePartsListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePartsListMutation(baseOptions?: Apollo.MutationHookOptions<DeletePartsListMutation, DeletePartsListMutationVariables>) {
        return Apollo.useMutation<DeletePartsListMutation, DeletePartsListMutationVariables>(DeletePartsListDocument, baseOptions);
      }
export type DeletePartsListMutationHookResult = ReturnType<typeof useDeletePartsListMutation>;
export type DeletePartsListMutationResult = Apollo.MutationResult<DeletePartsListMutation>;
export type DeletePartsListMutationOptions = Apollo.BaseMutationOptions<DeletePartsListMutation, DeletePartsListMutationVariables>;
export const EditPartsListDocument = gql`
    mutation editPartsList($data: EditPartsListRequest!) {
  editPartsList(data: $data) {
    isSuccess
  }
}
    `;
export type EditPartsListMutationFn = Apollo.MutationFunction<EditPartsListMutation, EditPartsListMutationVariables>;

/**
 * __useEditPartsListMutation__
 *
 * To run a mutation, you first call `useEditPartsListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPartsListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPartsListMutation, { data, loading, error }] = useEditPartsListMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditPartsListMutation(baseOptions?: Apollo.MutationHookOptions<EditPartsListMutation, EditPartsListMutationVariables>) {
        return Apollo.useMutation<EditPartsListMutation, EditPartsListMutationVariables>(EditPartsListDocument, baseOptions);
      }
export type EditPartsListMutationHookResult = ReturnType<typeof useEditPartsListMutation>;
export type EditPartsListMutationResult = Apollo.MutationResult<EditPartsListMutation>;
export type EditPartsListMutationOptions = Apollo.BaseMutationOptions<EditPartsListMutation, EditPartsListMutationVariables>;
export const ProfilePageDocument = gql`
    query profilePage {
  profilePage {
    isSuccess
    tagging {
      tags {
        ...HtmlTag
      }
    }
    metaData {
      pageTitle
      metaTags {
        attributes {
          ...HtmlTagAttribute
        }
      }
    }
    labels {
      ...Label
    }
    industries {
      id
      value
    }
    customer {
      id
      name
      surname
      email
      industry
      purchasingFor
      mobile
      subscribed
    }
  }
}
    ${HtmlTagFragmentDoc}
${HtmlTagAttributeFragmentDoc}
${LabelFragmentDoc}`;

/**
 * __useProfilePageQuery__
 *
 * To run a query within a React component, call `useProfilePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfilePageQuery(baseOptions?: Apollo.QueryHookOptions<ProfilePageQuery, ProfilePageQueryVariables>) {
        return Apollo.useQuery<ProfilePageQuery, ProfilePageQueryVariables>(ProfilePageDocument, baseOptions);
      }
export function useProfilePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfilePageQuery, ProfilePageQueryVariables>) {
          return Apollo.useLazyQuery<ProfilePageQuery, ProfilePageQueryVariables>(ProfilePageDocument, baseOptions);
        }
export type ProfilePageQueryHookResult = ReturnType<typeof useProfilePageQuery>;
export type ProfilePageLazyQueryHookResult = ReturnType<typeof useProfilePageLazyQuery>;
export type ProfilePageQueryResult = Apollo.QueryResult<ProfilePageQuery, ProfilePageQueryVariables>;
export const RecentlyViewedDocument = gql`
    query recentlyViewed {
  recentlyViewed {
    labels {
      name
      text
    }
    recentlyViewedArea {
      products {
        stockCode
        imageUrl
        pdpUrl
        title
        price {
          priceAfter {
            displayValue
          }
        }
        isInBasket
      }
      categories {
        ...recentlyViewedItem
      }
      brands {
        ...recentlyViewedItem
      }
    }
  }
}
    ${RecentlyViewedItemFragmentDoc}`;

/**
 * __useRecentlyViewedQuery__
 *
 * To run a query within a React component, call `useRecentlyViewedQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentlyViewedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentlyViewedQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecentlyViewedQuery(baseOptions?: Apollo.QueryHookOptions<RecentlyViewedQuery, RecentlyViewedQueryVariables>) {
        return Apollo.useQuery<RecentlyViewedQuery, RecentlyViewedQueryVariables>(RecentlyViewedDocument, baseOptions);
      }
export function useRecentlyViewedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecentlyViewedQuery, RecentlyViewedQueryVariables>) {
          return Apollo.useLazyQuery<RecentlyViewedQuery, RecentlyViewedQueryVariables>(RecentlyViewedDocument, baseOptions);
        }
export type RecentlyViewedQueryHookResult = ReturnType<typeof useRecentlyViewedQuery>;
export type RecentlyViewedLazyQueryHookResult = ReturnType<typeof useRecentlyViewedLazyQuery>;
export type RecentlyViewedQueryResult = Apollo.QueryResult<RecentlyViewedQuery, RecentlyViewedQueryVariables>;
export const RegistrationPageDocument = gql`
    query registrationPage($token: String) {
  registrationPage(token: $token) {
    isSuccess
    tagging {
      tags {
        ...HtmlTag
      }
    }
    labels {
      ...Label
    }
    industries {
      id
      value
    }
  }
}
    ${HtmlTagFragmentDoc}
${LabelFragmentDoc}`;

/**
 * __useRegistrationPageQuery__
 *
 * To run a query within a React component, call `useRegistrationPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationPageQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRegistrationPageQuery(baseOptions?: Apollo.QueryHookOptions<RegistrationPageQuery, RegistrationPageQueryVariables>) {
        return Apollo.useQuery<RegistrationPageQuery, RegistrationPageQueryVariables>(RegistrationPageDocument, baseOptions);
      }
export function useRegistrationPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegistrationPageQuery, RegistrationPageQueryVariables>) {
          return Apollo.useLazyQuery<RegistrationPageQuery, RegistrationPageQueryVariables>(RegistrationPageDocument, baseOptions);
        }
export type RegistrationPageQueryHookResult = ReturnType<typeof useRegistrationPageQuery>;
export type RegistrationPageLazyQueryHookResult = ReturnType<typeof useRegistrationPageLazyQuery>;
export type RegistrationPageQueryResult = Apollo.QueryResult<RegistrationPageQuery, RegistrationPageQueryVariables>;
export const RegistrationTokenExpiredPageDocument = gql`
    query registrationTokenExpiredPage {
  registrationTokenExpiredPage {
    isSuccess
    redirectUrl
    labels {
      ...Label
    }
  }
}
    ${LabelFragmentDoc}`;

/**
 * __useRegistrationTokenExpiredPageQuery__
 *
 * To run a query within a React component, call `useRegistrationTokenExpiredPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationTokenExpiredPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationTokenExpiredPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegistrationTokenExpiredPageQuery(baseOptions?: Apollo.QueryHookOptions<RegistrationTokenExpiredPageQuery, RegistrationTokenExpiredPageQueryVariables>) {
        return Apollo.useQuery<RegistrationTokenExpiredPageQuery, RegistrationTokenExpiredPageQueryVariables>(RegistrationTokenExpiredPageDocument, baseOptions);
      }
export function useRegistrationTokenExpiredPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegistrationTokenExpiredPageQuery, RegistrationTokenExpiredPageQueryVariables>) {
          return Apollo.useLazyQuery<RegistrationTokenExpiredPageQuery, RegistrationTokenExpiredPageQueryVariables>(RegistrationTokenExpiredPageDocument, baseOptions);
        }
export type RegistrationTokenExpiredPageQueryHookResult = ReturnType<typeof useRegistrationTokenExpiredPageQuery>;
export type RegistrationTokenExpiredPageLazyQueryHookResult = ReturnType<typeof useRegistrationTokenExpiredPageLazyQuery>;
export type RegistrationTokenExpiredPageQueryResult = Apollo.QueryResult<RegistrationTokenExpiredPageQuery, RegistrationTokenExpiredPageQueryVariables>;
export const ResetPasswordPageDocument = gql`
    query resetPasswordPage($token: String!) {
  resetPasswordPage(token: $token) {
    isSuccess
    tagging {
      tags {
        ...HtmlTag
      }
    }
    metaData {
      pageTitle
      metaTags {
        attributes {
          ...HtmlTagAttribute
        }
      }
    }
    labels {
      ...Label
    }
  }
}
    ${HtmlTagFragmentDoc}
${HtmlTagAttributeFragmentDoc}
${LabelFragmentDoc}`;

/**
 * __useResetPasswordPageQuery__
 *
 * To run a query within a React component, call `useResetPasswordPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResetPasswordPageQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordPageQuery(baseOptions: Apollo.QueryHookOptions<ResetPasswordPageQuery, ResetPasswordPageQueryVariables>) {
        return Apollo.useQuery<ResetPasswordPageQuery, ResetPasswordPageQueryVariables>(ResetPasswordPageDocument, baseOptions);
      }
export function useResetPasswordPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResetPasswordPageQuery, ResetPasswordPageQueryVariables>) {
          return Apollo.useLazyQuery<ResetPasswordPageQuery, ResetPasswordPageQueryVariables>(ResetPasswordPageDocument, baseOptions);
        }
export type ResetPasswordPageQueryHookResult = ReturnType<typeof useResetPasswordPageQuery>;
export type ResetPasswordPageLazyQueryHookResult = ReturnType<typeof useResetPasswordPageLazyQuery>;
export type ResetPasswordPageQueryResult = Apollo.QueryResult<ResetPasswordPageQuery, ResetPasswordPageQueryVariables>;